<template>
  <el-container style="height: 100%">
    <el-row style="width: 100%">
      <el-header
        v-if="renderHeaderMenu"
        class="contaer-all-header"
      >
        <div class="container-header">
          <div style="display: flex; align-items: center">
            <el-row
              type="flex"
              justify="center"
              align="center"
            >
              <el-col>
                <el-image
                  class="logo-image"
                  :src="logoImg"
                />
              </el-col>
            </el-row>
            <div class="container-right-logo">
              <p class="p-sistema">
                Sistema
              </p>
              <p class="p-gerencial">
                Gerencial
              </p>
            </div>
          </div>
          <el-row
            type="flex"
            justify="start"
            align="center"
            style="flex: 1; margin-left: 103px"
          >
            <router-link
              v-for="item in menu"
              v-show="verifyPermission(item.permissions, permissionsLoggedUser)"
              :key="item.title"
              :to="
                verifyPermission(item.permissions, permissionsLoggedUser)
                  ? item.path
                  : '#'
              "
              active-class="active"
              :class="
                includesCurrentRouter(item.title)
                  ? 'container-item-menu active'
                  : 'container-item-menu'
              "
            >
              <p>{{ item.title }}</p>
            </router-link>
          </el-row>

          <el-row
            type="flex"
            align="center"
          >
            <el-dropdown
              trigger="click"
              class="dropdown-content"
              @command="handleCommandDropDown"
            >
              <el-avatar :src="menuIcon" />
              <div class="name-next-to-avatar">
                <p>{{ userLogged.displayName }}</p>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>{{ userLogged.displayName }}</el-dropdown-item>
                <el-dropdown-item command="logout">
                  <el-row
                    type="flex"
                    align="center"
                  >
                    <el-col class="container-dropdown-item">
                      <img
                        :src="logoutIcon"
                        height="15"
                        width="15"
                      >
                    </el-col>
                    <el-col class="container-dropdown-item">
                      Sair
                    </el-col>
                  </el-row>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-row>
        </div>
      </el-header>
      <el-container :class="leftMenu ? 'container-row' : 'container-column'">
        <menu-column
          v-if="leftMenu && renderHeaderMenu"
          :menu-render="menuRender"
        />
        <el-main :class="leftMenu ? 'container-main' : 'container-main-no-submenu'">
          <slot />
        </el-main>
      </el-container>
    </el-row>
  </el-container>
</template>

<script>
import MenuColumn from '../MenuColumn/MenuColumn.vue';

import { submenuRateio, submenuSettings, submenuClosure } from '../MenuColumn/submenus';
import MENU from './menu';
import verifyPermission from '../../utils/verifyPermission';
import { getLoggedUserStorage, removeLoggedUserStorage } from '../../storage/modules/auth';

const logoImg = require('../../../public/media/logos/logo1.svg');
const defaultMenuIcon = require('../../../public/media/icons/menu_icon.svg');
const logoutIcon = require('../../../public/media/icons/logout.svg');

export default {
  name: 'MenuApplication',
  components: { MenuColumn },
  data() {
    return {
      userLogged: undefined,
      permissionsLoggedUser: [],
      logoImg,
      menuIcon: '',
      menuRender: [],
      menu: MENU.menu,
      defaultMenuIcon,
      logoutIcon,
    };
  },
  computed: {
    renderHeaderMenu() {
      if (this.$route.name === 'Login' || this.$route.name === 'Validacao') {
        return false;
      }
      return true;
    },
    onRoutes() {
      const activePath = this.$route.path;
      return activePath;
    },
  },
  watch: {
    $route(to) {
      this.checkMenuColumn(to.path);
    },
  },
  beforeMount() {
    this.checkMenuColumn(this.$route.path);
  },
  created() {
    const { userLogged: localUserLogged } = getLoggedUserStorage();
    this.userLogged = localUserLogged;
    if (this.userLogged) {
      this.permissionsLoggedUser = localUserLogged?.functionalityIds || [];
      this.menuIcon = localUserLogged?.photos[0]?.value || this.defaultMenuIcon;
    }
  },
  methods: {
    verifyPermission,
    logout() {
      this.$api().get('/auth/logout');

      removeLoggedUserStorage();
      this.$router.replace({ name: 'Login' });
    },
    includesCurrentRouter(menuTitle) {
      if (menuTitle === MENU.menuTitlesConsts.RATEIO) {
        if (submenuRateio.allPaths.includes(this.$route.path)) {
          this.menuRender = submenuRateio.items;
          this.leftMenu = true;
          return true;
        }
        return false;
      }
      if (menuTitle === MENU.menuTitlesConsts.CONFIGURACOES) {
        if (submenuSettings.allPaths.includes(this.$route.path)) {
          this.menuRender = submenuSettings.items;
          this.leftMenu = true;
          return true;
        }
        return false;
      }
      if (menuTitle === MENU.menuTitlesConsts.FECHAMENTO) {
        if (submenuClosure.allPaths.includes(this.$route.path)) {
          this.menuRender = submenuClosure.items;
          this.leftMenu = true;
          return true;
        }
        return false;
      }
      return false;
    },
    checkMenuColumn(path) {
      switch (path) {
        case MENU.menuPathsConsts.RATEIO:
          this.menuRender = submenuRateio.items;
          this.leftMenu = true;
          break;
        case MENU.menuPathsConsts.CONFIGURACOES:
          this.menuRender = submenuSettings.items;
          this.leftMenu = true;
          break;
        case MENU.menuPathsConsts.FECHAMENTO:
          this.menuRender = submenuClosure.items;
          this.leftMenu = true;
          break;
        default:
          this.leftMenu = false;
          break;
      }
    },
    handleCommandDropDown(command) {
      if (!command) return;

      if (command === 'logout') {
        this.logout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-item-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0.5rem;
  min-width: 57px;
  box-shadow: 0px 0px 0px 0px $primary-color;
  transition: all 0.4s;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $text-color;
    transition: all 0.5s;
  }

  &:hover {
    background-color: #efefef;
  }

  &.active {
    box-shadow: 0px 2px 0px 0px $primary-color;
    p {
      color: $primary-color;
    }
  }
}

.el-header {
  padding: 0;
  background-color: $backgroung-header;
}
.contaer-all-header {
  display: flex;
  flex-direction: column;
  padding-left: 62px;
  padding-right: 116px;
  height: 66px !important;
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
}

.name-next-to-avatar {
  align-items: center;
  margin-right: 10px;
  display: none;
}

.container-dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-content {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  cursor: pointer;
}

.dropdown-content:hover {
  .name-next-to-avatar {
    display: flex;
  }
}

.container-avatar-user {
  display: flex;
  align-items: center;
}

.title-application {
  margin-left: 1.5rem;
}

.logo-image {
  width: 39.37px;
  height: 40.21px;
  background: linear-gradient(180deg, #f98f39 50%, #fed667 100%, #fed667 100%);
  border-radius: 6.16733px;
}

.container-right-logo {
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-image-aside {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
}

.p-sistema {
  width: 36.07px;
  height: 12.28px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 8.52565px;
  line-height: 10px;
}

.p-gerencial {
  width: 68.38px;
  height: 19.19px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13.8846px;
  line-height: 16px;
}

.container-main {
  margin-top: 49px;
}

.container-main-no-submenu {
  margin-top: 49px;
  padding: 0px 62px;
}

.container-row {
  display: flex;
  flex-direction: row !important;
}

.container-column {
  display: flex;
  flex-direction: column !important;
}
</style>
