<template>
  <div>
    <bread-crumb :breadcrumbs="['Configurações', 'Eventos']" />
    <el-row>
      <el-col :span="18">
        <h1>Evento</h1>
        <br><br>
      </el-col>
    </el-row>
    <el-form>
      <el-row>
        <guard :needed-permissions="[PERMISSIONS_EVENT.QUERY]">
          <el-col
            :xs="24"
            :sm="24"
            :md="10"
            :lg="5"
            :xl="4"
          >
            <el-form-item
              label="Evento"
            >
              <br>
              <el-select
                v-model="formEvents"
                size="small"
                multiple
                filterable
                placeholder="Evento"
                autocomplete
                clearable
                remote
                loading-text="Aguarde..."
                :remote-method="getEvents"
                value-key="id"
              >
                <el-option
                  v-for="event in eventsList"
                  :key="event.id"
                  :label="`${event.code} - ${event.description}`"
                  :value="event"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </guard>
        <guard :needed-permissions="[PERMISSIONS_EVENT.DOWNLOAD]">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="5"
            :xl="5"
          >
            <el-form-item label=".">
              <br>
              <el-button
                type="primary"
                size="small"
                :disabled="loadingDownload"
                @click="downloadExcel"
              >
                DOWNLOAD
              </el-button>
            </el-form-item>
          </el-col>
        </guard>
      </el-row>
      <el-table
        v-loading="loadingDownload"
        :data="tableData"
        style="width: 100%"
        stripe
      >
        <el-table-column
          property="code"
          label="Código do evento"
        />
        <el-table-column
          property="description"
          label="Nome do evento"
        />
        <el-table-column
          property="category"
          label="Tipo"
        />
        <el-table-column
          property="apportionableType"
          label="Rateio"
          :formatter="apportionableTypeRenderer"
        />
        <el-table-column
          property="isActive"
          label="Status"
          :formatter="statusRenderer"
        />
        <el-table-column label="Ações">
          <template slot-scope="scope">
            <div>
              <guard
                :needed-permissions="[PERMISSIONS_EVENT.UPDATE]"
                show-tooltip
              >
                <img
                  :src="editIcon"
                  alt="edit"
                  height="17"
                  width="17"
                  style="cursor: pointer"
                  @click="startEdition(scope.row)"
                >
                <template v-slot:denied>
                  <img
                    :src="editIcon"
                    alt="edit"
                    height="17"
                    width="17"
                    style="cursor: pointer"
                  >
                </template>
              </guard>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <guard :needed-permissions="[PERMISSIONS_EVENT.UPDATE]">
        <el-form>
          <el-dialog
            title="Detalhar e editar evento"
            :visible.sync="dialogEdit"
            width="30%"
            top="5%"
          >
            <el-form>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item label="Nome Evento">
                    <br>
                    <strong>{{ selectedEvent?.description }}</strong>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="Código Evento">
                    <br>
                    <strong>{{ selectedEvent?.code }}</strong>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item label="Tipo de Custo">
                    <el-select
                      v-model="selectedEvent.apportionableType"
                      size="small"
                      filterable
                      placeholder="Evento"
                      autocomplete
                      clearable
                    >
                      <el-option
                        key="p"
                        label="Provento"
                        value="p"
                      />
                      <el-option
                        key="e"
                        label="Encargo"
                        value="e"
                      />
                      <el-option
                        key="b"
                        label="Benefício"
                        value="b"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="">
                    <el-select
                      v-model="selectedEvent.creditAccountingAccount"
                      size="small"
                      placeholder=""
                      filterable
                      clearable
                      :disabled="selectedEvent.apportionableType!=='b'
                        && selectedEvent.apportionableType!=='e'"
                      auto-complete
                      value-key="id"
                    >
                      <el-option
                        v-if="selectedEvent.apportionableType==='b'"
                        key="1"
                        label="Benefício"
                        value="BENEFÍCIOS"
                      />
                      <el-option
                        v-if="selectedEvent.apportionableType==='b'"
                        key="2"
                        label="Desconto"
                        value="DESCONTO"
                      />
                      <el-option
                        v-if="selectedEvent.apportionableType==='e'"
                        key="3"
                        label="Pagamento de Proventos"
                        value="PAGAMENTO DE PROVENTOS"
                      />
                      <el-option
                        v-if="selectedEvent.apportionableType==='e'"
                        key="4"
                        label="Encargos"
                        value="ENCARGOS"
                      />
                      <el-option
                        v-if="selectedEvent.apportionableType==='e'"
                        key="5"
                        label="Baixa de encargos"
                        value="BAIXA DE ENCARGOS"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item label="C. Contábil Crédito">
                    <el-select
                      v-model="selectedEvent.creditAccountingAccount"
                      size="small"
                      placeholder="C. Contábil Crédito"
                      filterable
                      clearable
                      value-key="id"
                      remote
                      :remote-method="getTypeCosts"
                    >
                      <el-option
                        v-for="accountingAccount in accountingAccountsOptions"
                        :key="accountingAccount.id"
                        :label="`${accountingAccount.code} - ${accountingAccount.name}`"
                        :value="accountingAccount"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="C. Contábil Débito">
                    <el-select
                      v-model="selectedEvent.debitAccountingAccount"
                      size="small"
                      placeholder="C. Contábil Débito"
                      filterable
                      clearable
                      value-key="id"
                      remote
                      :remote-method="getTypeCosts"
                    >
                      <el-option
                        v-for="accountingAccount in accountingAccountsOptions"
                        :key="accountingAccount.id"
                        :label="`${accountingAccount.code} - ${accountingAccount.name}`"
                        :value="accountingAccount"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item
                    label="Rateável ?"
                  >
                    <br>
                    <el-switch
                      v-model="selectedEvent.paysCorrelated"
                      active-color="#13ce66"
                      :active-text="selectedEvent.paysCorrelated ? 'Sim' : 'Não'"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item
                    label="Status"
                  >
                    <br>
                    <el-switch
                      v-model="selectedEvent.isActive"
                      active-color="#13ce66"
                      :active-text="selectedEvent.isActive ? 'Ativo' : 'Inativo'"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button
                size="small"
                @click="dialogEdit = false"
              >Cancelar</el-button>
              <el-button
                size="small"
                type="primary"
                @click="updateEvent"
              >Salvar</el-button>
            </span>
          </el-dialog>
        </el-form>
      </guard>
    </el-form>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

const editIcon = require('../../public/media/icons/edit.svg');

export default {
  name: 'ConfiguracoesEvento',
  components: { BreadCrumb, Guard },
  data() {
    return {
      PERMISSIONS_EVENT: FUNCTIONALITY_IDS.CONFIG.EVENT,
      editIcon,
      dialogEdit: false,
      switchCorrelated: false,
      switchStatus: false,
      tableData: [],
      selectedEvent: {
        creditAccountingAccount: undefined,
        debitAccountingAccount: undefined,
        paysCorrelated: true,
        isActive: true,
      },
      eventsList: [],
      formEvents: [],
      accountingAccountsOptions: [],
      loadingDownload: true,
    };
  },
  watch: {
    formEvents: {
      async handler(val) {
        this.tableData = val;
      },
    },
  },
  async created() {
    await this.getEvents();
    this.loadingDownload = false;
  },
  methods: {
    async getTypeCosts(query) {
      const params = {
        filter: query,
      };
      await this.$api()
        .get('/accounting-account/typeCost', { params })
        .then(({ data }) => {
          this.accountingAccountsOptions = data;
        });
    },
    async getEvents(search = '') {
      const params = { search, page: 1, size: 20 };

      await this.$api()
        .get('/entry-type/list', { params })
        .then(({ data }) => {
          this.eventsList = data;
        });
    },
    async startEdition(item) {
      this.selectedEvent = item;
      await this.getTypeCosts();
      this.dialogEdit = true;
    },
    async updateEvent() {
      await this.$api()
        .patch(`/entry-type/${this.selectedEvent.id}`, this.selectedEvent)
        .then(() => {
          this.dialogEdit = false;
          this.$notify.success({
            title: 'Alerta',
            message: 'Evento alterado com sucesso',
            duration: 3000,
          });
        });
    },
    statusRenderer(row, column, cellValue) {
      if (cellValue !== false) {
        return (
          <div style="color: #67c23a;">
            <span>Ativo</span>
          </div>
        );
      }
      return (
        <div style="color: red;">
          <span>Inativo</span>
        </div>
      );
    },
    apportionableTypeRenderer(row, column, cellValue) {
      if (cellValue === 'p') {
        return 'Proventos';
      }
      if (cellValue === 'b') {
        return 'Benefícios';
      }
      if (cellValue === 'e') {
        return 'Encargos';
      }
      return '';
    },
    async downloadExcel() {
      this.loadingDownload = true;
      const eventCodes = this.formEvents.map((e) => e.code);
      const params = {
        search: eventCodes.length > 0 ? eventCodes : '',
      };

      await this.$api()
        .get('/entry-type/download', { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report.xlsx';
          link.click();
        });
      this.loadingDownload = false;
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}
hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
.download {
  position: relative;
  left: 1020px;
  top: 50px;
}
</style>
