<template>
  <div>
    <el-form>
      <div v-if="!isEdit && !isDetail">
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Prédio">
              <el-select
                v-model="correlatedData.building"
                filterable
                clearable
                value-key="id"
                @change="selectBuilding"
              >
                <el-option
                  v-for="building in buildingsList"
                  :key="building.id"
                  :label="building.name"
                  :value="building"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="Centro de custo">
              <el-select
                v-model="correlatedData.costcenter"
                filterable
                clearable
                remote
                :remote-method="getCostcenters"
                reserve-keyword
                autocomplete
                class="big-select"
                value-key="id"
                :loading="loadingCostcenters"
              >
                <el-option
                  v-for="item in costcentersList"
                  :key="item.id"
                  :label="`${item.code} - ${item.name}`"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Tipo de despesa">
              <el-select
                v-model="correlatedData.accountingAccount"
                filterable
                clearable
                remote
                :remote-method="getTypeCosts"
                value-key="id"
                :loading="loadingTypeExpenses"
              >
                <el-option
                  v-for="accountingAccount in expenseTypes"
                  :key="accountingAccount.id"
                  :label="accountingAccount.name"
                  :value="accountingAccount"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="Data do lançamento">
              <el-date-picker
                v-model="correlatedData.releaseDate"
                class="small-select"
                format="dd/MM/yyyy"
                placeholder="Data do lançamento"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Valor">
              <el-currency-input v-model="correlatedData.value" />
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="Documento">
              <el-input
                v-model="correlatedData.fiscalNote"
                class="big-select"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Provisão">
              <br>
              <el-switch
                v-model="correlatedData.provision"
                :active-text="correlatedData.provision ? 'Sim' : 'Não'"
                active-color="#13ce66"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div v-else-if="isDetail && !isEdit">
        <el-row :gutter="20">
          <el-col :span="10">
            <p>Prédio</p>
            <b>{{ correlatedData.building.name }}</b>
          </el-col>
          <el-col :span="10">
            <p>Centro de custo</p>
            <b>{{ correlatedData.costcenter?.name }}</b>
          </el-col>
        </el-row>
        <br>
        <el-row :gutter="20">
          <el-col :span="10">
            <p>Tipo de despesa</p>
            <b>
              {{ correlatedData.accountingAccount.code ?
                correlatedData.accountingAccount.code : "" }} -
              {{ correlatedData.accountingAccount.name ?
                correlatedData.accountingAccount.name : "" }}
            </b>
          </el-col>
          <el-col :span="10">
            <p>Origem do Lançamento</p>
            <b>{{ correlatedData.releaseOrigin }}</b>
          </el-col>
        </el-row>
        <br>
        <el-row :gutter="20">
          <el-col :span="10">
            <p>Provisão</p>
            <b>{{ correlatedData.provision }}</b>
          </el-col>
          <el-col :span="10">
            <p>Data do Lançamento</p>
            <b>{{ correlatedData.releaseDate }}</b>
          </el-col>
        </el-row>
        <br>
        <el-row :gutter="20">
          <el-col :span="10">
            <p>Valor</p>
            <b>{{ correlatedData.value }}</b>
          </el-col>
          <el-col :span="10">
            <p>Documento</p>
            <b>{{ correlatedData.fiscalNote }}</b>
          </el-col>
        </el-row>
        <br>
      </div>
      <div v-else>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Centro de custo">
              <el-select
                v-model="correlatedData.costcenter"
                filterable
                clearable
                remote
                :remote-method="getCostcenters"
                reserve-keyword
                autocomplete
                value-key="id"
                :loading="loadingCostcenters"
              >
                <el-option
                  v-for="item in costcentersList"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="Valor">
              <el-currency-input v-model="correlatedData.value" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Tipo de despesa">
              <el-select
                v-model="correlatedData.accountingAccount"
                filterable
                clearable
                value-key="id"
                remote
                :remote-method="getTypeCosts"
                :loading="loadingTypeExpenses"
              >
                <el-option
                  v-for="accountingAccount in expenseTypes"
                  :key="accountingAccount.id"
                  :label="accountingAccount.name"
                  :value="accountingAccount"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="Documento">
              <el-input
                v-model="correlatedData.fiscalNote"
                class="big-select"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div v-if="isDetail">
        <p>Observação</p>
        <div class="border-box">
          <div class="text-inside">
            {{ correlatedData.observation }}
          </div>
        </div>
      </div>
      <div v-else-if="haveObservation(isEdit, correlatedData)">
        <el-form-item label="Observação">
          <el-input
            v-model="obs"
            type="textarea"
            placeholder=""
            maxlength="240"
            show-word-limit
          />
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="Observação">
          <el-input
            v-model="correlatedData.observation"
            type="textarea"
            placeholder=""
            maxlength="240"
            show-word-limit
          />
        </el-form-item>
      </div>
    </el-form>
    <span
      v-if="!isDetail"
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="closeModal">Cancelar</el-button>
      <el-button
        type="primary"
        @click="submit"
      >Salvar</el-button>
    </span>
    <span
      v-else
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        type="primary"
        @click="closeModal"
      >OK</el-button>
    </span>
  </div>
</template>

<script>
import ElCurrencyInput from '../ElCurrencyInput/ElCurrencyInput.vue';

export default {
  name: 'AddModalCorrelated',
  components: {
    ElCurrencyInput,
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    isEdit: Boolean,
    isDetail: Boolean,
    buildingsList: { type: Array, default: () => [] },
    submitForm: { type: Function, default: () => {} },
    correlatedToUpdate: undefined,
  },
  data() {
    return {
      correlatedData: undefined,
      costcentersList: undefined,
      expenseTypes: undefined,
      loadingCostcenters: false,
      loadingTypeExpenses: false,
      obsNewText: '',
    };
  },
  computed: {
    obs: {
      get() {
        if (this.obsNewText) {
          return `${this.correlatedData.observation}${this.obsNewText}`;
        }
        return this.correlatedData.observation;
      },
      set(newValue) {
        const extraTextArray = newValue.split('\n');
        const extraText = extraTextArray[extraTextArray.length - 1];
        const observations = newValue.split(`${this.correlatedData.observation}`);
        const firstLetter = observations[observations.length - 1];
        this.obsNewText = firstLetter.length === 1 && extraText.length > 1 ? `\n${firstLetter}` : `\n${extraText}`;
      },
    },
  },
  async created() {
    this.correlatedData = this.correlatedToUpdate || {
      building: '',
      costcenter: '',
      accountingAccount: '',
      releaseOrigin: 'MANUAL',
      provision: false,
      releaseDate: new Date(),
      value: 0,
      fiscalNote: '',
      observation: '',
    };
    if (this.isEdit) {
      await this.getCostcenters(this.correlatedToUpdate?.costcenter?.name);
      await this.getTypeCosts(this.correlatedToUpdate?.accountingAccount?.name);
    } else {
      await this.getCostcenters();
      await this.getTypeCosts();
    }
  },
  methods: {
    submit() {
      this.correlatedData.observation = this.obsNewText.replace(/[\r\n]/gm, '');
      if (this.isEdit) {
        this.submitForm(this.correlatedData, this.correlatedToUpdate.id);
      } else {
        this.submitForm(this.correlatedData, '');
      }
    },
    haveObservation(isEdit, correlatedData) {
      return isEdit && correlatedData?.observation;
    },
    async getCostcenters(query) {
      const params = {
        search: query,
        size: 15,
        page: 1,
      };
      this.loadingCostcenters = true;
      await this.$api()
        .get('/costcenter', { params })
        .then(({ data }) => {
          this.costcentersList = data.data;
        })
        .finally(() => {
          this.loadingCostcenters = false;
        });
    },
    getTypeCosts(query) {
      const params = {
        filter: query,
      };
      this.loadingTypeExpenses = true;
      this.$api()
        .get('/accounting-account/typeCost', { params })
        .then(({ data }) => {
          this.expenseTypes = data;
        })
        .finally(() => {
          this.loadingTypeExpenses = false;
        });
    },
    selectBuilding(event) {
      this.costcentersList.push(event.transientCostcenter);
    },
  },
};
</script>

<style lang="scss" scoped>
.big-select {
  width: 137% !important;
}

p {
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
}

b {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  align-items: center;
}

.border-box {
  border: 1px solid #8e8e8e;
  margin-bottom: 32px;
  margin-top: 12px;
}

.text-inside {
  margin: 18px;
  border-radius: 4px;
}
</style>
