import Vue from 'vue';
import Vuex from 'vuex';
import variables from '../variables';
import LocalstorageStore from '../plugins/localstorage.store.plugin';

import AuthModule from './modules/auth.store';
import MenuHeaderModule from './modules/menuHeader.store';

const storeUserPlugin = LocalstorageStore.create(
  'auth/setUser',
  variables.auth.userdetais,
  (state) => state.auth.user,
);

const storeTokenPlugin = LocalstorageStore.create(
  'auth/setToken',
  variables.auth.appToken,
  (state) => state.auth.token,
);

const storeMenuHeaderPlugin = LocalstorageStore.create(
  'activeMenuHeader',
  variables.activeMenuHeader,
  (state) => state.activeMenuHeader,
);

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [storeUserPlugin, storeTokenPlugin, storeMenuHeaderPlugin],
  modules: {
    auth: AuthModule,
    menuHeader: MenuHeaderModule,
  },
});
