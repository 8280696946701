const FUNCTIONALITY_IDS = {
  INCOME_REPORT: {
    QUERY: 1,
    DOWNLOAD: 2,
  },
  PAYROLL: {
    IMPORT: 3,
    ACTIONS: 4,
  },
  APPORTIONMENT: {
    HR: {
      IMPORTATION: {
        IMPORT: 6,
        DOWNLOAD: 7,
      },
      CONFERENCE: {
        CHECK: 8,
        DETAILS: 9,
        DOWNLOAD: 10,
      },
      GRATIFICATION: {
        QUERY: 11,
        CREATE: 12,
        UPDATE: 13,
        REMOVE: 14,
        DOWNLOAD: 15,
      },
      AWARDS: {
        DOWNLOAD: 60,
        QUERY: 61,
        AWARD: 62,
      },
    },
    CORRELATES: {
      ENTRY: {
        QUERY: 16,
        CREATE: 17,
        UPDATE: 18,
        DOWNLOAD: 19,
      },
      HEADCOUNT: {
        QUERY: 20,
        UPDATE: 21,
        DOWNLOAD: 22,
      },
      MOVEMENT: {
        IMPORT: 68,
        QUERY: 23,
        EXECUTE_APPORTIONMENT: 24,
        DOWNLOAD: 25,
      },
      APPROPRIATION: {
        DOWNLOAD: 63,
        QUERY: 64,
        APPROPRIATE: 65,
        UPDATE: 66,
        REMOVE: 67,
      },
    },
  },
  ADJUSTS: {
    QUERY: 26,
    REQUEST: 27,
    UPDATE: 28,
    DETAIL: 29,
    DOWNLOAD: 30,
  },
  PROVISION: {
    QUERY: 31,
    CREATE: 32,
    UPDATE: 33,
    REVERT: 34,
    REMOVE: 35,
    DOWNLOAD: 59,
  },
  CLOSING: {
    ACCOUNTING: {
      QUERY: 36,
      DETAIL: 37,
      PERIOD_ACTION: 38,
      SYNC_SYNTHETIC: 39,
    },
    ENTRIES_AND_ADJUSTS: {
      OPEN_CLOSE: 40,
    },
    REPORTS: {
      QUERY: 41,
      DOWNLOAD: 42,
    },
  },
  CONFIG: {
    PROJECT_STRUCTURE: {
      FULL: 69,
    },
    BUILDING: {
      CREATE: 43,
      UPDATE: 44,
    },
    PROJECT: {
      UPDATE: 45,
      DOWNLOAD: 46,
    },
    ASSOCIATION: {
      QUERY: 47,
      WRITE: 48,
    },
    ACCOUNTING_ACCOUNT: {
      QUERY: 49,
      UPDATE: 50,
      DOWNLOAD: 51,
    },
    EVENT: {
      QUERY: 52,
      UPDATE: 53,
      DOWNLOAD: 54,
    },
    GRATIFICATION: {
      QUERY: 55,
      UPDATE: 56,
      DOWNLOAD: 57,
    },
  },
  PROFILE: {
    FULL: 58,
  },
};

export default FUNCTIONALITY_IDS;
