<template>
  <el-table
    v-loading="loading"
    :data="data"
    stripe
  >
    <el-table-column
      property="costcenter.name"
      label="Centro de custo"
      min-width="140"
    />
    <el-table-column
      property="expense"
      label="Tipo de despesas"
      min-width="140"
    />
    <el-table-column
      property="releaseOrigin"
      label="Origem do lançamento"
      min-width="150"
    />
    <el-table-column
      property="provisionText"
      label="Provisão"
      min-width="140"
    />
    <el-table-column
      property="releaseDate"
      label="Data do lançamento"
      min-width="150"
      :formatter="dateRenderer"
    />
    <el-table-column
      property="formatedValue"
      label="Valor"
      min-width="140"
    />
    <el-table-column
      property="fiscalNote"
      label="Documento"
      min-width="140"
    />
    <el-table-column
      property=""
      label="Ação"
      min-width="70"
    >
      <template slot-scope="scope">
        <guard
          :needed-permissions="[PERMISSIONS_ENTRY.UPDATE]"
          show-tooltip
        >
          <div @click="editCorrelated(scope.row)">
            <i class="el-icon-edit hover" />
          </div>
          <template v-slot:denied>
            <i class="el-icon-edit hover" />
          </template>
        </guard>
      </template>
    </el-table-column>
    <el-table-column
      property=""
      label=""
      min-width="70"
    >
      <template slot-scope="scope">
        <div @click="detailCorrelated(scope.row)">
          <i class="el-icon-search hover" />
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import { DateTime } from 'luxon';
import FUNCTIONALITY_IDS from '../../constants/auth/permissions';
import Guard from '../Guard/Guard.vue';

export default {
  name: 'CorrelatedApportionment',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    Guard,
  },
  props: {
    data: {
      type: Array,
      require: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    editCorrelated: Function,
    detailCorrelated: Function,
  },
  data() {
    return {
      PERMISSIONS_ENTRY: FUNCTIONALITY_IDS.APPORTIONMENT.CORRELATES.ENTRY,
    };
  },
  methods: {
    dateRenderer(row, column, cellValue) {
      return DateTime.fromSQL(cellValue).toFormat('dd/LL/yyyy');
    },
  },
};
</script>

<style>
.hover {
  cursor: pointer;
}
</style>
