<template>
  <el-dialog
    width="55%"
    title="Apropriar"
    :close-on-click-modal="false"
    :visible.sync="showModal"
    :before-close="handleClose"
    :fullscreen="fullscreen"
  >
    <hr>

    <el-form
      v-if="selectedAppropriation"
      id="form-appropriation-edit"
      ref="editFormData"
      :model="editFormData"
      label-position="top"
      hide-required-asterisk
    >
      <el-row :gutter="15">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item label="Data de lançamento">
            <span>
              {{
                selectedAppropriation?.date && dateRenderer(selectedAppropriation?.date)
              }}
            </span>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item label="Tipo">
            <span>
              {{ selectedAppropriation?.type | formatterType }}
            </span>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item label="Conta contábil">
            <span>
              {{ selectedAppropriation.accountingAccount?.code }} -
              {{ selectedAppropriation.accountingAccount?.name }}
            </span>
          </el-form-item>
        </el-col>

        <el-col
          v-if="!file?.length"
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item label="Centro de custo">
            <span>
              {{ selectedAppropriation.costcenter?.code }} -
              {{ selectedAppropriation.costcenter?.name }}
            </span>
          </el-form-item>
        </el-col>

        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Documento"
            prop="document"
          >
            <el-input v-model="editFormData.document" />
          </el-form-item>
        </el-col>

        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Parcelas"
            prop="appropriationPortion"
          >
            <el-input
              v-model="editFormData.appropriationPortion"
              placeholder="01/12"
              :maxlength="5"
              @input.native="handleChangeParcel"
              @keypress.native="keyPressInParcel"
            />
          </el-form-item>
        </el-col>

        <el-col
          v-if="!file?.length"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-form-item
            label="Valor"
            prop="value"
          >
            <el-currency-input v-model="editFormData.value" />
          </el-form-item>
        </el-col>

        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-form-item
            label="Observação"
            prop="observation"
          >
            <el-input
              v-model="editFormData.observation"
              type="textarea"
              :rows="3"
              placeholder="Observação..."
              resize="none"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-form
      v-if="!selectedAppropriation"
      id="form-appropriation-save"
      ref="formData"
      :model="formData"
      :rules="formDataRules"
      label-position="top"
      hide-required-asterisk
    >
      <el-row :gutter="15">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Data de lançamento"
            prop="date"
          >
            <el-date-picker
              v-model="formData.date"
              type="date"
              placeholder="Data de lançamento"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Tipo"
            prop="type"
          >
            <el-select
              v-model="formData.type"
              clearable
              filterable
              placeholder="Tipo"
            >
              <el-option
                value="LANCAMENTO"
                label="Lançamento"
              />
              <el-option
                value="APROPRIACAO"
                label="Apropriação"
              />
              <el-option
                value="RATEIO"
                label="Rateio"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Conta contábil Origem"
            prop="originAccountingAccount"
          >
            <el-select
              v-model="formData.originAccountingAccount"
              filterable
              placeholder="Conta contábil"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getaccountingAccountOrigin"
              :loading="loadingaccountingAccountOrigin"
            >
              <el-option
                v-for="item in accountingAccountsOrigin"
                :key="item.id"
                :label="`${item?.code} - ${item.name}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Conta contábil Destino"
            prop="targetAccountingAccount"
          >
            <el-select
              v-model="formData.targetAccountingAccount"
              filterable
              placeholder="Conta contábil"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getaccountingAccountDestination"
              :loading="loadingaccountingAccountDestination"
            >
              <el-option
                v-for="item in accountingAccountsDestination"
                :key="item.id"
                :label="`${item?.code} - ${item.name}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          v-if="!file?.length"
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Centro de custo Origem"
            prop="originCostcenter"
          >
            <el-select
              v-model="formData.originCostcenter"
              filterable
              placeholder="Centros de custo"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getCostcentersOrigin"
              :loading="loadingCostsCenterOrigin"
            >
              <el-option
                v-for="item in costsCenterListOrigin"
                :key="item.id"
                :label="`${item?.code} - ${item.name}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          v-if="!file?.length"
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Centro de custo Destino"
            prop="targetCostcenter"
          >
            <el-select
              v-model="formData.targetCostcenter"
              filterable
              placeholder="Centros de custo Origem"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getCostcentersDestination"
              :loading="loadingCostsCenterDestination"
            >
              <el-option
                v-for="item in costsCenterListDestination"
                :key="item.id"
                :label="`${item?.code} - ${item.name}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Documento"
            prop="document"
          >
            <el-input v-model="formData.document" />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Parcelas"
            prop="appropriationPortion"
          >
            <el-input
              v-model="formData.appropriationPortion"
              placeholder="01/12"
              :maxlength="5"
              @input.native="handleChangeParcel"
              @keypress.native="keyPressInParcel"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="!file?.length"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-form-item
            label="Valor"
            prop="value"
          >
            <el-currency-input v-model="formData.value" />
          </el-form-item>
        </el-col>

        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-form-item
            label="Observação"
            prop="observation"
          >
            <el-input
              v-model="formData.observation"
              type="textarea"
              :rows="3"
              placeholder="Observação..."
              resize="none"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        v-if="!selectedAppropriation"
        id="open-upload-button"
        :disabled="formData.type !== 'RATEIO'"
        @click="openUpload"
      >
        UPLOAD
      </el-button>
      <el-button
        id="close-btn-apropriacao-dialog"
        :disabled="loadingRegister"
        @click="handleClose"
      >
        CANCELAR
      </el-button>
      <el-button
        id="register-appropriation"
        type="primary"
        :disabled="loadingRegister"
        :loading="loadingRegister"
        @click="registerAppropriate"
      >
        SALVAR
      </el-button>
    </span>

    <el-dialog
      v-if="!selectedAppropriation"
      title="Upload"
      :close-on-click-modal="false"
      :visible.sync="showUpload"
      :before-close="handleCloseUpload"
      :fullscreen="fullscreen"
      append-to-body
    >
      <el-row
        type="flex"
        justify="center"
      >
        <el-upload
          drag
          action="#"
          :on-remove="cleanFile"
          :on-change="handleChange"
          :file-list="file"
          :limit="1"
          :on-exceed="handleExceed"
          :http-request="handleChange"
          accept=".xls, .xlsx"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">
            <p>Arraste arquivos aqui, ou</p>
            <b>clique para selecionar o arquivo do seu computador</b>
          </div>
        </el-upload>
      </el-row>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import {
  Row,
  Col,
  Select,
  Form,
  FormItem,
  Button,
  Dialog,
  Option,
  DatePicker,
} from 'element-ui';
import ElCurrencyInput from '../ElCurrencyInput/ElCurrencyInput.vue';

export default {
  name: 'ApropriacaoDialog',
  components: {
    'el-col': Col,
    'el-row': Row,
    'el-select': Select,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-button': Button,
    'el-dialog': Dialog,
    'el-option': Option,
    'el-date-picker': DatePicker,
    ElCurrencyInput,
  },
  filters: {
    formatterType(type) {
      if (type) {
        if (type === 'LANCAMENTO') {
          return 'Lançamento';
        }
        if (type === 'APROPRIACAO') {
          return 'Apropriação';
        }
        return 'Rateio';
      }
      return '';
    },
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    handleClose: {
      type: Function,
      required: true,
    },
    selectedAppropriation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const validation = {
      required: { required: true, message: 'Campo obrigatório', trigger: 'submit' },
      portion: {
        min: 5, message: 'Deve conter 4 dígitos', trigger: 'submit',
      },
    };

    return {
      windowWidth: 0,
      fullscreen: false,
      loadingRegister: false,
      accountingAccountsOrigin: [],
      loadingaccountingAccountOrigin: false,
      accountingAccountsDestination: [],
      loadingaccountingAccountDestination: false,
      costsCenterListDestination: [],
      loadingCostsCenterDestination: false,
      costsCenterListOrigin: [],
      loadingCostsCenterOrigin: false,
      editFormData: {
        document: null,
        appropriationPortion: null,
        value: null,
        observation: null,
      },
      formData: {
        date: null,
        type: null,
        originAccountingAccount: null,
        targetAccountingAccount: null,
        originCostcenter: null,
        targetCostcenter: null,
        document: null,
        appropriationPortion: null,
        value: null,
        observation: null,
      },
      formDataRules: {
        date: [validation.required],
        type: [validation.required],
        originAccountingAccount: [validation.required],
        document: [validation.required],
        appropriationPortion: [validation.portion],
        value: [validation.required],
      },
      file: [],
      showUpload: false,
    };
  },
  watch: {
    windowWidth(width) {
      this.fullscreen = Boolean(width < 768);
    },
    showModal() {
      this.formData = {
        date: null,
        employee: null,
        costcenter: null,
        value: null,
      };

      this.$refs.formData?.clearValidate();
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });

    if (!this.selectedAppropriation) {
      this.getCostcentersOrigin('');
      this.getCostcentersDestination('');
      this.getaccountingAccountOrigin('');
      this.getaccountingAccountDestination('');
    } else {
      this.editFormData = {
        document: this.selectedAppropriation?.document ?? null,
        appropriationPortion: this.selectedAppropriation?.portion ?? null,
        value: this.selectedAppropriation?.value ?? null,
      };
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    registerAppropriate() {
      if (this.selectedAppropriation) {
        const params = {
          document: this.editFormData.document,
          portion: this.editFormData.appropriationPortion,
          value: this.editFormData.value,
          observation: this.editFormData.observation,
        };

        this.loadingRegister = true;
        this.$api()
          .patch(`/appropriation/update/${this.selectedAppropriation.id}?date=${this.selectedAppropriation.date}`, params)
          .then(() => {
            this.handleClose();
            this.$notify({
              title: 'Apropriação',
              message: 'Apropriação alterada com sucesso!',
              type: 'success',
            });
          })
          .finally(() => {
            this.loadingRegister = false;
          });
        return;
      }

      this.$refs.formData.validate((valid) => {
        if (!valid) {
          return;
        }

        const params = {
          date: this.formData.date,
          type: this.formData.type,
          targetAccountingAccount: this.formData.targetAccountingAccount,
          originAccountingAccount: this.formData.originAccountingAccount,
          appropriationList: [],
          observation: this.formData.observation,
          document: this.formData.document,
          portion: this.formData.appropriationPortion,
        };

        if (this.file?.length === 0) {
          params.appropriationList = [{
            originCostcenter: this.formData.originCostcenter,
            destinationCostcenter: this.formData.targetCostcenter,
            value: this.formData.value,
          }];
        }

        this.loadingRegister = true;
        const formData = new FormData();
        formData.append('file', this.file[0]?.raw);

        this.$api()
          .post('/appropriation', formData, { params }, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.handleClose();
            this.$notify({
              title: 'Apropriação',
              message: 'Apropriação cadastrada com sucesso!',
              type: 'success',
            });
          })
          .finally(() => {
            this.loadingRegister = false;
          });
      });
    },
    getaccountingAccountOrigin(search = '') {
      this.loadingaccountingAccountOrigin = true;

      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.$api()
        .get('/accounting-account/list', { params })
        .then((response) => {
          this.accountingAccountsOrigin = response.data.data;
        })
        .finally(() => {
          this.loadingaccountingAccountOrigin = false;
        });
    },
    getaccountingAccountDestination(search = '') {
      this.loadingaccountingAccountDestination = true;

      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.$api()
        .get('/accounting-account/list', { params })
        .then((response) => {
          this.accountingAccountsDestination = response.data.data;
        })
        .finally(() => {
          this.loadingaccountingAccountDestination = false;
        });
    },
    getCostcentersOrigin(search = '') {
      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.loadingCostsCenterOrigin = true;

      this.$api()
        .get('/costcenter/only', { params })
        .then(({ data }) => {
          this.costsCenterListOrigin = data.data;
        })
        .finally(() => {
          this.loadingCostsCenterOrigin = false;
        });
    },
    getCostcentersDestination(search = '') {
      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.loadingCostsCenterDestination = true;

      this.$api()
        .get('/costcenter/only', { params })
        .then(({ data }) => {
          this.costsCenterListDestination = data.data;
        })
        .finally(() => {
          this.loadingCostsCenterDestination = false;
        });
    },
    handleChangeParcel(event) {
      const { value } = event.target;

      if (this.selectedAppropriation) {
        if (this.editFormData.appropriationPortion.length < 3) {
          this.editFormData.appropriationPortion = value.replace(/[^0-9.]/g, '');
          return;
        }
      } else if (this.formData.appropriationPortion.length < 3) {
        this.formData.appropriationPortion = value.replace(/[^0-9.]/g, '');
        return;
      }

      /*
        Verifica se o usuário estar pressionando a tecla '/' após a inserção da '/'
        no método keyPressInParcel.
      */
      const barSplit = value.split('/');

      if (barSplit.length > 2) {
        const [item1, item2] = barSplit;

        if (this.selectedAppropriation) {
          this.editFormData.appropriationPortion = item1 + (item2 !== '/' ? `/${item2}` : '');
          return;
        }
        this.formData.appropriationPortion = item1 + (item2 !== '/' ? `/${item2}` : '');
        return;
      }
      //

      if (this.selectedAppropriation) {
        this.editFormData.appropriationPortion = value.replace(/[^0-9./]/g, '');
      } else {
        this.formData.appropriationPortion = value.replace(/[^0-9./]/g, '');
      }
    },
    keyPressInParcel(event) {
      if (event.keyCode !== 46 && event.keyCode !== 8) {
        if (this.selectedAppropriation) {
          if (this.editFormData.appropriationPortion?.length === 2) {
            this.editFormData.appropriationPortion += '/';
          }
        } else if (this.formData.appropriationPortion?.length === 2) {
          this.formData.appropriationPortion += '/';
        }
      }
    },
    openUpload() {
      this.showUpload = true;
    },
    handleCloseUpload() {
      this.showUpload = false;
    },
    cleanFile() {
      this.file = [];
    },
    handleChange(_file, fileList) {
      this.file = fileList.slice(-3);
    },
    handleExceed() {
      this.$message.warning('Você só pode importar 1 arquivo.');
    },
    dateRenderer(date) {
      return date ? DateTime.fromISO(date).toFormat('dd/LL/yyyy') : null;
    },

  },
};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: -25px;
    margin-bottom: 25px;
    background: #e4e7ed;
  }

  .el-form {
    width: 100%;
  }

  .el-select, .el-input, .el-date-editor {
    width: 100% !important;
  }
</style>
