<template>
  <el-aside
    v-if="!isLoginPage"
    :style="asideLayout"
  >
    <section class="menu-left">
      <div
        v-for="item in menuRender"
        :key="item.title"
        class="title-sub-menu"
      >
        <h3>{{ item.title }}</h3>
        <router-link
          v-for="menuItem in item.menuItems"
          v-show="verifyPermission(menuItem.permissions, permissionsLoggedUser)"
          :key="menuItem.path"
          :to="verifyPermission(menuItem.permissions, permissionsLoggedUser) ? menuItem.path : '#'"
          active-class="active"
          class="row-sub-menu"
        >
          <p>{{ menuItem.title }}</p>
        </router-link>
      </div>
    </section>
  </el-aside>
</template>

<script>
import transictionDefault from '../../assets/styles/variablesToLayout';
import { getLoggedUserStorage } from '../../storage/modules/auth';

import verifyPermission from '../../utils/verifyPermission';

export default {
  name: 'MenuColumn',
  props: {
    menuRender: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      permissionsLoggedUser: [],
    };
  },
  computed: {
    isLoginPage() {
      return this.$route.name === 'Login';
    },
    asideLayout() {
      return {
        width: '215px',
        height: '500px',
        transition: transictionDefault,
        'margin-left': '62px',
        'margin-right': '10px',
        'margin-top': '65px',
      };
    },
    borderActive() {
      return {
        'border-left': '2px solid #E87305',
      };
    },
  },
  created() {
    const { userLogged } = getLoggedUserStorage();
    this.permissionsLoggedUser = userLogged?.functionalityIds || [];
  },
  methods: {
    verifyPermission,
  },
};
</script>

<style lang="scss" scoped>
.menu-left {
  .title-sub-menu {
    h3 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      font-feature-settings: "tnum" on, "lnum" on;
      color: $text-color;
    }

    .row-sub-menu {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      cursor: pointer;
      margin-top: 8px;
      margin-bottom: 8px;
      padding-left: 18px;
      height: 30px;
      width: 215px;

      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        font-feature-settings: "tnum" on, "lnum" on;
      }

      border-left: 2px solid transparent;

      &.active {
        p {
          color: $primary-color;
        }
        border-left: 2px solid $primary-color;
      }
      transition: all 0.3s;
    }

    .row-sub-menu:hover {
      background-color: #fafafa;
    }
  }
}
</style>
