import instance from 'axios';
import { removeLoggedUserStorage } from '../../storage/modules/auth';
import variables from '../../variables';

instance.defaults.timeout = 180000;
instance.defaults.baseURL = variables.api.uri;
instance.defaults.withCredentials = true;

instance.interceptors.response.use((response) => response, async (error) => {
  if (error.response?.status === 401) {
    removeLoggedUserStorage();
    return Promise.reject(error);
  }
  if (error.response?.status === 403) {
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

export default instance;
