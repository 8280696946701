<template>
  <div>
    <bread-crumb :breadcrumbs="['Configurações', 'Gratificação']" />
    <el-row>
      <el-col :span="18">
        <h1>Conta contábil</h1>
        <br><br>
      </el-col>
    </el-row>
    <el-form>
      <el-row>
        <guard :needed-permissions="[PERMISSIONS_ACCOUNTING_ACCOUNT.QUERY]">
          <el-col
            :xs="24"
            :sm="24"
            :md="10"
            :lg="5"
            :xl="4"
          >
            <el-form-item
              label="Conta contábil"
            >
              <br>
              <el-select
                v-model="accountingAccount"
                size="small"
                placeholder="Encargo Incentivado"
                filterable
                clearable
                value-key="id"
                remote
                multiple
                :remote-method="getTypeCosts"
              >
                <el-option
                  v-for="accountingAccount in accountingAccountsOptions"
                  :key="accountingAccount.id"
                  :label="`${accountingAccount.code} - ${accountingAccount.name}`"
                  :value="accountingAccount.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </guard>
        <guard :needed-permissions="[PERMISSIONS_ACCOUNTING_ACCOUNT.DOWNLOAD]">
          <el-col
            :xs="24"
            :sm="24"
            :md="10"
            :lg="5"
            :xl="5"
          >
            <el-form-item>
              <el-row type="flex">
                <el-col>
                  <el-button
                    type="primary"
                    size="small"
                    class="download"
                    :disabled="loadingDownload"
                    @click="downloadExcel"
                  >
                    DOWNLOAD
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </guard>
      </el-row>
      <el-table
        v-loading="loadingDownload"
        :data="tableData"
        style="width: 100%"
        stripe
      >
        <el-table-column
          property="code"
          label="Número Conta"
        />
        <el-table-column
          property="name"
          label="Nome Conta"
        />
        <el-table-column
          property="classification"
          label="Classificação"
        />
        <el-table-column
          property="type"
          label="Tipo"
        />
        <el-table-column
          property="codeAccountNature.name"
          label="Natureza"
        />
        <el-table-column
          property="isActive"
          label="Status"
          :formatter="statusRenderer"
        />
        <el-table-column label="Ações">
          <template slot-scope="scope">
            <div>
              <guard
                :needed-permissions="[PERMISSIONS_ACCOUNTING_ACCOUNT.UPDATE]"
                show-tooltip
              >
                <img
                  :src="editIcon"
                  alt="edit"
                  height="17"
                  width="17"
                  style="cursor: pointer"
                  @click="startEdition(scope.row)"
                >
                <template v-slot:denied>
                  <img
                    :src="editIcon"
                    alt="edit"
                    height="17"
                    width="17"
                    style="cursor: pointer"
                  >
                </template>
              </guard>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <guard :needed-permissions="[PERMISSIONS_ACCOUNTING_ACCOUNT.UPDATE]">
        <el-form>
          <el-dialog
            title="Editar Conta Contábil"
            :visible.sync="dialogEdit"
            width="30%"
            top="5%"
          >
            <el-form>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item label="Nome Conta contábil">
                    <br>
                    <strong>{{ accountingAccountToUpdate?.name }}</strong>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="Conta contábil">
                    <br>
                    <strong>{{ accountingAccountToUpdate?.code }}</strong>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item
                    label="Tipo de lançamento"
                  >
                    <br>
                    <el-select
                      v-model="accountingAccountToUpdate.type"
                      placeholder="Tipo de lançamento"
                      size="small"
                      clearable
                      filterable
                      autocomplete
                    >
                      <el-option
                        key="1"
                        label="Folha"
                        value="Folha"
                      />
                      <el-option
                        key="2"
                        label="Outros"
                        value="Outros"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="Natureza">
                    <el-select
                      v-model="accountingAccountToUpdate.codeAccountNature"
                      placeholder="Natureza"
                      size="small"
                      clearable
                      filterable
                      reserve-keyword
                      autocomplete
                      value-key="id"
                    >
                      <el-option
                        v-for="nature in accountNatures"
                        :key="nature.id"
                        :label="nature.name"
                        :value="nature"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item label="Classificação">
                    <el-select
                      v-model="accountingAccountToUpdate.classification"
                      placeholder="Classificação"
                      size="small"
                      clearable
                      filterable
                      reserve-keyword
                      autocomplete
                    >
                      <el-option
                        key="1"
                        label="Ativo"
                        value="Ativo"
                      />
                      <el-option
                        key="2"
                        label="Passivo"
                        value="Passivo"
                      />
                      <el-option
                        key="3"
                        label="Despesa"
                        value="Despesa"
                      />
                      <el-option
                        key="4"
                        label="Receita"
                        value="Receita"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="9" />
              </el-row>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item
                    label="Status"
                  >
                    <br>
                    <el-switch
                      v-model="accountingAccountToUpdate.isActive"
                      :active-text="
                        accountingAccountToUpdate.isActive ? 'Ativo' : 'Inativo'
                      "
                      active-color="#13ce66"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button
                size="small"
                @click="dialogEdit = false"
              >Cancelar</el-button>
              <el-button
                size="small"
                type="primary"
                @click="updateAccountingAccount"
              >Salvar</el-button>
            </span>
          </el-dialog>
        </el-form>
      </guard>
    </el-form>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

const editIcon = require('../../public/media/icons/edit.svg');

export default {
  name: 'ConfiguracoesContaContabil',
  components: { BreadCrumb, Guard },
  data() {
    return {
      PERMISSIONS_ACCOUNTING_ACCOUNT: FUNCTIONALITY_IDS.CONFIG.ACCOUNTING_ACCOUNT,
      editIcon,
      dialogEdit: false,
      switchStatus: false,
      tableData: [],
      accountingAccountsOptions: [],
      accountingAccount: undefined,
      accountingAccountToUpdate: {
        codeAccountNature: undefined,
        type: '',
      },
      accountNatures: [],
      loadingDownload: false,
    };
  },
  watch: {
    accountingAccount: {
      async handler(val) {
        if (val && val.length > 0) {
          this.tableData = await this.getTypeCosts(val, true);
        } else {
          await this.getTypeCosts('', false);
          this.tableData = [];
        }
      },
    },
  },
  async created() {
    await this.getTypeCosts();
    await this.getAccountNatures();
    this.creating = false;
  },
  methods: {
    async getTypeCosts(query, allData) {
      const params = allData
        ? {
          filter: query,
          allData: true,
        }
        : {
          filter: query,
        };
      let dataForTable = [];
      await this.$api()
        .get('/accounting-account/typeCost', { params })
        .then(({ data }) => {
          this.accountingAccountsOptions = data;
          dataForTable = data;
        });
      return dataForTable;
    },
    startEdition(item) {
      this.accountingAccountToUpdate = item;
      this.dialogEdit = true;
    },
    async getAccountNatures() {
      await this.$api()
        .get('/account-nature/list')
        .then(({ data }) => {
          this.accountNatures = data;
        });
    },
    async updateAccountingAccount() {
      if (this.accountingAccountToUpdate.codeAccountNature === '') {
        this.accountingAccountToUpdate.codeAccountNature = null;
      }
      await this.$api()
        .patch('/accounting-account/update', this.accountingAccountToUpdate)
        .then(() => {
          this.$notify.success({
            title: 'Alerta',
            message: 'Alteração realizada com sucesso',
            duration: 3000,
          });
          this.dialogEdit = false;
        });
    },
    async downloadExcel() {
      this.loadingDownload = true;
      const params = {
        filter: this.accountingAccount,
      };
      await this.$api()
        .get('/accounting-account/download', { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report.xlsx';
          link.click();
        });
      this.loadingDownload = false;
    },
    statusRenderer(row, column, cellValue) {
      if (cellValue !== false) {
        return (
          <div style="color: #67c23a;">
            <span>Ativo</span>
          </div>
        );
      }
      return (
        <div style="color: red;">
          <span>Inativo</span>
        </div>
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}
hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
.download {
  position: relative;
  left: 1100px;
  top: 50px;
}
</style>
