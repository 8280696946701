<template>
     <el-dialog
      title="Detalhes do ajuste"
      :visible.sync="open"
      width="50%"
      :before-close="handleClose"
    >
    <hr />
      <el-row :gutter="20">
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <div class="col-detail">
            <span>Mês / Ano Referência</span>
            <strong>
              {{ selectedRow.adjust?.month }}/{{ selectedRow.adjust?.year }}
            </strong>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <div class="col-detail">
            <span>ID Ajuste</span>
            <strong>{{ selectedRow?.specialCode }}</strong>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <div class="col-detail">
            <span>Conta contábil</span>
            <strong>{{ selectedRow.adjust?.accountingAccount?.name }}</strong>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <div class="col-detail">
            <span>Situação</span>
            <strong>{{ selectedRow.status }}</strong>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <div class="col-detail">
            <span>Data de atualização da Situação (Status)</span>
            <strong>{{ selectedRow.statusUpdateDate }}</strong>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <div class="col-detail">
            <span>Tipo Transferência</span>
            <strong>{{ selectedRow.adjust?.transferType }}</strong>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <div class="col-detail">
            <span>Tipo Ajuste</span>
            <strong>{{ selectedRow.adjust?.type }}</strong>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <div class="col-detail">
            <span>Colaborador</span>
            <strong>{{ selectedRow.adjust?.employee?.name }}</strong>
          </div>
        </el-col>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <div class="col-detail">
            <span>CC Origem</span>
            <strong>{{ selectedRow.originCC?.name }}</strong>
          </div>
        </el-col>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <div class="col-detail">
            <span>CC Destino</span>
            <strong>{{ selectedRow.destinationCC?.name }}</strong>
          </div>
        </el-col>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <div class="col-detail">
            <span>Data Solicitação</span>
            <strong>{{ selectedRow.requestDate }}</strong>
          </div>
        </el-col>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <div class="col-detail">
            <span>Observação</span>
            <strong>{{ selectedRow.observation }}</strong>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
</template>

<script>
import { Row } from 'element-ui';

export default {
  name: 'AdjustDetails',
  components: {
    'el-row': Row,
  },
  props: {
    selectedRow: Object,
    handleClose: {
      type: Function,
      require: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>
.col-detail {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}

</style>
