import { render, staticRenderFns } from "./CorrelatosApropriacao.vue?vue&type=template&id=fbc658e0&scoped=true&"
import script from "./CorrelatosApropriacao.vue?vue&type=script&lang=js&"
export * from "./CorrelatosApropriacao.vue?vue&type=script&lang=js&"
import style0 from "./CorrelatosApropriacao.vue?vue&type=style&index=0&id=fbc658e0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc658e0",
  null
  
)

export default component.exports