<template>
  <div class="container">
    <el-row class="el-row-container">
      <el-col
        :xl="6"
        :lg="6"
        :md="8"
        :sm="12"
        :xs="22"
      >
        <el-card>
          <div class="container-form">
            <div class="container-top">
              <el-image
                class="el-image-logo"
                :src="logoImg"
              />
              <div>
                <p class="p-sistema">
                  Sistema
                </p>
                <p class="p-gerencial">
                  Gerencial
                </p>
              </div>
            </div>

            <div class="container-bottom">
              <el-form
                ref="form"
                :model="form"
              >
                <el-form-item>
                  <div>
                    <br>
                    <el-button
                      type="primary"
                      plain
                      class="el-button-login"
                      @click="authGoogle()"
                    >
                      <span
                        class="btn-inner--icon"
                      ><img
                        src="/media/logos/google.svg"
                        style="width: 15px"
                      ></span>
                      <span style="margin-left: 0.75em">Entrar com Google</span>
                    </el-button>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
const logoImg = require('../../public/media/logos/logo1.svg');
const variables = require('../variables');

export default {
  data() {
    return {
      logoImg,
      form: {
        email: '',
        password: '',
      },
      loading: false,
    };
  },
  methods: {
    async authGoogle() {
      try {
        window.open(`${variables.default.api.uri}/google`, '_self');
      } catch {
        this.$notify.error({
          title: 'Alerta',
          message: 'Usuário não encontrado',
          duration: 3000,
        });
        this.$router.replace({ name: 'Login' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  height: 100%;

  .el-row-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .container-form::-webkit-scrollbar {
    display: none;
  }

  .container-form {
    min-height: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    max-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    .container-top {
      display: flex;
      flex: 0.5;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-bottom: 1rem;
      width: 100%;

      .el-image-logo {
        width: 10rem;
        height: 7rem;
      }

      .p-sistema {
        text-align: center;
        margin-top: 0.5rem;
        font-weight: 300;
        margin-bottom: 0;
      }

      .p-gerencial {
        text-align: center;
        font-weight: 400;
        margin-top: 0;
        font-size: 30px;
        line-height: 1rem;
      }
    }

    .container-bottom {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 3rem;
      width: 100%;
    }

    .container-input {
      width: 100%;
      margin-top: 1.5rem;
    }

    .el-button-login {
      width: 100%;
    }
  }
}
</style>
