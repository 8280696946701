<template>
  <div>
    <bread-crumb :breadcrumbs="['Rateio', 'RH', 'Conferência']" />
    <h2>Conferência</h2>
    <guard :needed-permissions="[PERMISSIONS_IMPORTATION.CHECK]">
      <template v-slot:denied>
        <span>Sem acesso</span>
      </template>
      <el-form
        ref="form"
        label-position="top"
        label-width="100%"
        hide-required-asterisk
        :model="formData"
        :rules="rules"
      >
        <el-row
          type="flex"
          align="center"
          style="gap: 10px"
        >
          <el-date-picker
            v-if="!showReport"
            v-model="formData.yearMonth"
            type="month"
            placeholder="Mês e ano Contábil"
            format="MMM/yyyy"
            value-format="yyyy-MM"
            size="medium"
          />
          <el-button
            v-if="!showReport"
            type="primary"
            :loading="loadingConference"
            size="small"
            @click="submitForm('form')"
          >
            CONFERIR
          </el-button>
        </el-row>
      </el-form>
      <div
        v-if="!showReport"
        class="table"
        style="padding: 120px 1px"
      >
        <span><strong style="margin-top: 50px">Custo total</strong></span>
        <el-table
          v-loading="loadingTable"
          :data="tableData"
          stripe
        >
          <el-table-column
            property="exibitionName"
            label="Item"
            width="180"
            align="left"
            sortable
          />
          <el-table-column
            property="payroll"
            label="Folha"
            width="180"
            align="right"
            sortable
            :formatter="(row) => formatterValue(row, 'payroll')"
          />
          <el-table-column
            property="apportionment"
            label="Rateio"
            align="right"
            sortable
            :formatter="(row) => formatterValue(row, 'apportionment')"
          />
          <el-table-column
            property="difference"
            label="Diferença"
            align="right"
            sortable
            :formatter="(row) => formatterValue(row, 'difference')"
          >
            <template slot-scope="scope">
              <div class="differenceTooltip">
                <span> {{ valueRowFormatCurrency(scope.row.difference) }}</span>
                <el-tooltip
                  v-if="scope.row.difference > 0"
                  effect="dark"
                  content="Colaboradores com divergência"
                  placement="right"
                  class="iconTolltipDifference"
                >
                  <i class="el-icon-info" />
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="acoes"
            label="Ações"
            align="center"
          >
            <template slot-scope="scope">
              <el-row v-if="scope.row.difference > 0">
                <guard
                  :needed-permissions="[PERMISSIONS_IMPORTATION.DETAILS]"
                  show-tooltip
                >
                  <a
                    style="cursor: pointer"
                    class="acoes-column"
                    @click="handleClickShowReport(scope.row.name)"
                  >
                    Exibir Relatório
                  </a>
                  <template v-slot:denied>
                    <a
                      class="acoes-column"
                      style="color: #808080"
                    >Exibir Relatório</a>
                  </template>
                </guard>
                <guard
                  :needed-permissions="[PERMISSIONS_IMPORTATION.DOWNLOAD]"
                  show-tooltip
                >
                  <a
                    style="cursor: pointer"
                    class="acoes-column"
                    align="center"
                    @click="handleClickDownloadReport(scope.row.name)"
                  >
                    Download Relatório
                  </a>
                  <template v-slot:denied>
                    <a
                      class="acoes-column"
                      style="color: #808080"
                    >Download Relatório</a>
                  </template>
                </guard>
              </el-row>
              <el-row v-else>
                <guard
                  :needed-permissions="[PERMISSIONS_IMPORTATION.DETAILS]"
                  show-tooltip
                >
                  <a
                    class="acoes-column"
                    style="color: #808080"
                  >
                    Exibir Relatório
                  </a>
                  <template v-slot:denied>
                    <a
                      class="acoes-column"
                      style="color: #808080"
                    >Exibir Relatório</a>
                  </template>
                </guard>
                <guard
                  :needed-permissions="[PERMISSIONS_IMPORTATION.DOWNLOAD]"
                  show-tooltip
                >
                  <a
                    class="acoes-column"
                    style="color: #808080"
                  >
                    Download Relatório
                  </a>
                  <template v-slot:denied>
                    <a
                      class="acoes-column"
                      style="color: #808080"
                    >Download Relatório</a>
                  </template>
                </guard>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-button
          v-if="showReport"
          :icon="ArrowLeft"
          class="el-icon-back"
          @click="handleClickBack()"
        >
          VOLTAR
        </el-button>
        <span><strong
          v-if="showReport"
          class="descriptionItem"
          property="description"
        >Detalhe dos {{ description }}</strong></span>
        <el-table
          v-if="showReport"
          :data="tableData"
          lazy
          class="tableReport"
          style="padding: 30px 1px"
          stripe
          @row-click="loadChildren"
        >
          <el-table-column
            property="badge"
            label="Chapa"
            sortable
          />
          <el-table-column
            min-width="150px"
            property="name"
            label="Nome do colaborador"
            align="left"
            sortable
          />
          <el-table-column
            property="payroll"
            label="Folha"
            align="right"
            sortable
            :formatter="(row) => formatterValue(row, 'payroll')"
          />
          <el-table-column
            property="apportionment"
            label="Rateio"
            align="right"
            sortable
            :formatter="(row) => formatterValue(row, 'apportionment')"
          />
          <el-table-column
            property="difference"
            label="Diferença"
            align="right"
            sortable
            :formatter="(row) => formatterValue(row, 'difference')"
          />
        </el-table>
      </div>
      <el-dialog
        v-if="openModal"
        :visible.sync="openModal"
        :title="user"
      >
        <el-table
          v-loading="loading"
          :data="tableDetailReport"
          style="width: 100%"
          stripe
        >
          <el-table-column
            property="event"
            label="Evento"
            align="left"
            sortable
          />
          <el-table-column
            property="description"
            label="Descrição"
            align="left"
          />
          <el-table-column
            property="payroll"
            label="Folha"
            align="left"
            sortable
            :formatter="(row) => formatterValue(row, 'payroll')"
          />
          <el-table-column
            property="apportionment"
            label="Rateio"
            align="left"
            sortable
            :formatter="(row) => formatterValue(row, 'apportionment')"
          />
        </el-table>
      </el-dialog>
    </guard>
  </div>
</template>

<script>
import {
  Row,
  Form,
  DatePicker,
  TableColumn,
  Table,
  Button,
  Tooltip,
} from 'element-ui';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import numberFormat from '../utils/IntlNumberFormat';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

export default {
  name: 'RhConferencia',
  components: {
    BreadCrumb,
    'el-form': Form,
    'el-row': Row,
    'el-date-picker': DatePicker,
    'el-table-column': TableColumn,
    'el-table': Table,
    'el-button': Button,
    'el-tooltip': Tooltip,
    Guard,
  },
  data() {
    return {
      PERMISSIONS_IMPORTATION: FUNCTIONALITY_IDS.APPORTIONMENT.HR.CONFERENCE,
      description: '',
      user: '',
      showReport: false,
      loadingConference: false,
      badge: null,
      formData: {
        yearMonth: '',
      },
      rules: {
        yearMonth: [{ required: true, message: 'Campo obrigatório', trigger: ['submit'] }],
      },
      tableData: [],
      dataForBadge: {},
      dataAllItens: [],
      tableDetailReport: [],
      apportionableType: undefined,
      loadingTable: false,
      openModal: false,
    };
  },
  methods: {
    submitForm(formName) {
      this.loadingTable = true;
      this.showReport = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingConference = true;
          this.$api()
            .get(`accounting-entries/data-difference/${this.formData.yearMonth}`)
            .then(({ data }) => {
              this.dataForBadge = {
                earnings: data.dataForBadge.earnings.map((item) => ({
                  ...item,
                  hasChildren: true,
                  children: { ...item },
                })),
                benefits: data.dataForBadge.benefits.map((item) => ({
                  ...item,
                  hasChildren: true,
                  children: { ...item },
                })),
                charges: data.dataForBadge.charges.map((item) => ({
                  ...item,
                  hasChildren: true,
                  children: { ...item },
                })),
              };
              this.dataAllItens = [
                {
                  ...data.earnings,
                  exibitionName: 'Proventos',
                  name: 'earnings',
                },
                {
                  ...data.benefits,
                  exibitionName: 'Beneficios',
                  name: 'benefits',
                },
                {
                  ...data.charges,
                  exibitionName: 'Encargos',
                  name: 'charges',
                },
              ];
              this.tableData = [...this.dataAllItens];
            })
            .finally(() => {
              this.loadingConference = false;
              this.loadingTable = false;
            });
        }
      });
    },
    handleClickShowReport(itemInDataForBadge) {
      this.description = itemInDataForBadge;
      if (this.description === 'earnings') {
        this.description = 'proventos';
        this.apportionableType = 'p';
      } else if (this.description === 'charges') {
        this.description = 'encargos';
        this.apportionableType = 'e';
      } else {
        this.description = 'beneficios';
        this.apportionableType = 'b';
      }
      this.tableData = this.dataForBadge[itemInDataForBadge];
      this.showReport = true;
    },
    async handleClickDownloadReport(itemInDataForBadge) {
      const { name, code } = this.getApportionableType(itemInDataForBadge);
      const { yearMonth } = this.formData;
      await this.$api()
        .get(`/accounting-entries/difference-report-excel/${code}/${yearMonth}`, {
          responseType: 'blob',
        })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `conferência RH ${name} ${yearMonth}.xlsx`;
          link.click();
        });
    },
    formatterValue(row, attribute) {
      return numberFormat(row[attribute]);
    },
    valueRowFormatCurrency(value) {
      return numberFormat(value);
    },
    handleClickBack() {
      this.tableData = this.dataAllItens;
      this.showReport = false;
    },
    payrollFormat(row) {
      return `${row.payroll.value} - ${row.payroll.accountingAccount}`;
    },
    apportionmentFormat(row) {
      return `${row.apportionment?.value} - ${row.apportionment?.accountingAccount}`;
    },
    async loadChildren(row) {
      this.user = `${row.badge} - ${row.name}`;
      this.loading = true;
      await this.$api()
        .get(
          `accounting-entries/difference-info/${row.badge}?yearMonth=${this.formData.yearMonth}&apportionableType=${this.apportionableType}`,
        )
        .then(({ data }) => {
          this.tableDetailReport = data;
          this.loading = false;
          this.openModal = true;
        });
    },
    getApportionableType(itemInDataForBadge) {
      if (itemInDataForBadge === 'earnings') {
        return { name: 'proventos', code: 'p' };
      }
      if (itemInDataForBadge === 'charges') {
        return { name: 'encargos', code: 'e' };
      }
      if (itemInDataForBadge === 'benefits') {
        return { name: 'beneficios', code: 'b' };
      }
      throw new Error('Item inválido');
    },
  },
};
</script>

<style lang="scss" scoped>

h2 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 30px;
}

.iconTolltipDifference {
  color: #e87305;
}

.differenceTooltip {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.dialog-footer {
  padding: 50px 10px;
  font-size: 12px;
  line-height: 18px;
}

.acoes-column {
  margin-right: 20px;
  align: "center";
}

.descriptionItem {
  position: absolute;
  top: 250px;
  left: 288px;
}

.el-icon-back {
  font-family: "Roboto";
  position: absolute;
  border-radius: 50px;
  top: 200px;
  right: 50px;
  outline: unset;
}
</style>
