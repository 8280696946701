<template>
  <el-input
    ref="input"
    v-currency="options"
    placeholder="R$"
    :disabled="disable"
    :value="formattedValue"
    @input="handleInput"
  />
</template>

<script>
import { setValue, getValue } from 'vue-currency-input';
import { Input } from 'element-ui';

export default {
  name: 'ElCurrencyInput',
  components: {
    'el-input': Input,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({
        locale: 'pt-BR',
        currency: 'BRL',
        autoDecimalMode: true,
        allowNegative: false,
        distractionFree: true,
      }),
    },
    disable: {
      type: Boolean,
    },
  },
  data() {
    return {
      formattedValue: null,
    };
  },
  watch: {
    value: 'setValue',
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    handleInput(value) {
      this.$emit('input', getValue(this.$refs.input));
      this.formattedValue = value;
    },
    setValue(value) {
      setValue(this.$refs.input, value);
    },
  },
};
</script>
