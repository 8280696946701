export default {
  env: {
    nodeEnv: process.env.NODE_ENV,
  },
  api: {
    uri: process.env.VUE_APP_API_URI,
  },
  auth: {
    appToken: '',
    userLogged: null,
    userdetais: {
      id: '',
      name: '',
      email: '',
    },
  },
  activeMenuHeader: null,
};
