<template>
  <el-dialog
    :title="isEdit ? 'Edição e Detalhe' : 'Gratificar'"
    width="40%"
    :fullscreen="fullscreen"
    :visible.sync="open"
    :before-close="onClose"
  >
    <hr>
    <div
      v-loading="loadingData"
      class="container"
    >
      <el-form
        :model="formValue"
        label-position="top"
      >
        <el-row :gutter="20">
          <div v-if="!isEdit">
            <el-col
              :xs="24"
              :sm="24"
              :md="14"
              :lg="14"
              :xl="14"
            >
              <el-form-item label="Data Lançamento">
                <el-date-picker
                  v-model="yearMonth"
                  type="month"
                  placeholder="Data Lançamento"
                  format="MMM/yyyy"
                  value-format="yyyy-MM"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
            >
              <el-form-item label="Colaborador">
                <el-select
                  v-model="formValue.employee"
                  filterable
                  placeholder="Colaborador"
                  autocomplete
                  clearable
                  remote
                  loading-text="Aguarde..."
                  value-key="id"
                  :remote-method="getEmployees"
                  popper-append-to-body
                  :loading="loadingEmployees"
                >
                  <el-option
                    v-for="item in employees"
                    :key="item.id"
                    :label="`${item.badge} - ${item.name}`"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <div v-else>
            <el-col
              :xs="25"
              :sm="24"
              :md="14"
              :lg="14"
              :xl="14"
            >
              <p>Mês / Ano Contábil</p>
              <b>{{ formValue.month }}/{{
                formValue.year
              }}</b>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="10"
              :lg="10"
              :xl="10"
            >
              <p>ID Gratificação</p>
              <b>{{ formValue.code }}</b>
            </el-col>
            <br><br><br>
            <el-col
              :xs="24"
              :sm="24"
              :md="14"
              :lg="14"
              :xl="14"
            >
              <p>Origem Lançamento</p>
              <b> {{ formValue.releaseOrigin }}</b>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="10"
              :lg="10"
              :xl="10"
            >
              <p>Colaborador</p>
              <b>{{ formValue.employee?.badge }}
                - {{ formValue.employee?.name }}</b>
            </el-col>
            <br><br><br>
          </div>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <el-form-item label="Centro de custo">
              <el-select
                v-model="formValue.costcenter"
                filterable
                placeholder="Centros de Custo"
                autocomplete
                clearable
                remote
                loading-text="Aguarde..."
                value-key="id"
                :remote-method="getCostsCenter"
                :loading="loadingCostsCenter"
              >
                <el-option
                  v-for="item in costsCenterList"
                  :key="item.id"
                  :label="`${item.code} - ${item.name}`"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="14"
            :lg="14"
            :xl="14"
          >
            <el-form-item label="Regional">
              <el-select
                v-model="formValue.branch"
                filterable
                clearable
                value-key="id"
              >
                <el-option
                  v-for="branch in branches"
                  :key="branch.code"
                  :label="branch.name"
                  :value="branch"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="10"
            :lg="10"
            :xl="10"
          >
            <el-form-item label="Valor">
              <el-currency-input v-model="formValue.value" />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <el-form-item label="Observação">
              <el-input
                v-model="formValue.observation"
                type="textarea"
                maxlength="200"
                show-word-limit
                rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          align="center"
          justify="end"
        >
          <el-button
            style="background-color: #f5f5f5; border: 0px"
            size="small"
            @click="handleClose"
          >
            Cancelar
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="save"
          >
            Salvar
          </el-button>
        </el-row>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {
  Dialog, Form, Row, Col, FormItem, DatePicker, Input, Select, Button,
} from 'element-ui';

import ElCurrencyInput from '../ElCurrencyInput/ElCurrencyInput.vue';

export default {
  name: 'RhGratificacaoDialog',
  components: {
    'el-dialog': Dialog,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-row': Row,
    'el-col': Col,
    'el-date-picker': DatePicker,
    'el-input': Input,
    'el-select': Select,
    'el-button': Button,
    ElCurrencyInput,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    selectedGratificacao: [Object, null],
    createGratification: Function,
    updateGratification: Function,
    isEdit: Boolean,
  },
  data() {
    return {
      formValue: {
        costcenter: null,
        employee: null,
        year: null,
        month: null,
        branch: null,
        value: 0,
        obs: '',
      },
      loadingCostsCenter: false,
      costsCenterList: [],
      branches: [],
      fullscreen: false,
      windowWidth: 0,
      employees: [],
      loadingData: this.isEdit,
      loadingEmployees: false,
      yearMonth: undefined,
    };
  },
  watch: {
    windowWidth(width) {
      this.fullscreen = Boolean(width < 768);
    },
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });
    if (this.selectedGratificacao) {
      await this.getCostsCenter(this.selectedGratificacao?.costcenter?.code.substring(0, 6));
      this.formValue = JSON.parse(JSON.stringify(this.selectedGratificacao));
      this.yearMonth = `${this.selectedGratificacao.year}-${this.selectedGratificacao.month}`;
      this.loadingData = false;
      return;
    }
    await this.getCostsCenter();
  },
  async created() {
    await this.getBranches();
    await this.getEmployees();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    handleClose() {
      this.onClose();
      this.formValue = {
        costcenter: null,
        employee: null,
        year: null,
        month: null,
        branch: null,
        value: 0,
        obs: '',
      };
    },
    async getCostsCenter(search = '') {
      this.loadingCostsCenter = true;
      const params = { search, page: 1, size: 20 };
      await this.$api()
        .get('/costcenter', { params })
        .then((response) => {
          this.costsCenterList = response.data.data;
        })
        .finally(() => {
          this.loadingCostsCenter = false;
        });
    },
    async getEmployees(search = '') {
      this.loadingEmployees = true;
      const params = { search, page: 1, size: 15 };
      await this.$api()
        .get('/employee/list', { params })
        .then((response) => {
          this.employees = response.data.data;
        })
        .finally(() => {
          this.loadingEmployees = false;
        });
    },
    getBranches() {
      this.$api()
        .get('/branch/list')
        .then(({ data }) => {
          this.branches = data.data;
        });
    },
    async save() {
      if (
        !this.yearMonth
        || !this.formValue.employee
        || !this.formValue.costcenter
        || !this.formValue.branch
        || !this.formValue.value
      ) {
        this.$notify.error({
          title: 'Alerta',
          message: 'Preencha todos os campos',
          duration: 3000,
        });
        return;
      }

      if (!this.isEdit) {
        const [year, month] = this.yearMonth.split('-').map(Number);
        this.formValue.year = year;
        this.formValue.month = month;
        const form = {
          yearMonth: this.yearMonth,
          releaseOrigin: 'MANUAL',
          ...this.formValue,
        };
        await this.createGratification(form);
      } else {
        const data = {
          params: { yearMonth: this.yearMonth },
          gratification: {
            id: this.formValue.id,
            costcenter: this.formValue.costcenter.id,
            branch: this.formValue.branch.id,
            value: this.formValue.value,
            obs: this.formValue.obs,
          },
        };
        await this.updateGratification(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .el-form {
    width: 100%;
    .el-select,
    .el-input {
      width: 100%;
    }
  }
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
</style>
