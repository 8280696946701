import { render, staticRenderFns } from "./Folha.vue?vue&type=template&id=77551ad6&scoped=true&"
import script from "./Folha.vue?vue&type=script&lang=js&"
export * from "./Folha.vue?vue&type=script&lang=js&"
import style0 from "./Folha.vue?vue&type=style&index=0&id=77551ad6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77551ad6",
  null
  
)

export default component.exports