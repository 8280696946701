<template>
  <el-dialog
    :title="isEdit ? 'Detalhes e Editar Perfil' : 'Criar perfil'"
    width="40%"
    :fullscreen="fullscreen"
    :visible.sync="open"
    :before-close="onClose"
  >
    <div class="container">
      <el-form
        :model="formValue"
        label-position="top"
      >
        <el-row :gutter="10">
          <div v-if="!isEdit">
            <el-col :span="10">
              <p>Nome</p>
              <el-input
                v-model="formValue.name"
                placeholder="Nome"
                maxlength="200"
              />
            </el-col>
            <el-col :span="10">
              <p>E-mail</p>
              <el-input
                v-model="formValue.email"
                placeholder="E-Mail"
                maxlength="200"
              />
            </el-col>
          </div>
          <div v-else>
            <el-col :span="10">
              <p>Nome</p>
              <b>
                {{ formValue.name }}
              </b>
            </el-col>
            <el-col :span="10">
              <p>E-mail</p>
              <b>
                {{ formValue.email }}
              </b>
            </el-col>
          </div>
        </el-row>
        <div v-if="profileIsGP">
          <el-row>
            <label> Projeto Associados: </label>
          </el-row>
          <el-table
            :data="selectedProfileCostcenters"
            style="width: 100%"
            stripe
            scroll
          >
            <el-table-column
              property="name"
              label="Projeto"
            />
            <el-table-column
              property="code"
              label="CC"
            />
          </el-table>
        </div>
        <el-row :gutter="30">
          <div>
            <el-col :span="10">
              <el-form-item label="Status">
                <el-select
                  v-model="formValue.status"
                  filterable
                  placeholder="Status"
                  clearable
                  remote
                  loading-text="Aguarde..."
                >
                  <el-option
                    value="ativo"
                    label="Ativo"
                  />
                  <el-option
                    value="cancelado"
                    label="Cancelado"
                  />
                  <el-option
                    value="suspenso"
                    label="Suspenso"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="Perfil">
                <el-select
                  v-model="formValue.profile"
                  value-key="id"
                  filterable
                  placeholder="Perfil"
                  clearable
                  remote
                  loading-text="Aguarde..."
                >
                  <el-option
                    v-for="profile in profiles"
                    :key="profile.id"
                    :label="profile.name"
                    :value="profile"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </div>
        </el-row>
        <el-row
          type="flex"
          align="center"
          justify="end"
        >
          <el-button
            style="background-color: #f5f5f5; border: 0px"
            size="small"
            @click="handleClose"
          >
            Cancelar
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="save"
          >
            Salvar
          </el-button>
        </el-row>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {
  Dialog, Form, Row, Col, FormItem, Select, Button,
} from 'element-ui';

export default {
  name: 'EditProfileDialog',
  components: {
    'el-dialog': Dialog,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-row': Row,
    'el-col': Col,
    'el-select': Select,
    'el-button': Button,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    selectedProfile: [Object, null],
    updateUser: Function,
    createUser: Function,
    isEdit: Boolean,
  },
  data() {
    return {
      initialValues: {
        name: null,
        email: null,
        status: 0,
        profile: { id: null, name: null },
      },
      formValue: { ...this.initialValues },
      fullscreen: false,
      windowWidth: 0,
      profiles: [],
      selectedProfileCostcenters: [],
      profileIsGP: false,
    };
  },
  watch: {
    windowWidth(width) {
      this.fullscreen = Boolean(width < 768);
    },
  },
  async mounted() {
    if (this.selectedProfile) {
      this.formValue = this.selectedProfile;
      this.getUsersCostcenters();
    }
    this.getProfiles();
    this.isProfileGp();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  methods: {
    async getProfiles() {
      const params = { forCreation: true };
      await this.$api()
        .get('/profile/profiles', { params })
        .then((response) => {
          this.profiles = response.data;
        });
    },
    async getUsersCostcenters() {
      const params = { userId: this.selectedProfile.id };
      await this.$api()
        .get('/profile/costcenters', { params })
        .then((response) => {
          this.selectedProfileCostcenters = response.data;
        });
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    handleClose() {
      this.onClose();
      this.formValue = this.initialValues;
    },
    isProfileGp() {
      if (this.selectedProfile && this.selectedProfile.profile.id === 4) {
        this.profileIsGP = true;
      } else {
        this.profileIsGP = false;
      }
    },
    async save() {
      if (!this.formValue.name
          || !this.formValue.email
          || !this.formValue.status
          || !this.formValue.profile) {
        this.$notify.error({
          title: 'Alerta',
          message: 'Preencha todos os campos',
          duration: 3000,
        });
        return;
      }
      if (this.isEdit) {
        await this.updateUser(this.formValue);
      } else {
        await this.createUser(this.formValue);
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
  .container {
    .el-form {
      width: 100%;
      .el-select,
      .el-input {
        width: 100%;
      }
    }
  }
  </style>
