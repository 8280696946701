import PERMISSIONS_IN_MENU from './permissions';

const menuTitlesConsts = {
  DRE: 'DRE',
  FOLHA: 'Folha',
  RATEIO: 'Rateio',
  AJUSTES: 'Ajustes',
  PROVISOES: 'Provisões',
  FECHAMENTO: 'Fechamento',
  CONFIGURACOES: 'Configurações',
};

const menuPathsConsts = {
  DRE: '/lancamentos',
  FOLHA: '/folha',
  RATEIO: '/rhImportacao',
  AJUSTES: '/ajustes',
  PROVISOES: '/provisoes',
  FECHAMENTO: '/fechamentoContabil',
  CONFIGURACOES: '/configuracoes',
};

const menu = [
  {
    path: menuPathsConsts.DRE,
    title: menuTitlesConsts.DRE,
    permissions: PERMISSIONS_IN_MENU.INCOME_REPORT,
  },
  {
    path: menuPathsConsts.FOLHA,
    title: menuTitlesConsts.FOLHA,
    permissions: PERMISSIONS_IN_MENU.PAYROLL,
  },
  {
    path: menuPathsConsts.RATEIO,
    title: menuTitlesConsts.RATEIO,
    permissions: PERMISSIONS_IN_MENU.APPORTIONMENT,
  },
  {
    path: menuPathsConsts.AJUSTES,
    title: menuTitlesConsts.AJUSTES,
    permissions: PERMISSIONS_IN_MENU.ADJUSTS,
  },
  {
    path: menuPathsConsts.PROVISOES,
    title: menuTitlesConsts.PROVISOES,
    permissions: PERMISSIONS_IN_MENU.PROVISION,
  },
  {
    path: menuPathsConsts.FECHAMENTO,
    title: menuTitlesConsts.FECHAMENTO,
    permissions: PERMISSIONS_IN_MENU.CLOSING,
  },
  {
    path: menuPathsConsts.CONFIGURACOES,
    title: menuTitlesConsts.CONFIGURACOES,
    permissions: PERMISSIONS_IN_MENU.CONFIG,
  },
];

const data = {
  menu, menuTitlesConsts, menuPathsConsts,
};

export default data;
