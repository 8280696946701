<template>
  <div>
    <bread-crumb :breadcrumbs="['Configurações']" />
    <el-form />
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';

export default {
  name: 'Configuracoes',
  components: { BreadCrumb },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
