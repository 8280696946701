<template>
  <div class="container">
    <bread-crumb :breadcrumbs="['Ajustes']" />
    <el-row>
      <el-col :span="18">
        <h1>Ajustes</h1>
        <br><br>
        <h2>Filtros</h2>
      </el-col>
    </el-row>
    <el-form
      ref="form"
      label-position="top"
    >
      <el-row
        :gutter="20"
        style="margin-right: 0 !important"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="3"
          :xl="3"
        >
          <el-form-item label="Mês / Ano">
            <el-date-picker
              v-model="formData.yearMonth"
              size="small"
              type="month"
              placeholder="Mês / Ano"
              format="MMM/yyyy"
              value-format="yyyy-MM"
              class="inputs-full-width"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item label="Conta contábil">
            <el-select
              v-model="formData.ledgerAccount"
              class="inputs-full-width"
              multiple
              filterable
              placeholder="Conta contábil"
              autocomplete
              clearable
              remote
              size="small"
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getLedgeAccount"
              :loading="loadingLedgerAccountList"
            >
              <el-option
                v-for="item in ledgerAccountList"
                :key="item.id"
                :label="`${item.code} - ${item.name}`"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item label="CC Destino">
            <el-select
              v-model="formData.costCenterDestiny"
              multiple
              size="small"
              filterable
              placeholder="Centros de Custo"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getCostsCenterDestinyFilter"
              :loading="loadingCostCenterDestinyFilter"
              class="inputs-full-width"
            >
              <el-option
                v-for="item in costCenterDestinyFilter"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item label="ID Ajuste">
            <el-select
              v-model="formData.idSettings"
              size="small"
              filterable
              placeholder="ID Ajuste"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getIdSettings"
              :loading="loadingIdSettings"
              class="inputs-full-width"
            >
              <el-option
                v-for="item in idSettings"
                :key="item.id"
                :label="item.specialCode"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="3"
          :xl="3"
        >
          <el-form-item label="Situação">
            <el-select
              v-model="formData.situation"
              size="small"
              clearable
              filterable
              placeholder="Situação"
              class="inputs-full-width"
            >
              <el-option
                value="APROVADO"
                label="Aprovado"
              />
              <el-option
                value="REJEITADO"
                label="Rejeitado"
              />
              <el-option
                value="PENDENTE"
                label="Pendente"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="6"
          :xl="9"
        >
          <el-row
            type="flex"
            justify="end"
            class="container-btns gap-1"
          >
            <guard :needed-permissions="[PERMISSIONS_ADJUSTS.REQUEST]">
              <el-button
                type="text"
                @click="requestDialog"
              >
                Solicitar
              </el-button>
            </guard>
            <guard :needed-permissions="[PERMISSIONS_ADJUSTS.DOWNLOAD]">
              <el-button
                size="small"
                :disabled="loadingDownload"
                :loading="loadingDownload"
                @click="getReport"
              >
                Download
              </el-button>
            </guard>
            <guard :needed-permissions="[PERMISSIONS_ADJUSTS.QUERY]">
              <el-button
                size="small"
                type="primary"
                :disabled="loadingGetAdjusts"
                :loading="loadingGetAdjusts"
                @click="getAdjusts(1)"
              >
                Consultar
              </el-button>
            </guard>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      v-loading="loadingGetAdjusts || loadingDownload"
      :data="tableData.results"
      style="width: 100%"
      stripe
    >
      <el-table-column
        property="adjust.accountingAccount.name"
        label="Conta contábil"
        :formatter="formatterAcctAcc"
      />
      <el-table-column
        property="adjust.type"
        label="Tipo de ajuste"
        width="120"
      />
      <el-table-column
        property="originCC"
        label="CC Origem"
        :formatter="formatterCostCenter"
      />
      <el-table-column
        property="destinationCC"
        label="CC Destino"
        :formatter="formatterCostCenter"
      />
      <el-table-column
        property="value"
        :formatter="formatterValue"
        label="Valor"
        width="140"
      />
      <el-table-column
        property="specialCode"
        label="ID Ajuste"
        width="120"
      />
      <el-table-column
        property="status"
        label="Situação"
        width="130"
      />
      <el-table-column
        property="requestDate"
        label="Data Solicitação"
        :formatter="formatterDate"
        width="140"
      />
      <el-table-column
        label="Ações"
        align="right"
      >
        <template slot-scope="scope">
          <div class="actions gap-1">
            <guard
              :needed-permissions="[PERMISSIONS_ADJUSTS.UPDATE]"
              show-tooltip
            >
              <img
                :src="editIcon"
                alt="edit"
                height="18"
                width="18"
                @click="handleOpenDialogSetting(scope)"
              >
              <template v-slot:denied>
                <img
                  :src="editIcon"
                  alt="edit"
                  height="18"
                  width="18"
                >
              </template>
            </guard>
            <guard
              :needed-permissions="[PERMISSIONS_ADJUSTS.DETAIL]"
              show-tooltip
            >
              <img
                :src="searchIcon"
                alt="search"
                height="18"
                width="18"
                @click="handleOpenDialogDetail(scope)"
              >
              <template v-slot:denied>
                <img
                  :src="searchIcon"
                  alt="search"
                  height="18"
                  width="18"
                >
              </template>
            </guard>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-row
      type="flex"
      justify="center"
      class="container-pagination"
    >
      <el-pagination
        small
        background
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="tableData.lastPage"
        :current-page="tableData.page"
        :page-count="tableData.lastPage"
        @current-change="getAdjusts"
      />
    </el-row>

    <adjust-insert
      v-if="dialogRequest"
      :open="dialogRequest"
      :handle-close="() => (dialogRequest = false)"
      :form-request="formRequest"
    />

    <adjust-edit
      v-if="selectedRow"
      :open="openDialogAdjustEdit"
      :form-edit.sync="formEdit"
      :selected-row="selectedRow"
      :handle-close="handleCloseDialogSetting"
      @clickCancel="handleCloseDialogSetting"
      @clickSave="clickSaveEdit"
    />

    <adjust-details
      v-if="selectedRow"
      :open="openDialogAdjustDetail"
      :selected-row="selectedRow"
      :handle-close="handleCloseDialogDetail"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { Form, FormItem } from 'element-ui';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import AdjustEdit from '../components/Adjusts/AdjustEditModal.vue';
import numberFormat from '../utils/IntlNumberFormat';
import AdjustDetails from '../components/Adjusts/AdjustDetailsModal.vue';
import AdjustInsert from '../components/Adjusts/AdjustAddModal.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import Guard from '../components/Guard/Guard.vue';

const searchIcon = require('../../public/media/icons/search.svg');
const editIcon = require('../../public/media/icons/edit.svg');

export default {
  name: 'Ajustes',
  components: {
    BreadCrumb,
    AdjustEdit,
    AdjustDetails,
    AdjustInsert,
    'el-form': Form,
    'el-form-item': FormItem,
    Guard,
  },
  data() {
    const validateLedgerAccount = (rule, value, callback) => {
      if (this.formRequest.type === 'CONTABIL') {
        if (!value) {
          callback(new Error('Obrigatório para Tipo Contábil'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      PERMISSIONS_ADJUSTS: FUNCTIONALITY_IDS.ADJUSTS,
      rules: {
        ledgerAccount: [{ validator: validateLedgerAccount, trigger: 'submit' }],
      },
      rulesFormEdit: {
        note: [{ required: true, message: 'Campo obrigatório', trigger: 'submit' }],
      },
      editIcon,
      searchIcon,
      tableData: {
        results: [],
        total: 0,
        size: 20,
        page: 1,
        lastPage: 1,
      },
      ledgerAccountList: [],
      loadingLedgerAccountList: false,
      loadingCostsCenter: false,
      loadingIdSettings: false,
      dialogSetting: false,
      dialogRequest: false,
      dialogDetail: false,
      formData: {
        yearMonth: '',
        ledgerAccount: [],
        costCenterDestiny: [],
        idSettings: null,
        situation: null,
      },
      idSettings: [],
      formRequest: {
        collaborator: null,
        yearMonth: '',
        typeTransfer: 'percentage',
        typeSettings: null,
        note: '',
        type: null,
        ledgerAccount: null,
        rows: [
          {
            costCenterOrigin: null,
            costCenterDestiny: null,
            value: 0,
          },
        ],
      },
      formEdit: {
        ledgerAccount: null,
        status: '',
        idSetting: '',
        note: '',
      },
      costCenterDestinyFilter: [],
      loadingCostCenterDestinyFilter: false,
      costCenterOriginSolicitation: [],
      loadingCostsCenterOriginSolicitation: false,
      costCenterDistinySolicitation: [],
      loadingCostsCenterDistinySolicitation: false,
      logicalSheet: false,
      logicalAccounting: false,
      showInputPercentage: false,
      itemsSearchColaborator: [],
      loadingItemsSearchColaborator: false,
      loadingGetAdjusts: false,
      loadingSubmit: false,
      selectedRow: null,
      loadingEditSetting: false,
      loadingDownload: false,
      openDialogAdjustDetail: false,
      openDialogAdjustEdit: false,
    };
  },
  mounted() {
    this.getLedgeAccount();
    this.getCostsCenterDestinyFilter();
    this.getIdSettings();
  },
  methods: {
    getAdjusts(page) {
      if (!this.formData.yearMonth) {
        this.$notify({
          title: 'Consultar Ajustes',
          message: 'Selecione o mês/ano',
          type: 'warning',
        });
        return;
      }

      const ledgerAccountSelected = this.formData.ledgerAccount?.map((item) => item.id);
      const costCenterDestinySelected = this.formData.costCenterDestiny?.map((item) => item.id);

      const params = {
        page,
        size: this.tableData.size,
        accountingAccount: ledgerAccountSelected.length > 0 ? ledgerAccountSelected : null,
        destinationCC: costCenterDestinySelected.length > 0 ? costCenterDestinySelected : null,
        specialCode: this.formData.idSettings?.specialCode || null,
        status: this.formData.situation || null,
      };

      this.loadingGetAdjusts = true;
      this.$api()
        .get(`/adjusts/year-month/${this.formData.yearMonth}`, { params })
        .then(({ data }) => {
          this.tableData.results = data?.data || [];
          this.tableData.total = data.total;
          this.tableData.page = data.currentPage;
          this.tableData.lastPage = data.lastPage;
        })
        .finally(() => {
          this.loadingGetAdjusts = false;
        });
    },
    requestDialog() {
      this.formRequest = {
        collaborator: null,
        yearMonth: '',
        typeTransfer: 'percentage',
        typeSettings: null,
        note: '',
        type: null,
        ledgerAccount: null,
        rows: [
          {
            costCenterOrigin: null,
            costCenterDestiny: null,
            value: 0,
          },
        ],
      };
      this.dialogRequest = true;
    },
    getLedgeAccount(search = '') {
      this.loadingLedgerAccountList = true;

      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.$api()
        .get('/accounting-account/list', { params })
        .then((response) => {
          this.ledgerAccountList = response.data.data;
        })
        .finally(() => {
          this.loadingLedgerAccountList = false;
        });
    },
    getCostsCenterDestinyFilter(search = '') {
      this.loadingCosgetCostsCenterOriginSolicitationtCenterDestinyFilter = true;

      const params = {
        search,
        page: 1,
        size: 20,
        parameterized: true,
      };

      this.$api()
        .get('/costcenter/only', { params })
        .then(({ data }) => {
          this.costCenterDestinyFilter = data.data;
        })
        .finally(() => {
          this.loadingCostCenterDestinyFilter = false;
        });
    },
    getIdSettings(search = '') {
      this.loadingIdSettings = true;
      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.$api()
        .get('/adjusts/all/special-codes', { params })
        .then((response) => {
          this.idSettings = response.data.data;
        })
        .finally(() => {
          this.loadingIdSettings = false;
        });
    },
    handleChancheNF(value) {
      this.formRequest.value = value.replace(/[^0-9.]/g, '');
    },
    handleOpenDialogDetail({ row }) {
      this.selectedRow = row;
      this.openDialogAdjustDetail = true;
    },
    handleCloseDialogDetail() {
      this.selectedRow = null;
      this.openDialogAdjustDetail = false;
    },
    handleOpenDialogSetting({ row }) {
      this.selectedRow = row;
      this.formEdit = {
        ledgerAccount: row?.adjust?.accountingAccount,
        status: row.status,
        idSetting: row?.specialCode,
        note: '',
      };
      this.dialogSetting = true;
      this.openDialogAdjustEdit = true;
    },
    handleCloseDialogSetting() {
      this.selectedRow = null;
      this.dialogSetting = false;
      this.openDialogAdjustEdit = false;
      this.formEdit = {
        ledgerAccount: null,
        status: '',
        idSetting: '',
        note: '',
      };
    },
    handleCloseDialogInsert() {
      this.selectedRow = null;
      this.dialogSetting = false;
      this.openDialogAdjustEdit = false;
      this.formEdit = {
        ledgerAccount: null,
        status: '',
        idSetting: '',
        note: '',
      };
    },
    clickSaveEdit(value) {
      this.formEdit = value;
      this.requestEditSetting();
    },
    requestEditSetting() {
      this.loadingEditSetting = true;
      const { year, month } = this.selectedRow.adjust;
      const params = {
        status: this.formEdit.status,
        observation: this.formEdit.note,
        yearMonth: `${year}-${month}`,
      };

      this.$api()
        .patch(`/adjusts/entry/${this.selectedRow.id}`, params)
        .then(() => {
          this.handleCloseDialogSetting();
          this.$notify.success({
            title: 'Alerta',
            message: 'Ajuste atualizado com sucesso',
            duration: 3000,
          });
        })
        .finally(() => {
          this.loadingEditSetting = false;
        });
    },
    validateParamsToGetReport() {
      const errors = [];

      if (!this.formData.yearMonth) {
        errors.push('mês/ano');
      }

      if (errors.length > 0) {
        const message = `Informe ${errors.length > 1 ? 'os campos' : 'o campo'}: ${errors.join(
          ', ',
        )}`;
        this.$notify({
          title: 'Download do relatório',
          message,
          type: 'warning',
        });

        return false;
      }

      return true;
    },
    getReport() {
      if (!this.validateParamsToGetReport()) {
        return;
      }

      this.loadingDownload = true;

      const ledgerAccountSelected = this.formData.ledgerAccount?.map((item) => item.id);
      const costCenterDestinySelected = this.formData.costCenterDestiny?.map((item) => item.id);

      const params = {
        yearMonth: this.formData.yearMonth,
        accountingAccount: ledgerAccountSelected.length > 0 ? ledgerAccountSelected : null,
        destinationCC: costCenterDestinySelected.length > 0 ? costCenterDestinySelected : null,
        specialCode: this.formData.idSettings?.specialCode || null,
        status: this.formData.situation || null,
      };

      this.$api()
        .get('/adjusts/download', { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report.xlsx';
          link.click();
        })
        .finally(() => {
          this.loadingDownload = false;
        });
    },
    formatterDate(row) {
      const [year, month, day] = row.requestDate?.split('-');
      return DateTime.local(Number(year), Number(month), Number(day))
        .setLocale('fr')
        .toFormat('dd/LL/yyyy');
    },
    formatterValue(row) {
      if (row?.adjust?.transferType === '%') {
        return `${row.value}%`;
      }
      return numberFormat(row.value);
    },
    formatterAcctAcc(row) {
      return `${row?.adjust?.accountingAccount?.code || ''} - ${
        row?.adjust?.accountingAccount?.name || ''
      }`;
    },
    formatterCostCenter(row, column, cellValue) {
      return `${cellValue?.code || ''} - ${cellValue.name || ''}`;
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    cursor: pointer;
    & + img {
      margin-left: 0.5rem;
    }
  }
}

.inputs-full-width {
  width: 100% !important;
}

.container-btns {
  margin-top: 35px;
  .el-button {
    height: 33px;
  }
}
.container {
  padding: 0 4rem 0 0;
  .el-form {
    margin-top: 1.5rem;
    width: 100%;
    .el-select,
    .el-input {
      width: 100%;
    }
  }
}

.container-employee-apportionment {
  margin-bottom: 52px;
}
h1 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  color: #4b4b4b;
}
.title-employee-apportionment {
  margin-bottom: 9px;

  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}

.row-employee-apportionment {
  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
}

.container-pagination {
  margin-top: 1rem;
}

@media (max-width: 1280px) {
  .container-btns {
    margin-top: 0px;
    margin-bottom: 1rem;
  }
}
</style>
