<template>
  <el-dialog
    title="Provisionar"
    width="40%"
    :fullscreen="fullscreen"
    :visible.sync="open"
    :before-close="onClose"
  >
    <hr>
    <div class="container">
      <el-form
        ref="formValue"
        :model="formValue"
        label-position="top"
        hide-required-asterisk
        :rules="rules"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-form-item label="Data Contábil">
              <el-date-picker
                v-model="formValue.date"
                type="date"
                placeholder="Data Contábil"
                format="dd/MM/yyyy"
              />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-form-item label="Prédio">
              <el-select
                v-model="formValue.building"
                class="input"
                value-key="id"
                clearable
                filterable
                placeholder="Prédio"
                :loading="loadingBuildingsList"
              >
                <el-option
                  v-for="item in buildingsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <el-form-item label="Centro de custo">
              <el-select
                v-model="formValue.costCenter"
                filterable
                placeholder="Centro de Custo"
                autocomplete
                clearable
                remote
                loading-text="Aguarde..."
                value-key="id"
                :remote-method="getCostsCenter"
                :loading="loadingCostsCenter"
              >
                <el-option
                  v-for="item in costsCenterList"
                  :key="item.id"
                  :label="`${item.code} - ${item.name}`"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-form-item label="Tipo Provisão">
              <el-select
                v-model="formValue.provisionType"
                filterable
                placeholder="Tipo Provisão"
                clearable
                @change="changedProvisionType"
              >
                <el-option
                  label="Receita"
                  value="Receita"
                />
                <el-option
                  label="Despesa"
                  value="Despesa"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-form-item label="Provisão">
              <el-select
                v-model="formValue.provision"
                :disabled="!formValue.provisionType"
                filterable
                placeholder="Provisão"
                autocomplete
                clearable
                remote
                loading-text="Aguarde..."
                value-key="id"
                :remote-method="getProvisions"
                :loading="loadingProvisions"
                @change="changedProvision"
              >
                <el-option
                  v-for="item in provisionsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <div v-if="formValue.provision === 24">
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <el-form-item label="Conta Contábil de Débito">
                <el-select
                  v-model="formValue.debit"
                  filterable
                  placeholder="Débito"
                  autocomplete
                  clearable
                  remote
                  loading-text="Aguarde..."
                  value-key="id"
                  :remote-method="getAccountingAccountsForDebit"
                  :loading="loadingAccountingAccountDebit"
                >
                  <el-option
                    v-for="item in accountingAccountDebitList"
                    :key="item.id"
                    :label="`${item.code} - ${item.name}`"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <el-form-item label="Conta Contábil de Crédito">
                <el-select
                  v-model="formValue.credit"
                  filterable
                  placeholder="Crédito"
                  autocomplete
                  clearable
                  remote
                  loading-text="Aguarde..."
                  value-key="id"
                  :remote-method="getAccountingAccountsForCredit"
                  :loading="loadingAccountingAccountCredit"
                >
                  <el-option
                    v-for="item in accountingAccountCreditList"
                    :key="item.id"
                    :label="`${item.code} - ${item.name}`"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-form-item label="Valor">
              <el-currency-input v-model="formValue.value" />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <el-form-item label="Observação">
              <el-input
                v-model="formValue.observation"
                type="textarea"
                maxlength="200"
                show-word-limit
                rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          align="center"
          justify="end"
        >
          <el-button
            style="background-color: #f5f5f5; border: 0px"
            size="small"
            @click="handleClose"
          >
            Cancelar
          </el-button>
          <el-button
            type="primary"
            size="small"
            :disabled="loadingSubmit"
            :loading="loadingSubmit"
            @click="handleSubmit"
          >
            Salvar
          </el-button>
        </el-row>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import {
  Dialog, Form, Row, Col, FormItem, DatePicker, Input, Select, Button,
} from 'element-ui';

import ElCurrencyInput from '../../../../components/ElCurrencyInput/ElCurrencyInput.vue';

export default {
  name: 'ProvisionarDialog',
  components: {
    'el-dialog': Dialog,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-row': Row,
    'el-col': Col,
    'el-date-picker': DatePicker,
    'el-input': Input,
    'el-select': Select,
    'el-button': Button,
    ElCurrencyInput,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formValue: {
        date: null,
        building: null,
        costCenter: null,
        provisionType: null,
        provision: null,
        originRelease: null,
        observation: null,
        value: null,
        debit: null,
        credit: null,
      },
      loadingBuildingsList: false,
      buildingsList: [],
      loadingCostsCenter: false,
      costsCenterList: [],
      provisionsList: [],
      loadingProvisions: false,
      loadingSubmit: false,
      accountingAccountDebitList: [],
      loadingAccountingAccountDebit: false,
      accountingAccountCreditList: [],
      loadDebitAndCredit: false,
      loadDebit: false,
      loadCredit: false,
      loadingAccountingAccountCredit: false,
      fullscreen: false,
      windowWidth: 0,
    };
  },
  watch: {
    windowWidth(width) {
      this.fullscreen = Boolean(width < 768);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });
    this.getBuildings();
    this.getCostsCenter();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    async changedProvisionType() {
      this.formValue.provision = null;
      await this.getProvisions();
    },
    async changedProvision(id) {
      this.loadDebitAndCredit = true;
      if (id === 24) {
        await this.getAccountingAccounts();
      }
    },
    async getAccountingAccountsForDebit(search = '') {
      this.loadDebit = true;
      await this.getAccountingAccounts(search);
    },
    async getAccountingAccountsForCredit(search = '') {
      this.loadCredit = true;
      await this.getAccountingAccounts(search);
    },
    getAccountingAccounts(search) {
      if (this.loadDebit) {
        this.loadingAccountingAccountDebit = true;
      } else if (this.loadCredit) {
        this.loadingAccountingAccountCredit = true;
      } else {
        this.loadingAccountingAccountDebit = true;
        this.loadingAccountingAccountCredit = true;
      }
      const params = {
        search,
        page: 1,
        size: 20,
      };
      this.$api()
        .get('/accounting-account/list', { params })
        .then(({ data }) => {
          if (this.loadDebit) {
            this.accountingAccountDebitList = data.data;
          } else if (this.loadCredit) {
            this.accountingAccountCreditList = data.data;
          } else {
            this.accountingAccountDebitList = data.data;
            this.accountingAccountCreditList = data.data;
          }
        })
        .finally(() => {
          if (this.loadDebit) {
            this.loadingAccountingAccountDebit = false;
          } else if (this.loadCredit) {
            this.loadingAccountingAccountCredit = false;
          } else {
            this.loadingAccountingAccountDebit = false;
            this.loadingAccountingAccountCredit = false;
          }
        });
    },
    getCostsCenter(search = '') {
      this.loadingCostsCenter = true;
      const params = {
        search,
        page: 1,
        size: 20,
      };
      this.$api()
        .get('/costcenter', { params })
        .then(({ data }) => {
          this.costsCenterList = data.data;
        })
        .finally(() => {
          this.loadingCostsCenter = false;
        });
    },
    getBuildings() {
      this.loadingBuildingsList = true;
      this.$api()
        .get('/buildings')
        .then(({ data }) => {
          this.buildingsList = data.data;
        })
        .finally(() => {
          this.loadingBuildingsList = false;
        });
    },
    getProvisions(search = '') {
      this.loadingProvisions = true;
      const params = {
        search,
        page: 1,
        size: 20,
        onlyRevenueType: this.formValue?.provisionType === 'Receita',
        onlyExpenseType: this.formValue?.provisionType === 'Despesa',
      };
      this.$api()
        .get('/provisions/', { params })
        .then(({ data }) => {
          this.provisionsList = data.data;
        })
        .finally(() => {
          this.loadingProvisions = false;
        });
    },
    handleSubmit() {
      if (
        !this.formValue.date
        || !this.formValue.building
        || !this.formValue.provisionType
        || !this.formValue.provision
        || !this.formValue.value
        || (this.formValue.provision === 24 && (!this.formValue.debit || !this.formValue.credit))
      ) {
        this.$notify.error({
          title: 'Alerta',
          message:
            'Existem campos obrigatórios não preenchidos. Somente os campos Centro de custo e Observação são opcionais.',
          duration: 4000,
        });
        return;
      }
      const { year, month } = DateTime.fromJSDate(this.formValue.date);
      const params = {
        date: this.formValue.date,
        yearMonth: `${year}-${month}`,
        observation: this.formValue.observation,
        building: this.formValue.building,
        provision: this.formValue.provision,
        value: this.formValue.value,
        costcenter: this.formValue.costCenter,
        accountingAccount:
          this.formValue?.debit && this.formValue?.credit
            ? { debit: { id: this.formValue?.debit }, credit: { id: this.formValue?.credit } }
            : undefined,
      };

      this.loadingSubmit = true;

      this.$api()
        .post('/provisions/', params)
        .then(() => {
          this.$notify({
            title: 'Provisão',
            message: 'Provisão cadastrada com sucesso!',
            type: 'success',
          });
        })
        .finally(() => {
          this.handleClose();
          this.loadingSubmit = false;
        });
    },
    handleClose() {
      this.onClose();
      this.formValue = {
        date: null,
        building: null,
        costCenter: null,
        provisionType: null,
        provision: null,
        originRelease: null,
        observation: null,
        value: null,
        debit: null,
        credit: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .el-form {
    width: 100%;
    .el-select,
    .el-input {
      width: 100%;
    }
  }
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
</style>
