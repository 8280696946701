<template>
  <div>
    <bread-crumb :breadcrumbs="['Configurações', 'Associação']" />
    <el-row :gutter="20">
      <el-col :span="18">
        <h2>Associação</h2>
      </el-col>
    </el-row>
    <guard :needed-permissions="[PERMISSIONS_ASSOCIATION.QUERY]">
      <el-form
        ref="form"
        label-position="top"
        hide-required-asterisk
      >
        <el-row
          class="container"
          :gutter="5"
        >
          <el-col
            :xs="24"
            :sm="24"
            :md="10"
            :lg="4"
            :xl="3"
          >
            <el-form-item label="Prédio">
              <el-select
                v-model="formAssociationData"
                autocomplete
                filterable
                class="input"
                clearable
                placeholder="Prédio"
                :loading="loadingBuildingsList"
              >
                <el-option
                  v-for="item in buildingsList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </guard>
    <el-row style="padding: 35px">
      <el-col
        :span="4"
      >
        <div class="grid-content bg-purple">
          Prédio:
        </div>
        <br><strong v-if="selectedBuilding">{{
          selectedBuilding.name
        }}</strong>
      </el-col>
      <el-col
        :span="4"
      >
        <div class="grid-content bg-purple">
          Regra:
        </div>
        <br><strong v-if="selectedBuilding">{{
          showConfig()
        }}</strong>
      </el-col>
      <el-col
        :span="4"
      >
        <div class="grid-content bg-purple">
          Parâmetros:
        </div>
        <br><strong v-if="nameRulesFix && nameRulesHC">{{
          showParameters()
        }}</strong><strong
          v-if="stallsCount"
        >Nº de baias: {{ stallsCount }}</strong>
      </el-col>
      <el-col
        :span="4"
      >
        <div class="grid-content bg-purple">
          Percentual disponível:
        </div>
        <br><strong
          v-if="nameRulesFix && nameRulesHC"
        >{{ availablePercentage }}%</strong>
      </el-col>
      <guard :needed-permissions="[PERMISSIONS_ASSOCIATION.WRITE]">
        <div class="buttons">
          <el-button
            size="mini"
            type="info"
            style="height: 30px; text-align: center"
            :disabled="!formAssociationData"
            @click="handleOpenAddAsso"
          >
            Incluir Associação
          </el-button>
          <el-button
            type="primary"
            style="height: 30px"
            size="mini"
            :disabled="
              (availablePercentage != 0 ||
                !formAssociationData ||
                loadingSaveChanges) && tableDataBuilding.length !== 0
            "
            :loading="loadingSaveChanges"
            @click="dialogSaveChangesClick"
          >
            Salvar Alterações
          </el-button>
        </div>
      </guard>
    </el-row>
    <guard :needed-permissions="[PERMISSIONS_ASSOCIATION.WRITE]">
      <el-dialog
        v-if="dialogIncludeProject"
        title="Incluir Associação"
        :visible.sync="dialogIncludeProject"
        width="30%"
      >
        <el-form>
          <el-form-item label="Centro de custo">
            <el-select
              v-model="formInclude.costCenter"
              autocomplete
              filterable
              style="width: 100%"
              class="input"
              clearable
              remote
              loading-text="Aguarde..."
              placeholder="Centro de custo"
              :remote-method="costCenter"
              :loading="loadingCostCenterList"
              value-key="id"
            >
              <el-option
                v-for="costcenter in costcenters"
                :key="costcenter.code"
                :label="`${costcenter.code} - ${costcenter.name}`"
                :value="costcenter"
              />
            </el-select>
          </el-form-item>
          <el-row :gutter="20">
            <el-col
              v-if="formInclude.costCenter"
              :span="10"
            >
              <p style="bottom: 15px">
                Regras
              </p>
              <el-form-item>
                <el-select
                  v-model="formInclude.selectedRule"
                  placeholder="Regra"
                  style="top: 18px"
                  value-key="id"
                >
                  <el-option
                    v-for="item in getRulesSelectedBuilding()"
                    :key="item.id"
                    :label="item.apportionmentRule"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              v-if="
                formInclude.selectedRule?.apportionmentRule === 'FIXO' &&
                formInclude.costCenter &&
                validateFixedBuilding()
              "
              :span="10"
              :gutter="30"
            >
              <p style="bottom: 15px">
                Parâmetros
              </p>
              <br>
              <el-input
                v-model="formInclude.parameters"
                type="number"
                class="input"
                :min="0"
                :max="100"
                style="width: 190px"
                placeholder="%"
              >
                <template slot="append">
                  %
                </template>
              </el-input>
            </el-col>
          </el-row>
        </el-form>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            type="info"
            @click="dialogIncludeProject = false"
          >Cancelar</el-button>
          <el-button
            type="primary"
            :disabled="!formInclude.costCenter || !formInclude.selectedRule"
            @click="includeAssociation()"
          >OK</el-button>
        </span>
      </el-dialog>
    </guard>
    <el-table
      v-loading="tableBuildingLoad"
      :data="tableDataBuilding"
      stripe
    >
      <el-table-column
        property="costcenter.name"
        label="Nome do Projeto"
        width="350"
        align="left"
      />
      <el-table-column
        property="costcenter.code"
        label="Centro de custo"
        width="200"
        align="left"
      />
      <el-table-column
        property="costcenter.projectSubtype.name"
        label="Tipo"
        align="left"
        width="200"
      />
      <el-table-column
        property="buildingFraction.apportionmentRule"
        label="Regra"
        align="left"
        width="200"
      />
      <el-table-column
        property="formattedFraction"
        label="Parâmetros"
        align="left"
      />
      <el-table-column
        label="Ações"
        align="left"
      >
        <template slot-scope="scope">
          <div class="actions">
            <img
              :src="editIcon"
              alt="edit"
              height="18"
              width="18"
              @click="editBuilding(scope.row)"
            >
            <img
              :src="deleteIcon"
              alt="search"
              height="14"
              width="14"
              @click="deleteBuilding(scope.row)"
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <guard :needed-permissions="[PERMISSIONS_ASSOCIATION.WRITE]">
      <el-form>
        <el-dialog
          v-if="selectedRow"
          title="Exclusão"
          :visible.sync="dialogDeleteAssociation"
          width="30%"
        >
          <el-form-item>
            <span>
              Deseja excluir o projeto
              <strong>{{ selectedRow.costcenter.name }} ?</strong>
            </span>
          </el-form-item>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              type="info"
              @click="handleCloseDialogDeleteAssociation"
            >
              Cancelar
            </el-button>
            <el-button
              type="primary"
              @click="deleteAssociation"
            > OK </el-button>
          </span>
        </el-dialog>
      </el-form>
      <el-form>
        <el-dialog
          title="Deseja salvar as alterações ?"
          :visible.sync="dialogSaveChanges"
          width="30%"
        >
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              type="info"
              @click="dialogSaveChanges = false"
            >
              Cancelar
            </el-button>
            <el-button
              type="primary"
              :loading="loadingSaveChanges"
              :disabled="loadingSaveChanges"
              @click="saveChanges"
            >
              SIM
            </el-button>
          </span>
        </el-dialog>
      </el-form>
      <el-form>
        <el-dialog
          v-if="selectedRow"
          title="Editar Associação"
          :visible.sync="dialogEditAssociation"
          width="30%"
        >
          <el-form
            label-position="top"
            label-width="100%"
            :model="formEdit"
          >
            <el-form-item label="Centro de custo">
              <el-select
                v-model="formEdit.costcenter"
                autocomplete
                filterable
                style="width: 100%"
                class="input"
                clearable
                placeholder="Centro de custo"
                :loading="loadingCostCenterListEdit"
                value-key="id"
              >
                <el-option
                  v-for="costcenter in costcentersEdit"
                  :key="costcenter.code"
                  :label="`${costcenter.code} - ${costcenter.name}`"
                  :value="costcenter"
                />
              </el-select>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="Regras">
                  <el-select
                    v-model="formEdit.buildingFraction"
                    placeholder="Regra"
                    value-key="id"
                  >
                    <el-option
                      v-for="item in getRulesSelectedBuilding()"
                      :key="item.id"
                      :label="item.apportionmentRule"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                v-if="formEdit.buildingFraction?.apportionmentRule === 'FIXO'"
                :span="10"
              >
                <el-form-item label="Parâmetros">
                  <el-input
                    v-model="formEdit.fraction"
                    type="number"
                    class="input"
                    :min="0"
                    :max="100"
                    placeholder="%"
                  >
                    <template slot="append">
                      %
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              type="info"
              @click="dialogEditAssociation = false"
            >
              Cancelar
            </el-button>
            <el-button
              type="primary"
              @click="handleEditRow"
            >OK</el-button>
          </span>
        </el-dialog>
      </el-form>
    </guard>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

const editIcon = require('../../public/media/icons/edit.svg');
const deleteIcon = require('../../public/media/icons/delete.svg');

export default {
  name: 'ConfiguracoesAssociacao',
  components: { BreadCrumb, Guard },
  data() {
    return {
      PERMISSIONS_ASSOCIATION: FUNCTIONALITY_IDS.CONFIG.ASSOCIATION,
      editIcon,
      deleteIcon,
      tableDataBuilding: [],
      tableBuildingLoad: false,
      dialogDeleteAssociation: false,
      dialogEditAssociation: false,
      dialogIncludeProject: false,
      dialogSaveChanges: false,
      loadingBuildingsList: false,
      loadingCostCenterList: false,
      loadingCostCenterListEdit: false,
      loadingSaveChanges: false,
      formAssociationData: null,
      selectedBuilding: null,
      formCostCenter: null,
      buildingsList: [],
      costcenters: [],
      costcentersEdit: [],
      buildingSupport: [],
      nameRulesFix: false,
      nameRulesHC: false,
      stallsCount: false,
      selectedRow: null,
      availablePercentage: 0,
      totalPercentageSupport: 0,
      formEdit: {},
      formInclude: {
        parameters: null,
        selectedRule: null,
        costCenter: null,
      },
    };
  },
  watch: {
    formAssociationData(val) {
      this.selectedBuilding = this.buildingSupport.find(
        (item) => item.id === val,
      );
      if (this.formAssociationData === '' || null) {
        this.tableDataBuilding = null;
      }
      this.showConfig();
      this.tableBuildings();
    },
  },
  mounted() {
    this.getBuildings();
  },
  methods: {
    validateFixedBuilding() {
      return !this.selectedBuilding?.configs?.[0]?.fractions?.find(
        (item) => item.apportionmentRule === 'FIXO',
      );
    },
    handleCloseDialogDeleteAssociation() {
      this.dialogDeleteAssociation = false;
      this.selectedRow = null;
    },
    calculateTotalPercentageSupport() {
      this.totalPercentageSupport = this.tableDataBuilding.reduce(
        (prevVal, elem) => prevVal + elem.fraction * 100,
        0,
      );
      this.selectedBuilding.configs.forEach(({ fractions }) => {
        fractions.forEach((fraction) => {
          if (fraction.apportionmentRule === 'FIXO') {
            this.availablePercentage = fraction.fraction * 100 - this.totalPercentageSupport;
          }
        });
      });
    },
    deleteAssociation() {
      this.tableDataBuilding = this.tableDataBuilding.filter(
        (el) => el.id !== this.selectedRow.id,
      );
      this.calculateTotalPercentageSupport();
      this.handleCloseDialogDeleteAssociation();
    },
    handleOpenAddAsso() {
      this.costCenter();
      this.formInclude.costCenter = null;
      this.formInclude.selectedRule = null;
      this.formInclude.parameters = null;
      this.dialogIncludeProject = true;
    },
    async getBuildings() {
      this.loadingBuildingsList = true;
      this.$api()
        .get('/buildings', {
          params: {
            customDataForAssociation: true,
          },
        })
        .then(({ data }) => {
          this.buildingSupport = data.data;
          this.buildingsList = data.data?.map((building) => ({
            value: building.id,
            label: building.name,
          }));
        })
        .finally(() => {
          this.loadingBuildingsList = false;
        });
    },
    getRulesSelectedBuilding() {
      const rules = [];
      if (this.formAssociationData) {
        this.buildingSupport
          .filter((item) => item.id === this.formAssociationData)
          .forEach(({ configs }) => {
            configs.forEach(({ fractions }) => {
              fractions.forEach((fraction) => {
                if (!rules.includes(fraction.apportionmentRule)) {
                  rules.push({
                    apportionmentRule: fraction.apportionmentRule,
                    id: fraction.id,
                  });
                }
              });
            });
          });
      }
      return rules;
    },
    async tableBuildings() {
      this.tableBuildingLoad = true;
      this.$api()
        .get(`buildings/associations/${this.selectedBuilding.configs?.[0]?.id}`)
        .then(({ data }) => {
          this.tableDataBuilding = data.map((item) => ({
            ...item,
            formattedFraction:
              item.buildingFraction?.apportionmentRule === 'HEADCOUNT'
                ? ''
                : `${(item.fraction || 0) * 100}%`,
          }));
          this.totalPercentageSupport = this.tableDataBuilding.reduce(
            (prevVal, elem) => prevVal + elem.fraction * 100,
            0,
          );
          this.selectedBuilding.configs.forEach(({ fractions }) => {
            fractions.forEach((fraction) => {
              if (fraction.apportionmentRule === 'FIXO') {
                this.availablePercentage = fraction.fraction * 100 - this.totalPercentageSupport;
              }
            });
          });
        })
        .finally(() => {
          this.stallsCount = this.selectedBuilding.configs?.[0].headcountLimit;
          this.tableBuildingLoad = false;
        });
    },
    showParameters() {
      const parameters = [];
      this.selectedBuilding.configs.forEach(({ fractions }) => {
        fractions.forEach((fraction) => {
          parameters.push(
            `${
              fraction.apportionmentRule === 'HEADCOUNT'
                ? 'HC'
                : fraction.apportionmentRule
            } ${fraction.fraction * 100}%`,
          );
        });
      });
      return parameters.join(' - ');
    },
    showConfig() {
      let name = '';
      this.selectedBuilding.configs.forEach(({ fractions }) => {
        name = fractions.length > 1 ? 'Misto' : fractions[0]?.apportionmentRule;
        if (name === 'FIXO') {
          name = 'Fixo com limite';
          this.nameRulesFix = false;
          this.availablePercentage = 0;
        } else {
          this.nameRulesFix = true;
        }
        if (name === 'HEADCOUNT') {
          this.nameRulesHC = false;
          this.availablePercentage = 0;
        } else {
          this.nameRulesHC = true;
        }
      });
      return name;
    },
    costCenter(search = '') {
      if (this.dialogEditAssociation) {
        this.loadingCostCenterListEdit = true;
      } else {
        this.loadingCostCenterList = true;
      }
      const params = {
        search,
        parameterized: true,
        page: 1,
        size: 20,
      };
      this.$api()
        .get('costcenter/only', { params })
        .then(({ data }) => {
          if (this.dialogEditAssociation) {
            this.costcentersEdit = data.data;
          } else {
            this.costcenters = data.data;
          }
        })
        .finally(() => {
          if (this.dialogEditAssociation) {
            this.loadingCostCenterListEdit = false;
          } else {
            this.loadingCostCenterList = false;
          }
        });
    },
    includeAssociation() {
      this.dialogIncludeProject = false;
      const newRow = {
        buildingFraction: this.formInclude.selectedRule,
        costcenter: this.formInclude.costCenter,
        fraction: (this.formInclude.parameters || 0) / 100,
        formattedFraction:
          this.formInclude.selectedRule?.apportionmentRule === 'HEADCOUNT'
            ? ''
            : `${this.formInclude.parameters || 0}%`,
        id: uuid.v4(),
      };

      this.tableDataBuilding.push(newRow);

      this.calculateTotalPercentageSupport();
    },
    handleEditRow() {
      const index = this.tableDataBuilding.findIndex(
        (item) => item.id === this.formEdit?.id,
      );

      if (index < 0) {
        return;
      }

      const newValue = {
        ...this.formEdit,
        fraction: (this.formEdit?.fraction || 0) / 100,
        formattedFraction:
          this.formEdit.buildingFraction?.apportionmentRule === 'HEADCOUNT'
            ? ''
            : `${this.formEdit?.fraction || 0}%`,
      };

      const oldArray = [...this.tableDataBuilding];

      this.tableDataBuilding = [];

      oldArray[index] = newValue;

      this.tableDataBuilding = [...oldArray];

      this.calculateTotalPercentageSupport();

      this.dialogEditAssociation = false;
    },
    saveChanges() {
      if (!this.formAssociationData) {
        this.$notify.warning({
          title: 'Alerta',
          message: 'Selecione um prédio',
          duration: 3000,
        });
        return;
      }

      const params = this.tableDataBuilding.map((el) => ({
        buildingFraction: el.buildingFraction,
        costcenter: el.costcenter,
        fraction: el.fraction,
        id: typeof el.id === 'string' ? null : el.id,
      }));

      const buildingConfigId = this.buildingSupport.find(
        (item) => item.id === this.formAssociationData,
      )?.configs?.[0]?.id;

      this.loadingSaveChanges = true;

      this.$api()
        .put(
          `costcenter/save/changes?buildingConfigId=${buildingConfigId}`,
          params,
        )
        .then(() => {
          this.$notify.success({
            title: 'Sucesso',
            message: 'Alterações salvas com sucesso',
            duration: 3000,
          });

          this.tableBuildings();

          this.dialogSaveChanges = false;
        })
        .finally(() => {
          this.loadingSaveChanges = false;
        });
    },
    dialogSaveChangesClick() {
      this.dialogSaveChanges = true;
    },
    editBuilding(row) {
      this.costCenter();

      this.formEdit = {
        buildingFraction: row.buildingFraction,
        costcenter: row.costcenter,
        fraction: (row.fraction || 0) * 100,
        formattedFraction:
          row.buildingFraction?.apportionmentRule === 'HEADCOUNT'
            ? ''
            : `${(row.fraction || 0) * 100}%`,
        id: row.id,
      };

      this.selectedRow = row;

      this.dialogEditAssociation = true;
    },
    deleteBuilding(row) {
      this.formEdit = { ...row };
      this.dialogDeleteAssociation = true;
      this.selectedRow = row;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  position: relative;
  left: 175px;
}

.hover {
  cursor: pointer;
}

h2 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
.actions {
  img {
    cursor: pointer;
    & + img {
      margin-left: 0.5rem;
    }
  }
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
.container {
  position: relative;
  bottom: 0px;
}
</style>
