<template>
  <el-dialog
    width="30%"
    title="Cadastrar"
    :close-on-click-modal="false"
    :visible.sync="showRegisterModal"
    :before-close="handleCloseRegisterModal"
    :fullscreen="fullscreen"
  >
    <hr>
    <el-form
      ref="formRegister"
      :model="formRegister"
      :rules="formRegisterRules"
      label-position="top"
      hide-required-asterisk
    >
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-form-item
            label="Item"
            prop="item"
          >
            <el-select
              v-model="formRegister.item"
              filterable
              placeholder="Unidade Organizacional, BU ou célula"
              clearable
              class="inputs-full-width"
              @change="handleChangeItems"
            >
              <el-option
                :value="1"
                label="Unidade organizacional"
              />
              <el-option
                :value="2"
                label="BU"
              />
              <el-option
                :value="3"
                label="Célula"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <div v-if="formRegister.item">
          <el-col
            :xs="24"
            :sm="17"
            :md="17"
            :lg="17"
            :xl="17"
          >
            <el-form-item label="Nome">
              <el-input
                v-model="formRegister.name"
                placeholder="Nome"
                @input="(search) => getTableData(1, search)"
              />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="3"
            :md="3"
            :lg="3"
            :xl="3"
          >
            <el-form-item>
              <el-button
                style="margin-top: 30px;"
                :disabled="!formRegister.name"
                :loading="loadingAddItem"
                @click="addItem"
              >
                + INCLUIR
              </el-button>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <el-form-item>
              <el-table
                v-loading="loadingTableData"
                :data="formRegister.itemsList.results"
                stripe
              >
                <el-table-column
                  property="name"
                  label="Nome"
                />
                <el-table-column
                  fixed="right"
                  label="Ação"
                  width="80"
                >
                  <template slot-scope="scope">
                    <div class="actions">
                      <img
                        :src="deleteIcon"
                        alt="search"
                        height="18"
                        width="18"
                        @click="deleteItem(scope.$index)"
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-row
                type="flex"
                justify="center"
                class="container-pagination"
              >
                <el-pagination
                  small
                  background
                  hide-on-single-page
                  layout="prev, pager, next"
                  :page-size="formRegister.itemsList.lastPage"
                  :current-page="formRegister.itemsList.page"
                  :page-count="formRegister.itemsList.lastPage"
                  @current-change="getTableData"
                />
              </el-row>
            </el-form-item>
          </el-col>
        </div>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        :disabled="loadingSave"
        @click="handleCloseRegisterModal"
      >
        CANCELAR
      </el-button>
      <el-button
        type="primary"
        :disabled="loadingSave ||
          (!formRegister.itemsListRegister.length
            && !formRegister.itemsListDelete.length)"
        :loading="loadingSave"
        @click="handleClickSave"
      >
        SALVAR
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

const deleteIcon = require('../../../public/media/icons/delete.svg');

export default {
  name: 'ProjectStructureAddModal',
  props: {
    showRegisterModal: {
      type: Boolean,
      required: true,
    },
    handleCloseRegisterModal: {
      type: Function,
      required: true,
    },
    getOrganizationUnits: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      deleteIcon,
      windowWidth: 0,
      fullscreen: false,
      loadingSave: false,
      loadingTableData: false,
      loadingAddItem: false,
      formRegister: {
        item: null,
        name: null,
        itemsList: {
          results: [],
          total: 0,
          size: 8,
          page: 1,
          lastPage: 1,
        },
        itemsListRegister: [],
        itemsListDelete: [],
      },
      formRegisterRules: {
        item: [{ required: true, message: 'Campo obrigatório', trigger: 'submit' }],
      },
    };
  },

  watch: {
    windowWidth(width) {
      this.fullscreen = Boolean(width < 768);
    },
    showRegisterModal() {
      this.formRegister = {
        item: null,
        name: null,
        itemsList: {
          results: [],
          total: 0,
          size: 8,
          page: 1,
          lastPage: 1,
        },
        itemsListRegister: [],
        itemsListDelete: [],
      };
      this.$refs.formRegister?.clearValidate();
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },

    async handleChangeItems() {
      this.formRegister.name = null;
      this.formRegister.itemsList = {
        results: [],
        total: 0,
        size: 8,
        page: 1,
        lastPage: 1,
      };
      this.formRegister.itemsListRegister = [];
      this.formRegister.itemsListDelete = [];
      await this.getTableData(1);
    },

    async registerItems() {
      let endpoint;
      if (this.formRegister.item === 1) {
        endpoint = '/organization-unit/organizationUnit';
      }
      if (this.formRegister.item === 2) {
        endpoint = '/business-unit/businessUnit';
      }
      if (this.formRegister.item === 3) {
        endpoint = '/cell/cells';
      }
      this.$api()
        .post(`${endpoint}`, this.formRegister.itemsListRegister)
        .then(() => {
          this.$notify({
            title: 'Cadastrar',
            message: 'Cadastro efetuado com sucesso!',
            type: 'success',
          });
        });
    },

    deleteItem(index) {
      const itemDeleted = this.formRegister.itemsList.results[index];
      if (itemDeleted?.projects?.length || itemDeleted?.organizationUnit) {
        this.$notify.error({
          title: 'Alerta',
          message: 'Este item possui associação com projetos',
          duration: 3000,
        });
        return;
      }

      if (itemDeleted?.id) {
        this.formRegister.itemsListDelete.push(itemDeleted);
      }

      this.formRegister.itemsList.results.splice(index, 1);

      const addedItemIndex = this.formRegister.itemsListRegister.findIndex(
        (obj) => obj.name === itemDeleted?.name,
      );

      if (addedItemIndex !== -1) {
        this.formRegister.itemsListRegister.splice(addedItemIndex, 1);
      }
    },

    async deleteItems() {
      let endpoint;
      if (this.formRegister.item === 1) {
        endpoint = '/organization-unit/removeOrganizationUnits';
      }
      if (this.formRegister.item === 2) {
        endpoint = '/business-unit/removeBusinessUnits';
      }
      if (this.formRegister.item === 3) {
        endpoint = '/cell/removeCells';
      }
      const params = { ids: (this.formRegister.itemsListDelete.map((item) => item.id)) };

      this.$api()
        .delete(`${endpoint}`, { params })
        .then(() => {
          this.$notify({
            title: 'Exclusão',
            message: 'Exclusão efetuada com sucesso!',
            type: 'success',
          });
        });
    },

    async handleClickSave() {
      this.$refs.formRegister.validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.loadingSave = true;

        if (this.formRegister.itemsListRegister?.length) {
          await this.registerItems();
        }
        if (this.formRegister.itemsListDelete?.length) {
          await this.deleteItems();
        }

        this.loadingSave = false;
        this.handleCloseRegisterModal();
        this.getOrganizationUnits();
      });
    },

    async getTableData(page, search = '') {
      this.loadingTableData = true;
      let endpoint;
      if (this.formRegister.item === 1) {
        endpoint = '/organization-unit?includeProjects=true';
      }
      if (this.formRegister.item === 2) {
        endpoint = '/business-unit';
      }
      if (this.formRegister.item === 3) {
        endpoint = '/cell';
      }
      const params = { page, size: this.formRegister.itemsList.size, name: search };
      await this.$api().get(`${endpoint}`, { params })
        .then(({ data }) => {
          const listWithoutItemsDeleted = data?.data.filter(
            (item) => !this.formRegister.itemsListDelete.some(
              (itemDeleted) => itemDeleted.name === item.name,
            ),
          ) || [];

          if (page === 1) {
            this.formRegister.itemsList.results = this.formRegister.itemsListRegister
              .concat(listWithoutItemsDeleted);
          } else {
            this.formRegister.itemsList.results = listWithoutItemsDeleted;
          }

          this.formRegister.itemsList.total = data.total;
          this.formRegister.itemsList.page = data.currentPage;
          this.formRegister.itemsList.lastPage = data.lastPage;
        }).finally(() => {
          this.loadingTableData = false;
        });
    },

    async addItem() {
      this.loadingAddItem = true;
      await this.$api()
        .get(`/organization-unit/checkNameUse/${this.formRegister.name}`)
        .finally(() => {
          this.loadingAddItem = false;
        });
      const restoreItemIndex = this.formRegister.itemsListDelete.findIndex(
        (obj) => obj.name === this.formRegister.name,
      );
      if (restoreItemIndex !== -1) {
        this.formRegister.itemsListRegister.unshift(
          this.formRegister.itemsListDelete[restoreItemIndex],
        );
        this.formRegister.itemsList.results.unshift(
          this.formRegister.itemsListDelete[restoreItemIndex],
        );
        this.formRegister.itemsListDelete.splice(restoreItemIndex, 1);
      } else {
        this.formRegister.itemsList.results.unshift({ name: this.formRegister.name });
        this.formRegister.itemsListRegister.unshift({ name: this.formRegister.name });
      }
      this.loadingAddItem = false;
    },
  },

};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: -25px;
    margin-bottom: 25px;
    background: #e4e7ed;
  }

  .container-pagination {
    position: relative;
    top: 20px;
  }
  .actions {
    img {
      cursor: pointer;
    }
  }
  .inputs-full-width {
    width: 100% !important;
  }
</style>
