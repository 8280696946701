<template>
  <div>
    <bread-crumb
      :breadcrumbs="['Rateio', 'Correlatos', 'Movimentação']"
    />
    <h1>Movimentação</h1>
    <br><br><br>
    <span> Mês e ano contábil</span>
    <br><br>
    <el-form
      ref="form"
      label-position="top"
      label-width="100%"
      hide-required-asterisk
      :model="formData"
      :rules="rules"
    >
      <el-row
        :gutter="220"
        style="margin-right: 0px"
      >
        <el-col :span="16">
          <el-form-item prop="yearMonth">
            <el-date-picker
              v-model="formData.yearMonth"
              format="MM/yyyy"
              value-format="yyyy-MM"
              placeholder="Mês e ano contábil"
              type="month"
            />
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <guard :needed-permissions="[PERMISSIONS_MOVEMENT.QUERY]">
            <el-button
              type="primary"
              class="queryButton"
              :loading="loading"
              background-color="#e87305"
              @click="submitForm('form')"
            >
              CONSULTAR
            </el-button>
          </guard>
        </el-col>
        <el-col :span="1">
          <guard :needed-permissions="[PERMISSIONS_MOVEMENT.IMPORT]">
            <el-button
              type="secondary"
              class="importButton"
              :loading="loading"
              @click="openDialog()"
            >
              IMPORTAR MOVIMENTAÇÕES
            </el-button>
          </guard>
        </el-col>
      </el-row>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 95%"
        row-key="id"
        stripe
        class="table"
      >
        <el-table-column
          fixed
          prop="period"
          label="Referência"
          align="left"
        />
        <el-table-column
          prop="status"
          label="Status"
          align="left"
        />
        <el-table-column
          prop="actions"
          label="Ações"
          align="left"
          min-width="70px"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.actions">
              <span class="disabled-actions">Download Relatório</span>
              <guard
                :needed-permissions="[PERMISSIONS_MOVEMENT.EXECUTE_APPORTIONMENT]"
                show-tooltip
              >
                <a
                  class="ml-2"
                  @click="performApportionmentOfCorrelates(scope.row)"
                >
                  Executar rateio
                </a>
                <template v-slot:denied>
                  <a class="ml-2">
                    Executar rateio
                  </a>
                </template>
              </guard>
            </div>
            <div v-else>
              <guard
                :needed-permissions="[PERMISSIONS_MOVEMENT.DOWNLOAD]"
                show-tooltip
              >
                <a @click="downloadReport(scope.row)">Download Relatório</a>
                <template v-slot:denied>
                  <a class="ml-2">
                    Download Relatório
                  </a>
                </template>
              </guard>
              <span class="disabled-actions ml-2">Executar rateio</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-dialog
      title="Importar Movimentações"
      :visible.sync="showDialog"
      width="30%"
      top="5%"
    >
      <el-form>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Mês e ano contábil">
              <el-date-picker
                id="pick-date"
                v-model="period"
                format="MM/yyyy"
                value-format="yyyy-MM"
                placeholder="Mês e ano contábil"
                type="month"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" />
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="showDialog = false"
        >Cancelar</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          @click="importMovement"
        >Salvar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

export default {
  name: 'CorrelatosMovimentacao',
  components: { BreadCrumb, Guard },
  data() {
    return {
      PERMISSIONS_MOVEMENT: FUNCTIONALITY_IDS.APPORTIONMENT.CORRELATES.MOVEMENT,
      loading: false,
      tableData: [],
      formData: {
        yearMonth: '',
      },
      rules: {
        yearMonth: [{ required: true, message: 'Campo obrigatório', trigger: ['submit'] }],
      },
      showDialog: false,
      period: undefined,
      costcenterOptions: [],
      accountingAccountOptions: [],
    };
  },
  methods: {
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api()
            .get(`/movement/year-month/${this.formData.yearMonth}`)
            .then(({ data }) => {
              this.tableData = data.data.map((obj) => ({
                ...obj,
                period: `${obj.month}/${obj.year}`,
                status: obj.blockCorrelateLaunch ? 'Rateado' : 'Não rateado',
                actions: !obj.blockCorrelateLaunch,
              }));
            });
        }
      });
    },
    async downloadReport(row) {
      const { month, year } = row;
      try {
        const response = await this.$api().get(
          `/correlated-apportionment/movement/download/year/${year}/month/${month}`,
          { responseType: 'blob' },
        );
        const blob = new Blob([response.data], { type: 'application/excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Relatório Movimento Correlato_${month}_${year}.xlsx`;
        link.click();
      } catch (e) {
        this.$notify.error({
          title: 'Alerta',
          message: 'Erro ao realizar o download',
          duration: 3000,
        });
      }
    },
    async performApportionmentOfCorrelates(row) {
      const { id, month, year } = row;
      await this.$api()
        .post(
          `/correlated-apportionment/execute/apportionment/movement/${id}/year/${year}/month/${month}`,
        )
        .then(async () => {
          await this.submitForm('form');
          this.$notify.success({
            title: 'Sucesso',
            message: ' Movimentação de correlatos realizada com sucesso',
            duration: 3000,
          });
        });
    },
    async openDialog() {
      await this.getCostcenters();
      await this.getTypeCosts();
      this.showDialog = true;
    },
    async getCostcenters(query) {
      const params = {
        search: query,
        size: 15,
        page: 1,
      };
      await this.$api()
        .get('/costcenter', { params })
        .then(({ data }) => {
          this.costcenterOptions = data.data;
        });
    },
    getTypeCosts(query) {
      const params = { filter: query };
      this.$api()
        .get('/accounting-account/typeCost', { params })
        .then(({ data }) => {
          this.accountingAccountOptions = data;
        });
    },
    async importMovement() {
      if (!this.period) {
        this.$notify.error({
          title: 'Alerta',
          message: 'É necessário selecionar o mês e ano',
          duration: 3000,
        });
        return;
      }
      this.loading = true;
      const year = parseInt(this.period.split('-')[0], 10);
      const month = parseInt(this.period.split('-')[1], 10);

      const params = {
        month,
        year,
      };
      await this.$api()
        .get('/correlated-apportionment/movement/import', { params })
        .then(async () => {
          this.$notify.success({
            title: 'Sucesso',
            message: 'Movimentações importadas com sucesso',
            duration: 3000,
          });
          this.loading = false;
        })
        .catch(() => {
          this.$notify.error({
            title: 'Erro',
            message: 'Erro ao realizar importação de movimentações',
            duration: 3000,
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 10px;
}

.queryButton {
  padding: 7px 10px;
  font-size: 12px;
  line-height: 18px;
}

.importButton {
  padding: 7px 10px;
  font-size: 12px;
  line-height: 18px;
  color: #e87305;
}

.table {
  position: relative;
  top: 45px;
}

.disabled-actions {
  color: #c0c4cc;
  text-decoration: underline;
}

.ml-2 {
  margin-left: 10px;
}

a {
  cursor: pointer;
  text-decoration: underline;
}
</style>
