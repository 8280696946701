<template>
  <div>
    <bread-crumb :breadcrumbs="['Fechamento', 'Lançamentos e Ajustes']" />
    <guard :needed-permissions="[PERMISSIONS_ENTRIES_AND_ADJUSTS.OPEN_CLOSE]">
      <h1>Fechamento</h1>
      <h4>Lançamentos e ajustes</h4>
      <el-form
        ref="form"
        label-position="top"
        label-width="100%"
        hide-required-asterisk
        :model="formData"
      >
        <el-form-item
          label="Mês e ano Contábil"
          prop="date"
        >
          <el-date-picker
            v-model="formData.date"
            size="small"
            value-format="yyyy-MM"
            format="MM/yyyy"
            type="month"
            placeholder="Mês/ano contábil"
            @change="handleChangeDate"
          />
        </el-form-item>
      </el-form>
      <div class="container-table">
        <el-table
          v-loading="loadGetReleases"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            label="Menu"
            prop="row"
            width="150"
          />
          <el-table-column
            prop="toogle"
            width="100"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.toogle"
                @change="handleToogle(scope)"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            prop="status"
          />
          <el-table-column
            label="Última alteração"
            property="update"
            width="150"
            :formatter="(row) => dateRenderer(row.update)"
          />
          <el-table-column
            label="Usuário"
            property="user.name"
            width="250"
          />
          <el-table-column
            label="Ação"
            align="right"
            fixed="right"
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row.update"
                class="actions"
              >
                <img
                  :src="searchIcon"
                  alt="search"
                  height="18"
                  width="18"
                  @click="handleOpenDialogDetail(scope)"
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog
        title="Detalhe"
        :visible.sync="dialogDetail"
        width="50%"
      >
        <el-table
          :data="tableDataDetail"
          style="width: 100%"
          stripe
        >
          <el-table-column
            property="user.name"
            label="Usuário"
          />
          <el-table-column
            property="date"
            label="Data"
            :formatter="(row) => dateRenderer(row.date)"
          />
        </el-table>
      </el-dialog>
      <template v-slot:denied>
        <p>Sem acesso</p>
      </template>
    </guard>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import {
  Dialog, Table, TableColumn, Form, FormItem, Switch, DatePicker,
} from 'element-ui';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import Guard from '../components/Guard/Guard.vue';

const searchIcon = require('../../public/media/icons/search.svg');

export default {
  name: 'FechamentoLancamentosAjustes',
  components: {
    BreadCrumb,
    Guard,
    'el-dialog': Dialog,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-switch': Switch,
    'el-date-picker': DatePicker,
  },
  data() {
    return {
      PERMISSIONS_ENTRIES_AND_ADJUSTS: FUNCTIONALITY_IDS.CLOSING.ENTRIES_AND_ADJUSTS,
      searchIcon,
      dialogDetail: false,
      loadGetReleases: false,
      formData: {
        date: null,
      },
      tableDataDetail: [],
      tableData: [],
    };
  },
  methods: {
    handleToogle({ row }) {
      this.movementSetting(this.getNameTypeSetting(row.row));
    },
    handleOpenDialogDetail({ row }) {
      this.tableDataDetail = row.changes?.map((item) => ({
        date: item?.changeTime,
        user: {
          name: item?.responsibleUser,
        },
      }));
      this.dialogDetail = true;
    },
    movementSetting(type) {
      this.loadGetReleases = true;

      this.$api()
        .patch(`/closing/movement?yearMonth=${this.formData.date}&type=${type}`)
        .then(() => {
          this.getReleases();
        });
    },
    getReleases() {
      const params = {
        yearMonth: this.formData.date,
      };

      this.loadGetReleases = true;

      this.$api()
        .get('/closing/get/movements', { params })
        .then(({ data }) => {
          const newArrayItems = [];
          Object.keys(data).forEach((item) => {
            const value = data[item];
            newArrayItems.push({
              row: this.getNameSetting(item),
              toogle: value?.status === 'aberto',
              status: value?.status,
              update: value?.changes[0]?.changeTime || null,
              user: {
                name: value?.changes[0]?.responsibleUser || null,
              },
              changes: value?.changes || [],
            });
          });
          this.tableData = newArrayItems;
        })
        .finally(() => {
          this.loadGetReleases = false;
        });
    },
    handleChangeDate(value) {
      if (!value) return;
      this.getReleases();
    },
    dateRenderer(date) {
      return date ? DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm') : null;
    },
    getNameSetting(setting = '') {
      switch (setting) {
        case 'payroll':
          return 'Folha';
        case 'apportionment':
          return 'Rateio - RH';
        case 'correlatedApportionment':
          return 'Rateio - Correlatos';
        case 'gratification':
          return 'Rateio - Gratificação';
        case 'adjusts':
          return 'Ajustes';
        case 'provisions':
          return 'Provisões';
        default:
          return '-';
      }
    },
    getNameTypeSetting(type) {
      switch (type) {
        case 'Folha':
          return 'FOLHA';
        case 'Rateio - RH':
          return 'RH';
        case 'Rateio - Correlatos':
          return 'CORRELATOS';
        case 'Rateio - Gratificação':
          return 'GRATIFICAÇÃO';
        case 'Ajustes':
          return 'AJUSTES';
        case 'Provisões':
          return 'PROVISÕES';
        default:
          return '-';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  h1 {
    margin-bottom: 30px;
  }
  h4 {
    margin-bottom: 15px;
  }
  .container-table {
    margin-top: 50px;
  }
  .actions {
    img {
      cursor: pointer;
    }
  }
  hr {
    margin-top: -25px;
    margin-bottom: 25px;
    background: #e4e7ed;
  }
</style>
