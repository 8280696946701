<template>
  <div>
    <bread-crumb :breadcrumbs="['Folha']" />

    <el-row :gutter="20">
      <el-col :span="18">
        <h2>Folha</h2>
      </el-col>
      <el-col :span="2">
        <guard :needed-permissions="[PERMISSIONS_PAYROLL.IMPORT]">
          <el-button
            type="primary"
            :disabled="loading"
            @click="showModal = true"
          >
            IMPORTAR FOLHA
          </el-button>
        </guard>
      </el-col>
    </el-row>

    <div class="block">
      <br><br>
      <el-table
        v-loading="loading"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        :data="tableData.results"
        stripe
      >
        <el-table-column
          prop="treatedDate"
          label="Período"
        />
        <el-table-column
          prop="actionStatus"
          label="Status"
          :formatter="actionStatusRenderer"
        />
        <el-table-column
          prop="actions"
          label="Ações"
          align="left"
          min-width="100px"
        >
          <template slot-scope="scope">
            <guard :needed-permissions="[PERMISSIONS_PAYROLL.ACTIONS]">
              <div v-if="scope.row.actions">
                <guard
                  :needed-permissions="[PERMISSIONS_PAYROLL.ACTIONS]"
                  show-tooltip
                >
                  <a @click="downloadReport(scope.row)">Download Relatório</a>
                  <template v-slot:denied>
                    <span class="disabled-actions">Download Relatório</span>
                  </template>
                </guard>
                <a
                  class="ml-2"
                  @click="releaseData(scope.row)"
                >Liberar Dados</a>
                <a
                  class="ml-2"
                  @click="eraseData(scope.row)"
                >Apagar Dados</a>
              </div>
              <div v-else>
                <span class="disabled-actions">Download Relatório</span>
                <span class="disabled-actions ml-2">Liberar Dados</span>
                <span class="disabled-actions ml-2">Apagar Dados</span>
              </div>
            </guard>
          </template>
        </el-table-column>
      </el-table>
      <el-row
        type="flex"
        justify="center"
        class="container-pagination"
      >
        <el-pagination
          small
          background
          hide-on-single-page
          layout="prev, pager, next"
          :page-size="tableData.lastPage"
          :current-page="tableData.page"
          :page-count="tableData.lastPage"
          @current-change="getTableData"
        />
      </el-row>
    </div>
    <el-card v-if="showModal">
      <el-dialog
        width="30%"
        class="modal"
        title="Importar Folha"
        :visible.sync="showModal"
      >
        <hr>
        <div class="modal-form">
          <span>Mês e ano contábil</span>
          <br>
          <el-date-picker
            id="pick-date"
            v-model="period"
            format="MM/yyyy"
            value-format="yyyy-MM"
            placeholder="Mês e ano contábil"
            type="month"
          />
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="showModal = false">CANCELAR</el-button>
          <el-button
            type="primary"
            @click="importPayroll"
          >SALVAR</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import {
  Row, Col, TableColumn, Table, Button, Pagination,
} from 'element-ui';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import Guard from '../components/Guard/Guard.vue';

export default {
  name: 'Folha',
  components: {
    BreadCrumb,
    Guard,
    'el-row': Row,
    'el-col': Col,
    'el-table-column': TableColumn,
    'el-table': Table,
    'el-button': Button,
    'el-pagination': Pagination,
  },
  data() {
    return {
      PERMISSIONS_PAYROLL: FUNCTIONALITY_IDS.PAYROLL,
      tableData: {
        results: [],
        total: 0,
        size: 10,
        page: 1,
        lastPage: 1,
      },
      showModal: false,
      period: undefined,
      loading: false,
    };
  },
  created() {
    this.getTableData(1);
  },
  methods: {
    getTableData(page) {
      const params = {
        page,
        size: this.tableData.size,
      };
      this.loading = true;
      this.$api()
        .get('/movement/past-payrolls', { params })
        .then(({ data }) => {
          this.tableData.results = data.data.map((e) => {
            let { month } = DateTime.fromISO(e.date);
            const { year } = DateTime.fromISO(e.date);
            month = month.toString().padStart(2, '0');
            return {
              month,
              year,
              treatedDate: `${month}/${year}`,
              actionStatus: e.status,
              actions: !e.blocked,
            };
          }) || [];
          this.tableData.total = data.total;
          this.tableData.page = data.currentPage;
          this.tableData.lastPage = data.lastPage;
        }).finally(() => {
          this.loading = false;
        });
    },
    async importPayroll() {
      if (!this.period) {
        this.$notify.error({
          title: 'Alerta',
          message: 'É necessário selecionar o mês e ano',
          duration: 3000,
        });
        return;
      }
      this.showModal = false;
      this.loading = true;

      const year = parseInt(this.period.split('-')[0], 10);
      const month = parseInt(this.period.split('-')[1], 10);

      const form = {
        month,
        year,
        yearMonth: `${year}-${month}`,
      };
      await this.$api().post('/rm/importPayroll', form);
      this.$notify.success({
        title: 'Alerta',
        message: 'A importação da folha foi iniciada',
        duration: 3000,
      });
      await this.getTableData(1);
      this.loading = false;
    },
    async downloadReport(row) {
      this.loading = true;
      const { month, year } = row;
      try {
        const response = await this.$api().get(
          `accounting-entries/year-month/${year}-${month
            .toString()
            .padStart(2, '0')}/payroll-excel`,
          { responseType: 'blob' },
        );
        const blob = new Blob([response.data], { type: 'application/excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'report.xlsx';
        link.click();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async releaseData(row) {
      const { month, year } = row;
      await this.$api()
        .put(`/movement/releaseData/${year}-${month.toString().padStart(2, '0')}`)
        .then(async () => {
          await this.getTableData(1);
          this.$notify.success({
            title: 'Sucesso',
            message: 'Dados liberados com sucesso',
            duration: 3000,
          });
        });
    },
    async eraseData(row) {
      this.$confirm(
        'Esta ação irá remover permanentemente todos os dados importados.',
        'Confirmar apagar dados',
        {
          confirmButtonText: 'Apagar',
          cancelButtonText: 'Cancelar',
          type: 'warning',
        },
      ).then(async () => {
        this.loading = true;
        const { month, year } = row;
        await this.$api()
          .delete(
            `accounting-entries/year-month/${year}-${month
              .toString()
              .padStart(2, '0')}/payroll-entries`,
          )
          .then(() => {
            this.loading = false;
            this.$notify.success({
              title: 'Sucesso',
              message: 'Dados apagados com sucesso',
              duration: 3000,
            });
            this.getTableData(1);
          });
      });
    },
    actionStatusRenderer(row, column, cellValue) {
      if (cellValue === 'pendente') {
        return (
          <div style="color: #F98F39;">
            <span>Pendente</span>
            <i class="el-icon-warning ml-2"></i>
          </div>
        );
      }
      if (cellValue === 'finalizado') {
        return (
          <div style="color: #67c23a;">
            <span>Arquivo Importado</span>
            <i class="el-icon-success ml-2"></i>
          </div>
        );
      }
      if (cellValue === 'liberado') {
        return (
          <div style="color: #67c23a;">
            <span>DRE liberado</span>
            <i class="el-icon-success ml-2"></i>
          </div>
        );
      }
      if (cellValue === 'apagado') {
        return (
          <div style="color: #F98F39;">
            <span>Dados apagados</span>
            <i class="el-icon-success ml-2"></i>
          </div>
        );
      }
      return (
        <div style="color: red;">
          <span>Erro ao importar</span>
          <i class="el-icon-error ml-2"></i>
        </div>
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-form {
  position: relative;
  left: 70px;
}
.modal {
  height: 277;
}
a {
  cursor: pointer;
  text-decoration: underline;
}
.disabled-actions {
  color: #c0c4cc;
  text-decoration: underline;
}
.ml-2 {
  margin-left: 10px;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}

.container-pagination {
  margin-top: 1rem;
}

h2 {
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
}
</style>
