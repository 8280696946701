<template>
  <div>
    <el-dialog
      width="30%"
      title="Transferir"
      :close-on-click-modal="false"
      :visible.sync="showTransferModal"
      :before-close="handleClose"
      :fullscreen="fullscreen"
    >
      <hr>
      <el-form
        ref="transferForm"
        :model="transferForm"
        :rules="transferFormRules"
        label-position="top"
        hide-required-asterisk
      >
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <el-form-item
              label="Unidade Organizacional para transferência"
              prop="organizationUnit"
            >
              <el-select
                v-model="transferForm.organizationUnit"
                placeholder="Unidade Organizacional"
                autocomplete
                filterable
                clearable
                remote
                :remote-method="getOrganizationUnits"
                value-key="id"
                loading-text="Aguarde..."
                :loading="loadingOrganizationUnits"
              >
                <el-option
                  v-for="item in organizationUnitsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :disabled="loadingTransfer"
          @click="handleClose"
        >
          CANCELAR
        </el-button>
        <el-button
          type="primary"
          :disabled="loadingTransfer"
          :loading="loadingTransfer"
          @click="handleClickTransfer"
        >
          Transferir
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="showCheckModal"
      title="Alerta"
      :visible.sync="showCheckModal"
      :close-on-click-modal="false"
      width="30%"
    >
      <hr>
      <span>
        Os seguintes centros de custo associados a esta
        {{ selectedBusinessUnitRow ? 'BU' : 'Célula' }}
        serão transferidos para a Unidade organizacional selecionada.
      </span>
      <br><br>
      <span>Centros de custo:</span>
      <br><br>
      <div style="margin-left: 25px;">
        <ul>
          <li
            v-for="costcenter in affectedCostcenters"
            :key="costcenter.id"
          >
            {{ `${costcenter.code} - ${costcenter.name}` }}
          </li>
        </ul>
      </div>
      <br><br>
      <span>Deseja continuar ?</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="info"
          :disabled="loadingCheck"
          @click="handleCloseCheckModal"
        >
          CANCELAR
        </el-button>
        <el-button
          type="primary"
          :disabled="loadingCheck"
          :loading="loadingCheck"
          @click="transfer"
        >
          CONFIRMAR
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'ProjectStructureTransferModal',
  props: {
    showTransferModal: {
      type: Boolean,
      required: true,
    },
    handleCloseTransferModalForBusinessUnit: {
      type: Function,
      required: true,
    },
    handleCloseTransferModalForCell: {
      type: Function,
      required: true,
    },
    getCellTableData: {
      type: Function,
      required: true,
    },
    getBusinessUnitTableData: {
      type: Function,
      required: true,
    },
    selectedBusinessUnitRow: Object,
    selectedCellRow: Object,
  },
  data() {
    return {
      windowWidth: 0,
      fullscreen: false,

      loadingOrganizationUnits: false,
      organizationUnitsList: [],

      loadingTransfer: false,

      affectedCostcenters: [],
      loadingCheck: false,
      showCheckModal: false,

      lists: {
        cells: {
          insertedItemsIds: [],
          removedItemsIds: [],
        },
        businessUnits: {
          insertedItemsIds: [],
          removedItemsIds: [],
        },
      },

      transferForm: {
        organizationUnit: null,
      },
      transferFormRules: {
        organizationUnit: [{ required: true, message: 'Campo obrigatório', trigger: 'submit' }],
      },
    };
  },

  watch: {
    windowWidth(width) {
      this.fullscreen = Boolean(width < 768);
    },
    showTransferModal() {
      this.transferForm = {
        organizationUnit: null,
      };
      this.$refs.transferForm?.clearValidate();
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });
  },

  async created() {
    await this.getOrganizationUnits();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },

    async getOrganizationUnits(search = '') {
      this.loadingOrganizationUnits = true;

      const params = {
        page: 1,
        size: 25,
        name: search,
      };

      await this.$api().get('/organization-unit', { params })
        .then(({ data }) => {
          this.organizationUnitsList = data.data || [];
        })
        .finally(() => {
          this.loadingOrganizationUnits = false;
        });
    },

    handleCloseCheckModal() {
      this.showCheckModal = false;
      this.handleClose();
    },

    handleClose() {
      if (this.selectedBusinessUnitRow) {
        this.handleCloseTransferModalForBusinessUnit();
      } else {
        this.handleCloseTransferModalForCell();
      }
    },

    async transfer() {
      this.loadingCheck = true;

      await this.$api().patch(`/organization-unit/${this.transferForm.organizationUnit}`, this.lists)
        .then(() => {
          this.$notify({
            title: 'Transferir',
            message: 'Transferências realizadas com sucesso!',
            type: 'success',
          });
        })
        .finally(() => {
          this.loadingCheck = true;
          this.loadingTransfer = false;
          if (this.selectedBusinessUnitRow) {
            this.getBusinessUnitTableData(1);
          } else {
            this.getCellTableData(1);
          }
          this.handleClose();
        });
    },

    async handleClickTransfer() {
      this.$refs.transferForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.loadingTransfer = true;

        if (this.selectedBusinessUnitRow) {
          this.lists.businessUnits.insertedItemsIds.push(this.selectedBusinessUnitRow.id);
        } else {
          this.lists.cells.insertedItemsIds.push(this.selectedCellRow.id);
        }

        await this.$api().post('/organization-unit/check-associations', this.lists)
          .then((response) => {
            this.affectedCostcenters = response?.data?.affectedCostcenters;
          });

        if (this.affectedCostcenters.length) {
          this.showCheckModal = true;
        } else {
          await this.transfer();
        }
      });
    },
  },

};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: -25px;
    margin-bottom: 25px;
    background: #e4e7ed;
  }

  .container-pagination {
    position: relative;
    top: 20px;
  }
  .actions {
    img {
      cursor: pointer;
    }
  }
  .inputs-full-width {
    width: 100% !important;
  }
</style>
