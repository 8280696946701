<template>
  <div>
    <bread-crumb :breadcrumbs="['Configurações', 'Prédio']" />
    <el-row :gutter="20">
      <el-col :span="18">
        <h2>Configurações - Prédio</h2>
      </el-col>
      <el-col :span="2">
        <guard :needed-permissions="[PERMISSIONS_BUILDING.CREATE]">
          <el-button
            type="primary"
            :disabled="loading"
            size="mini"
            class="button-register"
            @click="openModalForRegister"
          >
            CADASTRAR
          </el-button>
        </guard>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      :data="tableData.results"
      :default-sort="{ prop: 'status', order: 'descending' }"
      stripe
      class="table"
    >
      <el-table-column
        property="name"
        label="Prédio"
      />
      <el-table-column
        property="transientCostcenter"
        label="CC Transitório"
        :formatter="transientCostcenterRenderer"
      />
      <el-table-column
        property="overheadCostcenter"
        label="CC Overhead"
        :formatter="overheadCostcenterRenderer"
      />
      <el-table-column
        property="branch.name"
        label="Regional"
      />
      <el-table-column
        sortable
        property="status"
        label="Status"
        :formatter="statusRenderer"
      />
      <el-table-column
        property="apportionmentRule"
        label="Regra"
        :formatter="ruleRenderer"
      />
      <el-table-column
        property="params"
        :formatter="paramsRenderer"
      />
      <el-table-column
        prop="acoes"
        label="Ação"
        align="center"
      >
        <template slot-scope="scope">
          <guard
            :needed-permissions="[PERMISSIONS_BUILDING.UPDATE]"
            show-tooltip
          >
            <div
              class="actions"
              @click="editBuilding(scope.row)"
            >
              <img
                :src="editIcon"
                alt="edit"
                height="18"
                width="18"
              >
            </div>
            <template v-slot:denied>
              <div class="actions">
                <img
                  :src="editIcon"
                  alt="edit"
                  height="18"
                  width="18"
                >
              </div>
            </template>
          </guard>
        </template>
      </el-table-column>
    </el-table>
    <el-row
      type="flex"
      justify="center"
      class="container-pagination"
    >
      <el-pagination
        small
        background
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="tableData.lastPage"
        :current-page="tableData.page"
        :page-count="tableData.lastPage"
        @current-change="getTableData"
      />
    </el-row>
    <el-dialog
      v-if="showModal"
      width="30%"
      class="modal"
      :title="modalTitle"
      :visible.sync="showModal"
      :before-close="handleClose"
    >
      <hr>
      <div v-loading="loadingData">
        <el-form
          :model="form"
          label-position="top"
        >
          <el-form-item label="Prédio">
            <el-input
              v-model="form.name"
            />
          </el-form-item>
          <el-form-item label="CC Transitório">
            <el-row :gutter="20">
              <el-col :span="20">
                <el-select
                  v-model="form.transientCostcenter"
                  filterable
                  placeholder="Centros de Custo"
                  autocomplete
                  clearable
                  remote
                  loading-text="Aguarde..."
                  value-key="id"
                  :remote-method="((search) => getCostcenters(true, false, search))"
                  :loading="loadingTransientCostcenter"
                  class="big-select"
                >
                  <el-option
                    v-for="transientCostcenter in transientCostcenters"
                    :key="transientCostcenter.id"
                    :label="`${transientCostcenter.code} - ${transientCostcenter.name}`"
                    :value="transientCostcenter"
                  />
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="CC Overhead">
            <el-row :gutter="20">
              <el-col :span="20">
                <el-select
                  v-model="form.overheadCostcenter"
                  filterable
                  clearable
                  value-key="id"
                  loading-text="Aguarde..."
                  autocomplete
                  remote
                  :remote-method="((search) => getCostcenters(false, true, search))"
                  :loading="loadingOverheadCostcenter"
                  class="big-select"
                >
                  <el-option
                    v-for="overheadCostcenter in overheadCostcenters"
                    :key="overheadCostcenter.code"
                    :label="`${overheadCostcenter.code} - ${overheadCostcenter.name}`"
                    :value="overheadCostcenter"
                  />
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="Regional">
                <el-select
                  v-model="form.branch"
                  filterable
                  clearable
                  value-key="id"
                >
                  <el-option
                    v-for="branch in branches"
                    :key="branch.code"
                    :label="branch.name"
                    :value="branch"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="10"
              class="status-field"
            >
              <p>Status</p>
              <br>
              <el-switch
                v-model="form.status"
                :active-text="form.status ? 'Ativo' : 'Inativo'"
                active-color="#13ce66"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="Regra">
                <el-select v-model="form.apportionmentRule">
                  <el-option
                    label="Misto"
                    value="MISTO"
                  />
                  <el-option
                    label="Fixo com limite"
                    value="FIXO"
                  />
                  <el-option
                    label="Headcount"
                    value="HEADCOUNT"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <div v-if="form.apportionmentRule === 'MISTO'">
              <el-col :span="6">
                <el-form-item label="HC">
                  <el-input v-model="form.params.headcount" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Fixo">
                  <el-input v-model="form.params.fixed" />
                </el-form-item>
              </el-col>
            </div>
            <el-col
              v-else-if="form.apportionmentRule === 'FIXO'"
              :span="9"
            >
              <el-form-item label="Número de baias">
                <el-input v-model="form.params.stallsNumber" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :disabled="loading"
          @click="handleClose"
        >CANCELAR</el-button>
        <el-button
          type="primary"
          :disabled="loading"
          @click="registerBuilding"
        >SALVAR</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

const editIcon = require('../../public/media/icons/edit.svg');

export default {
  name: 'ConfiguracoesPredio',
  components: { BreadCrumb, Guard },

  data() {
    return {
      PERMISSIONS_BUILDING: FUNCTIONALITY_IDS.CONFIG.BUILDING,
      editIcon,
      tableData: {
        results: [],
        total: 0,
        size: 5,
        page: 1,
        lastPage: 1,
      },
      form: {
        id: null,
        name: null,
        transientCostcenter: null,
        overheadCostcenter: null,
        status: null,
        apportionmentRule: null,
        params: {
          headcount: 0,
          fixed: 0,
        },
      },
      loading: false,
      showModal: false,
      isUpdate: false,
      branches: [],
      transientCostcenters: [],
      overheadCostcenters: [],
      loadingTransientCostcenter: false,
      loadingOverheadCostcenter: false,
      loadingData: false,
    };
  },
  computed: {
    modalTitle() {
      return this.isUpdate ? 'Alterar prédio' : 'Cadastrar prédio';
    },
  },
  async created() {
    await this.getTableData(1);
    await this.getBranches();
    await this.getCostcenters(true, true);
  },
  methods: {
    handleClose() {
      this.form = {
        id: null,
        name: null,
        transientCostcenter: null,
        overheadCostcenter: null,
        status: null,
        apportionmentRule: null,
        params: {
          headcount: 0,
          fixed: 0,
        },
      };
      this.showModal = false;
    },
    async getTableData(page) {
      this.loading = true;
      await this.$api()
        .get(`/buildings?page=${page}&size=${this.tableData.size}`)
        .then(({ data }) => {
          this.tableData.results = data.data.map((e) => {
            let params = {};
            let apportionmentRule;
            if (e.configs[0]) {
              apportionmentRule = e.configs[0].fractions.length > 1
                ? 'MISTO'
                : e.configs[0]?.fractions[0].apportionmentRule;
            } else {
              apportionmentRule = 'HEADCOUNT';
            }
            if (apportionmentRule === 'MISTO') {
              params = {
                fixed: 100 * e.configs[0]?.fractions[0].fraction,
                headcount: 100 * e.configs[0]?.fractions[1].fraction,
              };
            } else if (apportionmentRule === 'FIXO') {
              params = {
                stallsNumber: e.configs[0].headcountLimit,
              };
            }
            return {
              id: e.id,
              name: e.name,
              branch: e.branch,
              status: e.status,
              apportionmentRule,
              transientCostcenter: e.transientCostcenter,
              overheadCostcenter: e.overheadCostcenter,
              params,
            };
          }) || [];
          this.tableData.total = data.total;
          this.tableData.page = data.currentPage;
          this.tableData.lastPage = data.lastPage;
        });
      this.loading = false;
    },
    openModalForRegister() {
      this.form = {
        id: '',
        name: '',
        transientCostcenter: '',
        overheadCostcenter: null,
        status: true,
        apportionmentRule: '',
        params: {},
      };
      this.isUpdate = false;
      this.showModal = true;
    },
    async registerBuilding() {
      this.loading = true;
      if (this.isUpdate) {
        await this.updateBuilding();
        this.loading = false;
        return;
      }
      const newBuilding = { ...this.form };
      const isValid = this.validations(newBuilding);
      if (!isValid) {
        return;
      }
      await this.$api()
        .post('/buildings', newBuilding)
        .then(() => {
          this.$notify.success({
            title: 'Alerta',
            message: 'Prédio cadastrado com sucesso',
            duration: 3000,
          });
          this.showModal = false;
          this.getTableData(1);
        });
      this.loading = false;
    },
    async updateBuilding() {
      const isValid = this.validations(this.form);
      if (!isValid) {
        return;
      }
      await this.$api()
        .patch(`/buildings/${this.form.id}`, this.form)
        .then(() => {
          this.$notify.success({
            title: 'Alerta',
            message: 'Prédio atualizado com sucesso',
            duration: 3000,
          });
          this.showModal = false;
          this.getTableData(1);
        });
    },
    async editBuilding(row) {
      this.loadingData = true;
      this.isUpdate = true;
      this.showModal = true;
      await this.getCostcenters(true, false, row?.transientCostcenter?.code.substring(0, 6));
      await this.getCostcenters(false, true, row?.overheadCostcenter?.code.substring(0, 6));
      this.form = JSON.parse(JSON.stringify(row));
      this.loadingData = false;
    },
    overheadCostcenterRenderer(row, column, cellValue) {
      return cellValue ? `${cellValue.code} - ${cellValue.name}` : '';
    },
    transientCostcenterRenderer(row, column, cellValue) {
      return cellValue ? `${cellValue.code} - ${cellValue.name}` : '';
    },
    statusRenderer(row, column, cellValue) {
      if (cellValue === true) {
        return (
          <div style="color: #67c23a;">
            <span>Ativo</span>
          </div>
        );
      }
      return (
        <div style="color: red;">
          <span>Inativo</span>
        </div>
      );
    },
    paramsRenderer(row, column, cellValue) {
      if (row.apportionmentRule.toUpperCase() === 'MISTO') {
        return `HC ${cellValue.headcount}% Fixo ${cellValue.fixed}%`;
      }
      if (row.apportionmentRule.toUpperCase() === 'FIXO') {
        return cellValue.stallsNumber;
      }
      return '';
    },
    ruleRenderer(row, column, cellValue) {
      if (cellValue.toUpperCase() === 'FIXO') {
        return 'FIXO COM LIMITE';
      }
      return cellValue;
    },
    async getBranches() {
      return this.$api()
        .get('/branch/list')
        .then(({ data }) => {
          this.branches = data.data;
        });
    },
    async getCostcenters(transientCostcenter, overheadCostcenter, search = '') {
      const params = { search, page: 1, size: 20 };
      if (transientCostcenter) {
        this.loadingTransientCostcenter = true;
      }
      if (overheadCostcenter) {
        this.loadingOverheadCostcenter = true;
      }
      return this.$api().get('/costcenter', { params })
        .then(({ data }) => {
          if (transientCostcenter) {
            this.transientCostcenters = data.data;
          }
          if (overheadCostcenter) {
            this.overheadCostcenters = data.data;
          }
        }).finally(() => {
          if (transientCostcenter) {
            this.loadingTransientCostcenter = false;
          }
          if (overheadCostcenter) {
            this.loadingOverheadCostcenter = false;
          }
        });
    },
    validations(form) {
      if (!form.name || !form.transientCostcenter || !form.apportionmentRule) {
        this.$notify.error({
          title: 'Alerta',
          message: 'Preencha todos os campos',
          duration: 3000,
        });
        return false;
      }
      if (form.apportionmentRule === 'MISTO') {
        if (!form.params.headcount || !form.params.fixed) {
          this.$notify.error({
            title: 'Alerta',
            message: 'Preencha os parâmetros relativos à regra',
            duration: 3000,
          });
          return false;
        }
        if (
          Number.isNaN(Number(form.params.headcount))
          || Number.isNaN(Number(form.params.fixed))
        ) {
          this.$notify.error({
            title: 'Alerta',
            message: 'Os campos "HC" e "Fixo" a regra deve ser numéricos',
            duration: 3000,
          });
          return false;
        }
        if (Number(form.params.headcount) + Number(form.params.fixed) !== 100) {
          this.$notify.error({
            title: 'Alerta',
            message: 'A soma dos campos "HC" e "Fixo" deve resultar em 100',
            duration: 3000,
          });
          return false;
        }
      } else if (form.apportionmentRule === 'FIXO') {
        if (!form.params.stallsNumber) {
          this.$notify.error({
            title: 'Alerta',
            message: 'Determine o número de baias',
            duration: 3000,
          });
          return false;
        }
        if (Number.isNaN(parseInt(form.params.stallsNumber, 10))) {
          this.$notify.error({
            title: 'Alerta',
            message: 'O campo número de baias deve ser numérico',
            duration: 3000,
          });
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}
.hover {
  cursor: pointer;
}
.table {
  position: relative;
  top: 50px;
}
.button-register {
  position: relative;
  top: 15px;
}
.container-pagination {
  position: relative;
  top: 60px;
}

.status-field {
  position: relative;
  top: 5px;
}
.big-select {
  width: 121% !important;
}
.actions {
  img {
    cursor: pointer;
  }
}
hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
</style>
