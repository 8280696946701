/**
 * Format a number into a number, to this model: R$0,00.
 * @param {Number} number - number in currency.
 */
export default function numberFormat(number) {
  return new Intl.NumberFormat('pt-Br', {
    style: 'currency',
    currency: 'BRL',
  }).format(number);
}
