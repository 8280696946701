import variables from '../../variables';

const AuthModule = {
  namespaced: true,
  state: {
    userLogged: undefined,
    token: undefined,
  },
  mutations: {
    setUser(state, newUser) {
      state.userLogged = newUser;
    },
    setToken(state, newToken) {
      state.token = newToken;
    },
  },
  actions: {
    init(context) {
      let user;
      if (localStorage.getItem(variables.auth.userLogged) !== 'undefined') {
        user = JSON.parse(localStorage.getItem(variables.auth.userdetais));
      }
      let token;
      if (localStorage.getItem(variables.auth.appToken) !== 'undefined') {
        token = JSON.parse(localStorage.getItem(variables.auth.appToken));
      }
      context.commit('setUser', user);
      context.commit('setToken', token);
    },
    setUser(context, newUser) {
      if (newUser) {
        const user = {
          id: newUser.id,
          name: newUser.name,
          email: newUser.email,
        };

        context.commit('setUser', user);
      } else {
        context.commit('setUser', undefined);
      }
    },
    setToken(context, newToken) {
      if (newToken) {
        const data = {
          token: newToken,
        };

        context.commit('setToken', data);
      } else {
        context.commit('setToken', undefined);
      }
    },
    logout(context) {
      context.commit('setUser', undefined);
      context.commit('setToken', undefined);
    },
  },
  getters: {
    user(state) {
      return state.userLogged;
    },
    token(state) {
      return state.token;
    },
  },
};

export default AuthModule;
