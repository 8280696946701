<template>
  <div>
    <el-row
      :gutter="220"
      style="margin-right: 0px"
    >
      <el-col :span="18">
        <h2>Importação</h2>
      </el-col>
      <el-col :span="2">
        <el-button
          type="primary"
          class="buttonUpload"
          background-color="#e87305"
          @click="visibleOuterModal = true"
        >
          IMPORTAR
        </el-button>
      </el-col>
    </el-row>
    <el-dialog
      title="Importar Rateio RH"
      :visible.sync="visibleOuterModal"
      :before-close="handleCloseVisibleOuterModal"
      width="30%"
    >
      <hr>
      <el-dialog
        width="32%"
        title="Upload de Arquivo"
        :visible.sync="visibleInnerModal"
        :before-close="handleCloseVisibleInnerModal"
        center
        append-to-body
      >
        <el-upload
          style="margin-left: 10%"
          drag
          action="#"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :file-list="fileList"
          :limit="1"
          :on-exceed="handleExceed"
          :http-request="handleChange"
          accept=".txt, .xls, .xlsx"
        >
          <i class="el-icon-upload" />
          <div
            class="el-upload__text"
            style="padding: 8%"
          >
            Arraste arquivos aqui, ou
            <b>clique para selecionar o arquivo do seu computador</b>
          </div>
          <div
            slot="tip"
            class="el-upload__tip"
            width="100%"
          />
        </el-upload>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            id="#buttonSendFile"
            type="primary"
            :disabled="!fileList.length"
            @click="visibleInnerModal = false"
          >
            ENVIAR
          </el-button>
        </span>
      </el-dialog>

      <el-form :model="form">
        <el-form-item label="Mês e ano contábil">
          <br>
          <el-date-picker
            id="pick-date"
            v-model="form.date"
            format="MM/yyyy"
            value-format="yyyy-MM"
            placeholder="Mês e ano contábil"
            type="month"
          />
        </el-form-item>

        <el-form-item label="Tipo de RH">
          <br>
          <el-switch
            v-model="form.typeRH"
            active-text="Indireto"
            inactive-text="Direto"
          />
        </el-form-item>

        <el-form-item label="Tipo de Importação">
          <br>
          <el-switch
            v-model="form.upload.type"
            name="teste"
            active-color="#E87305"
            active-text="Manual"
            inactive-text="Automática"
          />
          <el-switch
            v-if="form.upload.type"
            v-model="form.upload.additionalInfo"
            class="ml-14"
            active-color="#E87305"
            active-text="Full"
            inactive-text="Delta"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            :disabled="!form.upload.type"
            type="primary"
            background-color="#e87305"
            class="buttonSelectFile"
            @click="visibleInnerModal = true"
          >
            Selecionar arquivo
          </el-button>
          <label
            v-if="form.upload.type && fileList.length"
            class="labelStatusFile"
          >
            <i class="el-icon-document ml-2"> {{ fileList[0].name }} </i>
          </label>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="buttonCancelUpload"
          @click="cleanForm(), (visibleOuterModal = false)"
        >
          CANCELAR
        </el-button>
        <el-button
          v-loading="loading"
          class="buttonSubmitUpload"
          type="primary"
          @click="onSubmit"
        >
          SALVAR
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  Dialog, Row, Col, Button, Switch, Form, FormItem, DatePicker, Upload,
} from 'element-ui';

export default {
  name: 'Upload',
  components: {
    'el-dialog': Dialog,
    'el-row': Row,
    'el-col': Col,
    'el-button': Button,
    'el-switch': Switch,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-date-picker': DatePicker,
    'el-upload': Upload,
  },
  data() {
    return {
      loading: false,
      visibleOuterModal: false,
      visibleInnerModal: false,
      fileList: [],
      form: {
        date: '',
        typeRH: false,
        upload: {
          type: true,
          additionalInfo: true,
        },
      },
    };
  },
  methods: {
    handleRemove() {
      this.cleanFile();
    },
    handleExceed() {
      this.$message.warning('Você só pode importar 1 arquivo.');
    },
    handleChange(file, fileList) {
      if (
        file.raw.type === 'text/plain'
        || file.raw.type === 'application/vnd.ms-excel'
        || file.raw.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.fileList = fileList.slice(-3);
      } else {
        this.cleanFile();
        this.alert('Extensão do arquivo inválida');
      }
    },
    handleCloseVisibleInnerModal(done) {
      this.cleanFile();
      done();
    },
    handleCloseVisibleOuterModal(done) {
      this.cleanForm();
      done();
    },
    cleanForm() {
      this.form.date = '';
      this.form.typeRH = false;
      this.cleanFile();
    },
    cleanFile() {
      this.fileList = [];
    },
    async onSubmit() {
      if (!this.form.date || (this.form.upload.type && !this.fileList.length)) {
        return this.alert('É necessário preencher todos os campos');
      }
      return this.upload();
    },
    alert(text) {
      this.$notify.error({
        title: 'Alerta',
        message: text,
        duration: 3000,
      });
    },
    async upload() {
      this.loading = true;
      const { date, upload, typeRH } = this.form;
      const formData = new FormData();
      formData.append('file', this.fileList[0]?.raw);
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      await this.$api().put(
        `/accounting-entries/year-month/${year}-${month}/apportionment-file`,
        formData,
        { params: { infoOfTheUpload: upload, isIndirect: typeRH } },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      this.visibleOuterModal = false;
      this.$notify.success({
        title: 'Alerta',
        message: 'Dados importados com sucesso',
        duration: 3000,
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}

.buttonUpload {
  padding: 7px 10px;
  font-size: 12px;
  line-height: 18px;
}

.buttonSelectFile {
  padding: 7px 16px;
  border-radius: 4px;
}

.labelStatusFile {
  margin-left: 20px;
}
span {
  color: rgba(94, 255, 0, 0.116);
}
.ml-2 {
  margin-left: 10px;
}

.ml-14 {
  margin-left: 70px;
}
</style>
