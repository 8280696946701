<template>
  <div>
    <bread-crumb :breadcrumbs="['Rateio', 'Correlatos', 'Lançamentos']" />
    <h1>Rateio - Correlatos</h1>
    <br><br>
    <el-row
      class="container pdright"
      type="flex"
      justify="space-between"
    >
      <h2>Filtros</h2>
      <guard :needed-permissions="[PERMISSIONS_ENTRY.CREATE]">
        <el-button
          size="small"
          class="include-btn"
          @click="addCorrelated"
        >
          + INCLUIR
        </el-button>
      </guard>
    </el-row>
    <br>
    <el-form
      ref="form"
      label-position="top"
    >
      <el-row
        class="container"
        :gutter="30"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Prédio"
            prop="building"
          >
            <el-select
              v-model="filters.building"
              class="input"
              value-key="id"
              clearable
              filterable
              placeholder="Prédio"
              :loading="loadingBuildingsList"
            >
              <el-option
                v-for="item in buildingsList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Centro de custo"
            prop="costcenter"
          >
            <el-select
              v-model="filters.costcenter"
              class="input"
              clearable
              filterable
              placeholder="Centro de custo"
              remote
              reserve-keyword
              autocomplete
              :remote-method="getCostcenters"
              :loading="loadingCostcenters"
            >
              <el-option
                v-for="item in costcentersList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Tipo de despesas"
            prop="accountingAccount"
          >
            <el-select
              v-model="filters.accountingAccount"
              class="input"
              clearable
              filterable
              placeholder="Tipo de despesas"
              remote
              reserve-keyword
              autocomplete
              :remote-method="getTypeCosts"
              :loading="loadingTypeExpenses"
            >
              <el-option
                v-for="item in typeExpensesList"
                :key="item.id"
                :label="`${item.code} - ${item.name}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Origem do lançamento"
            prop="building"
          >
            <el-select
              v-model="filters.releaseOrigin"
              class="input"
              clearable
              placeholder="Origem do lançamento"
              :loading="loadingBuildingsList"
            >
              <el-option
                key="1"
                label="Importado"
                value="IMPORTADO"
              />
              <el-option
                key="2"
                label="Manual"
                value="MANUAL"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="3"
          :xl="1"
        >
          <el-form-item label="Provisão">
            <el-switch
              v-model="filters.provision"
              active-color="#13ce66"
              :active-text="filters.provision ? 'Sim' : 'Não'"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="4"
          :xl="2"
        >
          <el-form-item label="">
            <el-row type="flex">
              <guard
                :needed-permissions="[PERMISSIONS_ENTRY.QUERY]"
                style="margin-right: 10px"
              >
                <el-button
                  :loading="loadingCorrelatedApportionment"
                  type="primary"
                  size="small"
                  @click="handleClickGetCorrelatedApportionment"
                >
                  CONSULTAR
                </el-button>
              </guard>
              <guard :needed-permissions="[PERMISSIONS_ENTRY.DOWNLOAD]">
                <el-button
                  :loading="loadingDownload"
                  type="secundary"
                  size="small"
                  class="download-button"
                  @click.prevent="getDownloadFile"
                >
                  DOWNLOAD
                </el-button>
              </guard>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <correlated-apportionment
      :data="tableData"
      :loading="loadingCorrelatedApportionment || loadingDownload"
      :edit-correlated="editCorrelated"
      :detail-correlated="detailCorrelated"
    />
    <el-row
      type="flex"
      justify="center"
      class="container-pagination"
    >
      <el-pagination
        small
        background
        hide-on-single-page
        layout="prev, pager, next"
        :total="paginationData.lastPage"
        :disabled="loadingCorrelatedApportionment"
        :page-size="tableDataParamsPagination.size"
        :current-page="paginationData.currentPage"
        @current-change="handleCurrentChange"
      />
    </el-row>
    <el-dialog
      v-if="showModal"
      width="30%"
      :title="
        isEdit
          ? 'Editar Lançamento'
          : isDetail
            ? 'Detalhe do Lançamento'
            : 'Incluir Lançamento'
      "
      :visible.sync="showModal"
    >
      <hr>
      <add-correlated-modal
        :close-modal="closeModal"
        :is-edit="isEdit"
        :is-detail="isDetail"
        :buildings-list="buildingsList"
        :submit-form="submitForm"
        :correlated-to-update="correlatedToUpdate"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  Row, Col, Pagination, Form, FormItem, Button, Switch, Select, Option,
} from 'element-ui';
import { DateTime } from 'luxon';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import CorrelatedApportionment from '../components/CorrelatedApportionment/CorrelatedApportionment.vue';
import numberFormat from '../utils/IntlNumberFormat';
import AddCorrelatedModal from '../components/AddCorrelatedModal/AddCorrelatedModal.vue';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import { getLoggedUserStorage } from '../storage/modules/auth';

export default {
  name: 'CorrelatosLancamentos',
  components: {
    'bread-crumb': BreadCrumb,
    'el-row': Row,
    'el-pagination': Pagination,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-col': Col,
    'el-button': Button,
    'el-switch': Switch,
    'el-select': Select,
    'el-option': Option,
    CorrelatedApportionment,
    AddCorrelatedModal,
    Guard,
  },
  data() {
    return {
      PERMISSIONS_ENTRY: FUNCTIONALITY_IDS.APPORTIONMENT.CORRELATES.ENTRY,
      filters: {
        building: '',
        costcenter: '',
        accountingAccount: '',
        releaseOrigin: '',
        provision: false,
      },
      correlatedToUpdate: undefined,
      buildingsList: [],
      loadingBuildingsList: false,
      typeExpensesList: [],
      loadingTypeExpenses: false,
      costcentersList: [],
      loadingCostcenters: false,
      tableData: [],
      loadingCorrelatedApportionment: false,
      loadingCorrelatedAdd: false,
      tableDataParamsPagination: {
        page: 1,
        size: 10,
      },
      paginationData: {
        currentPage: 1,
        lastPage: 1,
        total: 0,
      },
      showModal: false,
      isEdit: false,
      isDetail: false,
      loadingDownload: false,
    };
  },
  mounted() {
    const { userLogged } = getLoggedUserStorage();
    const permissions = userLogged?.functionalityIds || [];
    if (permissions.includes(this.PERMISSIONS_ENTRY.QUERY)) {
      this.getBuildings();
      this.getCostcenters();
      this.getTypeCosts();
    }
  },
  methods: {
    async getBuildings() {
      this.loadingBuildingsList = true;
      this.$api()
        .get('/buildings')
        .then(({ data }) => {
          this.buildingsList = data.data;
        })
        .finally(() => {
          this.loadingBuildingsList = false;
        });
    },
    handleClickGetCorrelatedApportionment() {
      this.tableDataParamsPagination = {
        ...this.tableDataParamsPagination,
        page: 1,
      };
      this.getCorrelatedApportionment();
    },
    addCorrelated() {
      this.isEdit = false;
      this.isDetail = false;
      this.correlatedToUpdate = undefined;
      this.showModal = true;
    },
    editCorrelated(correlated) {
      this.correlatedToUpdate = correlated;
      delete this.correlatedToUpdate.formatedValue;
      delete this.correlatedToUpdate.provisionText;
      delete this.correlatedToUpdate.expense;
      this.isEdit = true;
      this.isDetail = false;
      this.showModal = true;
    },
    getCorrelatedApportionment() {
      if (!this.filters.building) {
        this.$notify.error({
          title: 'Alerta',
          message: 'O campo "Prédio" é obrigatório',
          duration: 3000,
        });
        return;
      }
      this.loadingCorrelatedApportionment = true;

      const params = {
        ...this.tableDataParamsPagination,
        ...this.filters,
      };

      if (!params.value) delete params.value;

      this.$api()
        .get('/correlated-apportionment', { params })
        .then(({ data }) => {
          this.tableData = data.data?.map((item) => ({
            ...item,
            expense: `${item?.accountingAccount?.name} - ${item?.accountingAccount?.code}`,
            formatedValue: numberFormat(item.value),
            provisionText: item?.provision ? 'Sim' : 'Não',
          }));
          this.paginationData = {
            currentPage: Number(data.currentPage),
            lastPage: data.lastPage,
            total: data.total,
          };
        })
        .finally(() => {
          this.loadingCorrelatedApportionment = false;
        });
    },
    handleCurrentChange(newPage) {
      this.tableDataParamsPagination = {
        ...this.tableDataParamsPagination,
        page: newPage,
      };
      this.getCorrelatedApportionment();
    },
    getTypeCosts(query) {
      const params = {
        filter: query,
      };
      this.loadingTypeExpenses = true;
      this.$api()
        .get('/accounting-account/typeCost', { params })
        .then(({ data }) => {
          this.typeExpensesList = data;
        })
        .finally(() => {
          this.loadingTypeExpenses = false;
        });
    },
    getCostcenters(query) {
      const params = {
        search: query,
        size: 15,
        page: 1,
      };
      this.loadingCostcenters = true;
      this.$api()
        .get('/costcenter', { params })
        .then(({ data }) => {
          this.costcentersList = data.data;
        })
        .finally(() => {
          this.loadingCostcenters = false;
        });
    },
    getDownloadFile() {
      if (!this.filters.building) {
        this.$notify.error({
          title: 'Alerta',
          message: 'O campo "Prédio" é obrigatório',
          duration: 3000,
        });
        return;
      }
      this.loadingDownload = true;
      const params = {
        ...this.tableDataParamsPagination,
        ...this.filters,
      };

      if (!params.value) delete params.value;
      this.$api()
        .get('/correlated-apportionment/download', { params, responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report.xlsx';
          link.click();
        })
        .finally(() => {
          this.loadingDownload = false;
        });
    },
    submitForm(form, id) {
      const { year, month } = DateTime.fromJSDate(form.releaseDate);
      const data = {
        ...form,
        yearMonth: `${year}-${month}`,
      };
      if (!data.building || !data.accountingAccount) {
        this.$notify.error({
          title: 'Alerta',
          message: 'Os campos Prédio, Tipo de despesa são obrigatórios',
          duration: 3000,
        });
        return;
      }
      if (!data.costcenter) {
        data.costcenter = data.building.transientCostcenter;
      }
      if (this.isEdit) {
        this.$api()
          .patch(`/correlated-apportionment/${id}`, data)
          .then(() => {
            this.$notify.success({
              title: 'Alerta',
              message: 'Editado com sucesso',
              duration: 3000,
            });
            this.showModal = false;
            this.handleClickGetCorrelatedApportionment();
          });
      } else {
        this.$api()
          .post('/correlated-apportionment', data)
          .then(() => {
            this.$notify.success({
              title: 'Alerta',
              message: 'Incluído com sucesso',
              duration: 3000,
            });
            this.showModal = false;
            this.filters = {
              building: data.building.id,
              costcenter: '',
              accountingAccount: '',
              releaseOrigin: '',
              provision: data.provision,
            };
            this.handleClickGetCorrelatedApportionment();
          });
      }
    },
    closeModal() {
      this.showModal = false;
    },
    detailCorrelated(correlated) {
      this.correlatedToUpdate = correlated;
      delete this.correlatedToUpdate.formatedValue;
      delete this.correlatedToUpdate.provisionText;
      delete this.correlatedToUpdate.expense;
      this.isEdit = false;
      this.isDetail = true;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 25px;
}

.input {
  width: 100%;
}

.el-row {
  margin-right: 0 !important;
}

.el-form-item {
  margin-bottom: 0 !important;
}

.container-pagination {
  margin-top: 1rem;
}

.download-button {
  color: #e87305;
  outline-color: #e87305;
}

.side-side {
  display: flex;
}

.include-btn {
  color: #e87305;
}

.pdright {
  padding-right: 15px;
}

.white {
  color: white;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}

</style>
