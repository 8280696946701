import Vue from 'vue';
import VueRouter from 'vue-router';
import serviceUser from '../infra/services/user';
import ApplicationLayout from '../layouts/ApplicationLayout.vue';
import Ajustes from '../pages/Ajustes.vue';
import Configuracoes from '../pages/Configuracoes.vue';
import ConfiguracoesAssociacao from '../pages/ConfiguracoesAssociacao.vue';
import ConfiguracoesContaContabil from '../pages/ConfiguracoesContaContabil.vue';
import ConfiguracoesEstruturaProjetos from '../pages/ConfiguracoesEstruturaProjetos.vue';
import ConfiguracoesEvento from '../pages/ConfiguracoesEvento.vue';
import ConfiguracoesGratificacao from '../pages/ConfiguracoesGratificacao.vue';
import ConfiguracoesPredio from '../pages/ConfiguracoesPredio.vue';
import ConfiguracoesProjeto from '../pages/ConfiguracoesProjeto.vue';
import ControlePerfil from '../pages/ControlePerfil.vue';
import CorrelatosApropriacao from '../pages/CorrelatosApropriacao.vue';
import CorrelatosHeadcount from '../pages/CorrelatosHeadcount.vue';
import CorrelatosLancamentos from '../pages/CorrelatosLancamentos.vue';
import CorrelatosMovimentacao from '../pages/CorrelatosMovimentacao.vue';
import Fechamento from '../pages/Fechamento.vue';
import FechamentoContabil from '../pages/FechamentoContabil.vue';
import FechamentoLancamentosAjustes from '../pages/FechamentoLancamentosAjustes.vue';
import FechamentoRelatorios from '../pages/FechamentoRelatorios.vue';
import Folha from '../pages/Folha.vue';
import Lancamentos from '../pages/Lançamentos.vue';
import Login from '../pages/Login.vue';
import Perfil from '../pages/Perfil.vue';
import Provisoes from '../pages/Provisoes/Provisoes.vue';
import RhConferencia from '../pages/RhConferencia.vue';
import RhGratificacao from '../pages/RhGratificacao.vue';
import RhImportacao from '../pages/RhImportacao.vue';
import RhPremiacao from '../pages/RhPremiacao.vue';
import Validacao from '../pages/Validacao.vue';
import { getLoggedUserStorage, setLoggedUserStorage } from '../storage/modules/auth';

Vue.use(VueRouter);

const routerWithAuth = {
  meta: {
    requiresAuth: true,
  },
};

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: ApplicationLayout,
      children: [
        {
          path: '/',
          name: 'Login',
          component: Login,
        },
        {
          ...routerWithAuth,
          path: '/validacao',
          name: 'Validacao',
          component: Validacao,
        },
        {
          ...routerWithAuth,
          path: '/folha',
          name: 'Folha',
          component: Folha,
        },
        {
          ...routerWithAuth,
          path: '/lancamentos',
          name: 'Lançamentos',
          component: Lancamentos,
        },
        {
          ...routerWithAuth,
          path: '/ajustes',
          name: 'Ajustes',
          component: Ajustes,
        },
        {
          ...routerWithAuth,
          path: '/provisoes',
          name: 'Provisoes',
          component: Provisoes,
        },
        {
          ...routerWithAuth,
          path: '/fechamento',
          name: 'Fechamento',
          component: Fechamento,
        },
        {
          ...routerWithAuth,
          path: '/rhImportacao',
          name: 'RhImportacao',
          component: RhImportacao,
        },
        {
          ...routerWithAuth,
          path: '/rhConferencia',
          name: 'RhConferencia',
          component: RhConferencia,
        },
        {
          ...routerWithAuth,
          path: '/rh-gratificacao',
          name: 'RhGratificacao',
          component: RhGratificacao,
        },
        {
          ...routerWithAuth,
          path: '/rh-premiacao',
          name: 'RhPremiacao',
          component: RhPremiacao,
        },
        {
          ...routerWithAuth,
          path: '/correlatos-lancamentos',
          name: 'correlatosLancamento',
          component: CorrelatosLancamentos,
        },
        {
          ...routerWithAuth,
          path: '/correlatosHeadcount',
          name: 'CorrelatosHeadcount',
          component: CorrelatosHeadcount,
        },
        {
          ...routerWithAuth,
          path: '/correlatosMovimentacao',
          name: 'CorrelatosMovimentacao',
          component: CorrelatosMovimentacao,
        },
        {
          ...routerWithAuth,
          path: '/configuracoes',
          name: 'Configuracoes',
          component: Configuracoes,
        },
        {
          ...routerWithAuth,
          path: '/configuracoesEstruturaProjetos',
          name: 'ConfiguracoesEstruturaProjetos',
          component: ConfiguracoesEstruturaProjetos,
        },
        {
          ...routerWithAuth,
          path: '/configuracoesPredio',
          name: 'ConfiguracoesPredio',
          component: ConfiguracoesPredio,
        },
        {
          ...routerWithAuth,
          path: '/configuracoesProjeto',
          name: 'ConfiguracoesProjeto',
          component: ConfiguracoesProjeto,
        },
        {
          ...routerWithAuth,
          path: '/configuracoesAssociacao',
          name: 'ConfiguracoesAssociacao',
          component: ConfiguracoesAssociacao,
        },
        {
          ...routerWithAuth,
          path: '/configuracoesContaContabil',
          name: 'ConfiguracoesContaContabil',
          component: ConfiguracoesContaContabil,
        },
        {
          ...routerWithAuth,
          path: '/configuracoesEvento',
          name: 'ConfiguracoesEvento',
          component: ConfiguracoesEvento,
        },
        {
          ...routerWithAuth,
          path: '/configuracoesGratificacao',
          name: 'ConfiguracoesGratificacao',
          component: ConfiguracoesGratificacao,
        },
        {
          ...routerWithAuth,
          path: '/fechamentoContabil',
          name: 'FechamentoContabil',
          component: FechamentoContabil,
        },
        {
          ...routerWithAuth,
          path: '/fechamentoLancamentosAjustes',
          name: 'FechamentoLancamentosAjustes',
          component: FechamentoLancamentosAjustes,
        },
        {
          ...routerWithAuth,
          path: '/fechamentoRelatorios',
          name: 'FechamentoRelatorios',
          component: FechamentoRelatorios,
        },
        {
          ...routerWithAuth,
          path: '/controlePerfil',
          name: 'controlePerfil',
          component: ControlePerfil,
        },
        {
          ...routerWithAuth,
          path: '/perfil',
          name: 'Perfil',
          component: Perfil,
        },
        {
          ...routerWithAuth,
          path: '/apropriacao',
          name: 'Apropriacao',
          component: CorrelatosApropriacao,
        },
      ],
    },
  ],
});

router.beforeEach((to, _from, next) => {
  const loggedUser = getLoggedUserStorage();
  const requiresAuth = to.matched.some((route) => route.meta?.requiresAuth);

  if (to.path === '/' && loggedUser) {
    return router.replace({ name: 'Folha' });
  }

  if (!requiresAuth) {
    return next();
  }

  if (!loggedUser) {
    if (to.path === '/validacao') {
      return next();
    }
    serviceUser.getLoggedUser()
      .then((response) => {
        setLoggedUserStorage(response.data);
        return next();
      })
      .catch(() => router.replace({ name: 'Login' }));
  }

  return next();
});

export default router;
