import variables from '../../variables';

const MenuHeaderModule = {
  namespaced: true,
  state: {
    activeMenuHeader: undefined,
  },
  mutations: {
    setActiveMenuHeader(state, newActiveMenuHeader) {
      state.activeMenuHeader = newActiveMenuHeader;
    },
  },
  actions: {
    init(context) {
      context.commit('setActiveMenuHeader', localStorage.getItem(variables.activeMenuHeader) || undefined);
    },
    setActiveMenuHeader(context, newActiveMenuHeader) {
      context.commit('setActiveMenuHeader', newActiveMenuHeader || undefined);
    },
  },
  getters: {
    activeMenuHeader(state) {
      return state.activeMenuHeader;
    },
  },
};

export default MenuHeaderModule;
