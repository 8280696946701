<template>
  <el-dialog
    title="Editar Ajuste"
    width="30%"
    :visible.sync="open"
    :before-close="handleClose"
  >
    <hr>
    <el-row :gutter="20">
      <el-form
        ref="localFormEdit"
        :model="localFormEdit"
        :rules="rulesFormEdit"
        hide-required-asterisk
      >
        <el-col :span="12">
          <el-form-item label="Conta contábil">
            <el-input
              :value="localFormEdit?.ledgerAccount?.name || null"
              placeholder="Conta contábil"
              size="small"
              readonly
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="ID Ajuste">
            <el-input
              :value="localFormEdit?.idSetting || null"
              size="small"
              readonly
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Situação">
            <el-select
              v-model="localFormEdit.status"
              size="small"
              clearable
              filterable
              placeholder="Situação"
              class="inputs-full-width"
            >
              <el-option
                value="APROVADO"
                label="Aprovado"
              />
              <el-option
                value="REJEITADO"
                label="Rejeitado"
              />
              <el-option
                value="PENDENTE"
                label="Pendente"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="Observação"
            prop="note"
          >
            <el-input
              v-model="localFormEdit.note"
              type="textarea"
              :rows="3"
              placeholder="Observação"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <span slot="footer">
      <el-button
        size="small"
        @click="onCancel"
      >
        Cancelar
      </el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loadingEditSetting"
        :disabled="loadingEditSetting"
        @click="onSave"
      >
        Salvar
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

import { Row } from 'element-ui';

export default {
  name: 'AdjustEdit',
  components: {
    'el-row': Row,
  },
  props: {
    selectedRow: Object,
    loadingEditSetting: Boolean,
    formEdit: Object,
    rulesFormEdit: Object,
    handleClose: {
      type: Function,
      require: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localFormEdit: this.formEdit,
    };
  },
  methods: {
    onCancel() {
      this.$emit('clickCancel');
    },
    onSave() {
      this.$refs.localFormEdit.validate((valid) => {
        if (valid) {
          this.loadingEditSetting = true;
          this.$emit('clickSave', this.localFormEdit);
        }
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.inputs-full-width {
  width: 100% !important;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
</style>
