<template>
  <div>
    <bread-crumb :breadcrumbs="['Fechamento', 'Relatórios']" />
    <h1>Fechamento</h1>
    <h4>Contábil</h4>

    <guard :needed-permissions="[PERMISSIONS_REPORTS.QUERY]">
      <el-button-group>
        <el-button
          class="button-group"
          :class="typeSelection === 'synthetic' ? 'active-button' : 'inactive-button'"
          @click="typeSelection = 'synthetic'"
        >
          Resumido
        </el-button>
        <el-button
          class="button-group"
          :class="typeSelection === 'analytic' ? 'active-button' : 'inactive-button'"
          @click="typeSelection = 'analytic'"
        >
          Detalhado
        </el-button>
      </el-button-group>

      <el-form
        ref="form"
        label-position="top"
      >
        <el-row
          :gutter="15"
          style="margin-right: 0 !important; margin-top: 30px;"
        >
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="3"
            :xl="3"
          >
            <el-form-item label="Mês e ano contábil">
              <el-date-picker
                v-model="formData.yearMonth"
                size="small"
                type="month"
                placeholder="Mês e ano contábil"
                format="MMM/yyyy"
                value-format="yyyy-MM"
                class="inputs-full-width"
              />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="3"
            :xl="3"
          >
            <el-form-item label="Centro de custo">
              <el-select
                v-model="formData.costCenter"
                size="small"
                autocomplete
                filterable
                clearable
                remote
                loading-text="Aguarde..."
                placeholder="Centro de custo"
                :remote-method="costCenter"
                :loading="loadingCostCenterList"
                value-key="id"
                class="inputs-full-width"
                @change="changedCostcenter"
              >
                <el-option
                  v-for="costcenter in costcenters"
                  :key="costcenter.code"
                  :label="`${costcenter.code} - ${costcenter.name}`"
                  :value="costcenter.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="3"
            :xl="3"
          >
            <el-form-item label="Regional">
              <el-select
                v-model="formData.branch"
                size="small"
                filterable
                clearable
                :disabled="disableCostcenterRelatedFilters"
                value-key="id"
                class="inputs-full-width"
              >
                <el-option
                  v-for="branch in branches"
                  :key="branch.id"
                  :label="branch.name"
                  :value="branch.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="4"
            :xl="3"
          >
            <el-form-item label="Unidade de negócio">
              <el-select
                v-model="formData.businessUnit"
                size="small"
                filterable
                clearable
                :disabled="disableCostcenterRelatedFilters"
                value-key="id"
                class="inputs-full-width"
              >
                <el-option
                  v-for="businessUnity in businessUnities"
                  :key="businessUnity.id"
                  :label="businessUnity.name"
                  :value="businessUnity.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="4"
            :xl="3"
          >
            <el-form-item label="Tipo CC">
              <el-select
                v-model="formData.projectType"
                size="small"
                filterable
                clearable
                :disabled="disableCostcenterRelatedFilters"
                value-key="id"
                class="inputs-full-width"
              >
                <el-option
                  v-for="projectType in projectTypes"
                  :key="projectType.id"
                  :label="projectType.name"
                  :value="projectType.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="4"
            :xl="3"
          >
            <el-form-item label="Cliente">
              <el-select
                v-model="formData.client"
                size="small"
                filterable
                clearable
                remote
                autocomplete
                :disabled="disableCostcenterRelatedFilters"
                placeholder="Cliente"
                loading-text="Aguarde..."
                :remote-method="getClients"
                :loading="loadingClients"
                value-key="id"
                class="inputs-full-width"
              >
                <el-option
                  v-for="client in clients"
                  :key="client.id"
                  :label="client.name"
                  :value="client.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="3"
            :xl="3"
          >
            <el-form-item label="Conta contábil">
              <el-select
                v-model="formData.ledgerAccount"
                class="inputs-full-width"
                filterable
                placeholder="Conta contábil"
                autocomplete
                clearable
                remote
                size="small"
                loading-text="Aguarde..."
                value-key="id"
                :remote-method="getLedgeAccount"
                :loading="loadingLedgerAccountList"
              >
                <el-option
                  v-for="item in ledgerAccountList"
                  :key="item.id"
                  :label="`${item.code} ${item.name}`"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="4"
            :xl="3"
          >
            <el-row
              type="flex"
              justify="end"
              class="container-btns gap-1"
            >
              <guard :needed-permissions="[PERMISSIONS_REPORTS.DOWNLOAD]">
                <el-button
                  :loading="loadingDownloadReport"
                  size="small"
                  @click="downloadReport"
                >
                  Download
                </el-button>
              </guard>
              <guard :needed-permissions="[PERMISSIONS_REPORTS.QUERY]">
                <el-button
                  :loading="loadingList"
                  type="primary"
                  size="small"
                  @click="list(1)"
                >
                  Consultar
                </el-button>
              </guard>
            </el-row>
          </el-col>
        </el-row>
      </el-form>

      <div class="container-table">
        <el-table
          v-loading="loadingList || loadingDownloadReport"
          :data="tableData.results"
          style="width: 100%"
          stripe
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          />
          <el-table-column
            label="Mês / Ano contábil"
            prop="period"
            width="150"
          />
          <el-table-column
            label="Centro de Custo"
            prop="costcenter"
          />
          <el-table-column
            label="Conta Contábil"
            property="accountingAccount"
            width="200"
          />
          <el-table-column
            label="Regional"
            property="branch"
            width="200"
          />
          <el-table-column
            label="Unidade de Negócio"
            property="businessUnit"
          />
          <el-table-column
            label="Tipo CC"
            property="projectType"
          />
          <el-table-column
            label="Cliente"
            property="client"
          />
          <el-table-column
            label="Valor"
            property="value"
            width="150"
            :formatter="valorRenderer"
          />
        </el-table>
      </div>
      <el-row
        type="flex"
        justify="center"
        class="container-pagination"
      >
        <el-pagination
          small
          background
          hide-on-single-page
          layout="prev, pager, next"
          :page-size="tableData.lastPage"
          :current-page="tableData.page"
          :page-count="tableData.lastPage"
          @current-change="list"
        />
      </el-row>
      <template v-slot:denied>
        <p>Sem acesso</p>
      </template>
    </guard>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import numberFormat from '../utils/IntlNumberFormat';

export default {
  name: 'FechamentoRelatorios',
  components: { BreadCrumb, Guard },
  data() {
    return {
      PERMISSIONS_REPORTS: FUNCTIONALITY_IDS.CLOSING.REPORTS,
      typeSelection: 'synthetic',
      lastQueryParameters: null,
      disableCostcenterRelatedFilters: false,
      formData: {
        yearMonth: '',
        costCenter: null,
        ledgerAccount: null,
        branch: null,
        businessUnit: null,
        client: null,
        projectType: null,
      },
      costcenters: [],
      branches: [],
      businessUnities: [],
      clients: [],
      projectTypes: [],
      ledgerAccountList: [],
      loadingCostCenterList: false,
      loadingLedgerAccountList: false,
      loadingClients: false,
      tableData: {
        results: [],
        total: 0,
        size: 100,
        page: 1,
        lastPage: 1,
      },
      loadingList: false,
      loadingDownloadReport: false,
      multipleSelection: [],
    };
  },
  mounted() {
    this.costCenter('');
    this.getLedgeAccount('');
    this.getOptions();
  },
  methods: {
    getClients(search = '') {
      const params = {
        search,
        page: 1,
        size: 50,
      };

      this.loadingClients = true;

      this.$api()
        .get('/client/', { params })
        .then(({ data }) => {
          this.clients = data.data;
        })
        .finally(() => {
          this.loadingClients = false;
        });
    },
    costCenter(search = '') {
      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.loadingCostCenterList = true;

      this.$api()
        .get('costcenter/only', { params })
        .then(({ data }) => {
          this.costcenters = data.data;
        })
        .finally(() => {
          this.loadingCostCenterList = false;
        });
    },
    getLedgeAccount(search = '') {
      this.loadingLedgerAccountList = true;

      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.$api()
        .get('/accounting-account/list', { params })
        .then((response) => {
          this.ledgerAccountList = response.data.data;
        })
        .finally(() => {
          this.loadingLedgerAccountList = false;
        });
    },
    getOptions() {
      const params = {
        page: 1,
        size: 50,
      };
      this.$api()
        .get('/costcenter/dataInitial', { params })
        .then(({ data }) => {
          this.businessUnities = data.businessUnit;
          this.clients = data.client;
          this.branches = data.branch;
          this.projectTypes = data.projectType;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    valorRenderer(row) {
      return numberFormat(row.value);
    },
    getRowKey(row) {
      return row.id;
    },
    list(page) {
      const params = {
        yearMonth: this.formData.yearMonth,
        costcenter: this.formData.costCenter,
        accountingAccount: this.formData.ledgerAccount,
        branch: this.formData.branch,
        businessUnit: this.formData.businessUnit,
        projectType: this.formData.projectType,
        client: this.formData.client,
      };
      this.lastQueryParameters = JSON.parse(JSON.stringify(params));
      this.lastQueryParameters.typeSelection = this.typeSelection;
      params.page = page;
      params.size = this.tableData.size;
      this.loadingList = true;
      this.$api()
        .get(`/closing/report/list/${this.typeSelection}`, { params })
        .then(({ data }) => {
          this.tableData.results = data.data;
          this.tableData.total = data.total;
          this.tableData.page = data.currentPage;
          this.tableData.lastPage = data.lastPage;
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
    downloadReport() {
      if (!this.lastQueryParameters) {
        this.$notify({
          title: 'Download do Relatório de Provisões',
          message: 'Não foi possível realizar o download, por favor realize antes uma consulta',
          type: 'warning',
        });
        return;
      }
      const params = JSON.parse(JSON.stringify(this.lastQueryParameters));
      params.dataList = this.multipleSelection;
      this.loadingDownloadReport = true;
      this.$api()
        .get(`/closing/report/download/${this.lastQueryParameters.typeSelection}`, { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Fechamento - SG Pitang.xlsx';
          link.click();
        })
        .finally(() => {
          this.loadingDownloadReport = false;
        });
    },
    async changedCostcenter(val) {
      if (val) {
        this.disableCostcenterRelatedFilters = true;
        this.formData.branch = null;
        this.formData.businessUnit = null;
        this.formData.client = null;
        this.formData.projectType = null;
      } else {
        this.disableCostcenterRelatedFilters = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  h1 {
    margin-bottom: 30px;
  }
  h4 {
    margin-bottom: 15px;
  }

  h1 {
    padding: 7px 0px;
    color: #e87305;
    font-weight: 550;
    font-size: 20px;
    line-height: 10px;
  }
  .button-group {
    height: 32px;
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: $text-color;
  }

  .inactive-button {
    background: #F5F5F5;

    &:hover {
      background: #F5F5F5;
      color: $text-color
    }
  }

  .active-button {
    background: #D9D9D9;

    &:hover {
      background: #D9D9D9;
      color: $text-color
    }
  }

  .inputs-full-width {
    width: 100% !important;
  }

  .container-btns {
    padding-right: 0 7.5px;
    margin-top: 35px;
    .el-button {
      height: 33px;
    }
  }
  .container-pagination {
    margin-top: 1rem;
  }
  .container-table {
    margin-top: 50px;
  }

  @media (max-width: 1441px) {
    .container-btns {
      margin-top: 0px;
      margin-bottom: 1rem;
    }
  }
</style>
