<template>
  <el-table
    v-loading="loading"
    :data="data"
    stripe
    @row-contextmenu="editAditional"
  >
    <el-table-column
      property="costcenter.code"
      label="Centro de custo"
      min-width="140"
    />
    <el-table-column
      property="costcenter.projectType.name"
      label="Projeto"
      min-width="140"
    />
    <el-table-column
      property="employeesNumber"
      label="Headcount atual"
      min-width="150"
      :formatter="headcountRenderer"
    />
    <el-table-column
      property="employeesAdditionalNumber"
      label="Headcount adicional"
      min-width="150"
    >
      <template slot-scope="scope">
        <el-input-number
          v-if="edit && scope.row.id === idAdditional"
          v-model="additional"
          size="small"
          controls-position="right"
        />
        <label v-else>{{ scope.row.employeesAdditionalNumber || 0 }}</label>
      </template>
    </el-table-column>
    <el-table-column
      property="observation"
      label="Observação"
      min-width="150"
    >
      <template slot-scope="scope">
        <el-input
          v-if="edit && scope.row.id === idAdditional"
          v-model="obs"
          size="small"
          controls-position="right"
        />
        <label v-else>{{ scope.row.observation || '' }}</label>
      </template>
    </el-table-column>
    <el-table-column
      property=""
      label="Ações"
      min-width="70"
    >
      <template slot-scope="scope">
        <guard
          :needed-permissions="[PERMISSIONS_HEADCOUNT.UPDATE]"
          show-tooltip
        >
          <a
            v-if="edit && scope.row.id === idAdditional"
            class="include-action hover"
            @click="handleEditHeadcount(scope.row)"
          >
            Salvar
          </a>
          <a
            v-else
            class="include-action hover"
            @click="editAditional(scope.row)"
          >
            Alterar
          </a>
          <template v-slot:denied>
            <span class="disabled-actions">
              Alterar
            </span>
          </template>
        </guard>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import FUNCTIONALITY_IDS from '../../constants/auth/permissions';
import Guard from '../Guard/Guard.vue';

export default {
  name: 'CorrelatedHeadcountTable',
  components: {
    Guard,
    'el-table': Table,
    'el-table-column': TableColumn,
  },
  props: {
    data: {
      type: Array,
      require: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    editHeadcount: Function,
  },
  data() {
    return {
      PERMISSIONS_HEADCOUNT: FUNCTIONALITY_IDS.APPORTIONMENT.CORRELATES.HEADCOUNT,
      edit: false,
      additional: 0,
      obs: 0,
      idAdditional: -1,
    };
  },
  methods: {
    headcountRenderer(row, column, cellValue) {
      if (!cellValue) {
        return 0;
      }
      return cellValue.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    editAditional(row) {
      this.additional = row.employeesAdditionalNumber || 0;
      this.obs = row.observation || '';
      this.idAdditional = row?.id;
      this.edit = true;
    },
    async handleEditHeadcount(row) {
      await this.editHeadcount(
        { id: row.id, observation: this.obs || row.observation }, this.additional,
      );
      this.edit = false;
      this.idAdditional = -1;
    },
  },
};
</script>

<style>
.hover {
  cursor: pointer;
}
.disabled-actions {
  color: #c0c4cc;
  text-decoration: underline;
}
.include-action {
  text-decoration: underline;
}
</style>
