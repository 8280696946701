const LOCAL_STORAGE_AUTH_NAMES = {
  loggedUser: 'SGC_loggedUser',
  token: 'SGC_TOKEN',
};

const setLoggedUserStorage = (loggedUser) => localStorage.setItem(
  LOCAL_STORAGE_AUTH_NAMES.loggedUser,
  JSON.stringify(loggedUser),
);

const getLoggedUserStorage = () => JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_AUTH_NAMES.loggedUser),
);

const removeLoggedUserStorage = () => localStorage.removeItem(LOCAL_STORAGE_AUTH_NAMES.loggedUser);

const setTokenLoggedUser = (token = '') => localStorage.setItem(LOCAL_STORAGE_AUTH_NAMES.token, token);

const getTokenLoggedUser = () => localStorage.getItem(LOCAL_STORAGE_AUTH_NAMES.token);

const removeTokenLoggedUserStorage = () => localStorage.removeItem(LOCAL_STORAGE_AUTH_NAMES.token);

export {
  LOCAL_STORAGE_AUTH_NAMES,
  setLoggedUserStorage,
  getLoggedUserStorage,
  removeLoggedUserStorage,
  setTokenLoggedUser,
  getTokenLoggedUser,
  removeTokenLoggedUserStorage,
};
