<template>
  <div class="container-validation">
    <h4>
      Aguarde...
    </h4>
  </div>
</template>

<script>
import { setLoggedUserStorage } from '../storage/modules/auth';
import serviceUser from '../infra/services/user';

export default {
  name: 'Validacao',
  created() {
    const loading = this.$loading({
      lock: true,
      text: 'Validando dados...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    });
    serviceUser.getLoggedUser()
      .then((response) => {
        setLoggedUserStorage(response.data);
        this.$router.replace({ name: 'Folha' });
        window.location.reload();
      })
      .catch(() => this.$router.replace({ name: 'Login' }))
      .finally(() => loading.close());
  },
};
</script>

<style lang="scss" scoped>
  .container-validation {
    height: 80vh;
    width: 100%;
  }
</style>
