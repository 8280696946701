<template>
  <div>
    <bread-crumb :breadcrumbs="['DRE']" />
    <el-row :gutter="20">
      <el-col :span="2">
        <h2>DRE</h2>
      </el-col>
      <el-col :span="18">
        <el-switch
          v-model="isAnalytic"
          active-text="Analítico"
          inactive-text="Sintético"
        />
      </el-col>
    </el-row>
    <el-form
      label-position="top"
      label-width="100%"
      :model="formDreData"
    >
      <el-row :gutter="15">
        <el-col
          :xl="3"
          :lg="4"
          :md="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item label="Centro de custo">
            <el-select
              v-model="formDreData.selectedsCostsCenter"
              v-infinite-scroll="getMoreCostsCenter"
              class="input"
              multiple
              filterable
              placeholder="Centros de Custo"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              :remote-method="getCostsCenter"
              :loading="loadingGetOption"
              infinite-scroll-disabled="!loadingGetOption"
            >
              <el-option
                v-for="(item, index) in options"
                :key="item.value + index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="3"
          :lg="4"
          :md="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item label="Mês/Ano inicial">
            <el-date-picker
              v-model="formDreData.startDate"
              class="input"
              type="month"
              placeholder="Mês/Ano inicial"
              format="MMM/yyyy"
              value-format="yyyy-MM"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="3"
          :lg="4"
          :md="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item label="Mês/Ano final">
            <el-date-picker
              v-model="formDreData.endDate"
              class="input"
              type="month"
              placeholder="Mês/Ano final"
              format="MMM/yyyy"
              value-format="yyyy-MM"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="3"
          :lg="4"
          :md="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item label="Tipo">
            <el-select
              v-model="formDreData.selectedEvent"
              class="input"
              clearable
              placeholder="Tipo"
            >
              <el-option
                v-for="(item, index) in eventsList"
                :key="item.value + index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col
          :xl="3"
          :lg="4"
          :md="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item label="Relatório">
            <el-select
              v-model="formDreData.seletedFilterSelectOption"
              :disabled="disableFilter"
              class="input"
              clearable
              placeholder="Relatório"
            >
              <el-option
                v-for="(item, index) in filterSelectOptions"
                :key="item.value + index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :span="1.5"
          class="container-button-submity"
        >
          <guard
            :needed-permissions="[PERMISSIONS_DRE.QUERY]"
            show-tooltip
          >
            <el-button
              type="primary"
              @click="loadDre"
            >
              GERAR
            </el-button>
            <template v-slot:denied>
              <el-button
                type="primary"
                disabled
              >
                GERAR
              </el-button>
            </template>
          </guard>
        </el-col>
        <div v-if="showDownload">
          <el-col
            :span="2"
            class="container-button-submity"
          >
            <el-button
              type="secundary"
              class="download-button"
              :disabled="loadingDownload"
              :loading="loadingDownload"
              @click="downloadReport"
            >
              DOWNLOAD
            </el-button>
          </el-col>
        </div>
      </el-row>
    </el-form>

    <div class="block">
      <br><br>
      <el-table
        v-loading="loading"
        :data="tableData"
        lazy
        :load="loadChildren"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        stripe
      >
        <el-table-column
          prop="name"
          label="Tipo"
          sortable
          width="700"
          fixed
        />
        <el-table-column
          v-for="month in yearMonths"
          :key="month"
          prop="values"
          align="right"
          :label="getMonthName(month)"
          sortable
          width="235"
          :formatter="monthsRenderer"
        />
        <el-table-column
          align="right"
          prop="total"
          label="Total"
          sortable
          width="180"
          :formatter="totalRenderer"
        />
        <el-table-column
          prop="hasChildren"
          label="Ação"
          min-width="65"
          align="right"
        >
          <template
            v-if="scope.row.showDetailsIcon || scope.row.detailEmployee"
            slot-scope="scope"
          >
            <el-row
              type="flex"
              align="center"
              justify="end"
              class="button-detail"
              @click.native.prevent="
                handleClickDetailsEntries(scope.$index, scope.row)
              "
            >
              <img
                :src="searchIcon"
                alt="search"
                height="17"
                width="17"
              >
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <cost-center-detail
      v-if="openDialogCostCenterDetail"
      :open="openDialogCostCenterDetail"
      :cost-center-infos="costCenterInfos"
      :get-filters="() => currentFilters"
      :handle-close="() => (openDialogCostCenterDetail = false)"
    />
    <employee-detail
      v-if="openDialogEmployeeDetail"
      :open="openDialogEmployeeDetail"
      :cost-center-infos="costCenterInfos"
      :get-filters="() => currentFilters"
      :handle-close="() => (openDialogEmployeeDetail = false)"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import CostCenterDetail from '../components/CostCenter/CostCenterDetail/CostCenterDetail.vue';
import EmployeeDetail from '../components/EmployeeDetail.vue';
import numberFormat from '../utils/IntlNumberFormat';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

const searchIcon = require('../../public/media/icons/search.svg');

export default {
  components: {
    BreadCrumb, CostCenterDetail, EmployeeDetail, Guard,
  },
  data() {
    return {
      PERMISSIONS_DRE: FUNCTIONALITY_IDS.INCOME_REPORT,
      searchIcon,
      loadingGetOption: false,
      loading: false,
      disableFilter: true,
      options: [],
      yearMonths: undefined,
      selectedCostcenter: '',
      filterText: '',
      tableData: [],
      isAnalytic: true,
      formDreData: {
        selectedsCostsCenter: [],
        startDate: '',
        endDate: '',
        selectedEvent: 'CONTABIL',
        seletedFilterSelectOption: null,
      },
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      eventsList: [
        {
          value: 'GERENCIAL',
          label: 'Gerencial',
        },
        {
          value: 'CONTABIL',
          label: 'Contábil',
        },
      ],
      filterSelectOptions: [],
      page: 1,
      totalCostsCenterPages: 1,
      textSearchCostCenter: '',
      openDialogCostCenterDetail: false,
      openDialogEmployeeDetail: false,
      costCenterInfos: null,
      currentFilters: {},
      showDownload: false,
      loadingDownload: false,
    };
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },

    'formDreData.selectedEvent': function (typeSelect) {
      if (typeSelect === 'CONTABIL') {
        this.disableFilter = true;
        this.formDreData.seletedFilterSelectOption = null;
      } else {
        this.disableFilter = false;
      }
    },
  },

  async mounted() {
    await this.getCostsCenter('');
    await this.getFilterOptions();
  },
  methods: {
    getMonthName(date) {
      const dateInfo = date?.split('-');
      if (dateInfo) {
        return `${
          DateTime.local(parseInt(dateInfo[0], 10), parseInt(dateInfo[1], 10), 1).monthShort
        }/${dateInfo[0]}`.replace('.', '');
      }
      return '';
    },
    createFilter(queryString) {
      return (costcenter) => costcenter.value.toLowerCase().includes(queryString.toLowerCase());
    },
    handleSelect(item) {
      [this.selectedCostcenter] = item.value.split('-');
    },
    async getMoreCostsCenter() {
      if (this.page < this.totalCostsCenterPages) {
        this.page += 1;
        await this.getCostsCenter(this.textSearchCostCenter);
      }
    },
    async getCostsCenter(search = '') {
      this.loadingGetOption = true;

      this.textSearchCostCenter = search;

      const params = {
        search,
        page: this.page,
        size: 20,
      };

      this.$api()
        .get('/costcenter', { params })
        .then((response) => {
          this.options = response.data.data.map((item) => ({
            label: `${item.code}-${item.name}`,
            value: item.code,
          }));
          this.totalCostsCenterPages = response.data.lastPage;
        })
        .finally(() => {
          this.loadingGetOption = false;
        });
    },
    getFilters() {
      const startDateLocal = this.formDreData.startDate
        && DateTime.fromISO(this.formDreData.startDate).toISODate();
      const endDateLocal = this.formDreData.endDate
        && DateTime.fromISO(this.formDreData.endDate).plus({ months: 1, days: -1 }).toISODate();

      return {
        costcenter: this.formDreData.selectedsCostsCenter,
        startDate: startDateLocal,
        endDate: endDateLocal,
        event: this.formDreData.selectedEvent,
        accountingFilterId: this.formDreData.seletedFilterSelectOption,
        isSynthetic: !this.isAnalytic,
      };
    },
    downloadReport() {
      this.loadingDownload = true;
      this.$api()
        .get('', { responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report.xlsx';
          link.click();
        })
        .finally(() => {
          this.loadingDownload = false;
        });
    },
    isFormValid(params) {
      if (!params.event) {
        this.$notify.error({
          title: 'Alerta',
          message: 'O campo "Tipo" é obrigatorio',
          duration: 3000,
        });
        return false;
      }

      if (params.event === 'GERENCIAL' && !params.accountingFilterId) {
        this.$notify.error({
          title: 'Alerta',
          message: 'O campo "Relatório" é obrigatorio',
          duration: 3000,
        });
        return false;
      }

      if (!params.startDate || !params.endDate) {
        this.$notify.error({
          title: 'Alerta',
          message: 'Os campos "Mês/Ano" inicial e "Mês/Ano final" são obrigatórios',
          duration: 3000,
        });
        return false;
      }

      return true;
    },
    loadDre() {
      this.tableData = [];

      const params = this.getFilters();
      this.currentFilters = params;

      if (!this.isFormValid(params)) {
        return;
      }

      let url = '/accounting-entries/income-statement?';
      if (params.startDate) {
        url += `startDate=${params.startDate}`;
      }
      if (params.endDate) {
        url += `&endDate=${params.endDate}`;
      }
      if (params.costcenter) {
        url += `&costcenter=${params.costcenter}`;
      }
      if (params.accountingFilterId) {
        url += `&accountingFilterId=${params.accountingFilterId}`;
      }
      if (params.event) {
        url += `&event=${params.event}`;
      }
      if (!this.isAnalytic) {
        url += `&isSynthetic=${true}`;
      }

      this.loading = true;

      this.$api().get(url)
        .then((response) => {
          response.data.dre.forEach(this.setHasChildrenFlag);
          this.yearMonths = response.data?.yearMonths.sort();
          this.tableData = response.data?.dre.map((e) => ({
            ...e,
            children: e.children.map((child) => ({
              ...child,
              total: child.values.reduce((accumulator, value) => accumulator + value, 0),
            })),
            total: e.values.reduce((accumulator, value) => accumulator + value, 0),
          })) || [];
        })
        .finally(() => {
          this.loading = false;
          this.showDownload = true;
        });
    },
    setHasChildrenFlag(group) {
      const localGroup = group;
      const hasLoadedChildren = group.children.length;
      if (!hasLoadedChildren) {
        localGroup.hasChildren = true;
      }
      localGroup.children.forEach(this.setHasChildrenFlag);
    },
    async loadChildren(row, treeNode, resolve) {
      const filters = this.currentFilters;
      const results = await this.loadAccountingAccounts({
        ...filters,
        yearMonths: this.yearMonths,
        groupId: row.id,
        isSynthetic: !this.isAnalytic,
      });
      resolve(results);
    },
    handleClickDetailsEntries(_index, row) {
      if (row?.detailEmployee) {
        this.costCenterInfos = {
          id: row.id,
          name: row.name,
          detailEmployee: row.detailEmployee,
        };
        this.openDialogEmployeeDetail = true;
      } else if (row?.id) {
        this.costCenterInfos = {
          id: row.id,
          name: row.name,
        };
        this.openDialogCostCenterDetail = true;
      }
    },
    async loadAccountingAccounts(filters) {
      const response = await this.$api().get('/accounting-entries/results-per-account', {
        params: filters,
      });
      const results = response.data.resultsPerAccount.map((account) => ({
        id: account.id,
        name: `${account.name} (${account.code})`,
        code: account.code,
        values: account.values,
        showDetailsIcon: true,
      }));
      return results;
    },

    async getFilterOptions() {
      const response = await this.$api().get('/accounting-filter');
      this.filterSelectOptions = response.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    },
    totalRenderer(row) {
      const total = row.values.reduce((sum, value) => sum + value, 0);
      return numberFormat(total);
    },
    monthsRenderer(row, column, values) {
      const formatYearMonths = this.yearMonths.map((month) => this.getMonthName(month));
      const index = formatYearMonths.indexOf(column.label);
      return numberFormat(values[index] ? values[index] : 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-col {
  margin-bottom: 1rem;
}

.input {
  width: 100%;
}

.icon-button-search {
  color: $primary-color;
}

.container-button-submity {
  height: 100px;
  display: flex;
  align-items: center;
}

.button-detail {
  padding-right: 0.5rem;
  &:hover {
    cursor: pointer;
  }
}

h2 {
  color: #4b4b4b4b;
  font-weight: 550;
  font-size: 20px;
}

.download-button {
  color: #e87305;
  outline-color: #e87305;
}

@media (max-width: 900px) and (min-width: 1024px) {
  .container-button-submity {
    height: auto;
  }
}

@media only screen and (max-width: 425px) {
  .container-button-submity {
    height: auto;
  }
}
</style>
