import axios from 'axios';
import { removeLoggedUserStorage } from '../storage/modules/auth';

export const verifyAuthToken = (geterStoreAuthToken) => (geterStoreAuthToken ? { Authorization: `${geterStoreAuthToken.token}` } : undefined);

const AxiosPlugin = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        $api() {
          const headers = verifyAuthToken(this.$store?.getters['auth/token']);

          const http = axios.create({
            baseURL: options.api.uri,
            headers,
            withCredentials: true,
          });

          http.defaults.timeout = 180000;

          http.interceptors.response.use((response) => response, async (error) => {
            if (error.response?.status === 401) {
              this.$notify.error({
                title: 'Não autorizado',
                message: 'Necessário realizar login novamente',
                duration: 3000,
              });

              removeLoggedUserStorage();
              this.$router.push({ name: 'Login' });

              return Promise.reject(error);
            }
            if (error.response?.status === 403) {
              this.$notify.error({
                title: 'Não autorizado',
                message: 'Necessário permissão para acessar esta funcionalidade',
                duration: 3000,
              });
              return Promise.reject(error);
            }
            const text = error.response?.data instanceof Blob
              ? await error.response.data.text()
              : error.response?.data;

            this.$notify.error({
              title: 'Erro',
              message: text || 'Erro interno do servidor',
              duration: 3000,
            });

            return Promise.reject(error);
          });

          return http;
        },
      },
    });
  },
};

export default AxiosPlugin;
