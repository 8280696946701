import Vue from 'vue';
import ElementUI from 'element-ui';
import VueCurrencyInput from 'vue-currency-input';
import locale from 'element-ui/lib/locale/lang/pt-br';
import variables from './variables';
import App from './App.vue';
import router from './router/routes';
import 'element-ui/lib/theme-chalk/reset.css';
import './assets/styles/element-variables.scss';
import './assets/styles/global.scss';
import AxiosPlugin from './plugins/axios.plugin';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueCurrencyInput);
Vue.use(ElementUI, { locale });
Vue.use(AxiosPlugin, variables);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
