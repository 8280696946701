<template>
  <el-dialog
    title="Solicitar Ajuste"
    :visible.sync="open"
    top="5%"
    :before-close="handleClose"
  >
    <hr>
    <el-form
      ref="localFormRequest"
      hide-required-asterisk
      :model="localFormRequest"
      :rules="rules"
    >
      <el-row :gutter="20">
        <el-col
          :xl="8"
          :lg="8"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <el-form-item label="Mês / Ano Refêrencia">
            <el-date-picker
              v-model="localFormRequest.yearMonth"
              type="month"
              placeholder="Mês / Ano Refêrencia"
              format="MMM/yyyy"
              value-format="yyyy-MM"
              class="inputs-full-width"
            />
          </el-form-item>
        </el-col>

        <el-col
          :xl="8"
          :lg="8"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <el-form-item
            label="Tipo"
            prop="type"
          >
            <el-select
              v-model="localFormRequest.type"
              filterable
              clearable
              class="inputs-full-width"
            >
              <el-option
                value="FOLHA"
                label="Folha"
              />
              <el-option
                value="CONTABIL"
                label="Outros"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col
          v-if="logicalAccounting"
          :xl="8"
          :lg="8"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <el-form-item label="Ação">
            <el-select
              v-model="changingAccount"
              class="inputs-full-width"
              placeholder="Ação"
              autocomplete
              clearable
              value-key="id"
              :disabled="logicalSheet"
            >
              <el-option
                :value="true"
                label="Mudança de Conta"
              />
              <el-option
                :value="false"
                label="Reclasificação de CC"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        type="flex"
        align="center"
      >
        <el-col
          :xl="8"
          :lg="8"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <el-form-item
            label="Conta contábil"
            prop="ledgerAccount"
          >
            <el-select
              v-model="localFormRequest.ledgerAccount"
              class="inputs-full-width"
              placeholder="Conta contábil"
              filterable
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getLedgeAccount"
              :loading="loadingLedgerAccountList"
              :disabled="logicalSheet || changingAccount"
            >
              <el-option
                v-for="item in ledgerAccountList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
                {{ item.code }} - {{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        type="flex"
        align="center"
      >
        <el-col
          :xl="18"
          :lg="18"
          :md="22"
          :sm="22"
          :xs="22"
        >
          <el-form-item label="Colaborador">
            <el-select
              v-model="localFormRequest.collaborator"
              placeholder="Colaborador"
              class="inputs-full-width"
              style="width: 440px"
              filterable
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getCollaborators"
              :loading="loadingCollaborators"
              :disabled="logicalAccounting"
            >
              <el-option
                v-for="item in collaborators"
                :key="item.id"
                :label="`${item.badge} - ${item.name}`"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <div style="display: flex; align-items: center; padding-top: 1.3rem">
          <el-button
            type="text"
            circle
            :disabled="
              !localFormRequest.collaborator || loadingItemsSearchColaborator
            "
            @click="handleClickSarchCollaborator"
          >
            <img
              :src="searchIcon"
              alt="search-collaborator"
              width="15"
              height="15"
            >
          </el-button>
        </div>
      </el-row>
      <el-row
        v-if="itemsSearchColaborator.length > 0"
        class="container-employee-apportionment"
      >
        <el-row class="title-employee-apportionment">
          <el-col
            :xl="14"
            :lg="14"
            :md="14"
            :sm="14"
            :xs="14"
          >
            <h6>CC</h6>
          </el-col>
          <el-col
            :xl="4"
            :lg="4"
            :md="4"
            :sm="4"
            :xs="4"
          >
            <h6>Participação</h6>
          </el-col>
        </el-row>
        <el-row
          v-for="item in itemsSearchColaborator"
          :key="item.id"
          class="overflow-employee-apportionment"
        >
          <el-row
            v-for="i in item.employeeApportionment"
            :key="i.id"
            class="row-employee-apportionment"
          >
            <el-col
              :xl="14"
              :lg="14"
              :md="14"
              :sm="14"
              :xs="14"
            >
              <h6>{{ i.costcenter.name }}</h6>
            </el-col>
            <el-col
              :xl="4"
              :lg="4"
              :md="4"
              :sm="4"
              :xs="4"
            >
              <h6>{{ i.apportionment * 100 }}%</h6>
            </el-col>
          </el-row>
        </el-row>
      </el-row>

      <el-row :gutter="20">
        <el-col
          :xl="9"
          :lg="9"
          :md="12"
          :sm="24"
          :xs="24"
        >
          <el-form-item label="Tipo transferência">
            <el-select
              v-model="localFormRequest.typeTransfer"
              placeholder="Tipo transferência"
              class="inputs-full-width"
            >
              <el-option
                v-if="!logicalAccounting"
                value="percentage"
                label="%"
              />
              <el-option
                v-else
                value="currency"
                label="R$"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col
          :xl="9"
          :lg="9"
          :md="12"
          :sm="24"
          :xs="24"
        >
          <el-form-item label="Conta Débito">
            <el-select
              v-model="localFormRequest.debitAccount"
              filterable
              autocomplete
              clearable
              remote
              multiple
              loading-text="Aguarde..."
              value-key="id"
              class="inputs-full-width"
              :remote-method="getLedgeAccount"
              :loading="loadingLedgerAccountList"
              :disabled="!changingAccount"
              placeholder="Conta Débito"
            >
              <el-option
                v-for="item in ledgerAccountList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
                {{ item.code }} - {{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col
          :xl="9"
          :lg="9"
          :md="12"
          :sm="24"
          :xs="24"
        >
          <el-form-item label="Conta Crédito">
            <el-select
              v-model="localFormRequest.creditAccount"
              filterable
              autocomplete
              clearable
              remote
              multiple
              loading-text="Aguarde..."
              value-key="id"
              class="inputs-full-width"
              :remote-method="getLedgeAccount"
              :loading="loadingLedgerAccountList"
              :disabled="!changingAccount"
              placeholder="Conta Crédito"
            >
              <el-option
                v-for="item in ledgerAccountList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
                {{ item.code }} - {{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        v-for="(row, index) in formRequest.rows"
        :key="index"
        :gutter="20"
      >
        <el-col
          v-if="ccFile.length === 0"
          :span="9"
        >
          <el-form-item label="CC Origem">
            <el-select
              v-model="localFormRequest.rows[index].costCenterOrigin"
              filterable
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getCostsCenterOriginSolicitation"
              :loading="loadingCostsCenterOriginSolicitation"
              class="inputs-full-width"
              placeholder="CC Origem"
            >
              <el-option
                v-for="item in costCenterOriginSolicitation"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
                {{ item.code }} - {{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col
          v-if="ccFile.length === 0"
          :span="9"
        >
          <el-form-item label="CC Destino">
            <el-select
              v-model="localFormRequest.rows[index].costCenterDestiny"
              filterable
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getCostsCenterDistinySolicitation"
              :loading="loadingCostsCenterDistinySolicitation"
              class="inputs-full-width"
              placeholder="CC Destino"
            >
              <el-option
                v-for="item in costCenterDistinySolicitation"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
                {{ item.code }} - {{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          v-if="ccFile.length === 0"
          :span="6"
        >
          <el-form-item label="Valor">
            <el-input
              v-if="showInputPercentage"
              v-model="localFormRequest.rows[index].value"
              class="inputs-full-width"
              @input="(value) => validatePercentage(value, index)"
            />
            <el-currency-input
              v-else
              v-model="localFormRequest.rows[index].value"
              class="inputs-full-width"
            />
          </el-form-item>
        </el-col>
        <el-col v-else>
          <span> 1 arquivo salvo</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="18">
          <el-form-item label="Observação">
            <el-input
              v-model="localFormRequest.note"
              type="textarea"
              :rows="3"
              placeholder="Observação"
              maxlength="200"
              show-word-limit
              class="inputs-full-width"
            />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-button
              class="add-adjust"
              type="text"
              @click="addItemInformRequestRows"
            >
              + Incluir Ajuste
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-button
              class="add-adjust"
              type="text"
              style="color: blue;"
              @click="openUploadModal"
            >
              + Ajuste em lote
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        justify="end"
      >
        <el-button
          size="small"
          @click="dialogRequest = false"
        >
          Cancelar
        </el-button>
        <el-button
          size="small"
          type="primary"
          :disabled="loadingSubmit"
          :loading="loadingSubmit"
          @click="submitFormRequest"
        >
          Salvar
        </el-button>
      </el-row>
    </el-form>
    <el-dialog
      width="32%"
      title="Upload de Arquivo"
      :visible.sync="showUploadModal"
      :before-close="handleCloseVisibleInnerModal"
      center
      append-to-body
    >
      <el-upload
        style="margin-left: 10%"
        drag
        action="#"
        :on-remove="handleRemove"
        :file-list="ccFile"
        :limit="1"
        :on-exceed="handleExceed"
        :on-change="handleChange"
        :http-request="handleChange"
        accept=".txt, .xls, .xlsx"
      >
        <i class="el-icon-upload" />
        <div
          class="el-upload__text"
          style="padding: 8%"
        >
          Arraste arquivos aqui, ou
          <b>clique para selecionar o arquivo do seu computador</b>
        </div>
        <div
          slot="tip"
          class="el-upload__tip"
          width="100%"
        />
      </el-upload>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          id="#buttonSendFile"
          type="primary"
          :disabled="!ccFile.length"
          @click="showUploadModal = false"
        >
          ENVIAR
        </el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { Row } from 'element-ui';
import ElCurrencyInput from '../ElCurrencyInput/ElCurrencyInput.vue';

const searchIcon = require('../../../public/media/icons/search.svg');

export default {
  name: 'AdjustInsert',
  components: {
    'el-row': Row,
    ElCurrencyInput,
  },
  props: {
    selectedRow: Object,
    loadingEditSetting: Boolean,
    formRequest: Object,
    rulesFormEdit: Object,
    handleClose: {
      type: Function,
      require: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const validateLedgerAccount = (rule, value, callback) => {
      if (this.localFormRequest.type === 'CONTABIL' && !this.changingAccount) {
        if (!value) {
          callback(new Error('Obrigatório para Tipo Outros - Conta Contábil'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      collaborators: [],
      costCenterOriginSolicitation: [],
      costCenterDistinySolicitation: [],
      itemsSearchColaborator: [],
      loadingCollaborators: false,
      loadingSubmit: false,
      loadingCostsCenterOriginSolicitation: false,
      loadingCostsCenterDistinySolicitation: false,
      ledgerAccountList: [],
      localFormRequest: this.formRequest,
      localDialogSetting: this.dialogSetting,
      loadingItemsSearchColaborator: false,
      logicalSheet: false,
      logicalAccounting: false,
      loadingLedgerAccountList: false,
      showInputPercentage: false,
      searchIcon,
      rules: {
        ledgerAccount: [{ validator: validateLedgerAccount, trigger: 'submit' }],
      },
      ccFile: [],
      changingAccount: false,
      showUploadModal: false,
    };
  },
  watch: {
    'localFormRequest.type': function (typeSelect) {
      if (typeSelect === 'FOLHA') {
        this.logicalSheet = true;
        this.logicalAccounting = false;
        this.localFormRequest.ledgerAccount = null;
        this.localFormRequest.typeTransfer = null;
      } else if (typeSelect === 'CONTABIL') {
        this.logicalSheet = false;
        this.logicalAccounting = true;
        this.localFormRequest.collaborator = null;
        this.localFormRequest.typeTransfer = null;
      }
    },

    'localFormRequest.typeTransfer': function (typeTransfer) {
      this.showInputPercentage = Boolean(typeTransfer === 'percentage');
    },

    changingAccount: {
      handler(val) {
        if (val) {
          this.localFormRequest.ledgerAccount = null;
        } else {
          this.localFormRequest.debitAccount = [];
          this.localFormRequest.creditAccount = [];
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getLedgeAccount();
    this.getCostsCenterOriginSolicitation();
    this.getCostsCenterDistinySolicitation();
  },
  methods: {
    onCancel() {
      this.$emit('clickCancel');
    },
    onSave() {
      this.$refs.localFormEdit.validate((valid) => {
        if (valid) {
          this.loadingEditSetting = true;
          this.$emit('clickSave', this.localFormEdit);
        }
      });
    },
    getLedgeAccount(search = '') {
      this.loadingLedgerAccountList = true;

      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.$api()
        .get('/accounting-account/list', { params })
        .then((response) => {
          this.ledgerAccountList = response.data.data;
        })
        .finally(() => {
          this.loadingLedgerAccountList = false;
        });
    },

    getCollaborators(search = '') {
      this.loadingCollaborators = true;

      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.$api()
        .get('/employee/list', { params })
        .then((response) => {
          this.collaborators = response.data.data;
        })
        .finally(() => {
          this.loadingCollaborators = false;
        });
    },
    handleClickSarchCollaborator() {
      this.loadingItemsSearchColaborator = true;
      this.$api()
        .get(`/employee/${this.formRequest.collaborator.id}/all/relations/apportionment`)
        .then((response) => {
          this.itemsSearchColaborator = response.data;
        })
        .finally(() => {
          this.loadingItemsSearchColaborator = false;
        });
    },
    getCostsCenterOriginSolicitation(search = '') {
      this.loadingCostsCenterOriginSolicitation = true;

      const params = {
        search,
        page: 1,
        size: 20,
        parameterized: true,
      };

      this.$api()
        .get('/costcenter/only', { params })
        .then(({ data }) => {
          this.costCenterOriginSolicitation = data.data;
        })
        .finally(() => {
          this.loadingCostsCenterOriginSolicitation = false;
        });
    },
    getCostsCenterDistinySolicitation(search = '') {
      this.loadingCostsCenterDistinySolicitation = true;

      const params = {
        search,
        page: 1,
        size: 20,
        parameterized: true,
      };

      this.$api()
        .get('/costcenter/only', { params })
        .then(({ data }) => {
          this.costCenterDistinySolicitation = data.data;
        })
        .finally(() => {
          this.loadingCostsCenterDistinySolicitation = false;
        });
    },
    validatePercentage(value, index) {
      this.localFormRequest.rows[index].value = value.replace(/[^0-9.,]/g, '');
    },
    addItemInformRequestRows() {
      const lastRow = this.formRequest.rows[this.formRequest.rows.length - 1];

      if (!lastRow.costCenterOrigin || !lastRow.costCenterDestiny) {
        this.$notify({
          title: 'Incluir ajuste',
          message: 'Selecione CC Origem e Destino para incluir outro ajuste',
          type: 'warning',
        });
        return;
      }
      this.localFormRequest.rows.push({
        costCenterOrigin: null,
        costCenterDestiny: null,
        value: 0,
      });
    },
    openUploadModal() {
      this.showUploadModal = true;
      this.ccFile = [];
    },
    handleCloseVisibleInnerModal(done) {
      this.ccFile = [];
      done();
    },
    handleRemove() {
      this.ccFile = [];
    },
    handleExceed() {
      this.$message.warning('Você só pode importar 1 arquivo.');
    },
    handleChange(file, fileList) {
      if (
        file.raw.type === 'text/plain'
        || file.raw.type === 'application/vnd.ms-excel'
        || file.raw.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.ccFile = fileList.slice(-3);
      } else {
        this.ccFile = [];
        this.alert('Extensão do arquivo inválida');
      }
    },
    submitFormRequest() {
      this.$refs.localFormRequest.validate((valid) => {
        if (valid) {
          const [year, month] = this.localFormRequest.yearMonth?.split('-');

          const params = {
            month: Number(month),
            year: Number(year),
            yearMonth: this.localFormRequest.yearMonth,
            isPayroll: this.localFormRequest.type === 'FOLHA',
            type: this.localFormRequest.typeSettings,
            transferType: this.localFormRequest.typeTransfer === 'currency' ? 'R$' : '%',
            accountingAccount: this.localFormRequest.ledgerAccount?.id || null,
            employee: this.localFormRequest.collaborator?.id || null,
            observation: this.localFormRequest.note,
            adjustsEntry: this.localFormRequest.rows.map((row) => ({
              destinationCC: row.costCenterDestiny?.id,
              originCC: row.costCenterOrigin?.id,
              value: row?.value ? Number(String(row.value).replace(',', '.')) : 0,
            })),
            debitAccount: this.localFormRequest.debitAccount,
            creditAccount: this.localFormRequest.creditAccount,
          };

          this.loadingSubmit = true;

          this.$api()
            .post('/adjusts', params)
            .then(() => {
              this.$notify({
                title: 'Ajuste',
                message: 'Ajuste cadastrado com sucesso!',
                type: 'success',
              });
            })
            .finally(() => {
              this.loadingSubmit = false;
              this.handleClose();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.inputs-full-width {
  width: 100% !important;
}

.container-employee-apportionment {
  margin-bottom: 52px;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
.title-employee-apportionment {
  margin-bottom: 9px;

  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}
</style>
