<template>
  <div>
    <bread-crumb :breadcrumbs="['Perfil']" />
    <guard :needed-permissions="[PERMISSIONS_PROFILE.FULL]">
      <el-row
        :gutter="20"
        type="flex"
        justify="space-between"
      >
        <el-row
          type="flex"
          style="gap:10px"
        >
          <el-input
            v-model.lazy="searchEmployee"
            filterable
            placeholder="Colaborador"
            clearable
            remote
            loading-text="Aguarde..."
            class="big-select"
          />
          <el-select
            v-model="searchProfile"
            filterable
            placeholder="Perfil"
            clearable
            remote
            loading-text="Aguarde..."
            class="big-select"
          >
            <el-option
              v-for="profile in profiles"
              :key="profile.id"
              :label="profile.name"
              :value="profile.id"
            />
          </el-select>
        </el-row>
        <el-col
          :gutter="20"
          type="flex"
          align="end"
          justify="end"
        >
          <el-button
            @click="getMapping"
          >
            Mapa de Perfis
          </el-button>
          <el-button
            id="novo-usuario"
            type="primary"
            @click="handleClickCreate"
          >
            Novo Usuario
          </el-button>
        </el-col>
      </el-row>

      <div class="block">
        <br><br>
        <el-table
          v-loading="loading"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          :data="tableData"
          stripe
        >
          <el-table-column
            prop="email"
            label="E-mail"
            align="left"
            min-width="100px"
          />
          <el-table-column
            prop="profile.name"
            label="Perfil"
            align="left"
            min-width="100px"
          />
          <el-table-column
            prop="status"
            label="Status"
            align="left"
            min-width="100px"
            :formatter="statusRenderer"
          />
          <el-table-column
            label="Ação"
            align="left"
            width="100"
          >
            <template slot-scope="scope">
              <el-row
                type="flex"
                align="center"
              >
                <div
                  id="edit-button-profile"
                  class="container-icon"
                  @click="handleClickEdit(scope.row)"
                >
                  <img
                    :src="editIcon"
                    alt="edit"
                    height="20"
                    width="20"
                  >
                </div>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <edit-profile-dialog
        v-if="openProfileEditDialog"
        :is-edit="isEdit"
        :open="openProfileEditDialog"
        :on-close="handleCloseEdit"
        :selected-profile="selectedProfile"
        :update-user="updateUser"
        :create-user="createUser"
      />
    </guard>
  </div>
</template>

<script>
import {
  Row, TableColumn, Table, Button,
} from 'element-ui';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import EditProfileDialog from '../components/Profile/EditProfileDialog.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import Guard from '../components/Guard/Guard.vue';

const editIcon = require('../../public/media/icons/edit.svg');

export default {
  name: 'Perfil',
  components: {
    EditProfileDialog,
    BreadCrumb,
    'el-row': Row,
    'el-table-column': TableColumn,
    'el-table': Table,
    'el-button': Button,
    Guard,
  },
  data() {
    return {
      PERMISSIONS_PROFILE: FUNCTIONALITY_IDS.PROFILE,
      tableData: [],
      profiles: [],
      searchProfile: undefined,
      searchEmployee: undefined,
      showModal: false,
      period: undefined,
      loading: false,
      editIcon,
      openProfileEditDialog: false,
      selectedProfile: undefined,
      isiEdit: false,
    };
  },
  watch: {
    searchProfile: {
      async handler() {
        await this.getTableData();
      },
    },
    searchEmployee: {
      async handler() {
        await this.getTableData();
      },
    },
  },
  async mounted() {
    await this.getProfiles();
  },
  methods: {
    getMapping() {
      this.$router.push({ name: 'controlePerfil' });
    },
    handleClickCreate() {
      this.selectedProfile = null;
      this.isEdit = false;
      this.openProfileEditDialog = true;
    },
    handleClickEdit(row) {
      this.selectedProfile = row;
      this.isEdit = true;
      this.openProfileEditDialog = true;
    },
    handleCloseEdit() {
      this.openProfileEditDialog = false;
    },
    async getTableData() {
      const params = {
        profile: this.searchProfile,
        user: this.searchEmployee,
      };

      await this.$api()
        .get('/profile/users', { params })
        .then((response) => {
          this.tableData = response.data.map((e) => ({
            id: e.id,
            name: e.name,
            email: e.email,
            profile: e.profile,
            status: e.status,
          }));
        });
    },
    async getProfiles() {
      await this.$api()
        .get('/profile/profiles')
        .then((response) => {
          this.profiles = response.data;
        });
    },
    async createUser(form) {
      await this.$api()
        .post('/profile/users', form)
        .then(() => {
          this.openProfileEditDialog = false;
          this.$notify.success({
            title: 'Alerta',
            message: 'Usuario criado com Sucesso',
            duration: 3000,
          });
          this.getTableData();
        });
    },
    async updateUser(form) {
      await this.$api()
        .patch(`profile/users/${form.id}`, form)
        .then(() => {
          this.openProfileEditDialog = false;
          this.$notify.success({
            title: 'Alerta',
            message: 'Usuario alterado com sucesso',
            duration: 3000,
          });
          this.getTableData();
        });
    },
    statusRenderer(row, column, cellValue) {
      const text = cellValue.charAt(0).toUpperCase() + cellValue.slice(1);
      if (cellValue === 'ativo') {
        return (
          <div style="color: #67c23a;">
            <span>{{ text }}</span>
          </div>
        );
      }
      return (
        <div style="color: red;">
          <span>{{ text }}</span>
        </div>
      );
    },
  },
};
</script>

<style lang="scss" scoped>

  .green-status{
    color:green
  }

  .red-status{
    color:red
  }

  .big-select {
  width: 100% !important;
}
  a {
    cursor: pointer;
    text-decoration: underline;
  }
  h2 {
    color: #e87305;
    font-weight: 550;
    font-size: 20px;
  }
  </style>
