<template>
  <el-dialog
    :visible.sync="open"
    :before-close="handleClose"
    width="85%"
  >
    <span slot="title">
      {{costCenterInfos.name}}  <i class="el-icon-download hover-pointer" type="primary"
        @click="loadEntriesDetailById(true)" />
    </span>
    <el-table
      v-loading="loadingGetEtriesDetail"
      :data="tableData"
      stripe
      height="55vh"
    >
      <el-table-column
        property="date"
        label="Data"
        min-width="100"
        :formatter="formatterDate"
      />
      <el-table-column
        property="syntheticEntry.costcenter.name"
        label="Centro de Custo"
        min-width="200"
        :formatter="formatterCustCenter"
      />
      <el-table-column
        property="entryType.code"
        label="Tipo de lançamento"
        min-width="200"
        :formatter="formatterEntryType"
      />
      <el-table-column
        property="employee.badge"
        label="Colaborador"
        min-width="200"
        :formatter="formatterEmployee"
      />
      <el-table-column
        align="right"
        property="value"
        label="Valor"
        min-width="150"
        :formatter="formatterValue"
      />
    </el-table>
  </el-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import { Dialog, Table, TableColumn } from 'element-ui';
import numberFormat from '../../../utils/IntlNumberFormat';

export default {
  name: 'CostCenterDetail',
  components: {
    'el-dialog': Dialog,
    'el-table': Table,
    'el-table-column': TableColumn,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    costCenterInfos: {
      type: Object,
      required: true,
    },
    handleClose: {
      type: Function,
      require: true,
    },
    getFilters: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      loadingGetEtriesDetail: false,
      tableData: [],
    };
  },
  mounted() {
    this.loadEntriesDetailById();
  },
  methods: {
    loadEntriesDetailById(download) {
      const filters = this.getFilters();

      const params = {
        ...filters,
        accountingAccountId: this.costCenterInfos.id,
        download,
      };

      this.loadingGetEtriesDetail = true;

      this.$api()
        .get('/accounting-entries', { params })
        .then((response) => {
          if (download) {
            const blob = new Blob([response.data], { type: 'application/excel' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'report.xlsx';
            link.click();
          }
          this.tableData = response.data;
        })
        .finally(() => {
          this.loadingGetEtriesDetail = false;
        });
    },
    formatterCustCenter(row) {
      const {
        syntheticEntry: { costcenter },
      } = row;
      return `${costcenter.code}-${costcenter.name}`;
    },
    formatterAccountingAccount(row) {
      const {
        syntheticEntry: { accountingAccount },
      } = row;
      return `${accountingAccount.code}-${accountingAccount.name}`;
    },
    formatterEntryType(row) {
      const { entryType } = row;
      if (!entryType) return '';
      return `${entryType.code}-${entryType.description}`;
    },
    formatterEmployee(row) {
      const { employee, employeeApportionment } = row;
      let fmtEmployee = '';
      if (employee) {
        fmtEmployee = `${employee.badge}-${employee.name} `;
        if (employeeApportionment) {
          fmtEmployee = `${fmtEmployee} (${parseFloat(employeeApportionment.apportionment).toFixed(2) * 100}%)`;
        }
      }
      return fmtEmployee;
    },
    formatterDate(row) {
      const [year, month, day] = row.date?.split('-');
      return DateTime.local(Number(year), Number(month), Number(day))
        .setLocale('fr')
        .toFormat('dd/LL/yyyy');
    },
    formatterValue(row) {
      return numberFormat(row.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.hover-pointer {
  cursor: pointer;
}
</style>
