<template>
  <div>
    <bread-crumb :breadcrumbs="['Configurações', 'Estrutura de Projetos']" />
    <el-row>
      <el-col>
        <h2>Configurações - Estrutura de Projetos</h2>
      </el-col>
    </el-row>
    <br><br>
    <div v-loading="loadingAssociations">
      <el-row>
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <span>Unidade organizacional<br><br></span>
          <el-select
            v-model="organizationUnit"
            placeholder="Unidade Organizacional"
            autocomplete
            filterable
            clearable
            remote
            :remote-method="getOrganizationUnits"
            value-key="id"
            loading-text="Aguarde..."
            :loading="loadingOrganizationUnits"
          >
            <el-option
              v-for="item in organizationUnitsList"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-row
            type="flex"
            justify="end"
            class="container-btns gap-1"
          >
            <el-button
              type="primary"
              :disabled=" loadingSaveEditions || !organizationUnit
                || (!addBusinessUnitList.length && !addCellList.length &&
                  !deleteBusinessUnitList.length && !deleteCellList.length)"
              :loading="loadingSaveEditions"
              size="medium"
              @click="saveEditions"
            >
              Salvar Alterações
            </el-button>
            <el-button
              type="secundary"
              size="medium"
              @click="handleOpenRegister"
            >
              Cadastrar
            </el-button>
          </el-row>
        </el-col>
      </el-row>

      <project-structure-add-modal
        v-if="showRegisterModal"
        :handle-close-register-modal="handleCloseRegisterModal"
        :show-register-modal="showRegisterModal"
        :get-organization-units="getOrganizationUnits"
      />

      <project-structure-transfer-modal
        v-if="showTransferModal"
        :handle-close-transfer-modal-for-business-unit="handleCloseTransferModalForBusinessUnit"
        :handle-close-transfer-modal-for-cell="handleCloseTransferModalForCell"
        :show-transfer-modal="showTransferModal"
        :selected-cell-row="selectedCellRow"
        :selected-business-unit-row="selectedBusinessUnitRow"
        :get-cell-table-data="getCellTableData"
        :get-business-unit-table-data="getBusinessUnitTableData"
      />

      <div v-if="showAssociations">
        <br><br><br><br>
        <el-row :gutter="40">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-row>
              <el-col
                :xs="24"
                :sm="12"
                :md="12"
                :lg="12"
                :xl="12"
              >
                <span>BU<br><br></span>
                <el-select
                  v-model="businessUnit"
                  placeholder="BU"
                  autocomplete
                  filterable
                  clearable
                  remote
                  :remote-method="getBusinessUnits"
                  value-key="id"
                  loading-text="Aguarde..."
                  :loading="loadingBusinessUnits"
                >
                  <el-option
                    v-for="item in businessUnitList"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-col>
              <el-col
                :xs="24"
                :sm="12"
                :md="12"
                :lg="12"
                :xl="12"
              >
                <el-row
                  type="flex"
                  justify="end"
                  class="container-btns gap-1"
                >
                  <el-button
                    :disabled="!businessUnit"
                    :loading="loadingAddBusinessUnit"
                    @click="addBusinessUnit"
                  >
                    + INCLUIR
                  </el-button>
                </el-row>
              </el-col>
              <el-table
                v-loading="loadingBusinessUnitTableData"
                :data="businessUnitTableData.results"
                class="table"
                stripe
              >
                <el-table-column
                  property="name"
                  label="Nome"
                />
                <el-table-column
                  fixed="right"
                  label="Ação"
                  width="80"
                >
                  <template slot-scope="scope">
                    <div class="actions">
                      <img
                        class="ml-2"
                        :src="refreshIcon"
                        alt="refresh"
                        height="18"
                        width="18"
                        @click="handleOpenTransferForBusinessUnit(scope.row)"
                      >
                      <img
                        class="ml-2"
                        :src="deleteIcon"
                        alt="delete"
                        height="18"
                        width="18"
                        @click="deleteBusinessUnit(scope.$index)"
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <el-row
              type="flex"
              justify="center"
              class="container-pagination"
            >
              <el-pagination
                small
                background
                hide-on-single-page
                layout="prev, pager, next"
                :page-size="businessUnitTableData.lastPage"
                :current-page="businessUnitTableData.page"
                :page-count="businessUnitTableData.lastPage"
                @current-change="getBusinessUnitTableData"
              />
            </el-row>
          </el-col>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-row>
              <el-col
                :xs="24"
                :sm="12"
                :md="12"
                :lg="12"
                :xl="12"
              >
                <span>Célula<br><br></span>
                <el-select
                  v-model="cell"
                  placeholder="Célula"
                  autocomplete
                  filterable
                  clearable
                  remote
                  :remote-method="getCells"
                  value-key="id"
                  loading-text="Aguarde..."
                  :loading="loadingCells"
                >
                  <el-option
                    v-for="item in cellList"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-col>

              <el-col
                :xs="24"
                :sm="12"
                :md="12"
                :lg="12"
                :xl="12"
              >
                <el-row
                  type="flex"
                  justify="end"
                  class="container-btns gap-1"
                >
                  <el-button
                    :disabled="!cell"
                    :loading="loadingAddCell"
                    @click="addCell"
                  >
                    + INCLUIR
                  </el-button>
                </el-row>
              </el-col>

              <el-table
                v-loading="loadingCellTableData"
                :data="cellTableData.results"
                class="table"
                stripe
              >
                <el-table-column
                  property="name"
                  label="Nome"
                />
                <el-table-column
                  fixed="right"
                  label="Ação"
                  width="80"
                >
                  <template slot-scope="scope">
                    <div class="actions">
                      <img
                        class="ml-2"
                        :src="refreshIcon"
                        alt="refresh"
                        height="18"
                        width="18"
                        @click="handleOpenTransferForCell(scope.row)"
                      >
                      <img
                        class="ml-2"
                        :src="deleteIcon"
                        alt="search"
                        height="18"
                        width="18"
                        @click="deleteCell(scope.$index)"
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-row
                type="flex"
                justify="center"
                class="container-pagination"
              >
                <el-pagination
                  small
                  background
                  hide-on-single-page
                  layout="prev, pager, next"
                  :page-size="cellTableData.lastPage"
                  :current-page="cellTableData.page"
                  :page-count="cellTableData.lastPage"
                  @current-change="getCellTableData"
                />
              </el-row>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import ProjectStructureAddModal from '../components/ProjectStructure/ProjectStructureAddModal.vue';
import ProjectStructureTransferModal from '../components/ProjectStructure/ProjectStructureTransferModal.vue';

const refreshIcon = require('../../public/media/icons/refresh.svg');
const deleteIcon = require('../../public/media/icons/delete.svg');

export default {
  name: 'ConfiguracoesEstruturaProjetos',
  components: {
    BreadCrumb,
    ProjectStructureAddModal,
    ProjectStructureTransferModal,
  },

  data() {
    return {
      deleteIcon,
      refreshIcon,

      showAssociations: false,
      loadingAssociations: false,

      loadingOrganizationUnits: false,
      organizationUnit: null,
      organizationUnitsList: [],

      loadingBusinessUnits: false,
      businessUnit: null,
      businessUnitList: [],

      loadingCells: false,
      cell: null,
      cellList: [],

      loadingBusinessUnitTableData: false,
      businessUnitTableData: {
        results: [],
        total: 0,
        size: 10,
        page: 1,
        lastPage: 1,
      },
      addBusinessUnitList: [],
      deleteBusinessUnitList: [],

      loadingCellTableData: false,
      cellTableData: {
        results: [],
        total: 0,
        size: 10,
        page: 1,
        lastPage: 1,
      },
      addCellList: [],
      deleteCellList: [],

      lists: {
        cells: {
          insertedItemsIds: [],
          removedItemsIds: [],
        },
        businessUnits: {
          insertedItemsIds: [],
          removedItemsIds: [],
        },
      },

      loadingAddBusinessUnit: false,
      loadingAddCell: false,

      loadingSaveEditions: false,

      selectedBusinessUnitRow: null,
      selectedCellRow: null,
      showTransferModal: false,

      showRegisterModal: false,
    };
  },

  watch: {
    async organizationUnit(val) {
      this.loadingAssociations = true;
      this.showAssociations = false;

      this.addCellList = [];
      this.deleteCellList = [];

      this.addBusinessUnitList = [];
      this.deleteBusinessUnitList = [];

      if (val) {
        await this.getCells();
        await this.getBusinessUnits();

        await this.getBusinessUnitTableData(1);
        await this.getCellTableData(1);

        this.showAssociations = true;
      }
      this.loadingAssociations = false;
    },
  },
  async created() {
    await this.getOrganizationUnits();
  },

  methods: {
    async getBusinessUnitTableData(page, search = '') {
      this.loadingBusinessUnitTableData = true;

      const params = {
        page,
        name: search,
        size: this.businessUnitTableData.size,
      };

      await this.$api().get(`/business-unit/${this.organizationUnit?.id}`, { params })
        .then(({ data }) => {
          const listWithoutItemsDeleted = data?.data.filter(
            (item) => !this.deleteBusinessUnitList.some(
              (itemDeleted) => itemDeleted?.id === item?.id,
            ),
          ) || [];

          if (page === 1) {
            this.businessUnitTableData.results = this.addBusinessUnitList
              .concat(listWithoutItemsDeleted);
          } else {
            this.businessUnitTableData.results = listWithoutItemsDeleted;
          }

          this.businessUnitTableData.total = data.total;
          this.businessUnitTableData.page = data.currentPage;
          this.businessUnitTableData.lastPage = data.lastPage;
        })
        .finally(() => {
          this.loadingBusinessUnitTableData = false;
        });
    },

    async getCellTableData(page, search = '') {
      this.loadingCellTableData = true;

      const params = {
        page,
        name: search,
        size: this.cellTableData.size,
      };

      await this.$api().get(`/cell/${this.organizationUnit?.id}`, { params })
        .then(({ data }) => {
          const listWithoutItemsDeleted = data?.data.filter(
            (item) => !this.deleteCellList.some(
              (itemDeleted) => itemDeleted?.id === item?.id,
            ),
          ) || [];

          if (page === 1) {
            this.cellTableData.results = this.addCellList
              .concat(listWithoutItemsDeleted);
          } else {
            this.cellTableData.results = listWithoutItemsDeleted;
          }

          this.cellTableData.total = data.total;
          this.cellTableData.page = data.currentPage;
          this.cellTableData.lastPage = data.lastPage;
        })
        .finally(() => {
          this.loadingCellTableData = false;
        });
    },

    async getBusinessUnits(search = '') {
      this.loadingBusinessUnitList = true;

      const params = {
        page: 1,
        size: 25,
        name: search,
        onlyWithoutAssociation: true,
      };

      await this.$api().get('/business-unit', { params })
        .then(({ data }) => {
          this.businessUnitList = data?.data.filter(
            (item) => !this.addBusinessUnitList.some(
              (itemAdded) => itemAdded?.id === item?.id,
            ),
          ) || [];
        })
        .finally(() => {
          this.loadingBusinessUnitList = false;
        });
    },

    async getCells(search = '') {
      this.loadingCellList = true;

      const params = {
        page: 1,
        size: 25,
        name: search,
        onlyWithoutAssociation: true,
      };

      await this.$api().get('/cell', { params })
        .then(({ data }) => {
          this.cellList = data?.data.filter(
            (item) => !this.addCellList.some(
              (itemAdded) => itemAdded?.id === item?.id,
            ),
          ) || [];
        })
        .finally(() => {
          this.loadingCellList = false;
        });
    },

    async getOrganizationUnits(search = '') {
      this.loadingOrganizationUnits = true;

      const params = {
        page: 1,
        size: 25,
        name: search,
        includeProjects: true,
      };

      await this.$api().get('/organization-unit', { params })
        .then(({ data }) => {
          this.organizationUnitsList = data.data || [];
        })
        .finally(() => {
          this.loadingOrganizationUnits = false;
        });
    },

    async addBusinessUnit() {
      this.loadingAddBusinessUnit = true;

      const restoreItemIndex = this.deleteBusinessUnitList.findIndex(
        (obj) => obj?.id === this.businessUnit?.id,
      );
      if (restoreItemIndex !== -1) {
        this.businessUnitTableData.results.unshift(this.deleteBusinessUnitList[restoreItemIndex]);
        this.addBusinessUnitList.unshift(this.deleteBusinessUnitList[restoreItemIndex]);
        this.deleteBusinessUnitList.splice(restoreItemIndex, 1);
      } else {
        this.addBusinessUnitList.unshift(this.businessUnit);
        this.businessUnitTableData.results.unshift(this.businessUnit);
      }
      this.businessUnit = null;
      await this.getBusinessUnits();
      this.loadingAddBusinessUnit = false;
    },

    async addCell() {
      this.loadingAddCell = true;
      const restoreItemIndex = this.deleteCellList.findIndex(
        (obj) => obj?.id === this.cell?.id,
      );
      if (restoreItemIndex !== -1) {
        this.cellTableData.results.unshift(this.deleteCellList[restoreItemIndex]);
        this.addCellList.unshift(this.deleteCellList[restoreItemIndex]);
        this.deleteCellList.splice(restoreItemIndex, 1);
      } else {
        this.addCellList.unshift(this.cell);
        this.cellTableData.results.unshift(this.cell);
      }
      this.cell = null;
      await this.getCells();
      this.loadingAddCell = false;
    },

    async deleteBusinessUnit(index) {
      const itemDeleted = this.businessUnitTableData.results[index];

      if (itemDeleted?.id) {
        await this.$api().get(`/business-unit/${itemDeleted?.id}/check-association-with-costcenter`);
      }

      this.businessUnitTableData.results.splice(index, 1);

      const addedItemIndex = this.addBusinessUnitList.findIndex(
        (obj) => obj?.id === itemDeleted?.id,
      );

      if (addedItemIndex !== -1) {
        this.addBusinessUnitList.splice(addedItemIndex, 1);
      } else {
        this.deleteBusinessUnitList.push(itemDeleted);
      }

      this.businessUnit = null;
      await this.getBusinessUnits();
    },

    async deleteCell(index) {
      const itemDeleted = this.cellTableData.results[index];

      if (itemDeleted?.id) {
        await this.$api().get(`/cell/${itemDeleted?.id}/check-association-with-costcenter`);
      }

      this.cellTableData.results.splice(index, 1);

      const addedItemIndex = this.addCellList.findIndex(
        (obj) => obj?.id === itemDeleted?.id,
      );

      if (addedItemIndex !== -1) {
        this.addCellList.splice(addedItemIndex, 1);
      } else {
        this.deleteCellList.push(itemDeleted);
      }

      this.cell = null;
      await this.getCells();
    },

    async saveEditions() {
      this.loadingSaveEditions = true;

      this.lists.cells.insertedItemsIds = this.addCellList.map((item) => item.id);
      this.lists.cells.removedItemsIds = this.deleteCellList.map((item) => item.id);
      this.lists.businessUnits.insertedItemsIds = this.addBusinessUnitList.map((item) => item.id);
      this.lists.businessUnits.removedItemsIds = this.deleteBusinessUnitList.map((item) => item.id);

      await this.$api().patch(`/organization-unit/${this.organizationUnit?.id}`, this.lists)
        .then(() => {
          this.$notify({
            title: 'Salvar Alterações',
            message: 'Alterações realizadas com sucesso!',
            type: 'success',
          });
        })
        .finally(async () => {
          this.addBusinessUnitList = [];
          this.addCellList = [];
          this.deleteCellList = [];
          this.deleteBusinessUnitList = [];

          await this.getBusinessUnits();
          await this.getBusinessUnitTableData(1);

          await this.getCells();
          await this.getCellTableData(1);

          this.loadingSaveEditions = false;
        });
    },

    handleOpenTransferForBusinessUnit(row) {
      this.selectedBusinessUnitRow = row;
      this.showTransferModal = true;
    },

    async handleCloseTransferModalForBusinessUnit() {
      this.selectedBusinessUnitRow = null;
      this.showTransferModal = false;
    },

    handleOpenTransferForCell(row) {
      this.selectedCellRow = row;
      this.showTransferModal = true;
    },

    async handleCloseTransferModalForCell() {
      this.selectedCellRow = null;
      this.showTransferModal = false;
    },

    handleOpenRegister() {
      this.showRegisterModal = true;
    },

    async handleCloseRegisterModal() {
      this.showRegisterModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  h2 {
    padding: 7px 0px;
    color: #e87305;
    font-weight: 550;
    font-size: 20px;
    line-height: 18px;
  }
  .table {
    position: relative;
    top: 30px;
  }
  .container-pagination {
    position: relative;
    top: 60px;
  }

  .addButtons {
    margin-top: -2.5rem;
  }

  .container-btns {
    margin-top: 2rem;
  }
  .actions {
    img {
      cursor: pointer;
    }
  }
  .ml-2 {
    margin-left: 10px;
  }
</style>
