<template>
  <div>
    <bread-crumb :breadcrumbs="['Rateio', 'RH', 'Importação']" />
    <guard
      :needed-permissions="[PERMISSIONS_IMPORTATION.IMPORT]"
      show-tooltip
    >
      <Upload />
      <template v-slot:denied>
        <el-row
          type="flex"
          justify="end"
        >
          <el-col
            :span="2"
            style="margin-right: 150px;"
          >
            <el-button
              style="width: 85px; padding: 10px 5px;"
              type="primary"
              disabled
            >
              IMPORTAR
            </el-button>
          </el-col>
        </el-row>
      </template>
    </guard>
    <br>
    <el-table
      v-loading="loading"
      :data="tableData.results"
      style="width: 95%"
      stripe
    >
      <el-table-column
        fixed
        prop="period"
        label="Período"
      />
      <el-table-column
        prop="type"
        label="Tipo de RH"
      />
      <el-table-column
        prop="status"
        label="Status"
        :formatter="statusRenderer"
      />
      <el-table-column
        prop="actions"
        label="Ações"
        min-width="100px"
      >
        <template slot-scope="scope">
          <el-row>
            <guard
              :needed-permissions="[PERMISSIONS_IMPORTATION.DOWNLOAD]"
              show-tooltip
            >
              <a @click="downloadReport(scope.row)">Download Relatório</a>
              <template v-slot:denied>
                <a class="ml-2 disabled-link">Download Relatório</a>
              </template>
            </guard>
            <guard
              :needed-permissions="[PERMISSIONS_IMPORTATION.IMPORT]"
              show-tooltip
            >
              <a
                class="ml-2"
                @click="eraseApportionment(scope.row)"
              >Apagar Rateio</a>
              <template v-slot:denied>
                <a class="ml-2 disabled-link">Apagar Rateio</a>
              </template>
            </guard>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-row
      type="flex"
      justify="center"
      class="container-pagination"
    >
      <el-pagination
        small
        background
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="tableData.lastPage"
        :current-page="tableData.page"
        :page-count="tableData.lastPage"
        @current-change="getMovementData"
      />
    </el-row>
  </div>
</template>

<script>
import { TableColumn, Table, Row } from 'element-ui';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import Upload from '../components/Upload/Upload.vue';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

export default {
  name: 'RhImportacao',
  components: {
    BreadCrumb,
    Upload,
    'el-table-column': TableColumn,
    'el-table': Table,
    'el-row': Row,
    Guard,
  },
  data() {
    return {
      PERMISSIONS_IMPORTATION: FUNCTIONALITY_IDS.APPORTIONMENT.HR.IMPORTATION,
      loading: false,
      tableData: {
        results: [],
        total: 0,
        size: 10,
        page: 1,
        lastPage: 1,
      },
    };
  },
  async created() {
    this.loading = true;
    await this.getMovementData(1);
    this.loading = false;
  },
  methods: {
    async getMovementData(page) {
      const params = {
        page,
        size: this.tableData.size,
      };
      await this.$api()
        .get('/movement/', { params })
        .then(({ data }) => {
          this.tableData.results = data?.data.map((obj) => ({
            period: `${obj.month}/${obj.year}`,
            type: obj.isApportionmentDirect ? 'Direto' : 'Indireto',
            status: obj.apportionmentStatus,
          })) || [];
          this.tableData.total = data.total;
          this.tableData.page = data.currentPage;
          this.tableData.lastPage = data.lastPage;
        });
    },
    async eraseApportionment(row) {
      this.loading = true;
      const { period } = row;
      const month = period.split('/')[0];
      const year = period.split('/')[1];
      await this.$api()
        .put(`accounting-entries/erase-data/${year}-${month.toString().padStart(2, '0')}`)
        .then(() => {
          this.$notify.success({
            title: 'Sucesso',
            message: 'Rateio apagado com sucesso',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async downloadReport(row) {
      this.loading = true;
      const { period } = row;
      const month = period.split('/')[0];
      const year = period.split('/')[1];
      try {
        const response = await this.$api().get(
          `accounting-entries/year-month/${year}-${month
            .toString()
            .padStart(2, '0')}/apportionment-excel`,
          { responseType: 'blob' },
        );
        const blob = new Blob([response.data], { type: 'application/excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'report.xlsx';
        link.click();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    statusRenderer(cellValue) {
      const { status } = cellValue;
      if (status === 'pendente') {
        return (
          <div style="color: #F98F39;">
            <span> Pendente </span>
            <i class="el-icon-warning ml-2"></i>
          </div>
        );
      }
      if (status === 'finalizado') {
        return (
          <div style="color: #30A64A;">
            <span> Arquivo Importado </span>
            <i class="el-icon-success ml-2"></i>
          </div>
        );
      }
      return (
        <div style="color: #F56C6C;">
          <span>Erro de importação</span>
          <i class="el-icon-error ml-2"></i>
        </div>
      );
    },
  },
};
</script>

<style lang="scss" scoped>

a {
  cursor: pointer;
  text-decoration: underline;
  color: #0493c7;
}

.disabled-link {
  color: #c0c4cc;
}

.container-pagination {
  margin-top: 1rem;
}

.ml-2 {
  margin-left: 10px;
}
</style>
