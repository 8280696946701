import FUNCTIONALITY_IDS from '../../../constants/auth/permissions';

const APPORTIONMENTS_HR = FUNCTIONALITY_IDS.APPORTIONMENT.HR;
const APPORTIONMENTS_CORRELATES = FUNCTIONALITY_IDS.APPORTIONMENT.CORRELATES;
const { INCOME_REPORT } = FUNCTIONALITY_IDS;
const INCOME_PAYROLL = FUNCTIONALITY_IDS.PAYROLL;
const INCOME_ADJUSTS = FUNCTIONALITY_IDS.ADJUSTS;
const INCOME_PROVISION = FUNCTIONALITY_IDS.PROVISION;
const INCOME_CLOSING = FUNCTIONALITY_IDS.CLOSING;
const INCOME_CONFIG = FUNCTIONALITY_IDS.CONFIG;
const INCOME_PROFILE = FUNCTIONALITY_IDS.PROFILE;

const PERMISSIONS_IN_MENU = {
  INCOME_REPORT: [
    INCOME_REPORT.QUERY,
    INCOME_REPORT.DOWNLOAD,
  ],
  PAYROLL: [
    INCOME_PAYROLL.ACTIONS,
    INCOME_PAYROLL.IMPORT,
  ],
  APPORTIONMENT: [
    APPORTIONMENTS_HR.CONFERENCE.CHECK,
    APPORTIONMENTS_HR.CONFERENCE.DETAILS,
    APPORTIONMENTS_HR.CONFERENCE.DOWNLOAD,
    APPORTIONMENTS_HR.GRATIFICATION.CREATE,
    APPORTIONMENTS_HR.GRATIFICATION.DOWNLOAD,
    APPORTIONMENTS_HR.GRATIFICATION.QUERY,
    APPORTIONMENTS_HR.GRATIFICATION.REMOVE,
    APPORTIONMENTS_HR.GRATIFICATION.UPDATE,
    APPORTIONMENTS_HR.AWARDS.DOWNLOAD,
    APPORTIONMENTS_HR.AWARDS.QUERY,
    APPORTIONMENTS_HR.AWARDS.AWARD,
    APPORTIONMENTS_HR.IMPORTATION.DOWNLOAD,
    APPORTIONMENTS_HR.IMPORTATION.IMPORT,
    APPORTIONMENTS_CORRELATES.ENTRY.CREATE,
    APPORTIONMENTS_CORRELATES.ENTRY.DOWNLOAD,
    APPORTIONMENTS_CORRELATES.ENTRY.QUERY,
    APPORTIONMENTS_CORRELATES.ENTRY.UPDATE,
    APPORTIONMENTS_CORRELATES.HEADCOUNT.DOWNLOAD,
    APPORTIONMENTS_CORRELATES.HEADCOUNT.QUERY,
    APPORTIONMENTS_CORRELATES.HEADCOUNT.UPDATE,
    APPORTIONMENTS_CORRELATES.MOVEMENT.DOWNLOAD,
    APPORTIONMENTS_CORRELATES.MOVEMENT.EXECUTE_APPORTIONMENT,
    APPORTIONMENTS_CORRELATES.MOVEMENT.QUERY,
  ],
  ADJUSTS: [
    INCOME_ADJUSTS.DETAIL,
    INCOME_ADJUSTS.DOWNLOAD,
    INCOME_ADJUSTS.QUERY,
    INCOME_ADJUSTS.REQUEST,
    INCOME_ADJUSTS.UPDATE,
  ],
  PROVISION: [
    INCOME_PROVISION.CREATE,
    INCOME_PROVISION.QUERY,
    INCOME_PROVISION.REMOVE,
    INCOME_PROVISION.REVERT,
    INCOME_PROVISION.UPDATE,
  ],
  CLOSING: [
    INCOME_CLOSING.ACCOUNTING.DETAIL,
    INCOME_CLOSING.ACCOUNTING.PERIOD_ACTION,
    INCOME_CLOSING.ACCOUNTING.QUERY,
    INCOME_CLOSING.ACCOUNTING.SYNC_SYNTHETIC,
    INCOME_CLOSING.ENTRIES_AND_ADJUSTS.OPEN_CLOSE,
    INCOME_CLOSING.REPORTS.QUERY,
    INCOME_CLOSING.REPORTS.DOWNLOAD,
  ],
  CONFIG: [
    INCOME_CONFIG.ACCOUNTING_ACCOUNT.DOWNLOAD,
    INCOME_CONFIG.ACCOUNTING_ACCOUNT.QUERY,
    INCOME_CONFIG.ACCOUNTING_ACCOUNT.UPDATE,
    INCOME_CONFIG.ASSOCIATION.DOWNLOAD,
    INCOME_CONFIG.ASSOCIATION.QUERY,
    INCOME_CONFIG.ASSOCIATION.UPDATE,
    INCOME_CONFIG.BUILDING.CREATE,
    INCOME_CONFIG.BUILDING.DOWNLOAD,
    INCOME_CONFIG.BUILDING.UPDATE,
    INCOME_CONFIG.EVENT.DOWNLOAD,
    INCOME_CONFIG.EVENT.QUERY,
    INCOME_CONFIG.EVENT.UPDATE,
    INCOME_CONFIG.PROJECT.QUERY,
    INCOME_CONFIG.PROJECT.WRITE,
    INCOME_CONFIG.PROJECT_STRUCTURE.FULL,
  ],
  PROFILE: [
    INCOME_PROFILE.FULL,
  ],
};

export default PERMISSIONS_IN_MENU;
