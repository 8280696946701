<template>
  <el-dialog
    width="40%"
    title="Premiar"
    :close-on-click-modal="false"
    :visible.sync="showModal"
    :before-close="handleClose"
    :fullscreen="fullscreen"
  >
    <hr>
    <el-form
      ref="formData"
      :model="formData"
      :rules="formDataRules"
      label-position="top"
      hide-required-asterisk
    >
      <el-row :gutter="15">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Data de lançamento"
            prop="date"
          >
            <el-date-picker
              v-model="formData.date"
              type="date"
              placeholder="Data de lançamento"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Evento"
            prop="event"
          >
            <el-select
              v-model="formData.event"
              filterable
              placeholder="Evento"
              clearable
              value-key="id"
            >
              <el-option
                v-for="event in eventsList"
                :key="event.id"
                :label="`${event.code} - ${event.description}`"
                :value="event"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-form-item
            label="Colaborador"
            prop="employee"
          >
            <el-select
              v-model="formData.employee"
              filterable
              placeholder="Colaborador"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getEmployees"
              popper-append-to-body
              :loading="loadingEmployees"
            >
              <el-option
                v-for="item in employees"
                :key="item.id"
                :label="`${item.badge} - ${item.name}`"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-form-item
            label="Centro de custo"
            prop="costcenter"
          >
            <el-select
              v-model="formData.costcenter"
              filterable
              placeholder="Centros de Custo"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getCostcentersRegister"
              :loading="loadingCostsCenterRegister"
            >
              <el-option
                v-for="item in costsCenterListRegister"
                :key="item.id"
                :label="`${item.code} - ${item.name}`"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-form-item
            label="Valor"
            prop="value"
          >
            <el-currency-input v-model="formData.value" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        id="close-btn-premiacao-dialog"
        :disabled="loadingRegister"
        @click="handleClose"
      >
        CANCELAR
      </el-button>
      <el-button
        id="register-award"
        type="primary"
        :disabled="loadingRegister"
        :loading="loadingRegister"
        @click="registerAward"
      >
        SALVAR
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  Row,
  Col,
  Select,
  Form,
  FormItem,
  Button,
  Dialog,
  Option,
  DatePicker,
} from 'element-ui';
import ElCurrencyInput from '../ElCurrencyInput/ElCurrencyInput.vue';
import { AWARD_ENTRY_TYPES } from '../../constants/awards/award-entry-types';

export default {
  name: 'RhPremiacaoDialog',
  components: {
    'el-col': Col,
    'el-row': Row,
    'el-select': Select,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-button': Button,
    'el-dialog': Dialog,
    'el-option': Option,
    'el-date-picker': DatePicker,
    ElCurrencyInput,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    handleClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      windowWidth: 0,
      fullscreen: false,
      events: [],
      eventsList: [],
      employees: [],
      costsCenterListRegister: [],
      loadingCostsCenterRegister: false,
      loadingEmployees: false,
      loadingRegister: false,
      awardEvents: [
        AWARD_ENTRY_TYPES.MANAGER_AWARD_DIFFERENCE,
        AWARD_ENTRY_TYPES.MANAGER_AWARD,
      ],
      formData: {
        date: null,
        event: null,
        employee: null,
        costcenter: null,
        value: null,
      },
      formDataRules: {
        date: [{ required: true, message: 'Campo obrigatório', trigger: 'submit' }],
        event: [{ required: true, message: 'Campo obrigatório', trigger: 'submit' }],
        employee: [{ required: true, message: 'Campo obrigatório', trigger: 'submit' }],
        costcenter: [{ required: true, message: 'Campo obrigatório', trigger: 'submit' }],
        value: [{ required: true, message: 'Campo obrigatório', trigger: 'submit' }],
      },
    };
  },
  watch: {
    windowWidth(width) {
      this.fullscreen = Boolean(width < 768);
    },
    showModal() {
      this.formData = {
        date: null,
        event: null,
        employee: null,
        costcenter: null,
        value: null,
      };
      this.$refs.formData?.clearValidate();
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });

    this.getEmployees('');
    this.getCostcentersRegister('');
    this.getEvents(this.awardEvents);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    registerAward() {
      this.$refs.formData.validate((valid) => {
        if (!valid) {
          return;
        }

        const {
          date, event, employee, costcenter, value,
        } = this.formData;

        const params = {
          entryTypeId: event?.id,
          employeeId: employee?.id,
          costcenter: costcenter?.id,
          date,
          value,
        };

        this.loadingRegister = true;

        this.$api()
          .post('/awards', params)
          .then(() => {
            this.handleClose();
            this.$notify({
              title: 'Premiação',
              message: 'Premiação cadastrada com sucesso!',
              type: 'success',
            });
          })
          .finally(() => {
            this.loadingRegister = false;
          });
      });
    },
    getEvents(search = '') {
      const params = { search, page: 1, size: 20 };

      this.$api()
        .get('/entry-type/list', { params })
        .then(({ data }) => {
          this.eventsList = data;
        });
    },
    getEmployees(search = '') {
      this.loadingEmployees = true;
      const params = { search, page: 1, size: 15 };
      this.$api()
        .get('/employee/list', { params })
        .then((response) => {
          this.employees = response.data.data;
        })
        .finally(() => {
          this.loadingEmployees = false;
        });
    },
    getCostcentersRegister(search = '') {
      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.loadingCostsCenterRegister = true;

      this.$api()
        .get('/costcenter/only', { params })
        .then(({ data }) => {
          this.costsCenterListRegister = data.data;
        })
        .finally(() => {
          this.loadingCostsCenterRegister = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: -25px;
    margin-bottom: 25px;
    background: #e4e7ed;
  }

  .el-form {
    width: 100%;
  }

  .el-select, .el-input, .el-date-editor {
    width: 100% !important;
  }
</style>
