<template>
  <div>
    <bread-crumb :breadcrumbs="['Rateio', 'Correlatos', 'Headcount']" />
    <h1>Rateio - Correlatos</h1>
    <br><br>
    <el-form
      ref="form"
      label-position="top"
    >
      <el-row
        class="container"
        :gutter="30"
      >
        <el-col
          :xs="30"
          :sm="24"
          :md="10"
          :lg="5"
          :xl="3"
        >
          <el-form-item
            label="Mês e ano contábil"
            prop="building"
          >
            <el-date-picker
              v-model="filters.yearMonth"
              placeholder="Mês e ano contábil"
              value-format="yyyy-MM"
              format="MM/yyyy"
              type="month"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="5"
          :xl="3"
        >
          <el-form-item
            label="Centro de custo"
            prop="costcenter"
          >
            <el-select
              v-model="filters.costcenter"
              class="input"
              clearable
              filterable
              placeholder="Centro de custo"
              remote
              reserve-keyword
              autocomplete
              :remote-method="getCostcenters"
              :loading="loadingCostcenters"
            >
              <el-option
                v-for="item in costcentersList"
                :key="item.id"
                :label="item.code"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5"
        >
          <el-form-item label=".">
            <el-row type="flex">
              <el-col>
                <guard :needed-permissions="[PERMISSIONS_HEADCOUNT.QUERY]">
                  <el-button
                    :loading="loadingCorrelatedHeadcount"
                    type="primary"
                    size="small"
                    @click="handleClickGetCorrelatedHeadcount"
                  >
                    CONSULTAR
                  </el-button>
                </guard>
              </el-col>
              <el-col>
                <guard :needed-permissions="[PERMISSIONS_HEADCOUNT.DOWNLOAD]">
                  <a
                    :loading="loadingDownload"
                    class="download-button"
                    @click.prevent="getDownloadFile"
                  >
                    Download
                  </a>
                </guard>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <correlated-headcount-table
      :data="tableData"
      :loading="loadingCorrelatedHeadcount || loadingDownload"
      :edit-headcount="editHeadcount"
    />
    <el-row
      type="flex"
      justify="center"
      class="container-pagination"
    >
      <el-pagination
        small
        background
        hide-on-single-page
        layout="prev, pager, next"
        :total="paginationData.total"
        :disabled="loadingCorrelatedHeadcount"
        :page-size="tableDataParamsPagination.size"
        :current-page="paginationData.currentPage"
        @current-change="handleCurrentChange"
      />
    </el-row>
  </div>
</template>

<script>
import {
  Row, Col, Pagination, Form, FormItem, Button, Select, Option,
} from 'element-ui';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import CorrelatedHeadcountTable from '../components/CorrelatedHeadcount/CorrelatedHeadcountTable.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import { getLoggedUserStorage } from '../storage/modules/auth';
import Guard from '../components/Guard/Guard.vue';

export default {
  name: 'CorrelatosHeadcount',
  components: {
    'bread-crumb': BreadCrumb,
    'el-row': Row,
    'el-pagination': Pagination,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-col': Col,
    'el-button': Button,
    'el-select': Select,
    'el-option': Option,
    CorrelatedHeadcountTable,
    Guard,
  },
  data() {
    return {
      PERMISSIONS_HEADCOUNT: FUNCTIONALITY_IDS.APPORTIONMENT.CORRELATES.HEADCOUNT,
      filters: {
        yearMonth: '',
        costcenter: '',
      },
      costcentersList: [],
      loadingCostcenters: false,
      tableData: [],
      loadingCorrelatedHeadcount: false,
      tableDataParamsPagination: {
        page: 1,
        size: 10,
      },
      paginationData: {
        currentPage: 1,
        lastPage: 1,
        total: 0,
      },
      isEdit: false,
      loadingDownload: false,
    };
  },
  created() {
    const { userLogged } = getLoggedUserStorage();
    const permissions = userLogged?.functionalityIds || [];
    if (permissions.includes(this.PERMISSIONS_HEADCOUNT.QUERY)) {
      this.getCostcenters();
    }
  },
  methods: {
    handleClickGetCorrelatedHeadcount() {
      if (!this.filters.yearMonth) {
        this.$notify.error({
          title: 'Alerta',
          message: 'O campo "mês e ano contábil" deve ser preenchido',
          duration: 3000,
        });
        return;
      }
      this.tableDataParamsPagination = {
        ...this.tableDataParamsPagination,
        page: 1,
      };
      this.getCorrelatedHeadcount();
    },
    getCorrelatedHeadcount() {
      this.loadingCorrelatedHeadcount = true;
      let url = this.filters.costcenter
        ? `/headcount/${this.filters.yearMonth}?costCenterCode=${this.filters.costcenter}`
        : `/headcount/${this.filters.yearMonth}`;
      url += this.filters.costcenter
        ? `&page=${this.tableDataParamsPagination.page}&size=${this.tableDataParamsPagination.size}`
        : `?page=${this.tableDataParamsPagination.page}&size=${this.tableDataParamsPagination.size}`;
      this.$api()
        .get(url)
        .then(({ data }) => {
          this.tableData = data.data;
          this.paginationData = {
            currentPage: Number(data.currentPage),
            lastPage: data.lastPage,
            total: data.total,
          };
        })
        .finally(() => {
          this.loadingCorrelatedHeadcount = false;
        });
    },
    handleCurrentChange(newPage) {
      this.tableDataParamsPagination = {
        ...this.tableDataParamsPagination,
        page: newPage,
      };
      this.getCorrelatedHeadcount();
    },
    getCostcenters(query) {
      const params = {
        search: query,
        size: 15,
        page: 1,
      };
      this.loadingCostcenters = true;
      this.$api()
        .get('/costcenter', { params })
        .then(({ data }) => {
          this.costcentersList = data.data;
        })
        .finally(() => {
          this.loadingCostcenters = false;
        });
    },
    getDownloadFile() {
      this.loadingDownload = true;
      const url = this.filters.costcenter
        ? `/headcount/download/xlsx?yearMonth=${this.filters.yearMonth}?costCenterCode=${this.filters.costcenter}`
        : `/headcount/download/xlsx?yearMonth=${this.filters.yearMonth}`;
      this.$api()
        .get(url, { responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report.xlsx';
          link.click();
        })
        .finally(() => {
          this.loadingDownload = false;
        });
    },
    async editHeadcount(data, employeesAdditionalNumber) {
      const form = {
        id: data.id,
        observation: data.observation,
        employeesAdditionalNumber: employeesAdditionalNumber || data.employeesAdditionalNumber,
      };
      await this.$api()
        .patch('/headcount/update', form)
        .then(() => {
          this.$notify.success({
            title: 'Alerta',
            message: 'Alteração feita com sucesso',
            duration: 3000,
          });
          this.handleClickGetCorrelatedHeadcount();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 25px;
}

.input {
  width: 100%;
}

.el-row {
  margin-right: 0 !important;
}

.el-form-item {
  margin-bottom: 0 !important;
}

.container-pagination {
  margin-top: 1rem;
}

.download-button {
  text-decoration: underline;
  cursor: pointer;
}

.side-side {
  display: flex;
}

.include-btn {
  position: absolute;
  right: 20px;
  color: #e87305;
}
</style>
