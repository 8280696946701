<template>
  <div>
    <bread-crumb :breadcrumbs="['Rateio', 'Correlatos', 'Apropriação']" />
    <el-row>
      <el-col :span="18">
        <h1>Apropriação</h1>
        <br>
      </el-col>
    </el-row>
    <el-button-group style="margin-bottom: 30px">
      <el-button
        id="button-sintetic"
        class="button-group"
        :class="typeSelection === false ? 'active-button' : 'inactive-button'"
        @click="typeSelection = false"
      >
        Sintético
      </el-button>
      <el-button
        id="button-analitic"
        class="button-group"
        :class="typeSelection === true ? 'active-button' : 'inactive-button'"
        @click="typeSelection = true"
      >
        Analítico
      </el-button>
    </el-button-group>
    <el-form
      ref="filters"
      label-position="top"
      :model="filters"
    >
      <el-row :gutter="15">
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Data de lançamento"
            prop="dateRange"
          >
            <el-date-picker
              id="input-date-apropriacao"
              v-model="filters.dateRange"
              type="daterange"
              range-separator="-"
              start-placeholder="Início"
              end-placeholder="Fim"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Conta contábil"
            prop="accountingAccount"
          >
            <el-select
              id="input-conta-contabil"
              v-model="filters.accountingAccount"
              autocomplete
              filterable
              clearable
              remote
              multiple
              loading-text="Aguarde..."
              placeholder="Conta contábil"
              :remote-method="getaccountingAccount"
              :loading="loadingaccountingAccount"
              value-key="id"
              class="inputs-full-width"
            >
              <el-option
                v-for="accountingAccount in accountingAccounts"
                :key="accountingAccount.id"
                :label="`${accountingAccount.code} - ${accountingAccount.name}`"
                :value="accountingAccount"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Centro de custo"
            prop="costcenter"
          >
            <el-select
              id="input-centro-custo"
              v-model="filters.costcenter"
              autocomplete
              filterable
              clearable
              remote
              multiple
              loading-text="Aguarde..."
              placeholder="Centro de custo"
              :remote-method="getCostcenters"
              :loading="loadingCostcenterList"
              value-key="id"
              class="inputs-full-width"
            >
              <el-option
                v-for="costcenter in costcenters"
                :key="costcenter.code"
                :label="`${costcenter.code} - ${costcenter.name}`"
                :value="costcenter"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Documento"
            prop="document"
          >
            <el-input
              v-model="filters.document"
              class="inputs-full-width"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Tipo"
            prop="type"
          >
            <el-select
              v-model="filters.type"
              clearable
              filterable
              placeholder="Tipo"
            >
              <el-option
                value="LANCAMENTO"
                label="Lançamento"
              />
              <el-option
                value="APROPRIACAO"
                label="Apropriação"
              />
              <el-option
                value="RATEIO"
                label="Rateio"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="3"
        >
          <el-row class="container-button-submity">
            <guard :needed-permissions="[PERMISSIONS_APPROPRIATION.DOWNLOAD]">
              <el-button
                id="download-button"
                class="download-button"
                :disabled="loadingDownload"
                :loading="loadingDownload"
                @click="downloadReport"
              >
                DOWNLOAD
              </el-button>
            </guard>
            <guard :needed-permissions="[PERMISSIONS_APPROPRIATION.QUERY]">
              <el-button
                id="query-button"
                type="primary"
                style="margin-left: 15px;"
                :disabled="loadingList"
                :loading="loadingList"
                @click="getAppropriate(1)"
              >
                CONSULTAR
              </el-button>
            </guard>
            <guard :needed-permissions="[PERMISSIONS_APPROPRIATION.APPROPRIATE]">
              <el-button
                id="appropriation-button"
                type="secundary"
                style="background-color: #f5f5f5; margin-left: 15px; border: 0px;"
                @click="handleOpenAppropriate"
              >
                APROPRIAR
              </el-button>
            </guard>
          </el-row>
        </el-col>
      </el-row>
    </el-form>

    <div v-if="typeSelection">
      <div class="container-table">
        <el-table
          v-loading="loadingList"
          :data="tableData.results"
          style="width: 100%"
          stripe
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          />
          <el-table-column
            label="Data de lançamento"
            prop="date"
            :formatter="formatterDate"
          />
          <el-table-column
            label="Documento"
            property="document"
          />
          <el-table-column
            label="Conta contábil"
            prop="accountingAccount"
            :formatter="formatterAccountingAccount"
          />
          <el-table-column
            label="Centro de Custo"
            prop="costcenter"
            :formatter="formatterCostcenter"
          />
          <el-table-column
            label="Parcela"
            prop="portion"
          />
          <el-table-column
            label="Tipo"
            property="type"
            :formatter="formatterType"
          />
          <el-table-column
            label="Valor"
            property="value"
            :formatter="valorRenderer"
          />
          <el-table-column
            label="Ação"
            width="100"
          >
            <template slot-scope="scope">
              <el-row
                type="flex"
                align="center"
                class=".gap-1"
              >
                <guard
                  :needed-permissions="[PERMISSIONS_APPROPRIATION.UPDATE]"
                  show-tooltip
                >
                  <div class="container-icon">
                    <el-button
                      id="edit-button-gratificacao-rh"
                      circle
                      size="mini"
                      type="text"
                      :disabled="Boolean(scope?.row?.piramideEntryIntegration)"
                      @click="handleClickDetail(scope.row)"
                    >
                      <el-tooltip
                        v-if="scope?.row?.piramideEntryIntegration"
                        content="Item não pode ser alterado"
                        placement="top"
                        effect="light"
                      >
                        <img
                          :src="searchIcon"
                          alt="search"
                          height="20"
                          width="20"
                        >
                      </el-tooltip>
                      <img
                        v-else
                        :src="searchIcon"
                        alt="search"
                        height="20"
                        width="20"
                      >
                    </el-button>
                  </div>
                  <template v-slot:denied>
                    <div class="container-icon">
                      <el-button
                        circle
                        size="mini"
                        type="text"
                        disabled
                      >
                        <img
                          :src="searchIcon"
                          alt="search"
                          height="20"
                          width="20"
                        >
                      </el-button>
                    </div>
                  </template>
                </guard>
                <guard
                  :needed-permissions="[PERMISSIONS_APPROPRIATION.REMOVE]"
                  show-tooltip
                >
                  <div class="container-icon">
                    <el-button
                      circle
                      size="mini"
                      type="text"
                      :disabled="Boolean(scope?.row?.piramideEntryIntegration)"
                      @click="handleClickDelete(scope.row)"
                    >
                      <el-tooltip
                        v-if="scope?.row?.piramideEntryIntegration"
                        content="Item não pode ser excluído"
                        placement="top"
                        effect="light"
                      >
                        <img
                          :src="deleteIcon"
                          alt="search"
                          height="20"
                          width="20"
                        >
                      </el-tooltip>
                      <img
                        v-else
                        :src="deleteIcon"
                        alt="search"
                        height="20"
                        width="20"
                      >
                    </el-button>
                  </div>
                  <template v-slot:denied>
                    <div class="container-icon">
                      <el-button
                        circle
                        size="mini"
                        type="text"
                        disabled
                      >
                        <img
                          :src="deleteIcon"
                          alt="search"
                          height="20"
                          width="20"
                        >
                      </el-button>
                    </div>
                  </template>
                </guard>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else>
      <div class="container-table">
        <el-table
          v-loading="loadingList"
          :data="tableData.results"
          style="width: 100%"
          stripe
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          />
          <el-table-column
            label="Conta contábil"
            prop="name"
          />
          <el-table-column
            label="Documento"
            prop="document"
            :formatter="formatterDocumentInSintetic"
          />
          <el-table-column
            label="Valor"
            property="value"
            :formatter="valorRenderer"
          />
        </el-table>
      </div>
    </div>
    <el-row
      type="flex"
      justify="center"
      class="container-pagination"
    >
      <el-pagination
        small
        background
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="tableData.lastPage"
        :current-page="tableData.page"
        :page-count="tableData.lastPage"
        @current-change="getAppropriate"
      />
    </el-row>

    <apropriacao-dialog
      v-if="showModal"
      :selected-appropriation="selectedAppropriation"
      :show-modal="showModal"
      :handle-close="handleClose"
    />

    <el-dialog
      v-if="selectedAppropriation"
      title="Exclusão"
      :visible.sync="showDialogDelete"
      width="30%"
    >
      <span>
        Deseja excluir esta Apropriação?
      </span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="info"
          @click="handleCloseDialogDelete"
        >
          CANCELAR
        </el-button>
        <el-button
          type="primary"
          :disabled="loadingDelete"
          :loading="loadingDelete"
          @click="deleteAppropriation"
        >
          CONFIRMAR
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import Guard from '../components/Guard/Guard.vue';
import numberFormat from '../utils/IntlNumberFormat';
import ApropriacaoDialog from '../components/ApropriacaoDialog/ApropriacaoDialog.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

const searchIcon = require('../../public/media/icons/edit.svg');
const deleteIcon = require('../../public/media/icons/delete.svg');

export default {
  name: 'CorrelatosApropriacao',
  components: {
    BreadCrumb,
    ApropriacaoDialog,
    Guard,
  },
  data() {
    return {
      PERMISSIONS_APPROPRIATION: FUNCTIONALITY_IDS.APPORTIONMENT.CORRELATES.APPROPRIATION,
      deleteIcon,
      searchIcon,
      typeSelection: false,
      filters: {
        dateRange: null,
        type: null,
        accountingAccount: [],
        costcenter: [],
        document: null,
      },
      costcenters: [],
      accountingAccounts: [],
      loadingaccountingAccount: false,
      loadingCostcenterList: false,
      loadingDownload: false,
      loadingList: false,
      tableData: {
        results: [],
        total: 0,
        size: 50,
        page: 1,
        lastPage: 1,
      },
      multipleSelection: [],
      showModal: false,
      showDialogDelete: false,
      selectedAppropriation: null,
      loadingDelete: false,
    };
  },
  watch: {
    typeSelection: {
      handler() {
        this.tableData = {
          results: [],
          total: 0,
          size: 50,
          page: 1,
          lastPage: 1,
        };
        this.multipleSelection = [];
        this.lastQueryParameters = null;
      },
    },
  },
  mounted() {
    this.getaccountingAccount('');
    this.getCostcenters('');
  },
  methods: {
    handleOpenAppropriate() {
      this.showModal = true;
    },
    handleClose() {
      this.showModal = false;
      this.selectedAppropriation = null;
    },
    getaccountingAccount(search = '') {
      this.loadingaccountingAccount = true;

      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.$api()
        .get('/accounting-account/list', { params })
        .then((response) => {
          this.accountingAccounts = response.data.data;
        })
        .finally(() => {
          this.loadingaccountingAccount = false;
        });
    },
    getCostcenters(search = '') {
      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.loadingCostcenterList = true;

      this.$api()
        .get('/costcenter/only', { params })
        .then(({ data }) => {
          this.costcenters = data.data;
        })
        .finally(() => {
          this.loadingCostcenterList = false;
        });
    },
    downloadReport() {
      if (!this.lastQueryParameters) {
        this.$notify({
          title: 'Download do Relatório de Apropriação',
          message: 'Não foi possível realizar o download, por favor realize antes uma consulta',
          type: 'warning',
        });
        return;
      }
      const params = JSON.parse(JSON.stringify(this.lastQueryParameters));
      params.dataList = this.typeSelection
        ? this.multipleSelection.filter((item) => item?.costcenter)
        : this.multipleSelection.filter((item) => !item?.costcenter);
      this.loadingDownload = true;
      this.$api()
        .get('/appropriation/download', { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.id = 'download-file-id';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Apropriação - SG Pitang.xlsx';
          link.click();
        })
        .finally(() => {
          this.loadingDownload = false;
        });
    },
    getAppropriate(page) {
      const {
        dateRange, costcenter, document, accountingAccount, type,
      } = this.filters;

      if (!dateRange || dateRange?.length < 2) {
        this.$notify({
          title: 'Mês / Ano Contábil',
          message: 'Selecione o período de datas',
          type: 'warning',
        });
        return;
      }

      const params = {
        isAnalytic: this.typeSelection,
        startDate: dateRange[0],
        endDate: dateRange[1],
        costcenters: costcenter?.map(((c) => c?.id)),
        accountingAccounts: accountingAccount?.map(((a) => a?.id)),
        document,
        type,
      };

      this.lastQueryParameters = JSON.parse(JSON.stringify(params));

      params.page = page;
      params.size = this.tableData.size;
      this.loadingList = true;

      this.$api()
        .get('/appropriation/list', { params })
        .then(({ data }) => {
          this.tableData.results = data.data;
          this.tableData.total = Number(data.total);
          this.tableData.page = Number(data.currentPage);
          this.tableData.lastPage = Number(data.lastPage);
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
    getRowKey(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    valorRenderer(row) {
      return numberFormat(row.value);
    },
    formatterCostcenter(_row, _column, cellValue) {
      if (cellValue) {
        return `${cellValue.code} - ${cellValue.name}`;
      }
      return '';
    },
    formatterType(_row, _column, cellValue) {
      if (cellValue) {
        if (cellValue === 'LANCAMENTO') {
          return 'Lançamento';
        }
        if (cellValue === 'APROPRIACAO') {
          return 'Apropriação';
        }
        return 'Rateio';
      }
      return '';
    },
    formatterAccountingAccount(row) {
      if (row) {
        return `${row?.accountingAccount?.code} - ${row?.accountingAccount?.name}`;
      }
      return '';
    },
    formatterDate(_row, _column, cellValue) {
      if (cellValue) {
        return DateTime.fromISO(cellValue).toFormat('dd/LL/yyyy');
      }
      return null;
    },
    formatterDocumentInSintetic(row) {
      return row?.document ?? '';
    },
    handleClickDelete(row) {
      this.selectedAppropriation = row;
      this.handleOpenDialogDelete();
    },
    deleteAppropriation() {
      if (!this.selectedAppropriation?.id) {
        this.$notify({
          title: 'Apropriação',
          message: 'Selecione uma apropriação para excluí-la',
          type: 'warning',
        });
        return;
      }

      this.loadingDelete = true;

      this.$api()
        .delete(`/appropriation/${this.selectedAppropriation.id}?date=${this.selectedAppropriation.date}`)
        .then(() => {
          this.handleCloseDialogDelete();
          this.$notify({
            title: 'Apropriação',
            message: 'Apropriação excluída com sucesso!',
            type: 'success',
          });
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    handleClickDetail(row) {
      this.selectedAppropriation = row;
      this.handleOpenAppropriate();
    },
    handleOpenDialogDelete() {
      this.showDialogDelete = true;
    },
    handleCloseDialogDelete() {
      this.selectedAppropriation = null;
      this.showDialogDelete = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .el-form {
    width: 100%;
  }

  .el-select, .el-input, .el-date-editor {
    width: 100% !important;
  }

  h4 {
    margin-bottom: 15px;
  }

  h1 {
    padding: 7px 0px;
    color: #e87305;
    font-weight: 550;
    font-size: 20px;
    line-height: 10px;
    margin-bottom: 30px !important;
  }

  .download-button {
    color: #e87305;
    outline-color: #e87305;
  }

  .button-group {
    height: 32px;
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: $text-color;
  }

  .inactive-button {
    background: #F5F5F5;

    &:hover {
      background: #F5F5F5;
      color: $text-color
    }
  }

  .active-button {
    background: #D9D9D9;

    &:hover {
      background: #D9D9D9;
      color: $text-color
    }
  }

  .inputs-full-width {
    width: 100% !important;
  }

  .container-pagination {
    margin-top: 1rem;
  }
  .container-table {
    margin-top: 50px;
  }

  .container-button-submity {
    height: 100px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 1441px) {
    .container-button-submity {
      height: auto;
    }
  }

</style>
