<template>
  <div class="container">
    <bread-crumb :breadcrumbs="['Provisões']" />
    <el-row>
      <el-col :span="18">
        <h1>Provisões</h1>
        <br><br>
        <h2>Filtros</h2>
      </el-col>
    </el-row>
    <el-form
      :model="formValue"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :sm="12"
          :md="4"
          :lg="3"
          :xl="3"
        >
          <el-form-item label="Mês / Ano Contábil">
            <el-date-picker
              v-model="formValue.monthYear"
              type="month"
              placeholder="Mês/ano"
              format="MMM/yyyy"
              value-format="yyyy-MM"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="5"
          :lg="3"
          :xl="3"
        >
          <el-form-item label="Prédio">
            <el-select
              v-model="formValue.building"
              class="input"
              value-key="id"
              clearable
              filterable
              placeholder="Prédio"
              :loading="loadingBuildingsList"
            >
              <el-option
                v-for="item in buildingsList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="5"
          :lg="3"
          :xl="3"
        >
          <el-form-item label="Centro de custo">
            <el-select
              v-model="formValue.costCenter"
              multiple
              filterable
              placeholder="Centros de Custo"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getCostsCenter"
              :loading="loadingCostsCenter"
            >
              <el-option
                v-for="item in costsCenterList"
                :key="item.id"
                :label="`${item.code} - ${item.name}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="5"
          :lg="3"
          :xl="3"
        >
          <el-form-item label="Conta Contábil">
            <el-select
              v-model="formValue.accountingAccount"
              multiple
              filterable
              placeholder="Conta Contábil"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getAccountingAccounts"
              :loading="loadingAccountingAccount"
            >
              <el-option
                v-for="item in accountingAccountList"
                :key="item.id"
                :label="`${item.code} - ${item.name}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="5"
          :lg="3"
          :xl="3"
        >
          <el-form-item label="Tipo Provisão">
            <el-select
              v-model="formValue.provisionType"
              filterable
              placeholder="Provisão"
              clearable
              value-key="id"
            >
              <el-option
                label="Receita"
                value="Receita"
              />
              <el-option
                label="Despesa"
                value="Despesa"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-row
          type="flex"
          justify="end"
          class="container-btns gap-1"
        >
          <guard :needed-permissions="[PERMISSIONS_PROVISION.CREATE]">
            <el-button
              type="text"
              style="color: #0493c7"
              icon="el-icon-plus"
              @click="handleOpenProvisionar"
            >
              Provisionar
            </el-button>
          </guard>
          <guard :needed-permissions="[PERMISSIONS_PROVISION.DOWNLOAD]">
            <el-button
              style="background-color: #f5f5f5; border: 0px"
              size="small"
              :disabled="loadingDownloadReport"
              :loading="loadingDownloadReport"
              @click="downloadReport"
            >
              Download
            </el-button>
          </guard>
          <guard :needed-permissions="[PERMISSIONS_PROVISION.QUERY]">
            <el-button
              type="primary"
              size="small"
              :disabled="loadingGetProvisionsEntry"
              :loading="loadingGetProvisionsEntry"
              @click="getProvisionsEntry(1)"
            >
              CONSULTAR
            </el-button>
          </guard>
        </el-row>
      </el-row>
    </el-form>
    <div class="container-table">
      <el-table
        v-loading="loadingGetProvisionsEntry || loadingDownloadReport"
        :data="tableData.results"
        style="width: 100%"
        stripe
      >
        <el-table-column
          property="building.name"
          label="Prédio"
          width="140"
        />
        <el-table-column
          property="costcenter"
          label="Centro de Custo"
          width="170"
        />
        <el-table-column
          property="accountingAccount"
          label="Conta Contábil"
          width="200"
        />
        <el-table-column
          property="provisionType"
          label="Tipo Provisão"
          width="170"
        />
        <el-table-column
          property="provision.name"
          label="Provisão"
          width="170"
        />
        <el-table-column
          property="provisionOrigin"
          label="Origem Lançamento"
          width="170"
        />
        <el-table-column
          property="specialCode"
          label="ID Provisão"
          width="170"
        />
        <el-table-column
          label="Ações"
          width="150"
        >
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="center"
            >
              <guard
                :needed-permissions="[PERMISSIONS_PROVISION.UPDATE]"
                show-tooltip
              >
                <div
                  class="container-icon"
                  @click="handleClickDetail(scope.row)"
                >
                  <img
                    :src="searchIcon"
                    alt="search"
                    height="20"
                    width="20"
                  >
                </div>
                <template v-slot:denied>
                  <div class="container-icon">
                    <img
                      :src="searchIcon"
                      alt="search"
                      height="20"
                      width="20"
                    >
                  </div>
                </template>
              </guard>
              <guard
                :needed-permissions="[PERMISSIONS_PROVISION.REVERT]"
                show-tooltip
              >
                <div
                  class="container-icon"
                  @click="handleOpenToReverse(scope.row)"
                >
                  <img
                    :src="rollbackIcon"
                    alt="search"
                    height="17"
                    width="17"
                  >
                </div>
                <template v-slot:denied>
                  <div class="container-icon">
                    <img
                      :src="rollbackIcon"
                      alt="search"
                      height="17"
                      width="17"
                    >
                  </div>
                </template>
              </guard>
              <guard
                :needed-permissions="[PERMISSIONS_PROVISION.REMOVE]"
                show-tooltip
              >
                <div
                  class="container-icon"
                  @click="handleClickDelete(scope.row)"
                >
                  <img
                    :src="deleteIcon"
                    alt="search"
                    height="17"
                    width="17"
                  >
                </div>
                <template v-slot:denied>
                  <div class="container-icon">
                    <img
                      :src="deleteIcon"
                      alt="search"
                      height="17"
                      width="17"
                    >
                  </div>
                </template>
              </guard>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-form>
      <el-dialog
        v-if="selectedProvision"
        title="Exclusão"
        :visible.sync="dialogDeleteProvision"
        width="30%"
      >
        <el-form-item>
          <span>
            Deseja excluir o provisionamento
            <strong>{{ selectedProvision.specialCode }} ?</strong>
          </span>
        </el-form-item>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            type="info"
            @click="handleCloseDialogDelete"
          >
            CANCELAR
          </el-button>
          <el-button
            type="primary"
            :disabled="loadingDelete"
            :loading="loadingDelete"
            @click="deleteProvision"
          >
            CONFIRMAR
          </el-button>
        </span>
      </el-dialog>
    </el-form>

    <el-form>
      <el-dialog
        v-if="selectedProvision"
        title="Detalhe e Edição"
        :visible.sync="dialogDetail"
        width="55%"
      >
        <hr>
        <el-row :gutter="20">
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>Mês / Ano Contábil</span>
              <strong>
                {{ selectedProvision.date }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>ID Provisão</span>
              <strong>
                {{ selectedProvision.specialCode }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>Origem da Provisão</span>
              <strong>
                {{ selectedProvision.provisionOrigin }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="28"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
          >
            <el-form-item label="Prédio">
              <el-select
                v-model="formEdit.building"
                class="input"
                value-key="id"
                clearable
                filterable
                placeholder="Prédio"
                :loading="loadingBuildingsList"
              >
                <el-option
                  v-for="item in buildingsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>Centro de Custo</span>
              <strong>
                {{ selectedProvision.costcenter.code }} -
                {{ selectedProvision.costcenter.name }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>Conta Contábil</span>
              <strong>
                {{ selectedProvision.accountingAccount }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <el-form-item label="Tipo Provisão">
              <el-select
                v-model="formEdit.provisionType"
                filterable
                placeholder="Tipo Provisão"
                clearable
                @change="changedProvisionType"
              >
                <el-option
                  label="Receita"
                  value="Receita"
                />
                <el-option
                  label="Despesa"
                  value="Despesa"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <el-form-item label="Provisão">
              <el-select
                v-model="formEdit.provision"
                :disabled="!formEdit.provisionType"
                filterable
                placeholder="Provisão"
                autocomplete
                clearable
                remote
                loading-text="Aguarde..."
                value-key="id"
                :remote-method="getProvisions"
                :loading="loadingProvisions"
                @change="changedProvision"
              >
                <el-option
                  v-for="item in provisionsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <div v-if="formEdit.provision === 24">
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <el-form-item label="Conta Contábil de Débito">
                <el-select
                  v-model="formEdit.debit"
                  filterable
                  placeholder="Débito"
                  autocomplete
                  clearable
                  remote
                  loading-text="Aguarde..."
                  value-key="id"
                  :remote-method="getAccountingAccountsForDebit"
                  :loading="loadingAccountingAccountDebit"
                >
                  <el-option
                    v-for="item in accountingAccountDebitList"
                    :key="item.id"
                    :label="`${item.code} - ${item.name}`"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <el-form-item label="Conta Contábil de Crédito">
                <el-select
                  v-model="formEdit.credit"
                  filterable
                  placeholder="Crédito"
                  autocomplete
                  clearable
                  remote
                  loading-text="Aguarde..."
                  value-key="id"
                  :remote-method="getAccountingAccountsForCredit"
                  :loading="loadingAccountingAccountCredit"
                >
                  <el-option
                    v-for="item in accountingAccountCreditList"
                    :key="item.id"
                    :label="`${item.code} - ${item.name}`"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>Data da Provisão</span>
              <strong>
                {{ selectedProvision.provisionDate }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>Saldo da Provisão</span>
              <strong>
                {{ selectedProvision.provisionBalance }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>Data da Última Reversão</span>
              <strong>
                {{ selectedProvision.reversalDate }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>Valor Total das Reversões </span>
              <strong>
                {{ selectedProvision.reversalValue }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="24"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
          >
            <el-form-item label="Valor da Provisão">
              <el-currency-input v-model="formEdit.value" />
            </el-form-item>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <div class="col-detail">
              <span>Observações</span>
              <strong>
                {{ selectedProvision.observation || '' }}
              </strong>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="24"
          >
            <el-form-item>
              <el-button
                type="text"
                @click="addObservation = true"
              >
                + Mostrar campo para a adição de uma nova observação
              </el-button>
            </el-form-item>
          </el-col>
          <div v-if="addObservation">
            <el-col
              :xl="24"
              :lg="24"
              :md="24"
              :sm="24"
              :xs="24"
            >
              <el-form-item label="Observação">
                <el-input
                  v-model="formEdit.observation"
                  type="textarea"
                  maxlength="200"
                  show-word-limit
                  rows="4"
                />
              </el-form-item>
            </el-col>
          </div>
        </el-row>
        <span slot="footer">
          <el-button @click="handleCloseDialogDetail"> Cancelar </el-button>
          <el-button
            type="primary"
            :loading="loadingEdit"
            :disabled="loadingEdit"
            @click="editProvision"
          >
            Salvar
          </el-button>
        </span>
      </el-dialog>
    </el-form>
    <el-row
      type="flex"
      justify="center"
      class="container-pagination"
    >
      <el-pagination
        small
        background
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="tableData.lastPage"
        :current-page="tableData.page"
        :page-count="tableData.lastPage"
        @current-change="getProvisionsEntry"
      />
    </el-row>

    <provisionar-dialog
      v-if="openProvision"
      :open="openProvision"
      :on-close="handleCloseProvision"
    />
    <to-reverse-dialog
      v-if="openToReverse"
      :open="openToReverse"
      :on-close="handleCloseToReverse"
      :selected-provision="selectedProvision"
    />
  </div>
</template>

<script>
import {
  Row, Col, Form, FormItem, Select, Button, Option,
} from 'element-ui';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb.vue';
import ProvisionarDialog from './components/ProvisionarDialog/ProvisionarDialog.vue';
import ToReverseDialog from './components/ToReverseDialog/ToReverseDialog.vue';
import ElCurrencyInput from '../../components/ElCurrencyInput/ElCurrencyInput.vue';
import FUNCTIONALITY_IDS from '../../constants/auth/permissions';
import Guard from '../../components/Guard/Guard.vue';

const searchIcon = require('../../../public/media/icons/search.svg');
const deleteIcon = require('../../../public/media/icons/delete.svg');
const rollbackIcon = require('../../../public/media/icons/rollback.svg');

export default {
  name: 'Provisoes',
  components: {
    BreadCrumb,
    ProvisionarDialog,
    ToReverseDialog,
    'el-row': Row,
    'el-col': Col,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-select': Select,
    'el-button': Button,
    'el-option': Option,
    ElCurrencyInput,
    Guard,
  },
  data() {
    return {
      PERMISSIONS_PROVISION: FUNCTIONALITY_IDS.PROVISION,
      formValue: {
        monthYear: null,
        building: null,
        formValue: null,
        accountingAccount: null,
        provisionType: null,
      },
      formEdit: {},
      buildingsList: [],
      loadingBuildingsList: false,
      costsCenterList: [],
      loadingCostsCenter: false,
      accountingAccountList: [],
      loadingAccountingAccount: false,
      loadingGetProvisionsEntry: false,
      loadingDownloadReport: false,
      loadingDelete: false,
      dialogDeleteProvision: false,
      loadingEdit: false,
      dialogDetail: false,
      provisionsList: [],
      loadingProvisions: false,
      accountingAccountDebitList: [],
      loadingAccountingAccountDebit: false,
      accountingAccountCreditList: [],
      loadDebitAndCredit: false,
      loadDebit: false,
      loadCredit: false,
      loadingAccountingAccountCredit: false,
      addObservation: false,
      tableData: {
        results: [],
        total: 0,
        size: 10,
        page: 1,
        lastPage: 1,
      },
      searchIcon,
      deleteIcon,
      rollbackIcon,
      openProvision: false,
      selectedProvision: null,
      openToReverse: false,
    };
  },
  mounted() {
    this.getBuildings();
    this.getCostsCenter();
    this.getAccountingAccounts();
  },
  methods: {
    getBuildings() {
      this.loadingBuildingsList = true;
      this.$api()
        .get('/buildings')
        .then(({ data }) => {
          this.buildingsList = data.data;
        })
        .finally(() => {
          this.loadingBuildingsList = false;
        });
    },
    getCostsCenter(search = '') {
      this.loadingCostsCenter = true;
      const params = {
        search,
        page: 1,
        size: 20,
      };
      this.$api()
        .get('/costcenter', { params })
        .then(({ data }) => {
          this.costsCenterList = data.data;
        })
        .finally(() => {
          this.loadingCostsCenter = false;
        });
    },
    getAccountingAccounts(search = '') {
      this.loadingAccountingAccount = true;
      const params = {
        search,
        page: 1,
        size: 20,
        isProvision: true,
      };
      this.$api()
        .get('/accounting-account/list', { params })
        .then(({ data }) => {
          this.accountingAccountList = data.data;
        })
        .finally(() => {
          this.loadingAccountingAccount = false;
        });
    },
    getProvisionsEntry(page) {
      if (!this.formValue.monthYear) {
        this.$notify({
          title: 'Consultar Provisões',
          message: 'Selecione o mês/ano',
          type: 'warning',
        });
        return;
      }
      const params = {
        page,
        size: this.tableData.size,
        yearMonth: this.formValue.monthYear || null,
        building: this.formValue.building || null,
        costcenter: this.formValue.costCenter.length ? this.formValue.costCenter : null,
        accountingAccount: this.formValue.accountingAccount.length
          ? this.formValue.accountingAccount
          : null,
        onlyRevenueType: this.formValue?.provisionType === 'Receita',
        onlyExpenseType: this.formValue?.provisionType === 'Despesa',
      };
      this.loadingGetProvisionsEntry = true;
      this.$api()
        .get('/provisions/entries', { params })
        .then(({ data }) => {
          this.tableData.results = data?.data || [];
          this.tableData.total = data.total;
          this.tableData.page = data.currentPage;
          this.tableData.lastPage = data.lastPage;
        })
        .finally(() => {
          this.loadingGetProvisionsEntry = false;
        });
    },
    downloadReport() {
      if (!this.formValue.monthYear) {
        this.$notify({
          title: 'Download do Relatório de Provisões',
          message: 'Informe pelo menos o mês/ano',
          type: 'warning',
        });
        return;
      }
      this.loadingDownloadReport = true;
      const params = {
        yearMonth: this.formValue.monthYear || null,
        building: this.formValue.building || null,
        costcenter: this.formValue.costCenter.length ? this.formValue.costCenter : null,
        accountingAccount: this.formValue.accountingAccount.length
          ? this.formValue.accountingAccount
          : null,
        onlyRevenueType: this.formValue?.provisionType === 'Receita',
        onlyExpenseType: this.formValue?.provisionType === 'Despesa',
      };
      this.$api()
        .get('/provisions/entries/download', { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Provisões - SG Pitang.xlsx';
          link.click();
        })
        .finally(() => {
          this.loadingDownloadReport = false;
        });
    },
    async editProvision() {
      const OTHER_PROVISIONS = 24;
      if (
        !this.formEdit.building
        || !this.formEdit.provisionType
        || !this.formEdit.provision
        || !this.formEdit.value
        || (this.formEdit.provision === OTHER_PROVISIONS
          && (!this.formEdit.debit || !this.formEdit.credit))
      ) {
        this.$notify.error({
          title: 'Alerta',
          message:
            'Existem campos vazios. Somente o campo para a adição de uma nova observação pode ser vazio.',
          duration: 4000,
        });
        return;
      }
      this.loadingEdit = true;
      const yearMonth = this.selectedProvision.date.split('/');
      const params = {
        building:
          typeof this.formEdit.building === 'string'
            ? this.selectedProvision.building.id
            : this.formEdit.building,
        provision:
          typeof this.formEdit.provision === 'string'
            ? this.selectedProvision.provision.id
            : this.formEdit.provision,
        value: this.formEdit.value,
        observation: this.formEdit.observation,
        yearMonth: `${yearMonth[2]}-${yearMonth[1]}`,
      };
      this.$api()
        .patch(`/provisions/${this.selectedProvision.id}`, params)
        .then(() => {
          this.handleCloseDialogDetail();
          this.$notify.success({
            title: 'Alerta',
            message: 'Provisão alterada com sucesso',
            duration: 3000,
          });
        })
        .finally(() => {
          this.loadingEdit = false;
          this.getProvisionsEntry(1);
        });
    },
    async deleteProvision() {
      this.loadingDelete = true;
      const yearMonth = this.selectedProvision.date.split('/');
      await this.$api()
        .delete(
          `/provisions/${this.selectedProvision.id}?yearMonth=${yearMonth[2]}-${yearMonth[1]}`,
        )
        .then(() => {
          this.handleCloseDialogDelete();
          this.$notify.success({
            title: 'Alerta',
            message: 'Provisão excluída com sucesso',
            duration: 3000,
          });
        })
        .finally(() => {
          this.loadingDelete = false;
          this.getProvisionsEntry(1);
        });
    },
    getProvisions(search = '') {
      this.loadingProvisions = true;
      const params = {
        search,
        page: 1,
        size: 20,
        onlyRevenueType: this.selectedProvision?.provisionType === 'Receita',
        onlyExpenseType: this.selectedProvision?.provisionType === 'Despesa',
      };
      this.$api()
        .get('/provisions/', { params })
        .then(({ data }) => {
          this.provisionsList = data.data;
        })
        .finally(() => {
          this.loadingProvisions = false;
        });
    },
    handleClickDelete(row) {
      this.selectedProvision = row;
      this.dialogDeleteProvision = true;
    },
    handleCloseDialogDelete() {
      this.selectedProvision = null;
      this.dialogDeleteProvision = false;
    },
    async changedProvisionType() {
      this.formEdit.provision = null;
      await this.getProvisions();
    },
    async changedProvision(id) {
      this.loadDebitAndCredit = true;
      if (id === 24) {
        await this.getAccountingAccountsForEdit();
      }
    },
    async getAccountingAccountsForDebit(search = '') {
      this.loadDebit = true;
      await this.getAccountingAccountsForEdit(search);
    },
    async getAccountingAccountsForCredit(search = '') {
      this.loadCredit = true;
      await this.getAccountingAccountsForEdit(search);
    },
    getAccountingAccountsForEdit(search) {
      if (this.loadDebit) {
        this.loadingAccountingAccountDebit = true;
      } else if (this.loadCredit) {
        this.loadingAccountingAccountCredit = true;
      } else {
        this.loadingAccountingAccountDebit = true;
        this.loadingAccountingAccountCredit = true;
      }
      const params = {
        search,
        page: 1,
        size: 20,
        isProvision: false,
      };
      this.$api()
        .get('/provisions/all/accounting-accounts', { params })
        .then(({ data }) => {
          if (this.loadDebit) {
            this.accountingAccountDebitList = data.data;
          } else if (this.loadCredit) {
            this.accountingAccountCreditList = data.data;
          } else {
            this.accountingAccountDebitList = data.data;
            this.accountingAccountCreditList = data.data;
          }
        })
        .finally(() => {
          if (this.loadDebit) {
            this.loadingAccountingAccountDebit = false;
          } else if (this.loadCredit) {
            this.loadingAccountingAccountCredit = false;
          } else {
            this.loadingAccountingAccountDebit = false;
            this.loadingAccountingAccountCredit = false;
          }
        });
    },
    handleClickDetail(row) {
      this.selectedProvision = { ...row };
      this.getBuildings();
      this.getProvisions();
      this.formEdit = {
        building: row.building.name,
        provisionType: row.provisionType,
        provision: row.provision.name,
        value: row.provisionValue,
        observation: null,
        debit: row.provision.id === 24 ? `${row.debit.code} - ${row.debit.name}` : '',
        credit: row.provision.id === 24 ? `${row.credit.code} - ${row.credit.name}` : '',
      };
      this.addObservation = false;
      this.dialogDetail = true;
    },
    handleCloseDialogDetail() {
      this.selectedProvision = null;
      this.formEdit = {};
      this.dialogDetail = false;
    },
    handleOpenProvisionar() {
      this.selectedProvision = null;
      this.openProvision = true;
    },
    handleCloseProvision() {
      this.openProvision = false;
      this.selectedProvision = null;
    },
    handleOpenToReverse(row) {
      this.openToReverse = true;
      this.selectedProvision = row;
    },
    handleCloseToReverse() {
      this.openToReverse = false;
      this.selectedProvision = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0 4rem 0 0;
  .el-form {
    margin-top: 1.5rem;
    width: 100%;
    .el-select,
    .el-input {
      width: 100%;
    }
  }
  .container-btns {
    margin-bottom: 3rem;
    margin-top: 1.5rem;
  }
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  color: #4b4b4b;
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #4b4b4b;
}

.container-table {
  margin-top: 2rem;
}

.container-pagination {
  margin-top: 1rem;
}

.col-detail {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}
h1 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}
.container-icon {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & + div {
    margin-left: 1.5rem;
  }
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
</style>
