<template>
  <el-dialog
    width="85%"
    :title="costCenterInfos.name"
    :visible.sync="open"
    :before-close="handleClose"
  >
    <el-table
      v-loading="loadingGetEtriesDetail"
      :data="tableData.resultsPerEmployee"
      stripe
      height="55vh"
    >
      <el-table-column
        property="costcenter.name"
        label="Centro de Custo"
        min-width="200"
        :formatter="formatterCostCenter"
      />
      <el-table-column
        property="employee.badge"
        label="Colaborador"
        min-width="200"
        :formatter="formatterEmployee"
      />
      <el-table-column
        align="right"
        property="values"
        label="Valor / Rateio"
        min-width="150"
        :formatter="formatterValue"
      />
    </el-table>
  </el-dialog>
</template>

<script>
import { Dialog, Table, TableColumn } from 'element-ui';
import numberFormat from '../utils/IntlNumberFormat';

export default {
  name: 'EmployeeDetail',
  components: {
    'el-dialog': Dialog,
    'el-table': Table,
    'el-table-column': TableColumn,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    costCenterInfos: {
      type: Object,
      required: true,
    },
    handleClose: {
      type: Function,
      require: true,
    },
    getFilters: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      loadingGetEtriesDetail: false,
      tableData: [],
    };
  },
  mounted() {
    this.loadEntriesDetailById();
  },
  methods: {
    async loadEntriesDetailById(download) {
      const filters = this.getFilters();

      const params = {
        ...filters,
        accountingAccountId: this.costCenterInfos.id,
        download,
      };

      this.loadingGetEtriesDetail = true;
      params.groupId = params.accountingAccountId;
      const response = await this.$api().get('/accounting-entries/results-per-employee', {
        params,
      });
      this.tableData = response.data;
      this.loadingGetEtriesDetail = false;
    },
    formatterCostCenter(row) {
      const { costcenter } = row;
      return `${costcenter.code} - ${costcenter.name}`;
    },
    formatterEmployee(row) {
      const { employee } = row;
      if (employee) {
        return `${employee.badge} - ${employee.name}`;
      }
      return '';
    },
    formatterValue(row) {
      const { values } = row;
      return `${numberFormat(values[0].value)} (${
        values[0].apportionment ? values[0].apportionment * 100 : 0
      }% )`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
