<template>
  <div>
    <bread-crumb :breadcrumbs="['Configurações', 'Projeto']" />
    <h2>Configurações - Projeto</h2>
    <guard :needed-permissions="[PERMISSIONS_PROJETO.DOWNLOAD]">
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="3"
          :xl="3"
        >
          <el-select
            v-model="searchCostcenter"
            filterable
            placeholder="Centros de Custo"
            autocomplete
            clearable
            remote
            class="inputs-full-width"
            loading-text="Aguarde..."
            :remote-method="getCostcenters"
            :loading="loadingGetOption"
          >
            <el-option
              v-for="costcenter in costcenters"
              :key="costcenter.code"
              :label="`${costcenter.code} - ${costcenter.name}`"
              :value="costcenter.code"
            />
          </el-select>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="3"
          :xl="3"
        >
          <el-select
            v-model="searchProjectType"
            filterable
            placeholder="Tipo do Projeto"
            clearable
            remote
            class="inputs-full-width"
            loading-text="Aguarde..."
          >
            <el-option
              v-for="projectType in options.projectTypes"
              :key="projectType.id"
              :label="projectType.name"
              :value="projectType.id"
            />
          </el-select>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="3"
          :xl="3"
        >
          <el-select
            v-model="organizationUnit"
            placeholder="Unidade Organizacional"
            autocomplete
            filterable
            clearable
            remote
            :remote-method="getOrganizationUnits"
            value-key="id"
            loading-text="Aguarde..."
            class="inputs-full-width"
            :loading="loadingOrganizationUnits"
          >
            <el-option
              v-for="item in organizationUnitsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="3"
          :xl="3"
        >
          <el-select
            v-model="businessUnit"
            placeholder="BU"
            autocomplete
            filterable
            clearable
            remote
            :remote-method="getBusinessUnits"
            value-key="id"
            loading-text="Aguarde..."
            class="inputs-full-width"
            :loading="loadingBusinessUnits"
          >
            <el-option
              v-for="item in businessUnitList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="3"
          :xl="3"
        >
          <el-select
            v-model="cell"
            placeholder="Célula"
            autocomplete
            filterable
            clearable
            remote
            :remote-method="getCells"
            value-key="id"
            loading-text="Aguarde..."
            class="inputs-full-width"
            :loading="loadingCells"
          >
            <el-option
              v-for="item in cellList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="4"
          :xl="3"
        >
          <el-select
            v-model="parameterized"
            clearable
            filterable
            placeholder="Tipo"
            class="inputs-full-width"
          >
            <el-option
              :value="true"
              label="Parametrizado"
            />
            <el-option
              :value="false"
              label="Não parametrizado"
            />
          </el-select>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="5"
          :xl="6"
        >
          <el-row
            type="flex"
            justify="end"
            class="container-btns gap-1"
          >
            <el-button
              type="primary"
              :disabled="loadingConsult"
              size="small"
              @click="getTableData(
                parameterized,
                searchCostcenter,
                1,
                searchProjectType,
                organizationUnit,
                businessUnit,
                cell,
              )"
            >
              CONSULTAR
            </el-button>
            <el-button
              type="secundary"
              :disabled="loading"
              size="small"
              @click="download"
            >
              DOWNLOAD
            </el-button>
          </el-row>
        </el-col>
      </el-row>
      <template v-slot:denied>
        <h3>Sem permissao</h3>
      </template>
    </guard>
    <el-table
      v-loading="loading"
      :data="tableData.results"
      stripe
      class="table"
    >
      <el-table-column
        width="150"
        property="code"
        fixed="left"
        label="Centro de Custo"
      >
        <template slot-scope="slotData">
          <div v-if="!slotData.row.status">
            <el-tooltip
              class="item"
              effect="light"
              placement="right-start"
            >
              <div slot="content">
                Centro de custo<br>precisa ser<br>parametrizado
              </div>
              <div>
                <span>{{ slotData.row.code }}</span>
                <i
                  style="color: #DC3545;"
                  class="el-icon-warning ml-2"
                />
              </div>
            </el-tooltip>
          </div>
          <div v-if="slotData.row.status">
            <span>{{ slotData.row.code }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="150"
        property="branch.name"
        label="Regional"
      />
      <el-table-column
        width="150"
        property="organizationUnit"
        :formatter="renderer"
        label="Unidade Organizacional"
      />
      <el-table-column
        width="150"
        property="businessUnit"
        :formatter="renderer"
        label="BU"
      />
      <el-table-column
        width="150"
        property="cell"
        :formatter="renderer"
        label="Célula"
      />
      <el-table-column
        width="150"
        property="projectType"
        :formatter="renderer"
        label="Tipo do CC"
      />
      <el-table-column
        width="150"
        property="projectSubtype"
        :formatter="renderer"
        label="Subtipo do CC"
      />
      <el-table-column
        width="150"
        property="projectFundingLine"
        :formatter="renderer"
        label="Linha de fomento do projeto"
      />
      <el-table-column
        width="150"
        property="projectResourceSource"
        :formatter="renderer"
        label="Fonte de recurso"
      />
      <el-table-column
        width="150"
        property="client"
        :formatter="renderer"
        label="Cliente"
      />
      <el-table-column
        width="150"
        property="area"
        :formatter="renderer"
        label="Área"
      />
      <el-table-column
        width="150"
        property="paysCorrelate"
        label="Paga correlato"
      />
      <el-table-column
        width="150"
        property="releasedType"
        label="Tipo de lançamento"
      />
      <el-table-column
        width="150"
        property="consolidatedCode"
        :formatter="renderer"
        label="CC Consolidado"
      />
      <el-table-column
        width="150"
        property="status"
        label="Status"
      />
      <el-table-column
        prop="acoes"
        label="Ação"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <guard
            :needed-permissions="[PERMISSIONS_PROJETO.UPDATE]"
            show-tooltip
          >
            <div
              class="actions"
              @click="editProject(scope.row)"
            >
              <img
                :src="editIcon"
                alt="edit"
                height="18"
                width="18"
              >
            </div>
            <template v-slot:denied>
              <div class="actions">
                <img
                  :src="editIcon"
                  alt="edit"
                  height="18"
                  width="18"
                >
              </div>
            </template>
          </guard>
        </template>
      </el-table-column>
    </el-table>
    <el-row
      type="flex"
      justify="center"
      class="container-pagination"
    >
      <el-pagination
        small
        background
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="tableData.lastPage"
        :current-page="tableData.page"
        :page-count="tableData.lastPage"
        @current-change="((page) => getTableData(
          parameterized,
          searchCostcenter,
          page,
          searchProjectType,
          organizationUnit,
          businessUnit,
          cell,
        ))"
      />
    </el-row>
    <guard
      :needed-permissions="[PERMISSIONS_PROJETO.UPDATE]"
      show-tooltip
    >
      <el-dialog
        v-if="showModal"
        width="30%"
        class="modal"
        title="Editar Centro de Custo"
        :visible.sync="showModal"
      >
        <el-form :model="form">
          <el-form-item label="Centro de custo">
            <el-row :gutter="20">
              <el-col :span="20">
                <el-select
                  v-model="form.code"
                  filterable
                  clearable
                  disabled
                  class="big-select"
                >
                  <el-option
                    :key="form.code"
                    :label="`${form.code}- ${form.name}`"
                    :value="form.code"
                  />
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="Regional">
                <el-select
                  v-model="form.branch"
                  filterable
                  clearable
                  value-key="id"
                >
                  <el-option
                    v-for="branch in options.branches"
                    :key="branch.id"
                    :label="branch.name"
                    :value="branch"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="Unidade Organizacional">
                <el-select
                  v-model="form.organizationUnit"
                  filterable
                  clearable
                  value-key="id"
                  class="small-select"
                  @change="changedOrganizationUnit"
                >
                  <el-option
                    v-for="organizationUnit in options.organizationUnits"
                    :key="organizationUnit.id"
                    :label="organizationUnit.name"
                    :value="organizationUnit"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="BU">
                <el-select
                  v-model="form.businessUnit"
                  filterable
                  clearable
                  value-key="id"
                  :disabled="
                    !form.organizationUnit ||
                      (form?.cell !== null
                  && form?.cell !== ''
                      && (!form.organizationUnit || form.cell))"
                >
                  <el-option
                    v-for="bu in options.bus"
                    :key="bu.id"
                    :label="bu.name"
                    :value="bu"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="Célula">
                <el-select
                  v-model="form.cell"
                  filterable
                  clearable
                  class="small-select"
                  value-key="id"
                  :disabled="
                    !form.organizationUnit ||
                      (form?.businessUnit !== null
                  && form?.businessUnit !== ''
                  && (!form.organizationUnit
                      || form.businessUnit))"
                >
                  <el-option
                    v-for="cell in options.cells"
                    :key="cell.id"
                    :label="cell.name"
                    :value="cell"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="Tipo do CC">
                <el-select
                  v-model="form.projectType"
                  filterable
                  clearable
                  value-key="id"
                  @change="changedProjectType"
                >
                  <el-option
                    v-for="projectType in options.projectTypes"
                    :key="projectType.id"
                    :label="projectType.name"
                    :value="projectType"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="Subtipo do CC">
                <el-select
                  v-model="form.projectSubtype"
                  filterable
                  clearable
                  value-key="id"
                  class="small-select"
                  @change="changedPst"
                >
                  <el-option
                    v-for="projectSubtype in options.projectSubtypes"
                    :key="projectSubtype.id"
                    :label="projectSubtype.name"
                    :value="projectSubtype"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="Linha de fomento">
                <el-select
                  v-model="form.projectFundingLine"
                  filterable
                  clearable
                  value-key="id"
                  @change="changedPfl"
                >
                  <el-option
                    v-for="projectFundingLine in options.projectFundingLines"
                    :key="projectFundingLine.id"
                    :label="projectFundingLine.name"
                    :value="projectFundingLine"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="Subtipo de Fomento">
                <el-select
                  v-model="form.projectSubFundingLine"
                  filterable
                  clearable
                  class="small-select"
                  value-key="id"
                >
                  <el-option
                    v-for="projectSubFundingLine in options.projectSubFundingLines"
                    :key="projectSubFundingLine.id"
                    :label="projectSubFundingLine.name"
                    :value="projectSubFundingLine"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="Fonte de recurso">
                <el-select
                  v-model="selectedProjectResourceSource"
                  filterable
                  clearable
                  value-key="id"
                >
                  <el-option
                    v-for="projectResourceSource in options.projectResourceSources"
                    :key="projectResourceSource.id"
                    :label="projectResourceSource.name"
                    :value="projectResourceSource"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="Cliente">
                <el-select
                  v-model="form.client"
                  filterable
                  clearable
                  remote
                  autocomplete
                  placeholder="Cliente"
                  loading-text="Aguarde..."
                  :remote-method="getClients"
                  :loading="loadingClients"
                  value-key="id"
                  class="small-select"
                >
                  <el-option
                    v-for="client in options.clients"
                    :key="client.id"
                    :label="client.name"
                    :value="client"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <label>CC Grupo</label>
              <br><br>
              <el-form-item class="small-select">
                <el-select
                  v-model="form.mainCode"
                  filterable
                  clearable
                  value-key="id"
                >
                  <el-option
                    v-for="group in options.ccGroups"
                    :key="group.id"
                    :label="group.name"
                    :value="group"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="Área">
                <el-select
                  v-model="form.area"
                  filterable
                  clearable
                  class="small-select"
                  value-key="id"
                >
                  <el-option
                    v-for="area in options.areas"
                    :key="area.id"
                    :label="area.name"
                    :value="area"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="Tipo de Lançamento">
                <el-select
                  v-model="form.releasedType"
                  filterable
                  clearable
                >
                  <el-option
                    key="1"
                    label="Analítico"
                    value="ANALITICO"
                  />
                  <el-option
                    key="2"
                    label="Consolidado"
                    value="CONSOLIDADO"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="CC consolidado">
                <el-select
                  v-model="form.consolidatedCode"
                  filterable
                  clearable
                  class="small-select"
                  value-key="id"
                >
                  <el-option
                    v-for="costcenter in costcenters"
                    :key="costcenter.code"
                    :label="`${costcenter.code} - ${costcenter.name}`"
                    :value="costcenter.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <p>Paga correlato</p>
              <br>
              <el-switch
                v-model="form.paysCorrelate"
                :active-text="form.paysCorrelate ? 'Sim' : 'Não'"
                active-color="#13ce66"
              />
            </el-col>
            <el-col :span="9">
              <el-form-item label="Status">
                <el-select
                  v-model="form.status"
                  filterable
                  clearable
                  class="small-select"
                >
                  <el-option
                    key="1"
                    label="Ativo"
                    value="ATIVO"
                  />
                  <el-option
                    key="2"
                    label="Suspenso"
                    value="SUSPENSO"
                  />
                  <el-option
                    key="3"
                    label="Encerrado"
                    value="ENCERRADO"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button>CANCELAR</el-button>
          <el-button
            type="primary"
            @click="updateProject"
          >SALVAR</el-button>
        </span>
      </el-dialog>
    </guard>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';

const editIcon = require('../../public/media/icons/edit.svg');

export default {
  name: 'ConfiguracoesProjeto',
  components: { BreadCrumb, Guard },
  data() {
    return {
      PERMISSIONS_PROJETO: FUNCTIONALITY_IDS.CONFIG.PROJECT,
      editIcon,
      tableData: {
        results: [],
        total: 0,
        size: 10,
        page: 1,
        lastPage: 1,
      },
      loading: false,
      loadingConsult: false,
      showModal: false,
      costcenters: [],
      searchCostcenter: null,
      searchProjectType: null,
      parameterized: false,
      form: {
        name: '',
        code: '',
        projectType: null,
        projectSubtype: null,
        projectFundingLine: null,
        projectSubFundingLine: null,
        projectResourceSource: null,
        client: null,
        ccGroup: null,
        cell: null,
        businessUnit: null,
      },
      selectedProjectResourceSource: null,
      paginationData: {
        currentPage: 1,
        lastPage: 1,
        total: 0,
      },
      options: {
        projectTypes: [],
        projectSubtypes: [],
        projectFundingLines: [],
        projectSubFundingLines: [],
        projectResourceSources: [],
        clients: [],
        areas: [],
        releasedTypes: [],
        branches: [],
        ccGroups: [],
        bus: [],
        cells: [],
        organizationUnits: [],
      },
      loadingClients: false,
      loadingGetOption: false,
      pageCostCenter: 1,

      loadingOrganizationUnits: false,
      organizationUnit: null,
      organizationUnitsList: [],

      loadingBusinessUnits: false,
      businessUnit: null,
      businessUnitList: [],

      loadingCells: false,
      cell: null,
      cellList: [],
    };
  },
  watch: {
    selectedProjectResourceSource(value) {
      this.form.projectResourceSource = value;
    },
  },
  async created() {
    this.loading = true;
    this.loadingConsult = true;
    await this.getTableData(this.parameterized, '', 1, '', null, null, null);
    await this.getCostcenters();
    await this.getCCGroups();
    await this.getOptions();
    await this.getOrganizationUnits();
    await this.getBusinessUnits();
    await this.getCells();
    this.loading = false;
    this.loadingConsult = false;
  },
  methods: {
    getClients(search = '') {
      const params = {
        search,
        page: 1,
        size: 50,
      };

      this.loadingClients = true;

      this.$api()
        .get('/client/', { params })
        .then(({ data }) => {
          this.options.clients = data.data;
        })
        .finally(() => {
          this.loadingClients = false;
        });
    },
    async getTableData(
      parameterized,
      search,
      page,
      projectType,
      organizationUnit,
      businessUnit,
      cell,
    ) {
      const params = {
        page,
        size: this.tableData.size,
        parameterized,
        search,
        allDataOfTheCostcenter: true,
        projectType,
        organizationUnit,
        businessUnit,
        cell,
      };

      await this.$api().get('/costcenter/only', { params })
        .then(({ data }) => {
          this.tableData.results = data?.data || [];
          this.tableData.total = data.total;
          this.tableData.page = data.currentPage;
          this.tableData.lastPage = data.lastPage;
        });
    },
    async getOrganizationUnits(search = '') {
      this.loadingOrganizationUnits = true;

      const params = {
        page: 1,
        size: 25,
        name: search,
      };

      await this.$api().get('/organization-unit', { params })
        .then(({ data }) => {
          this.organizationUnitsList = data.data || [];
        })
        .finally(() => {
          this.loadingOrganizationUnits = false;
        });
    },
    async getBusinessUnits(search = '') {
      this.loadingBusinessUnitList = true;

      const params = {
        page: 1,
        size: 25,
        name: search,
      };

      await this.$api().get('/business-unit', { params })
        .then(({ data }) => {
          this.businessUnitList = data.data || [];
        })
        .finally(() => {
          this.loadingBusinessUnitList = false;
        });
    },
    async getCells(search = '') {
      this.loadingCellList = true;

      const params = {
        page: 1,
        size: 25,
        name: search,
      };

      await this.$api().get('/cell', { params })
        .then(({ data }) => {
          this.cellList = data?.data || [];
        })
        .finally(() => {
          this.loadingCellList = false;
        });
    },
    async updateProject() {
      await this.$api()
        .put(`/costcenter/project/${this.form.id}`, this.form)
        .then(async () => {
          this.showModal = false;
          this.$notify.success({
            title: 'Alerta',
            message: 'Alterações salvas',
            duration: 3000,
          });
          this.loading = true;
          await this.getTableData(this.parameterized, '', 1, '', null, null, null);
          this.loading = false;
        });
    },
    async editProject(row) {
      this.form = { ...row, consolidatedCode: row?.consolidatedCode?.id };
      if (this.form?.organizationUnit) {
        await this.getBuAndCells(this.form?.organizationUnit?.id);
      }
      if (this.form?.client) {
        await this.getOptions();
      }
      this.showModal = true;
    },
    async getCostcenters(search = '') {
      this.loadingGetOption = true;

      const params = {
        search,
        page: this.pageCostCenter,
        size: 50,
      };

      await this.$api()
        .get('/costcenter', { params })
        .then(({ data }) => {
          this.costcenters = data.data;
        })
        .finally(() => {
          this.loadingGetOption = false;
        });
    },
    async getCCGroups(search = '') {
      this.loadingGetOption = true;

      const params = { search, page: this.pageCostCenter, size: 50 };

      await this.$api()
        .get('/costcenter/cc-groups', { params })
        .then(({ data }) => {
          this.options.ccGroups = data;
        })
        .finally(() => {
          this.loadingGetOption = false;
        });
    },
    async getOptions() {
      const params = {
        page: 1,
        size: 50,
      };
      await this.$api()
        .get('/costcenter/dataInitial', { params })
        .then(({ data }) => {
          this.options.projectTypes = data.projectType;
          this.options.clients = data.client;
          this.options.areas = data.area;
          this.options.organizationUnits = data.organizationUnit;
          this.options.branches = data.branch;
        });
    },
    async findClientOptions(query) {
      if (query !== '') {
        await this.$api()
          .get(`/clients?search=${query}&size=15&page=1`)
          .then(({ data }) => {
            this.options.clients = data.data;
          });
      } else {
        this.options.clients = [];
      }
    },
    renderer(row, column, cellValue) {
      if (cellValue) {
        return cellValue.name;
      }
      return '';
    },
    async download() {
      this.loading = true;
      const params = {
        parameterized: this.parameterized,
        search: this.searchCostcenter,
        projectType: this.searchProjectType,
        organizationUnit: this.organizationUnit,
        businessUnit: this.businessUnit,
        cell: this.cell,
      };

      await this.$api()
        .get('/costcenter/project/projectExcel', { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report.xlsx';
          link.click();
        });
      this.loading = false;
    },
    async getSubtypes(projectTypeId) {
      await this.$api()
        .get(`/costcenter/project/subtypes?projectType=${projectTypeId}`)
        .then(({ data }) => {
          this.options.projectSubtypes = data.projectSubtypes;
          this.options.projectSubFundingLines = data.projectSubFundingLines;
        });
    },
    async getFundingLines(projectSubtypeId) {
      await this.$api()
        .get(`/costcenter/project/projectFundingLines?projectSubtype=${projectSubtypeId}`)
        .then(({ data }) => {
          this.options.projectFundingLines = data;
        });
    },
    async getResourceSources(projectFundingLineId) {
      await this.$api()
        .get(
          `/costcenter/project/projectResourceSources?projectFundingLine=${projectFundingLineId}`,
        )
        .then(({ data }) => {
          this.options.projectResourceSources = data;
        });
    },
    async getBuAndCells(organizationUnit) {
      await this.$api()
        .get(
          `/organization-unit/project/bu/cells?organizationUnit=${organizationUnit}`,
        )
        .then(({ data }) => {
          this.options.bus = data.bus;
          this.options.cells = data.cells;
        });
    },
    eraseSubtype() {
      this.form.projectSubtype = null;
      this.form.projectSubFundingLine = null;
      this.eraseFundingLine();
    },
    eraseFundingLine() {
      this.form.projectFundingLine = null;
      this.eraseResourceSource();
    },
    eraseResourceSource() {
      this.form.projectResourceSource = null;
    },
    eraseArea() {
      if (
        !(
          this.form.projectType
          && (this.form.projectType.name === 'Overhead'
            || this.form.projectType.name === 'Investimento')
        )
      ) {
        delete this.form.area;
      }
    },
    eraseBu() {
      this.form.businessUnit = '';
    },
    eraseCell() {
      this.form.cell = '';
    },
    async changedProjectType(val) {
      if (val?.id) {
        await this.getSubtypes(val.id);
      }
      this.eraseSubtype();
      this.eraseArea();
    },
    async changedOrganizationUnit(val) {
      if (val?.id) {
        await this.getBuAndCells(val.id);
      }
      this.eraseBu();
      this.eraseCell();
    },
    async changedPst(val) {
      if (val?.id) {
        await this.getFundingLines(val?.id);
      }
      this.eraseFundingLine();
    },
    async changedPfl(val) {
      this.eraseResourceSource();
      if (val?.id) {
        await this.getResourceSources(val?.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hover {
  cursor: pointer;
}

h2 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 2rem;
}

  .item {
    margin: 4px;
  }

.table {
  position: relative;
  top: 45px;
}
.button-register {
  position: relative;
  left: 100%;
}

.container-pagination {
  position: relative;
  top: 60px;
  margin-top: 1rem;
}

.status-field {
  position: relative;
  top: 5px;
}

.big-select {
  width: 121% !important;
}
.small-select {
  width: 125% !important;
}
.actions {
  img {
    cursor: pointer;
  }
}

.inputs-full-width {
  width: 100% !important;
}

.ml-2 {
  margin-left: 10px;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
</style>
