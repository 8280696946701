<template>
  <div>
    <bread-crumb :breadcrumbs="['Perfil', 'Mapa de Perfis']" />
    <el-row
      type="flex"
      justify="space-between"
      align="center"
      class="container-page-top"
    >
      <h1>Perfil</h1>
      <el-row
        :gutter="20"
        type="flex"
        justify="end"
        align="center"
        class="gap-1"
      >
        <el-button
          size="small"
          @click="handleBack"
        >
          Voltar
        </el-button>
        <guard :needed-permissions="[PERMISSIONS.PROFILE.FULL]">
          <el-button
            type="primary"
            size="small"
            :loading="loadingUpdateProfile"
            :disabled="loadingUpdateProfile"
            @click="updateProfile"
          >
            Salvar Alterações
          </el-button>
        </guard>
      </el-row>
    </el-row>
    <guard :needed-permissions="[PERMISSIONS.PROFILE.FULL]">
      <el-row :gutter="20">
        <el-table
          v-loading="loadingProfile"
          :data="tableData"
          style="width: 100%"
          :height="tableHight"
          :span-method="spanMethod"
        >
          <el-table-column
            label="Módulo"
            prop="module.name"
            min-width="130"
            fixed
          />
          <el-table-column
            label="Item"
            prop="item.name"
            min-width="150"
          />
          <el-table-column
            label="Sub-Item"
            prop="subItem.name"
            min-width="200"
          />
          <el-table-column
            label="Funcionalidade"
            prop="name"
            min-width="200"
          />
          <el-table-column
            v-for="(perfil, index) in perfis"
            :key="index"
            align="center"
            min-width="130"
            :prop="perfil.prop"
            :label="perfil.label"
          >
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.profiles[index].isActive" />
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <template v-slot:denied>
        <p>Sem acesso</p>
      </template>
    </guard>
  </div>
</template>

<script>
import PERMISSIONS from '../constants/auth/permissions';
import Guard from '../components/Guard/Guard.vue';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import { getLoggedUserStorage } from '../storage/modules/auth';

export default {
  components: {
    guard: Guard,
    BreadCrumb,
  },
  data() {
    return {
      tableData: [],
      perfis: [],
      loadingProfile: false,
      loadingUpdateProfile: false,
      windowWidth: 0,
      tableHight: '75vh',
      PERMISSIONS,
    };
  },
  watch: {
    windowWidth(width) {
      if (width > 768) {
        this.tableHight = '65vh';
      } else if (width < 769) {
        this.tableHight = '90vh';
      } else {
        this.tableHight = '75vh';
      }
    },
  },
  mounted() {
    const { userLogged } = getLoggedUserStorage();
    const permissions = userLogged?.functionalityIds || [];
    if (permissions.includes(this.PERMISSIONS.PROFILE.FULL)) {
      this.getConfigProfiel();
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });
  },
  methods: {
    handleBack() {
      this.$router.back();
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    updateProfile() {
      this.loadingUpdateProfile = true;
      this.$api()
        .put('/profile/permissions', this.tableData)
        .then(() => {
          this.$notify({
            title: 'Sucesso!',
            message: 'Alteração realizada!',
            type: 'success',
          });
          this.getConfigProfiel();
        })
        .catch(() => {
          this.$notify({
            title: 'Erro!',
            message: 'Erro ao tentar realizar alteração',
            type: 'warning',
          });
        })
        .finally(() => {
          this.loadingUpdateProfile = false;
        });
    },
    getConfigProfiel() {
      this.loadingProfile = true;
      this.$api()
        .get('/profile')
        .then(({ data }) => {
          if (data?.length > 0) {
            this.perfis = data[0].profiles.map((p) => ({ prop: 'isActive', label: p.name }));
            this.tableData = data;
          } else {
            this.perfis = [];
            this.tableData = [];
          }
        })
        .finally(() => {
          this.loadingProfile = false;
        });
    },
    spanMethod(infoTable) {
      const {
        row, columnIndex,
      } = infoTable;

      let span = {
        rowspan: 1,
        colspan: 1,
      };

      if (!row.item && row.subItem && columnIndex === 0) {
        span = {
          rowspan: 1,
          colspan: 1,
        };
      }

      if (!row.item && !row.subItem && columnIndex === 0) {
        span = {
          rowspan: 1,
          colspan: 3,
        };
      }

      if (!row.item && !row.subItem && (columnIndex === 1 || columnIndex === 2)) {
        span = {
          rowspan: 0,
          colspan: 0,
        };
      }

      return span;
    },
  },
};
</script>

<style lang="scss" scoped>
  .container-page-top {
    margin-bottom: 30px;
  }
</style>
