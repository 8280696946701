import FUNCTIONALITY_IDS from '../../../constants/auth/permissions';

const APPORTIONMENTS_HR = FUNCTIONALITY_IDS.APPORTIONMENT.HR;
const APPORTIONMENTS_CORRELATES = FUNCTIONALITY_IDS.APPORTIONMENT.CORRELATES;
const CLOSING_ACCOUNTING = FUNCTIONALITY_IDS.CLOSING.ACCOUNTING;
const CLOSING_ENTRIES_AND_ADJUSTS = FUNCTIONALITY_IDS.CLOSING.ENTRIES_AND_ADJUSTS;
const CLOSING_REPORTS = FUNCTIONALITY_IDS.CLOSING.REPORTS;
const CONFIG_BUILDING = FUNCTIONALITY_IDS.CONFIG.BUILDING;
const CONFIG_PROJECT = FUNCTIONALITY_IDS.CONFIG.PROJECT;
const CONFIG_ASSOCIATION = FUNCTIONALITY_IDS.CONFIG.ASSOCIATION;
const CONFIG_ACCOUNTING_ACCOUNT = FUNCTIONALITY_IDS.CONFIG.ACCOUNTING_ACCOUNT;
const CONFIG_EVENT = FUNCTIONALITY_IDS.CONFIG.EVENT;
const CONFIG_PROFILE = FUNCTIONALITY_IDS.PROFILE;
const CONFIG_GRATIFICATION = FUNCTIONALITY_IDS.CONFIG.GRATIFICATION;
const CONFIG_PROJECT_STRUCTURE = FUNCTIONALITY_IDS.CONFIG.PROJECT_STRUCTURE;

const PERMISSIONS_IN_MENU = {
  IMPORTATION: [
    APPORTIONMENTS_HR.IMPORTATION.IMPORT,
    APPORTIONMENTS_HR.IMPORTATION.DOWNLOAD,
  ],
  CONFERENCE: [
    APPORTIONMENTS_HR.CONFERENCE.CHECK,
    APPORTIONMENTS_HR.CONFERENCE.DETAILS,
    APPORTIONMENTS_HR.CONFERENCE.DOWNLOAD,
  ],
  GRATIFICATION: [
    APPORTIONMENTS_HR.GRATIFICATION.CREATE,
    APPORTIONMENTS_HR.GRATIFICATION.DOWNLOAD,
    APPORTIONMENTS_HR.GRATIFICATION.QUERY,
    APPORTIONMENTS_HR.GRATIFICATION.REMOVE,
    APPORTIONMENTS_HR.GRATIFICATION.UPDATE,
  ],
  AWARDS: [
    APPORTIONMENTS_HR.AWARDS.DOWNLOAD,
    APPORTIONMENTS_HR.AWARDS.QUERY,
    APPORTIONMENTS_HR.AWARDS.AWARD,
  ],
  APPORTIONMENT: [
    APPORTIONMENTS_CORRELATES.ENTRY.QUERY,
    APPORTIONMENTS_CORRELATES.ENTRY.DOWNLOAD,
    APPORTIONMENTS_CORRELATES.ENTRY.QUERY,
    APPORTIONMENTS_CORRELATES.ENTRY.UPDATE,
  ],
  HEADCOUNT: [
    APPORTIONMENTS_CORRELATES.HEADCOUNT.DOWNLOAD,
    APPORTIONMENTS_CORRELATES.HEADCOUNT.QUERY,
    APPORTIONMENTS_CORRELATES.HEADCOUNT.UPDATE,
  ],
  MOVEMENT: [
    APPORTIONMENTS_CORRELATES.MOVEMENT.DOWNLOAD,
    APPORTIONMENTS_CORRELATES.MOVEMENT.EXECUTE_APPORTIONMENT,
    APPORTIONMENTS_CORRELATES.MOVEMENT.QUERY,
  ],
  CLOSING_ACCOUNTING: [
    CLOSING_ACCOUNTING.DETAIL,
    CLOSING_ACCOUNTING.PERIOD_ACTION,
    CLOSING_ACCOUNTING.QUERY,
    CLOSING_ACCOUNTING.SYNC_SYNTHETIC,
  ],
  CLOSING_ENTRIES_AND_ADJUSTS: [
    CLOSING_ENTRIES_AND_ADJUSTS.OPEN_CLOSE,
  ],
  CLOSING_REPORTS: [
    CLOSING_REPORTS.DOWNLOAD,
    CLOSING_REPORTS.QUERY,
  ],
  CONFIG_PROJECT_STRUCTURE: [
    CONFIG_PROJECT_STRUCTURE.FULL,
  ],
  CONFIG_BUILDING: [
    CONFIG_BUILDING.CREATE,
    CONFIG_BUILDING.DOWNLOAD,
    CONFIG_BUILDING.UPDATE,
  ],
  CONFIG_PROJECT: [
    CONFIG_PROJECT.UPDATE,
    CONFIG_PROJECT.DOWNLOAD,
  ],
  CONFIG_ASSOCIATION: [
    CONFIG_ASSOCIATION.QUERY,
    CONFIG_ASSOCIATION.WRITE,
  ],
  CONFIG_ACCOUNTING_ACCOUNT: [
    CONFIG_ACCOUNTING_ACCOUNT.DOWNLOAD,
    CONFIG_ACCOUNTING_ACCOUNT.QUERY,
    CONFIG_ACCOUNTING_ACCOUNT.UPDATE,
  ],
  CONFIG_EVENT: [
    CONFIG_EVENT.DOWNLOAD,
    CONFIG_EVENT.QUERY,
    CONFIG_EVENT.UPDATE,
  ],
  CONFIG_GRATIFICATION: [
    CONFIG_GRATIFICATION.QUERY,
    CONFIG_GRATIFICATION.DOWNLOAD,
    CONFIG_GRATIFICATION.UPDATE,
  ],
  CONFIG_PROFILE: [
    CONFIG_PROFILE.FULL,
  ],
};

export default PERMISSIONS_IN_MENU;
