import PERMISSIONS_IN_MENU from './permissions';

const pathsConsts = {
  PROJECT_STRUCTURE: '/configuracoesEstruturaProjetos',
  PREDIO: '/configuracoesPredio',
  PROJETO: '/configuracoesProjeto',
  ASSOCIAO: '/configuracoesAssociacao',
  CONTA_CONTABIL: '/configuracoesContaContabil',
  EVENTO: '/configuracoesEvento',
  GRATIFICACAO: '/configuracoesGratificacao',
  PERFIS: '/perfil',
};

const submenuSettings = {
  menuHeaderActive: pathsConsts.PROJECT_STRUCTURE,
  allPaths: [
    pathsConsts.PROJECT_STRUCTURE,
    pathsConsts.PREDIO,
    pathsConsts.PROJETO,
    pathsConsts.ASSOCIAO,
    pathsConsts.CONTA_CONTABIL,
    pathsConsts.EVENTO,
    pathsConsts.GRATIFICACAO,
    pathsConsts.PERFIS,
  ],
  items: [
    {
      title: 'Configurações',
      menuItems: [
        {
          path: pathsConsts.PROJECT_STRUCTURE,
          title: 'Estrutura de projetos',
          permissions: PERMISSIONS_IN_MENU.CONFIG_PROJECT_STRUCTURE,
        },
        {
          path: pathsConsts.PREDIO,
          title: 'Prédio',
          permissions: PERMISSIONS_IN_MENU.CONFIG_BUILDING,
        },
        {
          path: pathsConsts.PROJETO,
          title: 'Projeto',
          permissions: PERMISSIONS_IN_MENU.CONFIG_PROJECT,
        },
        {
          path: pathsConsts.ASSOCIAO,
          title: 'Associação',
          permissions: PERMISSIONS_IN_MENU.CONFIG_ASSOCIATION,
        },
        {
          path: pathsConsts.CONTA_CONTABIL,
          title: 'Conta Contábil',
          permissions: PERMISSIONS_IN_MENU.CONFIG_ACCOUNTING_ACCOUNT,
        },
        {
          path: pathsConsts.EVENTO,
          title: 'Evento',
          permissions: PERMISSIONS_IN_MENU.CONFIG_EVENT,
        },
        {
          path: pathsConsts.GRATIFICACAO,
          title: 'Gratificação School',
          permissions: PERMISSIONS_IN_MENU.CONFIG_GRATIFICATION,
        },
        {
          path: pathsConsts.PERFIS,
          title: 'Perfil',
          permissions: PERMISSIONS_IN_MENU.CONFIG_PROFILE,
        },
      ],
    },
  ],
};

export default submenuSettings;
