<template>
  <menu-application>
    <router-view ref="router_view" />
  </menu-application>
</template>
<script>
import MenuApplication from '../components/MenuApplication/MenuApplication.vue';

export default {
  components: { MenuApplication },
};
</script>
<style lang="scss" scoped>
</style>
