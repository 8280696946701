<template>
  <span>
    <slot v-if="render" />
    <el-tooltip
      v-if="showTooltip && !render"
      :content="textTooltip"
      placement="top"
      effect="light"
    >
      <span>
        <slot name="denied" />
      </span>
    </el-tooltip>
    <slot
      v-else-if="!showTooltip && !render"
      name="denied"
    />
  </span>
</template>

<script>
import { Tooltip } from 'element-ui';
import { getLoggedUserStorage } from '../../storage/modules/auth';

import verifyPermission from '../../utils/verifyPermission';

export default {
  name: 'Guard',
  components: {
    'el-tooltip': Tooltip,
  },
  props: {
    neededPermissions: {
      type: Array,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      required: false,
    },
    textTooltip: {
      type: String,
      required: false,
      default: 'Sem Permissão',
    },
  },
  data() {
    return {
      render: false,
    };
  },
  beforeMount() {
    const { userLogged } = getLoggedUserStorage();
    if (userLogged) {
      this.render = verifyPermission(this.neededPermissions, userLogged.functionalityIds || []);
    } else {
      this.render = false;
    }
  },
  methods: {
    verifyPermission,
  },
};
</script>

<style lang="scss" scoped>
</style>
