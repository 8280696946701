import PERMISSIONS_IN_MENU from './permissions';

const pathsConsts = {
  IMPORTACAO: '/rhImportacao',
  RH_CONFERENCIA: '/rhConferencia',
  RH_GRATIFICACAO: '/rh-gratificacao',
  RH_PREMIACAO: '/rh-premiacao',
  LANCAMENTOS: '/correlatos-lancamentos',
  HEADCOUNT: '/correlatosHeadcount',
  MOVIMENTACAO: '/correlatosMovimentacao',
  REGRAS: '/correlatosRegras',
  CONFERENCIA: '/correlatosConferencia',
  GRAT_LANCAMENTOS: '/gratificacaoLancamentos',
  GRAT_CONFERENCIA: '/gratificacaoConferencia',
  GRAT_REGRAS: '/gratificacaoRegras',
  APPROPRIATION: '/apropriacao',
};

const submenuRateio = {
  menuHeaderActive: pathsConsts.IMPORTACAO,
  allPaths: [
    pathsConsts.IMPORTACAO,
    pathsConsts.RH_CONFERENCIA,
    pathsConsts.RH_GRATIFICACAO,
    pathsConsts.RH_PREMIACAO,
    pathsConsts.LANCAMENTOS,
    pathsConsts.HEADCOUNT,
    pathsConsts.MOVIMENTACAO,
    pathsConsts.REGRAS,
    pathsConsts.CONFERENCIA,
    pathsConsts.GRAT_LANCAMENTOS,
    pathsConsts.GRAT_CONFERENCIA,
    pathsConsts.GRAT_REGRAS,
    pathsConsts.APPROPRIATION,
  ],
  items: [
    {
      title: 'RH',
      menuItems: [
        {
          path: pathsConsts.IMPORTACAO,
          title: 'Importação',
          permissions: PERMISSIONS_IN_MENU.IMPORTATION,
        },
        {
          path: pathsConsts.RH_CONFERENCIA,
          title: 'Conferência',
          permissions: PERMISSIONS_IN_MENU.CONFERENCE,
        },
        {
          path: pathsConsts.RH_GRATIFICACAO,
          title: 'Gratificação',
          permissions: PERMISSIONS_IN_MENU.GRATIFICATION,
        },
        {
          path: pathsConsts.RH_PREMIACAO,
          title: 'Premiação',
          permissions: PERMISSIONS_IN_MENU.AWARDS,
        },
      ],
    },
    {
      title: 'Correlatos',
      menuItems: [
        {
          path: pathsConsts.LANCAMENTOS,
          title: 'Lançamentos',
          permissions: PERMISSIONS_IN_MENU.APPORTIONMENT,
        },
        {
          path: pathsConsts.APPROPRIATION,
          title: 'Apropriação',
          permissions: PERMISSIONS_IN_MENU.APPORTIONMENT,
        },
        {
          path: pathsConsts.HEADCOUNT,
          title: 'Headcount',
          permissions: PERMISSIONS_IN_MENU.HEADCOUNT,
        },
        {
          path: pathsConsts.MOVIMENTACAO,
          title: 'Movimentação',
          permissions: PERMISSIONS_IN_MENU.MOVEMENT,
        },
      ],
    },
  ],
};

export default submenuRateio;
