<template>
  <el-row class="container-breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        class="fixed-breadcrumb"
      >
        Sistema Gerencial
      </el-breadcrumb-item>
      <el-breadcrumb-item
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb"
      >
        {{ breadcrumb }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </el-row>
</template>

<script>
import { Row, Breadcrumb, BreadcrumbItem } from 'element-ui';

export default {
  name: 'BreadCrumb',
  components: {
    'el-row': Row,
    'el-breadcrumb': Breadcrumb,
    'el-breadcrumb-item': BreadcrumbItem,
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.container-breadcrumb {
  margin-bottom: 2rem;
}
.fixed-breadcrumb {
  font-weight: 500;
}
</style>
