import PERMISSIONS_IN_MENU from './permissions';

const pathsConsts = {
  CONTABIL: '/fechamentoContabil',
  RELATORIO: '/fechamentoRelatorios',
  LANCAMENTOS_E_AJUSTES: '/fechamentoLancamentosAjustes',
};

const submenuClosure = {
  menuHeaderActive: pathsConsts.CONTABIL,
  allPaths: [
    pathsConsts.CONTABIL,
    pathsConsts.LANCAMENTOS_E_AJUSTES,
    pathsConsts.RELATORIO,
  ],
  items: [
    {
      title: 'Fechamento',
      menuItems: [
        {
          path: pathsConsts.CONTABIL,
          title: 'Contábil',
          permissions: PERMISSIONS_IN_MENU.CLOSING_ACCOUNTING,
        },
        {
          path: pathsConsts.LANCAMENTOS_E_AJUSTES,
          title: 'Lançamentos e Ajustes',
          permissions: PERMISSIONS_IN_MENU.CLOSING_ENTRIES_AND_ADJUSTS,
        },
        {
          path: pathsConsts.RELATORIO,
          title: 'Relatório',
          permissions: PERMISSIONS_IN_MENU.CLOSING_REPORTS,
        },
      ],
    },
  ],
};

export default submenuClosure;
