<template>
  <div class="container">
    <bread-crumb :breadcrumbs="['Rateio', 'RH', 'Gratificação']" />
    <h2>Gratificação</h2>
    <div class="container-form">
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="14"
          :xl="14"
        >
          <el-row
            :gutter="20"
            type="flex"
          >
            <el-form label-position="top">
              <el-col
                :xs="24"
                :sm="12"
                :md="8"
                :lg="8"
                :xl="8"
              >
                <el-form-item label="Centro de custo">
                  <guard
                    :needed-permissions="[PERMISSIONS_GRATIFICATION.QUERY]"
                    show-tooltip
                  >
                    <el-select
                      v-model="costcenterFilter"
                      multiple
                      filterable
                      placeholder="Centros de Custo"
                      autocomplete
                      clearable
                      remote
                      loading-text="Aguarde..."
                      value-key="id"
                      :remote-method="getCostsCenter"
                      :loading="loadingCostsCenter"
                    >
                      <el-option
                        v-for="item in costsCenterList"
                        :key="item.id"
                        :label="`${item.code} - ${item.name}`"
                        :value="item.id"
                      />
                    </el-select>
                    <template v-slot:denied>
                      <el-input
                        placeholder="Centros de Custo"
                        disabled
                      />
                    </template>
                  </guard>
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="12"
                :md="8"
                :lg="8"
                :xl="8"
              >
                <el-form-item label="Colaborador">
                  <guard
                    :needed-permissions="[PERMISSIONS_GRATIFICATION.QUERY]"
                    show-tooltip
                  >
                    <el-select
                      v-model="employeeFilter"
                      filterable
                      placeholder="Colaborador"
                      autocomplete
                      clearable
                      remote
                      loading-text="Aguarde..."
                      value-key="id"
                      :remote-method="getEmployees"
                      popper-append-to-body
                      :loading="loadingEmployees"
                      multiple
                    >
                      <el-option
                        v-for="item in employeesOptions"
                        :key="item.id"
                        :label="`${item.badge} - ${item.name}`"
                        :value="item.id"
                      />
                    </el-select>
                    <template v-slot:denied>
                      <el-input
                        placeholder="Colaborador"
                        disabled
                      />
                    </template>
                  </guard>
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="12"
                :md="8"
                :lg="8"
                :xl="8"
              >
                <el-form-item label="Mês e ano contábil">
                  <guard
                    :needed-permissions="[PERMISSIONS_GRATIFICATION.QUERY]"
                    show-tooltip
                  >
                    <el-date-picker
                      v-model="yearMonthFilter"
                      type="month"
                      placeholder="Mês e ano contábil"
                      format="MMM/yyyy"
                      value-format="yyyy-MM"
                    />
                    <template v-slot:denied>
                      <el-input
                        placeholder="Mês e ano contábil"
                        disabled
                      />
                    </template>
                  </guard>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="10"
          :xl="10"
        >
          <el-row
            :gutter="20"
            type="flex"
            justify="end"
            class="container-btns"
          >
            <guard
              :needed-permissions="[PERMISSIONS_GRATIFICATION.CREATE]"
              show-tooltip
              style="margin-right: 15px;"
            >
              <el-button
                id="nova-gratificacao"
                style="background-color: #f5f5f5; border: 0px"
                size="small"
                @click="handleOpenGratificacaoDialog"
              >
                Nova Gratificação
              </el-button>
              <template v-slot:denied>
                <el-button
                  id="nova-gratificacao"
                  style="background-color: #f5f5f5; border: 0px"
                  size="small"
                  disabled
                >
                  Nova Gratificação
                </el-button>
              </template>
            </guard>
            <guard
              :needed-permissions="[PERMISSIONS_GRATIFICATION.DOWNLOAD]"
              show-tooltip
            >
              <el-button
                type="primary"
                size="small"
                :loading="loadingDownloadReport"
                :disabled="loadingDownloadReport"
                @click="downloadExcel"
              >
                Download
              </el-button>
              <template v-slot:denied>
                <el-button
                  type="primary"
                  size="small"
                  disabled
                >
                  Download
                </el-button>
              </template>
            </guard>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-form>
      <el-dialog
        v-if="selectedGratificacao"
        title="Exclusão"
        :visible.sync="dialogDeleteGratification"
        width="30%"
      >
        <hr>
        <el-form-item>
          <span>
            Deseja excluir a gratificação
            <strong>{{ selectedGratificacao.code }} ?</strong>
          </span>
        </el-form-item>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            type="info"
            @click="handleCloseDialogDelete"
          >
            CANCELAR
          </el-button>
          <el-button
            type="primary"
            :disabled="loadingDelete"
            :loading="loadingDelete"
            @click="deleteGratification"
          >
            CONFIRMAR
          </el-button>
        </span>
      </el-dialog>
    </el-form>
    <guard :needed-permissions="[PERMISSIONS_GRATIFICATION.QUERY]">
      <div class="container-table">
        <el-table
          v-loading="loadingGratifications || loadingDownloadReport"
          :data="tableData.results"
          style="width: 100%"
          stripe
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          />
          <el-table-column
            label="Centro de Custo"
            prop="costcenter"
            :formatter="formatterCostCenter"
          />
          <el-table-column
            property="branch.name"
            label="Regional"
            width="150"
          />
          <el-table-column
            property="employee"
            label="Colaborador"
            show-overflow-tooltip
            width="250"
            :formatter="formatterEmployee"
          />
          <el-table-column
            property="releaseOrigin"
            label="Origem Lançamento"
            width="200"
          />
          <el-table-column
            property="code"
            label="ID Gratificação"
            width="200"
          />
          <el-table-column
            property="value"
            label="Valor"
            width="150"
            :formatter="valorRenderer"
          />
          <el-table-column
            label="Ação"
            width="100"
          >
            <template slot-scope="scope">
              <el-row
                type="flex"
                align="center"
                style="gap: 15px"
              >
                <guard
                  :needed-permissions="[PERMISSIONS_GRATIFICATION.UPDATE]"
                  show-tooltip
                >
                  <div class="container-icon">
                    <el-button
                      id="edit-button-gratificacao-rh"
                      circle
                      size="mini"
                      type="text"
                      @click="handleClickDetail(scope.row)"
                    >
                      <img
                        :src="searchIcon"
                        alt="search"
                        height="20"
                        width="20"
                      >
                    </el-button>
                  </div>
                  <template v-slot:denied>
                    <div class="container-icon">
                      <el-button
                        id="edit-button-gratificacao-rh"
                        disabled
                        circle
                        size="mini"
                        type="text"
                      >
                        <img
                          :src="searchIcon"
                          alt="search"
                          height="20"
                          width="20"
                        >
                      </el-button>
                    </div>
                  </template>
                </guard>
                <guard
                  :needed-permissions="[PERMISSIONS_GRATIFICATION.REMOVE]"
                  show-tooltip
                >
                  <div class="container-icon">
                    <el-button
                      circle
                      size="mini"
                      type="text"
                      @click="handleClickDelete(scope.row)"
                    >
                      <img
                        :src="deleteIcon"
                        alt="search"
                        height="17"
                        width="17"
                      >
                    </el-button>
                  </div>
                  <template v-slot:denied>
                    <div class="container-icon">
                      <el-button
                        disabled
                        circle
                        size="mini"
                        type="text"
                      >
                        <img
                          :src="deleteIcon"
                          alt="search"
                          height="17"
                          width="17"
                        >
                      </el-button>
                    </div>
                  </template>
                </guard>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-row
          type="flex"
          justify="center"
          class="container-pagination"
        >
          <el-pagination
            small
            background
            hide-on-single-page
            layout="prev, pager, next"
            :page-size="tableData.lastPage"
            :current-page="tableData.page"
            :page-count="tableData.lastPage"
            @current-change="getTableData"
          />
        </el-row>
      </div>
      <template v-slot:denied>
        <span>Sem Permissão</span>
      </template>
    </guard>
    <rh-gratificacao-dialog
      v-if="openGratificacaoDialog"
      :open="openGratificacaoDialog"
      :on-close="handleCloseGratificacaoDialog"
      :selected-gratificacao="selectedGratificacao"
      :create-gratification="createGratification"
      :update-gratification="updateGratification"
      :is-edit="isEdit"
    />
  </div>
</template>

<script>
import {
  Row,
  Col,
  Table,
  TableColumn,
  Button,
  Form,
  FormItem,
  DatePicker,
  Select,
  Option,
} from 'element-ui';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import RhGratificacaoDialog from '../components/RhGratificacaoDialog/RhGratificacaoDialog.vue';
import numberFormat from '../utils/IntlNumberFormat';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import Guard from '../components/Guard/Guard.vue';

const searchIcon = require('../../public/media/icons/edit.svg');
const deleteIcon = require('../../public/media/icons/delete.svg');

export default {
  name: 'RhGratificacao',
  components: {
    BreadCrumb,
    RhGratificacaoDialog,
    'el-row': Row,
    'el-col': Col,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-date-picker': DatePicker,
    'el-select': Select,
    'el-option': Option,
    Guard,
  },
  data() {
    return {
      PERMISSIONS_GRATIFICATION: FUNCTIONALITY_IDS.APPORTIONMENT.HR.GRATIFICATION,
      searchIcon,
      deleteIcon,
      costcenterFilter: null,
      employeeFilter: null,
      yearMonthFilter: null,
      loadingCostsCenter: false,
      loadingGratifications: false,
      loadingDelete: false,
      dialogDeleteGratification: false,
      costsCenterList: [],
      isEdit: false,
      tableData: {
        results: [],
        total: 0,
        size: 6,
        page: 1,
        lastPage: 1,
      },
      multipleSelection: [],
      openGratificacaoDialog: false,
      selectedGratificacao: null,
      loadingEmployees: false,
      loadingDownloadReport: false,
      employeesOptions: [],
    };
  },
  watch: {
    costcenterFilter: {
      async handler() {
        await this.getTableData(1);
      },
    },
    employeeFilter: {
      async handler() {
        await this.getTableData(1);
      },
    },
    yearMonthFilter: {
      async handler() {
        await this.getTableData(1);
      },
    },
  },
  async mounted() {
    await this.getCostsCenter();
    await this.getEmployees();
  },
  methods: {
    formatterCostCenter(row, column, cellValue) {
      return `${cellValue.code} - ${cellValue.name}`;
    },
    formatterEmployee(row, column, cellValue) {
      return `${cellValue.badge} - ${cellValue.name}`;
    },
    async getTableData(page) {
      this.loadingGratifications = true;
      const params = {
        page,
        size: this.tableData.size,
        costcenterList: this.costcenterFilter,
        employeesList: this.employeeFilter,
        yearMonth: this.yearMonthFilter,
      };

      await this.$api()
        .get('/gratifications', { params })
        .then(({ data }) => {
          this.tableData.results = data?.data || [];
          this.tableData.total = data.total;
          this.tableData.page = data.currentPage;
          this.tableData.lastPage = data.lastPage;
        }).finally(() => {
          this.loadingGratifications = false;
        });
    },
    handleClickDetail(row) {
      this.selectedGratificacao = row;
      this.isEdit = true;
      this.openGratificacaoDialog = true;
    },
    handleCloseDialogDetail() {
      this.selectedGratificacao = null;
      this.openGratificacaoDialog = false;
    },
    async updateGratification(data) {
      const { gratification, params } = data;
      await this.$api()
        .patch(`/gratifications/${gratification.id}`, gratification, { params })
        .then(() => {
          this.handleCloseDialogDetail();
          this.$notify.success({
            title: 'Alerta',
            message: 'Gratificação alterada com sucesso',
            duration: 3000,
          });
        }).finally(() => {
          this.getTableData(1);
        });
    },
    handleClickDelete(row) {
      this.selectedGratificacao = row;
      this.dialogDeleteGratification = true;
    },
    handleCloseDialogDelete() {
      this.selectedGratificacao = null;
      this.dialogDeleteGratification = false;
    },
    async deleteGratification() {
      this.loadingDelete = true;
      const params = { yearMonth: `${this.selectedGratificacao.year}-${this.selectedGratificacao.month}` };
      await this.$api()
        .delete(`/gratifications/${this.selectedGratificacao.id}`, { params })
        .then(() => {
          this.handleCloseDialogDelete();
          this.$notify.success({
            title: 'Alerta',
            message: 'Gratificação excluída com sucesso',
            duration: 3000,
          });
        }).finally(() => {
          this.loadingDelete = false;
          this.getTableData(1);
        });
    },
    getCostsCenter(search = '') {
      this.loadingCostsCenter = true;
      const params = { search, page: 1, size: 20 };

      this.$api()
        .get('/costcenter', { params })
        .then((response) => {
          this.costsCenterList = response.data.data;
        })
        .finally(() => {
          this.loadingCostsCenter = false;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleOpenGratificacaoDialog() {
      this.selectedGratificacao = null;
      this.isEdit = false;
      this.openGratificacaoDialog = true;
    },
    handleCloseGratificacaoDialog() {
      this.selectedGratificacao = null;
      this.openGratificacaoDialog = false;
    },
    async createGratification(data) {
      await this.$api()
        .post('/gratifications', data)
        .then(() => {
          this.handleCloseGratificacaoDialog();
          this.$notify.success({
            title: 'Alerta',
            message: 'Nova gratificação criada',
            duration: 3000,
          });
        }).finally(() => {
          this.getTableData(1);
        });
    },
    async getEmployees(search = '') {
      this.loadingEmployees = true;
      const params = { search, page: 1, size: 15 };
      await this.$api()
        .get('/employee/list', { params })
        .then((response) => {
          this.employeesOptions = response.data.data;
        }).finally(() => {
          this.loadingEmployees = false;
        });
    },
    async downloadExcel() {
      const params = {
        costcenterList: this.costcenterFilter,
        employeesList: this.employeeFilter,
        yearMonth: this.yearMonthFilter,
        dataList: this.multipleSelection,
      };
      this.loadingDownloadReport = true;
      await this.$api()
        .get('/gratifications/excel', { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report.xlsx';
          link.click();
        }).finally(() => {
          this.loadingDownloadReport = false;
        });
    },
    getRowKey(row) {
      return row.id;
    },
    valorRenderer(row) {
      return numberFormat(row.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0 4rem 0 0;
  .el-form {
    width: 100%;
    .el-select,
    .el-input {
      width: 100%;
    }
  }
  .container-btns {
    margin-top: 35px;
    .el-button {
      height: 33px;
    }
  }
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
}

.container-form {
  margin-top: 78px;
}

.container-icon {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & + div {
    margin-left: 1.5rem;
  }
}
.container-pagination {
  margin-top: 1rem;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}

@media (max-width: 1024px) {
  .container {
    .container-btns {
      margin-top: 0px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0;
  }
}
</style>
