<template>
  <el-dialog
    title="Reverter"
    width="40%"
    :visible.sync="open"
    :before-close="onClose"
  >
    <hr>
    <div class="container">
      <el-form
        :model="form"
        label-position="top"
      >
        <el-row
          v-for="(row, index) in form.rows"
          :key="index"
          :gutter="20"
        >
          <el-col
            :xs="24"
            :sm="7"
            :md="7"
            :lg="7"
            :xl="7"
          >
            <el-form-item label="Valor da Reversão">
              <el-currency-input
                v-model="form.rows[index].value"
                :disable="form.rows[index].id"
                @input="handleChangeValue"
              />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="7"
            :md="7"
            :lg="7"
            :xl="7"
          >
            <el-form-item label="Saldo">
              <el-currency-input
                v-model="form.balance"
                :disable="true"
              />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="7"
            :md="7"
            :lg="7"
            :xl="7"
          >
            <el-form-item label="Documento">
              <el-input
                v-model="form.rows[index].invoice"
                :disabled="form.rows[index].id"
                @input="handleChangeInvoice($event, index)"
              />
            </el-form-item>
          </el-col>
          <div v-if="!form.rows[index].id">
            <el-col
              :xs="24"
              :sm="12"
              :md="4"
              :lg="3"
              :xl="3"
              style="padding: 27px 15px"
            >
              <el-button @click="deleteReversal(index)">
                <img
                  :src="deleteIcon"
                  alt="delete"
                  height="17"
                  width="17"
                >
              </el-button>
            </el-col>
          </div>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <el-row
              type="flex"
              justify="end"
            >
              <el-button
                type="text"
                icon="el-icon-plus"
                :disabled="form.balance <= 0"
                @click="addReversal"
              >
                Inserir Reversão
              </el-button>
            </el-row>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <el-form-item
              label=""
              style="margin-bottom: 0"
            >
              <el-row
                type="flex"
                justify="end"
              >
                <el-checkbox
                  v-model="form.lowerBalance"
                >
                  Baixar Saldo
                </el-checkbox>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <el-form-item label="Observação">
              <el-input
                v-model="form.observation"
                type="textarea"
                maxlength="200"
                show-word-limit
                rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          align="center"
          justify="end"
        >
          <el-button
            style="background-color: #f5f5f5; border: 0px"
            size="small"
            @click="handleClose"
          >
            Cancelar
          </el-button>
          <el-button
            type="primary"
            size="small"
            :disabled="checkIfExistsReversalsWithoutValues()"
            @click="handleSubmit"
          >
            Salvar
          </el-button>
        </el-row>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {
  Dialog, Form, Row, Col, FormItem, Input, Button, Checkbox,
} from 'element-ui';

import ElCurrencyInput from '../../../../components/ElCurrencyInput/ElCurrencyInput.vue';

const deleteIcon = require('../../../../../public/media/icons/delete.svg');

export default {
  name: 'ToReverseDialog',
  components: {
    'el-dialog': Dialog,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-row': Row,
    'el-col': Col,
    'el-input': Input,
    'el-button': Button,
    'el-checkbox': Checkbox,
    ElCurrencyInput,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    selectedProvision: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleteIcon,
      initialIndexOfNewReversals: this.$props.selectedProvision.provisionReversal.length,
      loadingSubmit: false,
      form: {
        observation: null,
        lowerBalance: false,
        balance: this.$props.selectedProvision.provisionBalance,
        rows: this.$props.selectedProvision.provisionReversal.length
          ? this.$props.selectedProvision.provisionReversal
          : [
            {
              value: null,
              invoice: null,
              provisionEntry: this.$props.selectedProvision.id,
            },
          ],
      },
    };
  },
  methods: {
    handleSubmit() {
      const yearMonth = this.selectedProvision.date.split('/');
      const params = {
        lowerBalance: this.form.lowerBalance,
        observation: this.form.observation,
        provisionReversal: this.form.rows,
        yearMonth: `${yearMonth[2]}-${yearMonth[1]}`,
      };

      this.loadingSubmit = true;

      this.$api()
        .post(`/provisions/${this.$props.selectedProvision.id}/create/reversal`, params)
        .then(() => {
          this.$notify({
            title: 'Reversão',
            message: 'Reversão cadastrada com sucesso!',
            type: 'success',
          });
        })
        .finally(() => {
          this.handleClose();
          this.loadingSubmit = false;
        });
    },
    handleClose() {
      this.onClose();
      this.form = {
        observation: null,
        lowerBalance: false,
        balance: this.$props.selectedProvision.provisionBalance,
        rows: this.$props.selectedProvision.provisionReversal.length
          ? this.$props.selectedProvision.provisionReversal
          : [
            {
              value: null,
              invoice: null,
              provisionEntry: this.$props.selectedProvision.id,
            },
          ],
      };
    },
    handleChangeInvoice(value, index) {
      this.form.rows[index].invoice = value.replace(/[^0-9.]/g, '');
    },
    handleChangeValue() {
      this.form.balance = this.$props.selectedProvision.provisionBalance;
      this.form.balance -= this.$props.selectedProvision.reversalValue;
      let totalNewReversals = 0;
      for (let index = this.initialIndexOfNewReversals; index < this.form.rows.length; index += 1) {
        totalNewReversals += this.form.rows[index].value;
      }
      this.form.balance -= totalNewReversals;
      if (this.form.balance < 0) {
        this.form.balance = 0;
      }
    },
    addReversal() {
      const lastRow = this.form.rows[this.form.rows.length - 1];

      if (!lastRow.value) {
        this.$notify({
          title: 'Incluir reversão',
          message: 'Insira um valor para a reversão, antes de tentar incluir outra.',
          type: 'warning',
        });
        return;
      }

      this.form.rows.push({
        value: null,
        invoice: null,
        provisionEntry: this.$props.selectedProvision.id,
      });
    },
    checkIfExistsReversalsWithoutValues() {
      let totalReversals = 0;
      let numberOfReversalsGreaterThanBalance = 0;
      this.form.rows.forEach((element) => {
        totalReversals += element.value;
        if (totalReversals > this.$props.selectedProvision.provisionBalance) {
          numberOfReversalsGreaterThanBalance += 1;
        }
      });
      const rule = this.$props.selectedProvision.provisionBalance === 0
        || numberOfReversalsGreaterThanBalance > 1
        || this.form.rows.every(
          (item) => item.value === null || item.value === '' || item.value === 0,
        );
      return rule;
    },
    deleteReversal(index) {
      this.form.rows.pop(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .el-form {
    width: 100%;
    .el-select,
    .el-input {
      width: 100%;
    }
  }
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
</style>
