<template>
  <div>
    <bread-crumb :breadcrumbs="['Rateio', 'RH', 'Premiação']" />
    <el-row>
      <el-col :span="18">
        <h1>Premiação</h1>
        <br>
      </el-col>
    </el-row>
    <el-button-group style="margin-bottom: 30px">
      <el-button
        id="button-sintetic"
        class="button-group"
        :class="typeSelection === false ? 'active-button' : 'inactive-button'"
        @click="typeSelection = false"
      >
        Sintético
      </el-button>
      <el-button
        id="button-analitic"
        class="button-group"
        :class="typeSelection === true ? 'active-button' : 'inactive-button'"
        @click="typeSelection = true"
      >
        Analítico
      </el-button>
    </el-button-group>
    <el-form
      ref="filters"
      label-position="top"
      :model="filters"
    >
      <el-row :gutter="15">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="3"
        >
          <el-form-item
            label="Data de lançamento"
            prop="dateRange"
          >
            <el-date-picker
              id="input-date-premiacao"
              v-model="filters.dateRange"
              type="daterange"
              range-separator="-"
              start-placeholder="Início"
              end-placeholder="Fim"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="3"
        >
          <el-form-item
            label="Centro de custo"
            prop="costcenter"
          >
            <el-select
              id="input-centro-custo"
              v-model="filters.costcenters"
              autocomplete
              filterable
              clearable
              remote
              multiple
              loading-text="Aguarde..."
              placeholder="Centro de custo"
              :disabled="disableCostcenter"
              :remote-method="getCostcenters"
              :loading="loadingCostcenterList"
              value-key="id"
              class="inputs-full-width"
              @change="changedCostcenter"
            >
              <el-option
                v-for="costcenter in costcenters"
                :key="costcenter.code"
                :label="`${costcenter.code} - ${costcenter.name}`"
                :value="costcenter.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="4"
          :xl="3"
        >
          <el-form-item
            label="Unidade de negócio"
            prop="businessUnit"
          >
            <el-select
              id="input-unidade-negocio"
              v-model="filters.businessUnit"
              filterable
              clearable
              :disabled="disableBusinessUnit"
              value-key="id"
              class="inputs-full-width"
              @change="changedBusinessUnit"
            >
              <el-option
                v-for="businessUnit in businessUnities"
                :key="businessUnit.id"
                :label="businessUnit.name"
                :value="businessUnit.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-row class="container-button-submity">
          <guard :needed-permissions="[PERMISSIONS_AWARDS.DOWNLOAD]">
            <el-button
              id="download-button"
              style="margin-left: 10px;"
              class="download-button"
              :disabled="loadingDownload"
              :loading="loadingDownload"
              @click="downloadReport"
            >
              DOWNLOAD
            </el-button>
            <template v-slot:denied>
              <el-button
                id="download-button-hidden"
                class="hidden"
              >
                DOWNLOAD
              </el-button>
            </template>
          </guard>
          <guard :needed-permissions="[PERMISSIONS_AWARDS.QUERY]">
            <el-button
              id="query-button"
              type="primary"
              style="margin-left: 15px;"
              :disabled="loadingList"
              :loading="loadingList"
              @click="getAwards(1)"
            >
              CONSULTAR
            </el-button>
            <template v-slot:denied>
              <el-button
                id="query-button-hidden"
                class="hidden"
              >
                CONSULTAR
              </el-button>
            </template>
          </guard>
          <guard :needed-permissions="[PERMISSIONS_AWARDS.AWARD]">
            <el-button
              id="award-button"
              type="secundary"
              style="background-color: #f5f5f5; margin-left: 15px; border: 0px;"
              @click="handleOpenAward"
            >
              PREMIAR
            </el-button>
            <template v-slot:denied>
              <el-button
                id="award-button-hidden"
                class="hidden"
              >
                PREMIAR
              </el-button>
            </template>
          </guard>
        </el-row>
      </el-row>
    </el-form>
    <div v-if="typeSelection">
      <div class="container-table">
        <el-table
          id="table-premiacao"
          v-loading="loadingList"
          :data="tableData.results"
          style="width: 100%"
          stripe
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          />
          <el-table-column
            label="Data de lançamento"
            prop="date"
            :formatter="formatterDate"
          />
          <el-table-column
            label="Chapa"
            property="employee.badge"
          />
          <el-table-column
            label="Colaborador"
            prop="employee"
            :formatter="formatterEmployee"
          />
          <el-table-column
            label="Centro de Custo"
            prop="costcenter"
            :formatter="formatterCostcenter"
          />
          <el-table-column
            label="Valor"
            property="value"
            :formatter="valorRenderer"
          />
        </el-table>
      </div>
    </div>
    <div v-else>
      <div class="container-table">
        <el-table
          v-loading="loadingList"
          :data="tableData.results"
          style="width: 100%"
          stripe
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          />
          <el-table-column
            label="Unidade de negócio"
            prop="businessUnit"
          />
          <el-table-column
            label="Valor"
            property="value"
            :formatter="valorRenderer"
          />
        </el-table>
      </div>
    </div>
    <el-row
      type="flex"
      justify="center"
      class="container-pagination"
    >
      <el-pagination
        small
        background
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="tableData.lastPage"
        :current-page="tableData.page"
        :page-count="tableData.lastPage"
        @current-change="getAwards"
      />
    </el-row>

    <rh-premiacao-dialog
      v-if="showModal"
      id="premiacao-dialog"
      :handle-close="handleClose"
      :show-modal="showModal"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { Row } from 'element-ui';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import numberFormat from '../utils/IntlNumberFormat';
import Guard from '../components/Guard/Guard.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import { AWARD_ENTRY_TYPES } from '../constants/awards/award-entry-types';
import RhPremiacaoDialog from '../components/RhPremiacaoDialog/RhPremiacaoDialog.vue';

export default {
  name: 'RhPremiacao',
  components: {
    BreadCrumb,
    Guard,
    'el-row': Row,
    RhPremiacaoDialog,
  },
  data() {
    return {
      PERMISSIONS_AWARDS: FUNCTIONALITY_IDS.APPORTIONMENT.HR.AWARDS,
      typeSelection: false,
      disableCostcenter: false,
      disableBusinessUnit: false,
      loadingCostcenterList: false,
      loadingDownload: false,
      loadingBusinessUnitiesList: false,
      loadingList: false,
      loadingRegister: false,
      loadingEmployees: false,
      loadingCostsCenter: false,
      loadingCostsCenterRegister: false,
      lastQueryParameters: null,
      showModal: false,
      fullscreen: false,
      filters: {
        dateRange: null,
        costcenters: [],
        businessUnit: null,
      },
      tableData: {
        results: [],
        total: 0,
        size: 100,
        page: 1,
        lastPage: 1,
      },
      awardEvents: [
        AWARD_ENTRY_TYPES.MANAGER_AWARD_DIFFERENCE,
        AWARD_ENTRY_TYPES.MANAGER_AWARD,
      ],
      costcenters: [],
      costsCenterListRegister: [],
      businessUnities: [],
      events: [],
      employee: [],
      multipleSelection: [],
      eventsList: [],
      employees: [],
    };
  },

  watch: {
    windowWidth(width) {
      this.fullscreen = Boolean(width < 768);
    },
    typeSelection: {
      handler() {
        this.tableData = {
          results: [],
          total: 0,
          size: 100,
          page: 1,
          lastPage: 1,
        };
        this.multipleSelection = [];
        this.lastQueryParameters = null;
      },
    },
  },

  created() {
    this.getCostcenters();
    this.getBusinessUnities();
  },

  methods: {
    handleClose() {
      this.showModal = false;
    },
    handleOpenAward() {
      this.getEmployees('');
      this.getCostcentersRegister('');
      this.getEvents(this.awardEvents);
      this.showModal = true;
    },
    valorRenderer(row) {
      return numberFormat(row.value);
    },
    getRowKey(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async downloadReport() {
      if (!this.lastQueryParameters) {
        this.$notify({
          title: 'Download do Relatório de Premiação',
          message: 'Não foi possível realizar o download, por favor realize antes uma consulta',
          type: 'warning',
        });
        return;
      }
      const params = JSON.parse(JSON.stringify(this.lastQueryParameters));
      params.dataList = this.typeSelection
        ? this.multipleSelection.filter((item) => !item.businessUnit)
        : this.multipleSelection.filter((item) => item.businessUnit);
      this.loadingDownload = true;
      this.$api()
        .get('/awards/download', { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.id = 'download-file-id';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Premiação - SG Pitang.xlsx';
          link.click();
        })
        .finally(() => {
          this.loadingDownload = false;
        });
    },
    async changedBusinessUnit(val) {
      if (val) {
        this.disableCostcenter = true;
        this.filters.costcenters = [];
      } else {
        this.disableCostcenter = false;
      }
    },
    async changedCostcenter(val) {
      if (val.length) {
        this.disableBusinessUnit = true;
        this.filters.businessUnit = null;
      } else {
        this.disableBusinessUnit = false;
      }
    },
    getCostcenters(search = '') {
      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.loadingCostcenterList = true;

      this.$api()
        .get('/costcenter/only', { params })
        .then(({ data }) => {
          this.costcenters = data.data;
        })
        .finally(() => {
          this.loadingCostcenterList = false;
        });
    },
    getCostcentersRegister(search = '') {
      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.loadingCostsCenterRegister = true;

      this.$api()
        .get('/costcenter/only', { params })
        .then(({ data }) => {
          this.costsCenterListRegister = data.data;
        })
        .finally(() => {
          this.loadingCostsCenterRegister = false;
        });
    },
    getBusinessUnities() {
      this.loadingBusinessUnitiesList = true;

      this.$api()
        .get('/business-unit/list')
        .then((response) => {
          this.businessUnities = response.data;
        })
        .finally(() => {
          this.loadingBusinessUnitiesList = false;
        });
    },
    getAwards(page) {
      const { dateRange, costcenters, businessUnit } = this.filters;

      if (!dateRange || dateRange?.length < 2) {
        this.$notify({
          title: 'Mês / Ano Contábil',
          message: 'Selecione o período de datas',
          type: 'warning',
        });
        return;
      }

      const params = {
        isAnalytic: this.typeSelection,
        startDate: dateRange[0],
        endDate: dateRange[1],
        costcenters,
        businessUnit,
      };

      this.lastQueryParameters = JSON.parse(JSON.stringify(params));

      params.page = page;
      params.size = this.tableData.size;
      this.loadingList = true;

      this.$api()
        .get('/awards/list', { params })
        .then(({ data }) => {
          this.tableData.results = data.data;
          this.tableData.total = Number(data.total);
          this.tableData.page = Number(data.currentPage);
          this.tableData.lastPage = Number(data.lastPage);
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
    getEvents(search = '') {
      const params = { search, page: 1, size: 20 };

      this.$api()
        .get('/entry-type/list', { params })
        .then(({ data }) => {
          this.eventsList = data;
        });
    },
    getEmployees(search = '') {
      this.loadingEmployees = true;
      const params = { search, page: 1, size: 15 };
      this.$api()
        .get('/employee/list', { params })
        .then((response) => {
          this.employees = response.data.data;
        })
        .finally(() => {
          this.loadingEmployees = false;
        });
    },
    formatterCostcenter(row, column, cellValue) {
      if (cellValue) {
        return `${cellValue.code} - ${cellValue.name}`;
      }
      return '';
    },
    formatterEmployee(row, column, cellValue) {
      if (cellValue) {
        return `${cellValue.cpf} - ${cellValue.name}`;
      }
      return '';
    },
    formatterDate(row, column, cellValue) {
      if (cellValue) {
        return DateTime.fromISO(cellValue).toFormat('dd/LL/yyyy');
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>

.el-form {
  width: 100%;
}

.el-select, .el-input, .el-date-editor {
  width: 100% !important;
}

h4 {
  margin-bottom: 15px;
}

  h1 {
    padding: 7px 0px;
    color: #e87305;
    font-weight: 550;
    font-size: 20px;
    line-height: 10px;
    margin-bottom: 30px !important;
  }

  .download-button {
    color: #e87305;
    outline-color: #e87305;
  }

  .button-group {
    height: 32px;
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: $text-color;
  }

  .inactive-button {
    background: #F5F5F5;

    &:hover {
      background: #F5F5F5;
      color: $text-color
    }
  }

  .active-button {
    background: #D9D9D9;

    &:hover {
      background: #D9D9D9;
      color: $text-color
    }
  }

  .inputs-full-width {
    width: 100% !important;
  }

  .container-pagination {
    margin-top: 1rem;
  }
  .container-table {
    margin-top: 50px;
  }

  .container-button-submity {
    height: 100px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 900px) and (min-width: 1024px) {
  .container-button-submity {
    height: auto;
  }

  @media only screen and (max-width: 425px) {
    .container-button-submity {
      height: auto;
    }
  }
}

</style>
