<template>
  <div>
    <bread-crumb :breadcrumbs="['Configurações', ' School']" />
    <el-row>
      <el-col :span="18">
        <h1>Gratificação</h1>
        <br><br>
      </el-col>
    </el-row>
    <el-form
      ref="form"
      label-position="top"
    >
      <el-row
        class="container"
        :gutter="30"
      >
        <el-col
          :xs="30"
          :sm="24"
          :md="10"
          :lg="5"
          :xl="4"
        >
          <el-form-item label="Centro de Custo">
            <el-select
              v-model="formCostcenters"
              style="width: 250px"
              size="small"
              multiple
              filterable
              placeholder="Centros de Custo"
              autocomplete
              clearable
              remote
              loading-text="Aguarde..."
              value-key="id"
              :remote-method="getCostsCenter"
              :loading="loadingCostsCenter"
            >
              <el-option
                v-for="costcenter in costsCenterList"
                :key="costcenter.code"
                :label="`${costcenter.code} - ${costcenter.name}`"
                :value="costcenter.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="10"
          :lg="5"
          :xl="3"
        >
          <el-form-item label="Regional">
            <el-select
              v-model="formBranch"
              size="small"
              clearable
              filterable
              placeholder="Regional"
              remote
              reserve-keyword
              autocomplete
            >
              <el-option
                v-for="branch in branches"
                :key="branch.id"
                :label="branch.name"
                :value="branch.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5"
        >
          <el-form-item>
            <el-row type="flex">
              <el-col>
                <el-button
                  type="primary"
                  size="small"
                  class="download"
                  @click="downloadExcel"
                >
                  DOWNLOAD
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
    >
      <el-table-column
        property="costcenter"
        :formatter="formatCostcenter"
        label="Centro de Custo"
        width="500"
      />
      <el-table-column
        property="branch.name"
        label="Regional"
        width="810"
      />
      <el-table-column label="Ações">
        <template slot-scope="scope">
          <div>
            <img
              :src="editIcon"
              alt="edit"
              height="17"
              width="17"
              style="cursor: pointer"
              @click="startEdition(scope.row)"
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-if="dialogEdit"
      title="Detalhar e editar gratificação"
      :visible.sync="dialogEdit"
      width="40%"
      :close-on-click-modal="false"
    >
      <hr>
      <el-form>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Centro de Custo">
              <span><strong>{{
                formatCostcenter(null, null, gratificationToUpdate?.costcenter)
              }}</strong></span>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="Regional">
              <span><strong>{{
                  gratificationToUpdate?.branch?.name
              }}</strong></span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Folha">
              <el-select
                v-model="gratificationToUpdate.payroll"
                placeholder="Folha"
                size="small"
                filterable
                clearable
                value-key="id"
                remote
                :remote-method="getTypeCosts"
                :loading="loadingAccountingAccountsOption"
              >
                <el-option
                  v-for="accountingAccount in accountingAccountsOptions"
                  :key="accountingAccount.id"
                  :label="`${accountingAccount.code} - ${accountingAccount.name}`"
                  :value="accountingAccount"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Encargo">
              <el-select
                v-model="gratificationToUpdate.charge"
                size="small"
                placeholder="Encargo"
                filterable
                clearable
                value-key="id"
                remote
                :remote-method="getTypeCostsCharges"
                :loading="loadingCharges"
              >
                <el-option
                  v-for="charge in charges"
                  :key="charge.id"
                  :label="`${charge.code} - ${charge.name}`"
                  :value="charge"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="Percentual encargos projetos">
              <el-input-number
                v-model="gratificationToUpdate.projectChargesPercent"
                type="number"
                class="input"
                size="small"
                :min="0"
                :max="100"
                :precision="2"
                :step="1"
                style="width: 190px"
                placeholder="%"
              >
                <template slot="append">
                  %
                </template>
              </el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="Encargo Incentivado">
              <el-select
                v-model="gratificationToUpdate.encouragedCharge"
                size="small"
                placeholder="Encargo Incentivado"
                filterable
                clearable
                value-key="id"
                remote
                :remote-method="getTypeCostsEncouragedCharges"
                :loading="loadingEncouragedCharges"
              >
                <el-option
                  v-for="encouragedCharge in encouragedCharges"
                  :key="encouragedCharge.id"
                  :label="`${encouragedCharge.code} - ${encouragedCharge.name}`"
                  :value="encouragedCharge"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="Percentual projeto Incentivado">
              <el-input-number
                v-model="
                  gratificationToUpdate.encouragedProjectChargesPercent
                "
                type="number"
                class="input"
                size="small"
                :min="0"
                :max="100"
                :precision="2"
                :step="1"
                style="width: 190px"
                placeholder="%"
              >
                <template slot="append">
                  %
                </template>
              </el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="dialogEdit = false"
        >
          Cancelar
        </el-button>
        <el-button
          size="small"
          type="primary"
          :disabled="loadingSave"
          :loading="loadingSave"
          @click="updateGratification"
        >
          Salvar
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';

const editIcon = require('../../public/media/icons/edit.svg');

export default {
  name: 'ConfiguracoesGratificacao',
  components: { BreadCrumb },
  data() {
    return {
      percentageChargesProject: null,
      percentageProjectEncouraged: null,
      dialogEdit: null,
      editIcon,
      costsCenterList: [],
      branches: [],
      loadingCostsCenter: false,
      formCostcenters: [],
      formBranch: [],
      tableData: [],
      gratificationToUpdate: {
        projectChargesPercent: 0,
        encouragedProjectChargesPercent: 0,
        payroll: null,
        charge: null,
        encouragedCharge: null,
      },
      accountingAccountsOptions: [],
      charges: [],
      encouragedCharges: [],
      loadingAccountingAccountsOption: false,
      loadingCharges: false,
      loadingEncouragedCharges: false,
      loadingSave: false,
    };
  },
  watch: {
    formCostcenters: {
      handler(val) {
        if ((val && val.length > 0) || this.formBranch) {
          this.getTableData({
            costCenterList: val,
            branch: this.formBranch,
          });
        } else {
          this.tableData = [];
        }
      },
    },
    formBranch: {
      handler(val) {
        if (val || this.formCostcenters.length > 0) {
          this.getTableData({
            costCenterList: this.formCostcenters,
            branch: val,
          });
        } else {
          this.tableData = [];
        }
      },
    },
  },
  created() {
    this.getTableData({});
    this.getCostsCenter();
    this.getBranches();
  },
  methods: {
    getCostsCenter(search = '') {
      this.loadingCostsCenter = true;

      const params = {
        search,
        page: 1,
        size: 20,
      };

      this.$api()
        .get('/costcenter', { params })
        .then((response) => {
          this.costsCenterList = response.data.data;
        })
        .finally(() => {
          this.loadingCostsCenter = false;
        });
    },
    formatCostcenter(row, _column, costcenter) {
      if (costcenter) {
        return `${costcenter.code} - ${costcenter.name}`;
      }
      return '';
    },
    async getBranches() {
      return this.$api()
        .get('/branch/list')
        .then(({ data }) => {
          this.branches = data.data;
        });
    },
    startEdition(gratification) {
      this.gratificationToUpdate = {
        ...gratification,
        projectChargesPercent:
          (gratification.projectChargesPercent || 0) * 100,
        encouragedProjectChargesPercent:
          (gratification.encouragedProjectChargesPercent || 0) * 100,
      };
      this.getTypeCosts(gratification.payroll.name);
      this.getTypeCostsCharges(gratification.charge.name);
      this.getTypeCostsEncouragedCharges(gratification.encouragedCharge.name);
      this.dialogEdit = true;
    },
    getTableData(form) {
      const params = {
        costcenterList: form.costCenterList,
        branch: form.branch,
      };
      this.$api()
        .get('/gratifications/settings', { params })
        .then(({ data }) => {
          this.tableData = data.data;
        });
    },
    getTypeCosts(query) {
      const params = {
        filter: query,
        size: 30,
      };

      this.loadingAccountingAccountsOption = true;
      this.$api()
        .get('/accounting-account/typeCost', { params })
        .then(({ data }) => {
          this.accountingAccountsOptions = data;
        })
        .finally(() => {
          this.loadingAccountingAccountsOption = false;
        });
    },
    getTypeCostsCharges(query) {
      const params = {
        filter: query,
        size: 30,
      };
      this.loadingCharges = true;
      this.$api()
        .get('/accounting-account/typeCost', { params })
        .then(({ data }) => {
          this.charges = data;
        })
        .finally(() => {
          this.loadingCharges = false;
        });
    },
    getTypeCostsEncouragedCharges(query) {
      const params = {
        filter: query,
        size: 30,
      };
      this.loadingEncouragedCharges = true;
      this.$api()
        .get('/accounting-account/typeCost', { params })
        .then(({ data }) => {
          this.encouragedCharges = data;
        })
        .finally(() => {
          this.loadingEncouragedCharges = false;
        });
    },
    updateGratification() {
      if (
        !this.gratificationToUpdate.payroll
        || !this.gratificationToUpdate.charge
        || !this.gratificationToUpdate.encouragedCharge
        || !this.gratificationToUpdate.projectChargesPercent
        || !this.gratificationToUpdate.encouragedProjectChargesPercent
      ) {
        this.$notify.error({
          title: 'Alerta',
          message: 'Preencha todos os campos',
          duration: 3000,
        });
        return;
      }

      const params = {
        ...this.gratificationToUpdate,
        projectChargesPercent:
          (this.gratificationToUpdate.projectChargesPercent || 0) / 100,
        encouragedProjectChargesPercent:
          (this.gratificationToUpdate.encouragedProjectChargesPercent || 0) / 100,
      };

      this.loadingSave = true;

      this.$api()
        .patch(`/gratifications/settings/${this.gratificationToUpdate.id}`, params)
        .then(() => {
          this.dialogEdit = false;
          this.getTableData({});
          this.$notify.success({
            title: 'Alerta',
            message: 'Gratificação alterada com sucesso',
            duration: 3000,
          });
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    async downloadExcel() {
      const params = {
        costcenterList: this.formCostcenters,
        branch: this.formBranch,
      };

      await this.$api()
        .get('/gratifications/settings/excel', { params, responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/excel' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'report.xlsx';
          link.click();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}

.download {
  position: relative;
  left: 800px;
  top: 50px;
}
hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
</style>
