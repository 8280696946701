<template>
  <div>
    <bread-crumb :breadcrumbs="['Fechamento', 'Contábil']" />
    <el-row>
      <el-col :span="18">
        <h1>Contábil</h1>
        <br><br>
      </el-col>
    </el-row>
    <el-form
      ref="form"
      label-position="top"
      label-width="100%"
      hide-required-asterisk
      :model="formData"
    >
      <guard
        :needed-permissions="[PERMISSIONS_ACCOUNTING.QUERY]"
        show-tooltip
      >
        <el-form-item
          label="Ano Contábil"
          prop="year"
        >
          <el-date-picker
            v-model="formData.year"
            type="year"
            placeholder="Ano Contábil"
            :loading="loadingGetAccountingClosings"
            format="yyyy"
            value-format="yyyy"
            @input="handleChangeValue"
          />
        </el-form-item>
      </guard>
      <el-table
        v-loading="loadingGetAccountingClosings"
        :data="tableData"
        style="width: 100%"
        stripe
      >
        <el-table-column
          property="period"
          label="Mês/Ano"
        />
        <el-table-column
          property="status"
          label="Status"
          :formatter="statusRenderer"
        />
        <el-table-column
          property="date"
          label="Data fechamento"
        />
        <el-table-column
          property="user"
          label="Usuário"
        />
        <el-table-column
          property="analyticSynchronization"
          label="Sincronização analítica"
        />
        <el-table-column
          property="syntheticSynchronization"
          label="Sincronização Sintética"
        />
        <el-table-column label="Ação">
          <template slot-scope="scope">
            <div class="actions">
              <guard
                :needed-permissions="[PERMISSIONS_ACCOUNTING.DETAIL]"
                show-tooltip
              >
                <img
                  :src="searchIcon"
                  alt="search"
                  height="18"
                  width="18"
                  :loading="loadingGetAccountingClosingsHistory"
                  @click="handleClickDetail(scope.row)"
                >
                <template v-slot:denied>
                  <img
                    :src="searchIcon"
                    alt="search"
                    height="18"
                    width="18"
                  >
                </template>
              </guard>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <guard :needed-permissions="[PERMISSIONS_ACCOUNTING.DETAIL]">
      <el-dialog
        title="Detalhe e Sincronização"
        :visible.sync="dialogDetail"
        width="80%"
        :before-close="handleClose"
      >
        <hr>
        <el-table
          v-loading="loadingGetAccountingClosingsHistory"
          :data="tableDataDetail.results"
          style="width: 100%"
          stripe
        >
          <el-table-column
            property="period"
            label="Mês/Ano"
          />
          <el-table-column
            prop="status"
            label="Status"
            :formatter="statusRenderer"
          />
          <el-table-column
            property="date"
            label="Data/Hora fechamento"
            :formatter="(row) => dateRenderer(row.date)"
          />
          <el-table-column
            property="user"
            label="Usuário"
          />
          <el-table-column
            property="analyticSynchronization"
            label="Sincronização analítica"
          />
          <el-table-column
            property="syntheticSynchronization"
            label="Sincronização Sintética"
          />
        </el-table>
        <el-row
          type="flex"
          justify="center"
          class="container-pagination"
        >
          <el-pagination
            small
            background
            hide-on-single-page
            layout="prev, pager, next"
            :page-size="tableDataDetail.lastPage"
            :current-page="tableDataDetail.page"
            :page-count="tableDataDetail.lastPage"
            @current-change="getAccountingClosingsHistory"
          />
        </el-row>
        <el-row
          type="flex"
          align="center"
          justify="end"
          class="gap-1"
        >
          <el-button
            size="small"
            @click="handleClose"
          >
            Cancelar
          </el-button>
          <guard :needed-permissions="[PERMISSIONS_ACCOUNTING.SYNC_SYNTHETIC]">
            <el-button
              :disabled="selectedRow?.status === 'aberto'"
              :loading="loadingSyncSynthetic"
              type="info"
              size="small"
              @click="syncSynthetic"
            >
              Sincronizar sintético
            </el-button>
          </guard>
          <el-button
            type="primary"
            size="small"
            @click="dialogPeriodAction = true"
          >
            Ação período
          </el-button>
        </el-row>
      </el-dialog>
    </guard>

    <guard :needed-permissions="[PERMISSIONS_ACCOUNTING.PERIOD_ACTION]">
      <el-dialog
        :title="selectedRow?.status === 'aberto' ? 'Fechar Período' : 'Reabrir Período'"
        :visible.sync="dialogPeriodAction"
        width="50%"
        :before-close="handleCloseDialogPeriodAction"
      >
        <hr>
        <span>
          Deseja <b>
            <span v-if="selectedRow?.status === 'aberto'">
              fechar
            </span>
            <span v-else>
              reabrir
            </span>
          </b>período contábil <b>{{ selectedRow?.period }}</b> ?
        </span>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            size="small"
            @click="handleCloseDialogPeriodAction"
          >
            Cancelar
          </el-button>
          <el-button
            type="primary"
            :loading="loadingPeriodAction"
            size="small"
            @click="PeriodAction"
          >
            <span v-if="selectedRow?.status === 'aberto'">
              Fechar Período
            </span>
            <span v-else>
              Reabrir Período
            </span>
          </el-button>
        </span>
      </el-dialog>
    </guard>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb.vue';
import FUNCTIONALITY_IDS from '../constants/auth/permissions';
import { getLoggedUserStorage } from '../storage/modules/auth';
import Guard from '../components/Guard/Guard.vue';

const searchIcon = require('../../public/media/icons/search.svg');

export default {
  name: 'FechamentoContabil',
  components: { BreadCrumb, Guard },
  data() {
    return {
      PERMISSIONS_ACCOUNTING: FUNCTIONALITY_IDS.CLOSING.ACCOUNTING,
      dialogDetail: false,
      dialogPeriodAction: false,
      searchIcon,
      tableDataDetail: {
        results: [],
        total: 0,
        size: 6,
        page: 1,
        lastPage: 1,
      },
      tableData: [],
      formData: {
        year: `${new Date().getFullYear()}`,
      },
      currentDate: new Date().getFullYear(),
      loadingGetAccountingClosings: false,
      loadingGetAccountingClosingsHistory: false,
      loadingSyncSynthetic: false,
      loadingPeriodAction: false,
      selectedRow: null,
    };
  },
  mounted() {
    const { userLogged } = getLoggedUserStorage();
    const permissions = userLogged?.functionalityIds || [];
    if (permissions.includes(this.PERMISSIONS_ACCOUNTING.QUERY)) {
      this.getAccountingClosings(this.currentDate);
    }
  },
  methods: {
    dateRenderer(date) {
      return date ? DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm') : null;
    },
    async getAccountingClosings(year = this.formData.year) {
      const params = {
        page: 1,
        year,
      };
      this.loadingGetAccountingClosings = true;
      this.$api()
        .get('/closing/', { params })
        .then(({ data }) => {
          this.tableData = data.data;
        })
        .finally(() => {
          this.loadingGetAccountingClosings = false;
        });
    },
    getAccountingClosingsHistory(page) {
      const params = {
        page,
        size: this.tableDataDetail.size,
      };
      this.loadingGetAccountingClosingsHistory = true;
      this.$api()
        .get(`/closing/history/${this.selectedRow.id}`, { params })
        .then(({ data }) => {
          this.tableDataDetail.results = data?.data || [];
          this.tableDataDetail.total = data.total;
          this.tableDataDetail.page = data.currentPage;
          this.tableDataDetail.lastPage = data.lastPage;
        })
        .finally(() => {
          this.loadingGetAccountingClosingsHistory = false;
        });
    },

    handleClickDetail(row) {
      this.selectedRow = { ...row };
      this.getAccountingClosingsHistory(1);
      this.dialogDetail = true;
    },

    handleClose() {
      this.selectedRow = null;
      this.dialogDetail = false;
      this.dialogPeriodAction = false;
    },

    handleCloseDialogPeriodAction() {
      this.dialogPeriodAction = false;
    },

    async syncSynthetic() {
      this.loadingSyncSynthetic = true;
      await this.$api()
        .post(`/closing/${this.selectedRow.id}/synchronization/synthetic`)
        .then(() => {
          this.$notify.success({
            title: 'Alerta',
            message: 'Sincronização sintética realizada com sucesso',
            duration: 3000,
          });
        })
        .finally(() => {
          this.getAccountingClosingsHistory(1);
          this.getAccountingClosings();
          this.handleClose();
          this.loadingSyncSynthetic = false;
        });
    },

    async PeriodAction() {
      this.loadingPeriodAction = true;
      await this.$api()
        .post(`/closing/${this.selectedRow.id}`)
        .then(() => {
          this.$notify.success({
            title: 'Alerta',
            message: this.selectedRow.status === 'aberto' ? 'Período fechado com sucesso' : 'Período reaberto com sucesso',
            duration: 3000,
          });
        })
        .finally(() => {
          this.getAccountingClosingsHistory(1);
          this.getAccountingClosings();
          this.handleClose();
          this.loadingPeriodAction = false;
        });
    },

    async handleChangeValue(value) {
      if (value) {
        await this.getAccountingClosings();
      } else {
        this.tableData = [];
      }
    },

    statusRenderer(cellValue) {
      const { status } = cellValue;
      if (status === 'fechado') {
        return (
        <div style="color: #DC3545;">
          <b><span>Fechado</span></b>
        </div>
        );
      }
      return (
        <div style="color: #30A64A;">
          <b><span>Aberto</span></b>
        </div>
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 7px 0px;
  color: #e87305;
  font-weight: 550;
  font-size: 20px;
  line-height: 18px;
}

hr {
  margin-top: -25px;
  margin-bottom: 25px;
  background: #e4e7ed;
}
.container-pagination {
  margin-top: 1rem;
}
.actions {
  img {
    cursor: pointer;
  }
}
</style>
